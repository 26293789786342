import React from 'react';
import styled from 'styled-components';
import SanityBlockContent from '../SanityBlockContent';

import { colors } from '../../../constants/style';
import orderNumberBordered from '../Styled/orderNumberBordered';

const OrderedListItemsDiv = styled('div').attrs({ className: 'ordered-item' })`
  font-family: Roboto-Regular, sans-serif;
  display: flex;
  gap: 1rem;
  .ordered-item {
    &__number {
      ${orderNumberBordered}
    }
    &__description {
      margin-top: 1.125rem;
      p {
        color: #4e4d6c;
        font-size: 1rem;
        line-height: 1.75rem;

        strong {
          color: ${colors.titleDark};
        }
      }
    }
  }
`;

const OrderedListItems = ({ arr = [] }) => {
  return arr.map((item, index) => {
    let number = String(index + 1);
    if (number.length < 2) {
      number = `0${number}`;
    }
    return (
      <OrderedListItemsDiv key={index}>
        <div className="ordered-item__number">{number}</div>{' '}
        <SanityBlockContent className="ordered-item__description" blocks={item.description} />
      </OrderedListItemsDiv>
    );
  });
};

export default OrderedListItems;
