import { graphql, useStaticQuery } from 'gatsby'

export const useBlogPostsSettingsQuery = () => {
  const data = useStaticQuery(graphql`
    {
      sanityBlogSettings(language: { eq: "en" }) {
        heroBlogPostLabel

        seo {
          title
          description
          image {
            alt
            asset {
              metadata {
                dimensions {
                  width
                  height
                }
              }
              url
            }
          }
        }

        contactSection {
          header
          buttonLabel
        }
      }
    }
  `)

  return data.sanityBlogSettings
}
