import React from 'react'
import { ArticleCard } from '../../ArticleCard'
import SliderSection from 'components/SliderSection/SliderSection'
import styled from 'styled-components'
import { colors } from '../../../../constants/style'

const YoutubeSectionStyled = styled(SliderSection)`
  background: ${colors.sectionTitleLight};
`

const YoutubeSection = ({ header, description, posts, seeMoreButton }) => {
  if (!posts) {
    return
  }

  return (
    <YoutubeSectionStyled
      header={header}
      description={description}
      items={posts}
      button={seeMoreButton}
      headerPosition='center'
    >
      {posts.map((item, index) => {
        const key = `post-${index}`
        return (
          <ArticleCard
            key={key}
            title={item.title}
            preTitle={item.postCategory}
            featuredImage={item.featuredImage}
            link={item.href}
            isWholeCardClickable
            hasArrow
            imageAspectRatio='16/9'
          />
        )
      })}
    </YoutubeSectionStyled>
  )
}

export default YoutubeSection
