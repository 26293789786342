import React from 'react'

import { SEO } from 'components/Seo'
import Layout from '../features/layout'
import BlockSection from '../components/sections/BlockSection'

const privacyPolicyAsRawSection = [
  {
    _key: '47f938f02dc2',
    _type: 'block',
    children: [
      {
        _key: '47f938f02dc20',
        _type: 'span',
        marks: [],
        text: 'Privacy policy',
      },
    ],
    markDefs: [],
    style: 'h1',
  },
  {
    _key: 'bc74f50f5714',
    _type: 'block',
    children: [
      {
        _key: 'bc74f50f57140',
        _type: 'span',
        marks: [],
        text: '',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '74e5f094bce8',
    _type: 'block',
    children: [
      {
        _key: '74e5f094bce80',
        _type: 'span',
        marks: [],
        text: 'This Privacy policy was published on April 26th, 2018.',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '64b499785d63',
    _type: 'block',
    children: [
      {
        _key: '64b499785d630',
        _type: 'span',
        marks: [],
        text: '',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: 'd569c2cf9f82',
    _type: 'block',
    children: [
      {
        _key: 'd569c2cf9f820',
        _type: 'span',
        marks: [],
        text: 'GDPR compliance',
      },
    ],
    markDefs: [],
    style: 'h3',
  },
  {
    _key: '0b1e0484f907',
    _type: 'block',
    children: [
      {
        _key: '0b1e0484f9070',
        _type: 'span',
        marks: [],
        text: '',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '587e2dc8cc54',
    _type: 'block',
    children: [
      {
        _key: '587e2dc8cc540',
        _type: 'span',
        marks: [],
        text: 'At 10Clouds we are committed to protect and respect your privacy in compliance with EU- General Data Protection Regulation (GDPR) 2016/679, dated April 27th, 2016. This privacy statement explains when and why we collect personal information, how we use it, the conditions under which we may disclose it to others and how we keep it secure. This Privacy Policy applies to the use of our services, products and our sales, but also marketing and client contract fulfillment activities. It also applies to individuals seeking a job at 10Clouds.',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '883be508167c',
    _type: 'block',
    children: [
      {
        _key: '883be508167c0',
        _type: 'span',
        marks: [],
        text: '',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '8434d2a95515',
    _type: 'block',
    children: [
      {
        _key: '8434d2a955150',
        _type: 'span',
        marks: [],
        text: 'About 10Clouds',
      },
    ],
    markDefs: [],
    style: 'h3',
  },
  {
    _key: '7389cc3ce2d8',
    _type: 'block',
    children: [
      {
        _key: '7389cc3ce2d80',
        _type: 'span',
        marks: [],
        text: '',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '3184786b3800',
    _type: 'block',
    children: [
      {
        _key: '3184786b38000',
        _type: 'span',
        marks: [],
        text: '10Clouds is an application development and design firm that specializes in different technologies and offers clients software design and development services. Our company is headquartered in Warsaw, Poland and you can get in touch with us by writing to ',
      },
      {
        _key: '3184786b38001',
        _type: 'span',
        marks: ['fc7237a9ec60'],
        text: 'hello@10clouds.com',
      },
      {
        _key: '3184786b38002',
        _type: 'span',
        marks: [],
        text: '.',
      },
    ],
    markDefs: [
      {
        _key: 'fc7237a9ec60',
        _type: 'link',
        href: 'mailto:hello@10clouds.com',
      },
    ],
    style: 'normal',
  },
  {
    _key: '038cbc16568c',
    _type: 'block',
    children: [
      {
        _key: '038cbc16568c0',
        _type: 'span',
        marks: [],
        text: '',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '3ecc184afb26',
    _type: 'block',
    children: [
      {
        _key: '3ecc184afb260',
        _type: 'span',
        marks: [],
        text: 'When we collect personal data about you',
      },
    ],
    markDefs: [],
    style: 'h3',
  },
  {
    _key: '06086cdbab1d',
    _type: 'block',
    children: [
      {
        _key: '06086cdbab1d0',
        _type: 'span',
        marks: [],
        text: 'When you interact with us in person – through correspondence, by phone, by social media, or through our 10clouds.com (“Site”).',
      },
    ],
    level: 1,
    listItem: 'bullet',
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '8a85794eab29',
    _type: 'block',
    children: [
      {
        _key: '8a85794eab290',
        _type: 'span',
        marks: [],
        text: 'When we get personal information from other legitimate sources, such as third-party data aggregators, 10Clouds marketing partners, public sources or social networks. We only use this data if you have given your consent to them to share your personal data with others.',
      },
    ],
    level: 1,
    listItem: 'bullet',
    markDefs: [],
    style: 'normal',
  },
  {
    _key: 'a3d58766af86',
    _type: 'block',
    children: [
      {
        _key: 'a3d58766af860',
        _type: 'span',
        marks: [],
        text: 'We may collect personal data if it is considered to be of legitimate interest and if this interest is not overridden by your privacy interests. We make sure an assessment is made, with an established mutual interest between you and 10Clouds.',
      },
    ],
    level: 1,
    listItem: 'bullet',
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '5ed5cb5d891a',
    _type: 'block',
    children: [
      {
        _key: '5ed5cb5d891a0',
        _type: 'span',
        marks: [],
        text: 'When you are using our products.',
      },
    ],
    level: 1,
    listItem: 'bullet',
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '25b52a4a5f40',
    _type: 'block',
    children: [
      {
        _key: '25b52a4a5f400',
        _type: 'span',
        marks: [],
        text: '',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '76ac5cfa669f',
    _type: 'block',
    children: [
      {
        _key: '76ac5cfa669f0',
        _type: 'span',
        marks: [],
        text: 'Why we collect and use personal data',
      },
    ],
    markDefs: [],
    style: 'h3',
  },
  {
    _key: '9dc0a2aa8eb0',
    _type: 'block',
    children: [
      {
        _key: '9dc0a2aa8eb00',
        _type: 'span',
        marks: [],
        text: 'We collect and use personal data mainly to perform direct sales, direct marketing, and customer service. We also collect data about partners and persons seeking a job or working in our company.',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '34b86a295f62',
    _type: 'block',
    children: [
      {
        _key: '34b86a295f620',
        _type: 'span',
        marks: [],
        text: 'We may use your information for the following purposes:',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '243ec23ebf41',
    _type: 'block',
    children: [
      {
        _key: '243ec23ebf410',
        _type: 'span',
        marks: [],
        text: 'Send you marketing communications which you have requested. These may include information about our services, products, events, activities, and promotions of our partners. This communication is subscription based and requires your consent.',
      },
    ],
    level: 1,
    listItem: 'bullet',
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '8d1fd2a1c573',
    _type: 'block',
    children: [
      {
        _key: '8d1fd2a1c5730',
        _type: 'span',
        marks: [],
        text: 'Send you information about the services and products that you have purchased from us.',
      },
    ],
    level: 1,
    listItem: 'bullet',
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '478db16794cf',
    _type: 'block',
    children: [
      {
        _key: '478db16794cf0',
        _type: 'span',
        marks: [],
        text: 'Perform direct sales activities in cases where legitimate and mutual interest is established.',
      },
    ],
    level: 1,
    listItem: 'bullet',
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '2f6184bef605',
    _type: 'block',
    children: [
      {
        _key: '2f6184bef6050',
        _type: 'span',
        marks: [],
        text: 'Provide you content and venue details on a webinar or event you signed up for.',
      },
    ],
    level: 1,
    listItem: 'bullet',
    markDefs: [],
    style: 'normal',
  },
  {
    _key: 'd264df033f31',
    _type: 'block',
    children: [
      {
        _key: 'd264df033f310',
        _type: 'span',
        marks: [],
        text: 'Reply to a ‘Contact me’ or other web forms you have completed on our Site (e.g., to download an ebook).',
      },
    ],
    level: 1,
    listItem: 'bullet',
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '566cc8ad4e91',
    _type: 'block',
    children: [
      {
        _key: '566cc8ad4e910',
        _type: 'span',
        marks: [],
        text: 'Follow up on incoming requests (client support, emails, chats, or phone calls).',
      },
    ],
    level: 1,
    listItem: 'bullet',
    markDefs: [],
    style: 'normal',
  },
  {
    _key: 'cedfd7980a3d',
    _type: 'block',
    children: [
      {
        _key: 'cedfd7980a3d0',
        _type: 'span',
        marks: [],
        text: 'Perform contractual obligations such as invoices, reminders, and similar. The contract may be with 10Clouds directly or with a 10Clouds partner.',
      },
    ],
    level: 1,
    listItem: 'bullet',
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '7438daa6ddad',
    _type: 'block',
    children: [
      {
        _key: '7438daa6ddad0',
        _type: 'span',
        marks: [],
        text: 'Notify you of any disruptions to our services.',
      },
    ],
    level: 1,
    listItem: 'bullet',
    markDefs: [],
    style: 'normal',
  },
  {
    _key: 'd9aafc95e279',
    _type: 'block',
    children: [
      {
        _key: 'd9aafc95e2790',
        _type: 'span',
        marks: [],
        text: 'Contact you to conduct surveys about your opinion on our services and products.',
      },
    ],
    level: 1,
    listItem: 'bullet',
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '2d36e98cc315',
    _type: 'block',
    children: [
      {
        _key: '2d36e98cc3150',
        _type: 'span',
        marks: [],
        text: 'When we do a business deal or negotiate a business deal, involving sale or transfer of all or a part of our business or assets. These deals can include any merger, financing, acquisition, or bankruptcy transaction or proceeding.',
      },
    ],
    level: 1,
    listItem: 'bullet',
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '04b0403b6902',
    _type: 'block',
    children: [
      {
        _key: '04b0403b69020',
        _type: 'span',
        marks: [],
        text: 'Process a job application.',
      },
    ],
    level: 1,
    listItem: 'bullet',
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '47f7b0a922f6',
    _type: 'block',
    children: [
      {
        _key: '47f7b0a922f60',
        _type: 'span',
        marks: [],
        text: 'To comply with laws.',
      },
    ],
    level: 1,
    listItem: 'bullet',
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '18d182d205e3',
    _type: 'block',
    children: [
      {
        _key: '18d182d205e30',
        _type: 'span',
        marks: [],
        text: 'To respond to lawful requests and legal process.',
      },
    ],
    level: 1,
    listItem: 'bullet',
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '843887b3c807',
    _type: 'block',
    children: [
      {
        _key: '843887b3c8070',
        _type: 'span',
        marks: [],
        text: 'To protect the rights and property of 10Clouds, our agents, customers, and others. Includes enforcing our agreements, policies, and terms of use.',
      },
    ],
    level: 1,
    listItem: 'bullet',
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '220cef59b97f',
    _type: 'block',
    children: [
      {
        _key: '220cef59b97f0',
        _type: 'span',
        marks: [],
        text: 'In an emergency. Includes protecting the safety of our employees, our customers, or any person.',
      },
    ],
    level: 1,
    listItem: 'bullet',
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '55d20d44bbd3',
    _type: 'block',
    children: [
      {
        _key: '55d20d44bbd30',
        _type: 'span',
        marks: [],
        text: '',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: 'a673bcaeefab',
    _type: 'block',
    children: [
      {
        _key: 'a673bcaeefab0',
        _type: 'span',
        marks: [],
        text: 'Type of personal data collected',
      },
    ],
    markDefs: [],
    style: 'h3',
  },
  {
    _key: 'b827e90c2107',
    _type: 'block',
    children: [
      {
        _key: 'b827e90c21070',
        _type: 'span',
        marks: [],
        text: 'We collect your email, full name and company’s name, but in addition, we can also collect phone numbers. We may also collect feedback, comments and questions received from you in service-related communication and activities, such as meetings, phone calls, chats, documents, and emails.',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '14fc49fdb639',
    _type: 'block',
    children: [
      {
        _key: '14fc49fdb6390',
        _type: 'span',
        marks: [],
        text: 'If you apply for a job at 10Clouds, we collect the data you provide during the application process. 10Clouds does not collect or process any particular categories of personal data, such as unique public identifiers or sensitive personal data.',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '4d57b1a4d03d',
    _type: 'block',
    children: [
      {
        _key: '4d57b1a4d03d0',
        _type: 'span',
        marks: [],
        text: '',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '61f4cb1bc705',
    _type: 'block',
    children: [
      {
        _key: '61f4cb1bc7050',
        _type: 'span',
        marks: [],
        text: 'Information we collect automatically',
      },
    ],
    markDefs: [],
    style: 'h3',
  },
  {
    _key: '87c0f777eba7',
    _type: 'block',
    children: [
      {
        _key: '87c0f777eba70',
        _type: 'span',
        marks: [],
        text: 'We automatically log information about you and your computer. For example, when visiting 10clouds.com, we log ‎your computer operating system type,‎ browser type,‎ browser language,‎ pages you viewed,‎ how long you spent on a page,‎ access times,‎ internet protocol (IP) address and information about your actions on our Site.',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '89dbcc498193',
    _type: 'block',
    children: [
      {
        _key: '89dbcc4981930',
        _type: 'span',
        marks: [],
        text: '',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: 'a22445993015',
    _type: 'block',
    children: [
      {
        _key: 'a224459930150',
        _type: 'span',
        marks: [],
        text: 'The use of cookies and web beacons',
      },
    ],
    markDefs: [],
    style: 'h3',
  },
  {
    _key: '97b879b480cd',
    _type: 'block',
    children: [
      {
        _key: '97b879b480cd0',
        _type: 'span',
        marks: [],
        text: 'We may log information using "cookies." Cookies are small data files stored on your hard drive by a website. Cookies help us make our Site and your visit better.',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: 'c1f006c9a945',
    _type: 'block',
    children: [
      {
        _key: 'c1f006c9a9450',
        _type: 'span',
        marks: [],
        text: 'We may log information using digital images called web beacons on our Site or in our emails.',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: 'b8f4d0fbbad3',
    _type: 'block',
    children: [
      {
        _key: 'b8f4d0fbbad30',
        _type: 'span',
        marks: [],
        text: 'This information is used to make our Site work more efficiently, as well as to provide business and marketing information to the owners of the Site, and to gather such personal data as browser type and operating system, referring page, path through site, domain of ISP, etc. for the purposes of understanding how visitors use our Site. Cookies and similar technologies help us tailor our Site to your personal needs, as well as to detect and prevent security threats and abuse. If used alone, cookies and web beacons do not personally identify you.',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '1cd57a6efa0c',
    _type: 'block',
    children: [
      {
        _key: '1cd57a6efa0c0',
        _type: 'span',
        marks: [],
        text: '',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: 'eb13b45a382a',
    _type: 'block',
    children: [
      {
        _key: 'eb13b45a382a0',
        _type: 'span',
        marks: [],
        text: 'How long we keep your data',
      },
    ],
    markDefs: [],
    style: 'h3',
  },
  {
    _key: '6be416553d82',
    _type: 'block',
    children: [
      {
        _key: '6be416553d820',
        _type: 'span',
        marks: [],
        text: 'We store personal data for as long as we find it necessary to fulfill the purpose for which the personal data was collected, while also considering our need to answer your queries or resolve possible problems. This helps us to comply with legal requirements under applicable laws, to attend to any legal claims/complaints, and for safeguarding purposes.',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '84ff39e1e3fa',
    _type: 'block',
    children: [
      {
        _key: '84ff39e1e3fa0',
        _type: 'span',
        marks: [],
        text: 'This means that we may retain your personal data for a reasonable period after your last interaction with us. When the personal data that we have collected is no longer required, we will delete it securely. We may process data for statistical purposes, but in such cases, data will be anonymized.',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: 'd895637d8129',
    _type: 'block',
    children: [
      {
        _key: 'd895637d81290',
        _type: 'span',
        marks: [],
        text: '',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '7792ce3e79b9',
    _type: 'block',
    children: [
      {
        _key: '7792ce3e79b90',
        _type: 'span',
        marks: [],
        text: 'Your rights to your personal data',
      },
    ],
    markDefs: [],
    style: 'h3',
  },
  {
    _key: '22f549f5e0ab',
    _type: 'block',
    children: [
      {
        _key: '22f549f5e0ab0',
        _type: 'span',
        marks: [],
        text: 'You have the following rights concerning your personal data:',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '569c8202dd89',
    _type: 'block',
    children: [
      {
        _key: '569c8202dd890',
        _type: 'span',
        marks: [],
        text: 'The right to request a copy of your personal data that 10Clouds holds about you.',
      },
    ],
    level: 1,
    listItem: 'bullet',
    markDefs: [],
    style: 'normal',
  },
  {
    _key: 'c06cf5bab3f9',
    _type: 'block',
    children: [
      {
        _key: 'c06cf5bab3f90',
        _type: 'span',
        marks: [],
        text: 'The right to request that 10Clouds correct your personal data if inaccurate or out of date.',
      },
    ],
    level: 1,
    listItem: 'bullet',
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '855209c6a39a',
    _type: 'block',
    children: [
      {
        _key: '855209c6a39a0',
        _type: 'span',
        marks: [],
        text: 'The right to request that your personal data is deleted when it is no longer necessary for 10Clouds to retain such data.',
      },
    ],
    level: 1,
    listItem: 'bullet',
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '5abebf9cc17c',
    _type: 'block',
    children: [
      {
        _key: '5abebf9cc17c0',
        _type: 'span',
        marks: [],
        text: 'The right to withdraw any consent to personal data processing at any time. For example, your consent to receive digital marketing messages. If you want to withdraw your consent for digital marketing messages, please make use of the link to manage your subscriptions included in our communication.',
      },
    ],
    level: 1,
    listItem: 'bullet',
    markDefs: [],
    style: 'normal',
  },
  {
    _key: 'e4723720f604',
    _type: 'block',
    children: [
      {
        _key: 'e4723720f6040',
        _type: 'span',
        marks: [],
        text: 'The right to request that 10Clouds provides you with your personal data.',
      },
    ],
    level: 1,
    listItem: 'bullet',
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '72635bc27397',
    _type: 'block',
    children: [
      {
        _key: '72635bc273970',
        _type: 'span',
        marks: [],
        text: 'The right to request a restriction on further data processing, in case there is a dispute about the accuracy or processing of your personal data.',
      },
    ],
    level: 1,
    listItem: 'bullet',
    markDefs: [],
    style: 'normal',
  },
  {
    _key: 'f855af25fd80',
    _type: 'block',
    children: [
      {
        _key: 'f855af25fd800',
        _type: 'span',
        marks: [],
        text: 'The right to object to the processing of personal data, in case data processing has been based on legitimate interest and/or direct marketing.',
      },
    ],
    level: 1,
    listItem: 'bullet',
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '86ae1b954366',
    _type: 'block',
    children: [
      {
        _key: '86ae1b9543660',
        _type: 'span',
        marks: [],
        text: '',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '4e3fc489341e',
    _type: 'block',
    children: [
      {
        _key: '4e3fc489341e0',
        _type: 'span',
        marks: [],
        text: 'Any query about your privacy rights should be sent to ',
      },
      {
        _key: '4e3fc489341e1',
        _type: 'span',
        marks: ['b3c4f2ae3d57'],
        text: 'hello@10clouds.com',
      },
      {
        _key: '4e3fc489341e2',
        _type: 'span',
        marks: [],
        text: '.',
      },
    ],
    markDefs: [
      {
        _key: 'b3c4f2ae3d57',
        _type: 'link',
        href: 'mailto:hello@10clouds.com',
      },
    ],
    style: 'normal',
  },
  {
    _key: '3df1d897a759',
    _type: 'block',
    children: [
      {
        _key: '3df1d897a7590',
        _type: 'span',
        marks: [],
        text: '',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '66a23fb8d3fe',
    _type: 'block',
    children: [
      {
        _key: '66a23fb8d3fe0',
        _type: 'span',
        marks: [],
        text: 'Hotjar’s privacy policy',
      },
    ],
    markDefs: [],
    style: 'h3',
  },
  {
    _key: 'baae9aa58845',
    _type: 'block',
    children: [
      {
        _key: 'baae9aa588450',
        _type: 'span',
        marks: [],
        text: 'We use ',
      },
      {
        _key: 'baae9aa588451',
        _type: 'span',
        marks: ['25dc07af0059'],
        text: 'Hotjar',
      },
      {
        _key: 'baae9aa588452',
        _type: 'span',
        marks: [],
        text: " in order to better understand our users’ needs and to optimize this service and experience. Hotjar is a technology service that helps us better understand our users experience (e.g. how much time they spend on which pages, which links they choose to click, what users do and don’t like, etc.) and this enables us to build and maintain our service with user feedback. Hotjar uses cookies and other technologies to collect data on our users’ behavior and their devices (in particular device's IP address (captured and stored only in anonymized form), device screen size, device type (unique device identifiers), browser information, geographic location (country only), preferred language used to display our website). Hotjar stores this information in a pseudonymized user profile. Neither Hotjar nor we will ever use this information to identify individual users or to match it with further data on an individual user. For further details, please see Hotjar’s privacy policy by clicking on",
      },
      {
        _key: 'baae9aa588453',
        _type: 'span',
        marks: ['a9e3c4019e53'],
        text: ' this link.',
      },
      {
        _key: 'baae9aa588454',
        _type: 'span',
        marks: [],
        text: '',
      },
    ],
    markDefs: [
      {
        _key: '25dc07af0059',
        _type: 'link',
        href: 'https://www.hotjar.com/',
      },
      {
        _key: 'a9e3c4019e53',
        _type: 'link',
        href: 'https://www.hotjar.com/legal/policies/privacy',
      },
    ],
    style: 'normal',
  },
  {
    _key: '84d20dcba04b',
    _type: 'block',
    children: [
      {
        _key: '84d20dcba04b0',
        _type: 'span',
        marks: [],
        text: 'You can opt-out to the creation of a user profile, Hotjar’s storing of data about your usage of our site and Hotjar’s use of tracking cookies on other websites by following ',
      },
      {
        _key: '84d20dcba04b1',
        _type: 'span',
        marks: ['5e93fcab47fd'],
        text: 'this opt-out link.',
      },
      {
        _key: '84d20dcba04b2',
        _type: 'span',
        marks: [],
        text: '',
      },
    ],
    markDefs: [
      {
        _key: '5e93fcab47fd',
        _type: 'link',
        href: 'https://www.hotjar.com/legal/compliance/opt-out',
      },
    ],
    style: 'normal',
  },
  {
    _key: '83f0febb66b3',
    _type: 'block',
    children: [
      {
        _key: '83f0febb66b30',
        _type: 'span',
        marks: [],
        text: '',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '159f933c0eda',
    _type: 'block',
    children: [
      {
        _key: '159f933c0eda0',
        _type: 'span',
        marks: [],
        text: 'Changes to this Privacy Policy',
      },
    ],
    markDefs: [],
    style: 'h3',
  },
  {
    _key: 'fc3215f8d42d',
    _type: 'block',
    children: [
      {
        _key: 'fc3215f8d42d0',
        _type: 'span',
        marks: [],
        text: '10Clouds reserves the right to amend this privacy policy at any time. The latest version will always be found on our Site. We encourage you to check this page occasionally to ensure that you are happy with any changes.',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: 'c2505a53e85b',
    _type: 'block',
    children: [
      {
        _key: 'c2505a53e85b0',
        _type: 'span',
        marks: [],
        text: 'If we make changes that significantly alter our privacy practices, we will notify you by email or post a notice on our Site before the change takes effect.',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
]

const PrivacyPage = () => {
  return (
    <Layout shouldNavigationStartTransparent={false}>
      <BlockSection content={privacyPolicyAsRawSection} isFirstSection={true} />
    </Layout>
  )
}

export function Head({ location }) {
  return (
    <SEO
      location={location}
      title="Privacy policy - GDPR, security policy and more"
      description="Privacy policy - GDPR, security policy and more"
    />
  )
}

export default PrivacyPage
