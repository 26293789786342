import { useStaticQuery, graphql } from 'gatsby'
import { ImageDataLike } from 'gatsby-plugin-image'

type URL = string

type Image = {
  asset: {
    gatsbyImageData: ImageDataLike
  }
}

type GlobalInfo = {
  bullets: string
  image: Image
}

type Hero = {
  title: string
}

type DownloadForm = {
  file: {
    asset: {
      url: URL
    }
  }
}

type SanityWhitePaperPageNode = {
  globalInfo: GlobalInfo
  hero: Hero
  downloadForm: DownloadForm
}

export const useWhitepaperResourcesQuery = () => {
  const data = useStaticQuery(
    graphql`
      {
        allSanityWhitePaperPage {
          nodes {
            globalInfo {
              bullets
              image {
                asset {
                  gatsbyImageData(height: 308, width: 315, layout: FIXED)
                }
              }
            }
            hero {
              title
            }
            downloadForm {
              file {
                asset {
                  url
                }
              }
            }
          }
        }
      }
    `,
  )

  return data.allSanityWhitePaperPage.nodes
    .filter((whitepaper: SanityWhitePaperPageNode) => whitepaper?.downloadForm?.file)
    .map(({ globalInfo, hero, downloadForm }: SanityWhitePaperPageNode) => ({
      bullets: globalInfo.bullets,
      image: globalInfo.image,
      file: downloadForm.file,
      title: hero.title,
    }))
}
