import styled from 'styled-components';

import { navbarMinHeight } from '../../../features/layout/components/commonLayoutStyle';
import { breakpoints } from '../../../../constants/style';

export const ProcessOfWorkSection = styled('section')`
  background-color: ${({ theme }) => theme.background};
  padding: 7rem 10.5rem !important;

  @media only screen and (max-width: ${breakpoints.desktopLarge}) {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  @media only screen and (max-width: ${breakpoints.desktopSmall}) {
    padding-top: ${navbarMinHeight};
    padding-bottom: ${navbarMinHeight};
  }

  @media only screen and (max-width: ${breakpoints.desktopSmall}) {
    padding: 7.0625rem 2rem !important;
  }
`;
