import React from 'react';

const AddressDescription = ({ email, phone, description }) => {
  return (
    <>
      {email && (
        <a className="link" href={`mailto:${email}`}>
          {email}
        </a>
      )}
      {phone && (
        <a className="link" href={`tel:${phone.replace(/ /g, '')}`}>
          {phone.replace(/ /g, '\u00a0')}
        </a>
      )}
      {description && <span>{description}</span>}
    </>
  );
};

export default AddressDescription;
