import styled from 'styled-components'
import { colors, breakpoints } from 'constants/style'
import {
  horizontalLayoutPadding,
  horizontalLayoutPaddingTablet,
  horizontalLayoutPaddingMobile,
} from 'features/layout/components/commonLayoutStyle'
import SanityBlockContent from '../SanityBlockContent'

export const StyledAnnouncementTopBar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${colors.primary};
  color: #ffffff;
  z-index: 10000;
  transition: max-height 0.25s ease-in-out, top 0.25s ease-in-out;
  max-height: 100%;
  padding: 0.5rem ${horizontalLayoutPadding};
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  gap: 1rem;

  p {
    font-size: 0.875rem;
    line-height: normal;
  }

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }


  &.hide {
    max-height: 0;
    top: -100%;
  }

  @media only screen and (max-width: ${breakpoints.desktop}) {
    padding: 0.5rem ${horizontalLayoutPaddingTablet};
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    padding: 0.5rem ${horizontalLayoutPaddingMobile};
  }
`

export const StyledSanityBlockContent = styled(SanityBlockContent)`
  margin: 0 auto;

  p {
    margin-bottom: 0;
  }

  a {
    color: white;
  }
`

export const StyledMessageCloseBox = styled.div`
  padding-left: 1rem;
  font-size: 0;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.25s ease-in-out;
  margin-left: auto;

  img {
    width: 16px;
    height: 16px;
  }

  &:hover {
    opacity: 1;
  }
`