import React from 'react';

import styled from 'styled-components';
import { navigate } from 'gatsby';

import { colors, breakpoints } from '../../../../constants/style';
import { horizontalLayoutPaddingMobile, verticalLayoutPadding } from '../../../features/layout/components/commonLayoutStyle';
import { NavLink } from '../../Link';
import Image from '../../Image';
import { parseSlug } from '../../../utils/slug';
import IconArrow from '../../icons/IconArrow';
import { blogArrowAnimationStyle, blogTitleAnimationStyle } from '../../../../src/features/blog/commonStyle';
import SectionHeader from '../../SectionHeader/SectionHeader';
import Button from '../../Buttons';

const PastEventsSection = styled('section')`
  position: relative;
  background-color: ${colors.backgroundLight};
  padding-top: ${verticalLayoutPadding};
  padding-bottom: ${verticalLayoutPadding};

  .events-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 2rem;
  }

  .item {
    display: block !important;
    width: 100%;
    height: 100%;

    .link {
      text-transform: none;
      color: ${colors.titleDark};

      .item__header {
        ${blogTitleAnimationStyle.base};
      }

      &:hover .item__header {
        ${blogTitleAnimationStyle.hover};
      }

      > span {
        display: block;
      }

      svg {
        ${blogArrowAnimationStyle.base}
        display: block;
        margin-top: 2rem;
      }

      &:hover svg {
        ${blogArrowAnimationStyle.hover}
      }
    }

    .item__category {
      color: ${colors.primary};
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.25rem;
      margin-bottom: 1.5rem;
    }

    .item__header {
      font-weight: 600;
      font-size: 1.5rem;
      line-height: 2.25rem;
      letter-spacing: 0.16px;
    }

    .item__text {
      padding: 2rem;
      svg {
        width: 23px;
      }
    }
  }

  .events-button {
    margin: 8rem auto 0;
    text-transform: uppercase;
    width: 15rem;
    font-weight: 600;
  }

  @media only screen and (max-width: ${breakpoints.desktopSmall}) {
    .item {
      width: auto !important;
    }

    a {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .events-container {
      grid-template-columns: 1fr 1fr;
      column-gap: 1.5rem;
      row-gap: 5rem;
    }
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    .header {
      padding: 0 ${horizontalLayoutPaddingMobile};
    }

    .description {
      padding: 0 ${horizontalLayoutPaddingMobile};
    }
    .events-container {
      grid-template-columns: 1fr;
    }
  }
`;

const PastEvents = ({ header, raw, voices }) => {
  voices = voices.slice(0, 3);

  return (
    <PastEventsSection>
      <SectionHeader title={header} subtitle={raw.description} />
      <div className="events-container">
        {voices.map((voice) => (
          <div className="item" key={`voice-${voice.title}`}>
            <NavLink to={`/voices/${parseSlug(voice.slug.current)}`}>
              <Image
                image={voice.featuredImage}
                style={{
                  aspectRatio: '16/9',
                  display: 'block',
                }}
              />
              <div className="item__text">
                <div className="item__category">{voice.preTitle.toUpperCase()}</div>
                <h3 className="item__header">{voice.title}</h3>
                <IconArrow />
              </div>
            </NavLink>
          </div>
        ))}
      </div>
      {/* this button in disabled for now, because theres no landing page with all 10c-voices, when its showup please uncomment it and provide proper url, the button was in QA and its ready to go */}
      {/* <Button variant="bordered" className="events-button" onClick={() => navigate('/')}>
        see more
      </Button> */}
    </PastEventsSection>
  );
};

export default PastEvents;
