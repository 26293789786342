import styled, { css } from 'styled-components';
import { colors } from '../../../constants/style';

const paddingEntryVertical = '1rem';
const paddingEntryHorizontal = '.5rem';
const paddingContainerVertical = '2.5rem';
const paddingContainerHorizontal = '1rem';
const scrollbarWidth = '3px';

const gradientStyle = css`
  position: fixed;
  left: calc(${paddingEntryHorizontal} + ${paddingContainerHorizontal});
  content: '';
  height: 3rem;
  width: calc(100% - (${paddingEntryHorizontal} * 2) - (${paddingContainerHorizontal} * 2));
  z-index: 5;
`;

const scrollbarStyle = css`
  ::-webkit-scrollbar {
    width: ${scrollbarWidth};
  }

  ::-webkit-scrollbar-thumb {
    background: ${colors.grayLight}70;
  }

  ::-webkit-scrollbar-track {
    background: ${colors.grayLight}30;
  }
`;

const scrollbarStyleFirefox = css`
  overflow-y: scroll;
  scrollbar-color: transparent transparent;
  scrollbar-width: thin;
  transition-property: scrollbar-color;
  transition-duration: 0s;
  transition-delay: 0.2s;
`;

const scrollbarStyleFirefoxHover = css`
  scrollbar-color: ${colors.grayLight}50 ${colors.grayLight}20;
`;

const StyledEmployeeEntry = styled('div')`
  padding: ${paddingEntryVertical} ${paddingEntryHorizontal};
  background-color: ${({ darkTheme }) => (!darkTheme ? '#fff' : '#160940')};
  height: 25rem;
  overflow: hidden;
  box-shadow: ${({ darkTheme }) => (!darkTheme ? '0px 8px 24px rgba(71, 90, 158, 0.1)' : 'none')};
  transition: box-shadow 0.25s ease-in-out;

  .employee__container {
    height: 300%;
    align-items: center;
    padding: ${paddingContainerVertical} ${paddingContainerHorizontal};
    font-family: Roboto-Regular, sans-serif;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    ${({ isUserUseFirefox }) => (isUserUseFirefox ? scrollbarStyleFirefox : scrollbarStyle)}
    transition-property: height;
    transition-timing-function: ease-in-out;
    transition-duration: 0s;
    transition-delay: 0.2s;
  }

  .employee__image {
    width: 8.75rem;
    height: 8.75rem;
    flex-shrink: 0;
    margin: 0 auto 2.5rem;
    border-radius: 50%;
    overflow: hidden;
    opacity: 1;
    transition: all 0.2s ease-in-out;
    /* to prevent image from covering linked icons */
    position: relative;
    z-index: 1;
  }

  .employee__data {
    /* to prevent image from covering linked icons */
    position: relative;
    z-index: 2;
  }

  .employee__name {
    font-family: BioSans-Regular, Roboto-Regular, sans-serif;
    margin-bottom: 0.5rem;
    font-size: 1.625rem;
    line-height: 2.25rem;
    letter-spacing: 0.01rem;
    color: ${({ darkTheme }) => (!darkTheme ? colors.titleDark : '#fff')};
    text-align: center;
  }

  .employee__position {
    font-size: 1rem;
    line-height: 1.75rem;
    margin-bottom: 1.5rem;
    color: ${({ darkTheme }) => (!darkTheme ? '#4e4d6c' : colors.grayLight)};
    text-align: center;
  }

  .employee__icons {
    display: none;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 1.5rem;

    .employee__link {
      margin: 0 0.5625rem 0 0.5625rem;
    }
  }

  .employee__description {
    display: none;
    font-size: 1rem;
    line-height: 1.75rem;
    color: ${({ darkTheme }) => (!darkTheme ? '#4e4d6c' : colors.grayLight)};
    text-align: center;
  }

  &:hover {
    box-shadow: 0 42px 64px 0 rgba(0, 0, 0, 0.05);
  }

  .employee__container:hover {
    overflow: auto;
    height: 100%;
    ${({ isUserUseFirefox }) => (isUserUseFirefox ? scrollbarStyleFirefoxHover : null)}

    .employee__image {
      width: 0;
      height: 0;
      opacity: 0;
      margin-bottom: 0;
    }

    .employee__icons {
      display: flex;
    }

    .employee__description {
      display: block;
    }

    &::before {
      ${gradientStyle}
      top: ${paddingEntryVertical};
      ${({ darkTheme }) =>
        !darkTheme
          ? 'background: linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 100));'
          : 'background: linear-gradient(0deg, rgba(22, 9, 64, 0), rgba(22, 9, 64, 100));'}
    }

    &::after {
      ${gradientStyle}
      bottom: ${paddingEntryVertical};
      ${({ darkTheme }) =>
        !darkTheme
          ? 'background: linear-gradient(180deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 100));'
          : 'background: linear-gradient(180deg, rgba(22, 9, 64, 0), rgba(22, 9, 64, 100));'}
    }
  }
`;
export default StyledEmployeeEntry;
