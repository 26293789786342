import React, { useEffect } from 'react'
import { Normalize } from 'styled-normalize'
import { Navigation } from './components/navigation'
import Footer from './components/footer'
import Cookies from './components/cookies/cookies'

import Fonts from '../../fonts'
import { GlobalStyle, Main } from './styled'

const Layout = ({ showNavigation, showFooter, children, shouldNavigationStartTransparent }) => {
  // Initialize Google Optimize experiment on 'optimize.activate' event.
  // Just passing of Optimize ID to Google Analytics plugin doesn't work.
  // Based on https://medium.com/@pisanocaroline/optimizing-google-optimize-in-gatsby-js-e67d0a59bfa1
  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ event: 'optimize.activate' })
  }, [])

  return (
    <>
      <Normalize />
      <Fonts />
      <GlobalStyle />
      {showNavigation && <Navigation shouldStartTransparent={shouldNavigationStartTransparent} />}
      <Main>{children}</Main>
      {showFooter && <Footer />}
      <Cookies />
    </>
  )
}

Layout.defaultProps = {
  showNavigation: true,
  showFooter: true,
  shouldNavigationStartTransparent: true,
  navigationVariant: '',
}

export default React.memo(Layout)
