import styled from 'styled-components';

import {
  horizontalLayoutPadding,
  horizontalLayoutPaddingTablet,
  horizontalLayoutPaddingMobile,
} from '../../../../features/layout/components/commonLayoutStyle';
import { colors, breakpoints } from '../../../../../constants/style';

const linksMarginTop = '2.5rem';

const ProjectExamplesEntryWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  background-color: ${colors.backgroundLight};

  .projectExamples__text-wrapper {
    flex: 0 0 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: ${horizontalLayoutPadding};
    position: relative;
    /* to cut off text shadow protruding beyond wrapper area */
    overflow: hidden;
  }
  .projectExamples__item-header {
    font-family: 'BioSans-SemiBold', sans-serif;
    font-size: 4.375rem;
    margin: 0 0 3.9375rem 0;
    position: relative;
    z-index: 1;
  }

  .projectExamples__item-tags {
    color: ${colors.primary};
    text-transform: uppercase;
    letter-spacing: 1.14px;
    font-family: Roboto-Bold, sans-serif;
    font-size: 1rem;
    margin-bottom: 1.3125rem;
    position: relative;
    z-index: 1;

    span:not(:first-child) {
      &::before {
        content: '\\00a0\\2022\\00a0';
        display: inline-block;
      }
    }
  }

  .projectExamples__item-description {
    position: relative;
    z-index: 1;
    max-width: 36.25rem;
    margin-bottom: 2.5rem;

    p {
      color: ${colors.mutedDark};
      font-size: 1.125rem;
      line-height: 2.11;
    }

    b,
    strong {
      color: #565062;
    }
  }

  .projectExamples__image-wrapper {
    flex: 0 0 50%;
    padding: 0;
    background: linear-gradient(
      140deg,
      ${(props) => (props.imageBackgroundColorStart ? props.imageBackgroundColorStart.hex : 'rgba(0, 0, 0, 0)')} 7%,
      ${(props) => (props.imageBackgroundColorEnd ? props.imageBackgroundColorEnd.hex : 'rgba(0, 0, 0, 0)')} 91%
    );

    .projectExamples__image {
      height: 100%;
      background-size: cover;
      background-position: center;
    }
  }

  .projectExamples__item-buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    z-index: 0;

    .projectExamples__shadow {
      position: absolute;
      left: 0;
      top: calc(50% + ${linksMarginTop} * 0.5);
      transform: translate(-7%, -50%);
      z-index: 0;
      font-family: BioSans-Bold, sans-serif;
      font-size: 10rem;
      letter-spacing: 0.625rem;
      text-transform: uppercase;
      color: #f3f3f8;
      white-space: nowrap;
    }

    .link {
      position: relative;
      color: #100727;
      margin-top: ${linksMarginTop};
      display: block;

      &:not(:last-child) {
        margin-right: 1.875rem;
      }

      .link__icon {
        transition: background-color 0.25s ease-in-out;
        svg {
          transition: fill 0.25s ease-in-out;
        }
      }

      &:hover {
        color: ${colors.titleDark};
        text-decoration: underline;

        .link__icon {
          background-color: ${colors.primary};

          svg {
            fill: #ffffff;
          }
        }
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.desktopLarge}) {
    .projectExamples__item-header {
      font-size: 3.5rem;
      margin-bottom: 5rem;
    }
  }

  @media only screen and (max-width: ${breakpoints.desktop}) {
    .projectExamples__text-wrapper {
      padding: 2rem ${horizontalLayoutPaddingTablet};
    }
  }

  @media only screen and (max-width: ${breakpoints.desktopSmall}) {
    flex-direction: column-reverse;

    .projectExamples__text-wrapper {
      flex: 0 0 auto;
      /* padding bottom is higher to prevent cutting out text shadow */
      padding: 2rem ${horizontalLayoutPaddingMobile} 6rem ${horizontalLayoutPaddingMobile};
    }

    .projectExamples__item-description {
      max-width: unset;
    }

    .projectExamples__item-header {
      margin-top: 2rem;
    }

    .projectExamples__image-wrapper {
      flex: 0 0 auto;

      .projectExamples__image {
        transform: none;
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.mobile}) {
    .projectExamples__item-header {
      font-size: 3rem;
      margin-bottom: 2rem;
    }
  }
`;

export default ProjectExamplesEntryWrapper;
