import React, { useState } from 'react'
import { BlockContentProps } from '@sanity/block-content-to-react'

import SectionHeader from 'components/SectionHeader/SectionHeader'

import styles from './accordion.module.scss'
import AccordionItem from 'components/AccordionItem'

type AccordionProps = {
  title: string
  description: BlockContentProps
  accordionList: {
    title: string
    description: string
  }[]
}

const Accordion = ({ title, description, accordionList }: AccordionProps) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null)

  const toggleAccordion = (index: number) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index))
  }

  const generateJSONLD = () => {
    const itemListElements = accordionList?.map((item) => ({
      '@type': 'Question',
      name: item.title,
      acceptedAnswer: {
        '@type': 'Answer',
        text: item.description,
      },
    }))

    const jsonLD = {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: itemListElements,
    }

    return JSON.stringify(jsonLD)
  }

  return (
    <section className={styles.accordionWrapper}>
      <SectionHeader title={title} headerPosition="center" subtitle={description} />
      <div className={styles.accordionContainer}>
        {accordionList.map((item, index) => (
          <AccordionItem
            key={`accordion-item-${index}`}
            question={item.title}
            answer={item.description}
            isActive={activeIndex === index}
            onClick={() => toggleAccordion(index)}
          />
        ))}
      </div>
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: generateJSONLD() }} />
    </section>
  )
}

export default Accordion
