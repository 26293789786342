import React from 'react';
import styled from 'styled-components';

import Image from '../../Image';
import { NavLinkButton } from '../../Link';
import { contact } from '../../../../constants/links';

import { breakpoints } from '../../../../constants/style';

const StyledImageSection = styled('section')`
  margin-bottom: 3rem;
  margin-top: 10rem;
  text-align: center;

  .contact-btn {
    display: inline-block;
  }

  @media only screen and (max-width: ${breakpoints.desktopSmall}) {
    margin-top: 3rem;
  }
`;

const ImageSection = ({ buttonLabel, image }) => {
  // do not render if there is no image
  if (!image) return null

  return (
    <StyledImageSection>
      <Image image={image} />
      {Boolean(buttonLabel) && (
        <NavLinkButton to={contact.url} className="contact-btn" variant="filled">
          {buttonLabel}
        </NavLinkButton>
      )}
    </StyledImageSection>
  );
};

export default ImageSection;
