import { useStaticQuery, graphql } from 'gatsby'

export const useSeoQuery = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          twitter {
            author
          }
          defaultLocale
          defaultLang
          siteUrl
          defaultImage {
            absoluteUrl
            width
            height
          }
        }
      }
    }
  `)

  return data
}
