import React, { Suspense } from 'react'
import { graphql } from 'gatsby'

import Layout from '../../features/layout'
import sectionsConfig from '../../features/dynamicSection/sections.config'
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator'
import 'hooks/graphql/others'
import 'hooks/graphql/blog'
import { SEO } from 'components/Seo'

export const query = graphql`
  query VoicesPageQuery($id: String!, $blogCategoryId: String!) {
    sanityVoicesPage(id: { eq: $id }) {
      shouldNavigationStartTransparent
      sections {
        __typename
        ...SanityHeroFragment

        ...ClientsSection

        ...PartnersSection

        ...AdvantagesSection

        ...WhySection

        ...BulletParagraphSection

        ...VersusSection

        ...SolutionsSection

        ...ProcessOfWorkSection

        ...ProcessOfDeliverySection

        ...ParagraphSection

        ...ReferralFormSection

        ...HeroForEvent

        ...OrderedListItemsSection

        ...WhatAreYouLookingForSection

        ...TechnologyDetailsSection

        ...TechnologiesSection

        ...ProjectExamplesSection

        ...CaseStudiesSection

        ...TestimonialsSection

        ...ShowcaseSection

        ...AwardsSection

        ...LinkedServicesSection

        ...DownloadSection

        ...ContactSection

        ...RichTextBox

        ...ProcessOfDevelopmentSection

        ...ImageSection

        ...PillarsSection

        ...ModalSection

        ...StatisticSection

        ...SloganSection

        ...AgileApproachSection

        ...ArticlesSection

        ...PastEventsSection

        ...HeroWithContactFormSection

        # job offers are taken from static query
        ...JobOffersSection

        # we use only raw block content
        ...BlockSection

        ...SubscribeSectionForCustomPage

        ...FeaturedOnSectionForCustomPage

        ...BenefitsSection

        ...TechnologySummarySection

        ...PerksSection

        ...TableSection

        ...EmployeesListSection

        ...PeopleListSection

        ...MainGuestSection

        ...ClickableEnhancedImage

        ...ResourcesListSection
      }

      _rawSections

      seo {
        ...SeoFragment
      }
    }

    postsByCategory: allSanityBlogPost(
      filter: { categories: { elemMatch: { id: { eq: $blogCategoryId } } } }
      limit: 6
      sort: { createdAt: DESC }
    ) {
      nodes {
        ...BlogPostFields
      }
    }
  }
`

const VoicesPage = (props) => {
  const {
    data: { sanityVoicesPage, postsByCategory },
  } = props
  const { shouldNavigationStartTransparent, sections, _rawSections } = sanityVoicesPage
  return (
    <Layout shouldNavigationStartTransparent={shouldNavigationStartTransparent}>
      <Suspense fallback={<LoadingIndicator />}>
        {sections.map((section, index) => {
          const currentSection = sectionsConfig[section._type]

          if (!currentSection) {
            console.error('currentSection', currentSection)
            console.error('current Type', section?._type)
            throw new Error(
              'Required section not defined. Please add it to the available sections list.',
            )
          }

          const blogPosts =
            section._type === 'articlesSection'
              ? [
                  ...section.blogPosts.filter(({ __typename }) => __typename === 'SanityBlogPost'),
                  ...postsByCategory.nodes,
                ]
              : null

          const sectionWithBlogPosts = { ...section, blogPosts }

          return (
            <Suspense fallback={<LoadingIndicator />} key={index}>
              {currentSection.condition(sectionWithBlogPosts) &&
                currentSection.template({
                  section: sectionWithBlogPosts,
                  index,
                  isPreviousSectionOfSameType: Boolean(
                    sections[index + 1] && sections[index + 1]._type === section._type,
                  ),
                  rawSection: _rawSections[index],
                })}
            </Suspense>
          )
        })}
      </Suspense>
    </Layout>
  )
}

export function Head({ location, data: { sanityVoicesPage } }) {
  return <SEO location={location} {...sanityVoicesPage.seo} />
}

export default VoicesPage
