import React from 'react'
import styled from 'styled-components'

import { colors } from '../../../constants/style'
import Image from '../Image'
import arrow from '../../../static/images/icons/arrow-long-right.svg'

const StyledEmployeeEntrySmall = styled.div`
  display: flex;
  gap: 1.5rem;
  font-size: 1rem;
  line-height: 1.25;
  align-items: center;

  .entry-small__image {
    height: 3.75rem;
    width: 3.75rem;
    border-radius: 50%;
    flex-shrink: 0;
    & > img {
      will-change: auto;
    }
  }

  .entry-small__name {
    display: flex;
    align-items: center;
    font-size: 1.3125rem;
    line-height: 2.25rem;
    color: var(--t1-title);
  }

  .entry-small__link {
    height: 2rem;
    flex-shrink: 0;

    &:hover {
      transform: translateX(0.25rem);
    }
  }

  .entry-small__position {
    color: var(--t1-body);
    text-align: left;
  }
`

const EmployeeEntrySmall = ({ image, name, position, links, className, description }) => {
  const link = links ? Object.values(links).find((item) => item) : false
  const hasDescription = Boolean(description)

  return (
    <StyledEmployeeEntrySmall className={className}>
      {image ? (
        <Image className="entry-small__image" image={image} alt={`${name}'s image`} />
      ) : null}
      <div>
        <div className="entry-small__name">
          {`${hasDescription ? 'By' : ''} ${name}`}
          {link && !hasDescription ? (
            <a href={link} target="_blank" rel="noreferrer" className="entry-small__link">
              <img src={arrow} alt="arrow" />
            </a>
          ) : null}
        </div>
        <div className="entry-small__position p3">{description || position}</div>
      </div>
    </StyledEmployeeEntrySmall>
  )
}

export default EmployeeEntrySmall
