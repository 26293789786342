import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../features/layout'
import FeaturedOn from '../../components/sections/FeaturedOn'
import ContactUs from '../../components/sections/ContactUs'
import Testimonials from '../../components/sections/Testimonials'

import { SEO } from 'components/Seo'
import WhySection from '../../components/sections/Why10Clouds'
import CaseStudies from '../../components/sections/CaseStudies'
import Articles from '../../components/sections/Articles'
import OtherServices from '../../components/sections/OtherServices'
import Benefits from '../../components/sections/Benefits'
import TechnologySummary from '../../components/sections/TechnologySummary'
import TechnologyHero from './components/TechnologyHero'
import { useDictionaryQuery } from 'hooks/graphql/page'
import 'hooks/graphql/section'
import 'hooks/graphql/blog'

export const query = graphql`
  query TechnologyPageQuery($id: String!, $blogCategoryId: String!) {
    sanityTechnology(id: { eq: $id }) {
      abstract {
        name
        image {
          asset {
            url
            gatsbyImageData
          }
        }
      }

      page {
        hero {
          title
          description
          contactButtonText
        }

        testimonialsSection {
          ...TestimonialsSection
        }

        whySection {
          ...WhySection
        }

        technologySummarySection {
          ...TechnologySummarySection
        }

        benefitsSection {
          ...BenefitsSection
        }

        articlesSection {
          ...ArticlesSection
        }

        caseStudiesSection {
          ...CaseStudiesSection
        }

        linkedServicesSection {
          ...LinkedServicesSection
        }

        contactSection {
          ...ContactSection
        }

        seo {
          ...SeoFragment
        }
      }
      _rawPage
    }

    blogPosts: allSanityBlogPost(
      filter: { categories: { elemMatch: { id: { eq: $blogCategoryId } } } }
      limit: 6
      sort: { createdAt: DESC }
    ) {
      nodes {
        ...BlogPostFields
      }
    }
  }
`

const TechnologyPage = (props) => {
  const {
    data: { sanityTechnology, blogPosts },
  } = props
  const { page, abstract, _rawPage } = sanityTechnology

  const {
    hero,
    whySection,
    technologySummarySection,
    benefitsSection,
    articlesSection,
    caseStudiesSection,
    linkedServicesSection,
    testimonialsSection,
    contactSection,
  } = page

  const { articlesSection: _rawArticlesSection, caseStudiesSection: _rawCaseStudiesSection } =
    _rawPage
  const articlesSectionBlogPosts = (blogPosts.nodes.length || articlesSection?.blogPosts) && [
    ...articlesSection.blogPosts.filter(({ __typename }) => __typename === 'SanityBlogPost'),
    ...blogPosts.nodes,
  ]
  const { technology } = useDictionaryQuery()

  return (
    <Layout>
      <TechnologyHero
        header={hero ? hero.title : null}
        description={hero ? hero.description : null}
        preheader={technology?.heroPretitle}
        contactButtonText={hero.contactButtonText}
        technologyImage={abstract.image}
        technologyName={abstract.name}
      />

      {Boolean(whySection && whySection.reasonsToWorkWith.length) && (
        <WhySection header={whySection.header} reasons={whySection.reasonsToWorkWith} />
      )}

      {Boolean(benefitsSection && benefitsSection.benefits.length) && (
        <Benefits
          header={benefitsSection.header}
          benefits={benefitsSection.benefits}
          removeTopPadding
        />
      )}

      {Boolean(technologySummarySection && technologySummarySection.summaryElements.length) && (
        <TechnologySummary
          header={technologySummarySection.header}
          items={technologySummarySection.summaryElements}
        />
      )}

      {Boolean(testimonialsSection && testimonialsSection.testimonials.length) && (
        <Testimonials
          header={testimonialsSection.header}
          testimonials={testimonialsSection.testimonials}
          showFirstWide
        />
      )}

      {Boolean(caseStudiesSection && caseStudiesSection.caseStudies.length) && (
        <CaseStudies
          header={caseStudiesSection.header}
          caseStudiesEntries={caseStudiesSection.caseStudies}
          raw={_rawCaseStudiesSection}
        />
      )}

      {Boolean(articlesSectionBlogPosts?.length) && (
        <Articles
          header={articlesSection.header}
          raw={_rawArticlesSection}
          blogPosts={articlesSectionBlogPosts}
        />
      )}

      {Boolean(linkedServicesSection && linkedServicesSection.servicesPages.length) && (
        <OtherServices
          header={linkedServicesSection.header}
          services={linkedServicesSection.servicesPages}
        />
      )}

      <FeaturedOn />

      {Boolean(contactSection && contactSection.buttonLabel) && (
        <ContactUs
          theme="lightToDark"
          header={contactSection.header}
          buttonLabel={contactSection.buttonLabel}
        />
      )}
    </Layout>
  )
}

export function Head({ location, data: { sanityTechnology } }) {
  const {
    page: { seo },
  } = sanityTechnology

  return <SEO location={location} {...seo} />
}

export default TechnologyPage
