import React from 'react';
import styled from 'styled-components';

import Image from '../../Image';

import { breakpoints, colors } from '../../../../constants/style';

const SolutionEntryWrapper = styled('div')`
  padding: 2.4375rem;
  position: relative;
  text-align: center;
  background-color: #1d0c42;
  cursor: default;

  /* this is to make item a square */
  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
    position: relative;
    z-index: -1;
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(140deg, #571dc0 -48%, #2c0a72 103%);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
  }

  .solution__icon-wrapper {
    height: 7.125rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 40%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    transition: top 0.3s ease-in-out;
  }

  .solution__icon {
    width: 98px;
    flex: 0 0 98px;
  }

  .solution__title {
    margin-top: 1.25rem;
    font-family: "BioSans-SemiBold", sans-serif;
    font-size: 1.375rem;
    color: #ffffff;
  }

  .solution__description {
    position: absolute;
    left: 0;
    top: 80%;
    width: 100%;
    padding: 0 2.4375rem;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 1rem;
    color: ${colors.mutedLighter};
    opacity: 0;
    transition: top 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }

  &:hover {
    &::before {
      opacity: 1;
    }

    .solution__icon-wrapper {
      top: 30%;
    }
    .solution__description {
      top: 65%;
      opacity: 1;
    }
  }

  @media only screen and (min-width: ${parseInt(breakpoints.desktopSmall, 10) + 1}px) and (max-width: ${breakpoints.desktopLarge}) {
    .solution__icon-wrapper {
      height: auto;
      top: 48%;
    }

    .solution__icon {
      width: 60px;
      flex: 0 0 60px;
    }

    .solution__title {
      font-size: 1.125rem;
      margin-top: 0.75rem;
    }

    .solution__description {
      font-size: 0.875rem;
      padding: 0 1rem;
    }

    &:hover {
      .solution__description {
        top: 56%;
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    height: 100%;

    &::after {
      display: none;
    }

    &::before {
      opacity: 1;
    }

    .solution__icon-wrapper {
      position: relative;
      left: 0;
      top: 0;
      height: auto;
      transform: translate(0, 0);
    }

    .solution__description {
      position: relative;
      top: 0;
      opacity: 1;
      margin-top: 1rem;
      padding: 0;
    }

    &:hover {
      .solution__icon-wrapper {
        top: 0;
      }
      .solution__description {
        top: 0;
      }
    }
  }
`;

const SolutionEntry = ({ icon, title, description }) => {
  return (
    <SolutionEntryWrapper>
      <div className="solution__icon-wrapper">
        <div className="solution__icon">
          <Image image={icon} />
        </div>
        <div className="solution__title">{title}</div>
      </div>
      <div className="solution__description">{description}</div>
    </SolutionEntryWrapper>
  );
};

export default SolutionEntry;
