import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import PropTypes from 'prop-types';
import StyledInput, {
  phoneInputInlineStyle,
  phoneInputBorderMouseOver,
  phoneInputButtonInlineStyle,
  phoneInputBorderMouseOut,
  phoneInputBorderError,
} from './styled';
import { useFormContext } from 'react-hook-form';
import { StyledErrorMsg, StyledPreLabel, StyledErrorContainer, StyledReqAsterisk } from '../commonFormStyle';

const InputPhone = ({
  label = '',
  required = false,
  inputClass = '',
  buttonClass = '',
  errorMessage = '',
  inputName,
  preLabel = '',
  placeholder = '',
  showError = true,
  initialValue = '',
  validationOptions = {},
}) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  const [isHovering, setIsHovering] = useState(false);
  const [inputBorder, setInputBorder] = useState(false);

  useEffect(() => {
    if (isHovering) {
      setInputBorder(phoneInputBorderMouseOver);
    } else if (showError && !!errors[inputName]) {
      setInputBorder(phoneInputBorderError);
    } else {
      setInputBorder(phoneInputBorderMouseOut);
    }
  }, [isHovering, errors]);

  const dynamicErrorMessage = errorMessage || `Field is required`;

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <StyledInput isError={showError && !!errors[inputName]}>
      <label htmlFor={inputName} className="input-label">
        {required && <StyledReqAsterisk>*&nbsp;</StyledReqAsterisk>}
        {preLabel && <StyledPreLabel isError={showError && !!errors[inputName]}>{preLabel}&nbsp;</StyledPreLabel>}
        {label}
      </label>
      <PhoneInput
        country={'pl'}
        inputProps={{
          name: inputName,
          label,
          id: inputName,
          placeholder: placeholder,
          onMouseEnter: handleMouseEnter,
          onMouseLeave: handleMouseLeave,
        }}
        // https://www.npmjs.com/package/react-phone-input-2#events
        {...register(inputName, { required, ...validationOptions })}
        onChange={(value) => setValue(inputName, value)}
        inputClass={`phone-input-ref ${inputClass}`}
        buttonClass={`phone-input-country-btn ${buttonClass}`}
        containerClass="phone-input-wrapper"
        searchClass="phone-input-search"
        value={initialValue ? initialValue : '48'}
        inputStyle={{ ...phoneInputInlineStyle, border: inputBorder }} //this inline styles are workaround for bug with incorrect styles, this styles are duplication of other input styles. This Bug is rare but occurres more often for new users.
        buttonStyle={phoneInputButtonInlineStyle} //this inline styles are workaround for bug with incorrect styles. This Bug is rare but occurres more often for new users.
      />
      <StyledErrorContainer>
        {showError && errors[inputName] && <StyledErrorMsg>{errors[inputName].message || dynamicErrorMessage}</StyledErrorMsg>}
      </StyledErrorContainer>
    </StyledInput>
  );
};

InputPhone.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  preLabel: PropTypes.string,
  inputName: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
  buttonClass: PropTypes.string,
  inputClass: PropTypes.string,
  showError: PropTypes.bool,
  initialValue: PropTypes.string,
  validationOptions: PropTypes.object,
};

export default InputPhone;
