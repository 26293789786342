import React from 'react';
import styled, { css } from 'styled-components';
import commonTooltipStyles from '../TooltipCommonStyles';

const gap = css`
  6px
`;

const StyledTooltipWrapper = styled('div')`
  position: relative;
  width: fit-content;

  &:hover::before {
    opacity: ${({ isVisible }) => {
      return isVisible ? '1' : '0';
    }};
    z-index: ${({ isVisible }) => {
      return isVisible ? '20' : '-20';
    }};
  }

  &::before {
    ${commonTooltipStyles};
    content: '${({ content }) => content}';
    color: #ffffff;
    transition: all 0.3s;
    ${({ position }) => {
      switch (position) {
        case 'top':
          return `
            bottom: calc(100% + ${gap});
            left: 50%;
          `;
        case 'right':
          return `
            transform: translateX(0);
            transform: translateY(-50%);
            left: calc(100% + ${gap});
            top: 50%;
          `;
        case 'left':
          return `
            transform: translateX(0);
            transform: translateY(-50%);
            right: calc(100% + ${gap});
            top: 50%;
          `;
        default:
          return `
            top: calc(100% + ${gap});
            left: 50%;
          `;
      }
    }}
  }

  ${({ passStyle }) => passStyle}
`;

const TooltipWrapper = ({ children, content, position = 'bottom', isVisible = true, passStyle }) => {
  return (
    <StyledTooltipWrapper position={position} content={content} isVisible={isVisible} passStyle={passStyle}>
      {children}
    </StyledTooltipWrapper>
  );
};

export default TooltipWrapper;
