import { useStaticQuery, graphql } from 'gatsby'

export const useEnvelopImageQuery = () => {
  const envelopeImg = useStaticQuery(graphql`
    {
      envelopeImg: file(relativePath: { eq: "contact-post.png" }) {
        childImageSharp {
          gatsbyImageData(formats: WEBP, placeholder: DOMINANT_COLOR)
        }
      }
    }
  `)
  return envelopeImg?.envelopeImg
}
