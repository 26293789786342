import { OverviewItemProps } from '../../types'
import styles from './overviewForCaseStudy.module.scss'

const OverviewItem = ({ label, value, renderFunction }: OverviewItemProps) => {
  return (
    <div className={styles.overviewItemWrapper}>
      {label && <p className={`${styles.overviewItemLabel} overline-p3`}>{label}</p>}
      <p className={`${styles.overviewItemText} p2`}>{renderFunction ? renderFunction() : value}</p>
    </div>
  )
}

export default OverviewItem
