export const shareToTwitter = (url) => {
  return `https://twitter.com/share?url=${url}`
}

export const shareToFacebook = (url) => {
  return `https://www.facebook.com/sharer/sharer.php?u=${url}`
}

export const shareToLinkedIn = (url) => {
  return `https://www.linkedin.com/shareArticle?url=${url}`
}
