import { css } from 'styled-components'

export const colors = {
  primary: '#602BE9',
  primaryRGB: '96, 43, 233',
  secondary: '#00d69e',
  secondaryRGB: '0, 214, 158',
  muted: '#8b8b98',
  mutedDark: '#7B7B8E',
  mutedLight: '#9393a7',
  mutedLighter: '#ceced9',
  background: '#0b0618',
  backgroundMilky: '#f1f1f4',
  backgroundLight: '#f8f8fc',
  backgroundDark: '#0c0121',
  backgroundGrey: '#f6f6fb',
  backgroundPurple: '#3e118a',
  navbarBackground: '#202020',
  sectionTitleLight: '#FFFFFF',
  sectionTitleDark: '#000000',
  titlePurple: '#574b76',
  titleDark: '#100727',
  tileBackground: '#1a0b3b',
  purpleGray: '#727292',
  blogText: '#363845',
  grayLight: '#c3c2d4',
  textLight: '#4E4D6C',
  blurple: '#471FC1',
  shadow: '#D4D4E1',
  backgroundGradients: {
    dark: {
      start: '#070413',
      end: '#100627',
    },
    blurple: {
      start: '#7e55f0',
      end: '#471FC1',
    },
  },
  error: '#c10000',
  success: '#00c100',
}

export const breakpoints = {
  mobileSmall: '375px', // galaxy 6, iphone 6/7/8
  mobile: '415px', // iphone 6/7/8 Plus
  mobileLarge: '744px',
  tablet: '810px', // iPad
  desktopSmall: '1024px', // small desktop screens
  desktop: '1280px', // laptop with MDPI screen
  desktopMac: '1366px', // laptop with MDPI screen
  desktopLarge: '1440px', // laptop with hDPI screen
  desktopLargest: '1680px',
  desktopMax: '1920px',
}

export const containerMaxWidth = '1576px'

export const targetIE = '(-ms-high-contrast: none), (-ms-high-contrast: active)'

export const zIndexes = {
  navigation: 100,
  technologiesItem: 2,
  technologiesItemAccent: 1,
  testimonialsHeader: 10,
  contactUs: 2,
  cookies: 99,
  modal: { background: 2000 },
}

const externalContentStyleMargins = {
  desktop: {
    bottomMargin: '2.5rem',
    topHeadingMargin: '3.75rem',
  },
  mobile: {
    bottomMargin: '1.5rem',
    topHeadingMargin: '2.5rem',
  },
}

export const blockContentStyle = css`
  h1,
  h2,
  h3 {
    margin-top: ${externalContentStyleMargins.desktop.topHeadingMargin};
  }

  h1,
  h2,
  h3,
  p,
  figure,
  blockquote,
  ol,
  ul {
    margin-bottom: ${externalContentStyleMargins.desktop.bottomMargin};
  }

  h1,
  h2,
  h3 {
    font-family: BioSans-Regular, Roboto-Regular, sans-serif;
    color: ${colors.titleDark};
    margin-top: ${externalContentStyleMargins.desktop.topHeadingMargin};
    margin-bottom: ${externalContentStyleMargins.desktop.bottomMargin};
  }

  h1 {
    font-size: 2.625rem;
  }
  h2 {
    font-size: 2.1rem;
  }
  h3 {
    font-size: 1.7rem;
  }

  p {
    font-family: Roboto-Regular, sans-serif;
    font-size: 1.25rem;
    line-height: 1.8;
    letter-spacing: 0.29px;
    color: #363845;
  }

  a {
    color: #5e1fe4;

    &:hover {
      color: ${colors.secondary};
    }
  }

  blockquote {
    border-left: solid 4px var(--t1-overline);
    padding-left: 3rem;
    font-family: DroidSerif, serif;
    font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: 0.34px;

    /*added because of other blockquote styling on blog post*/
    &::before {
      display: none;
    }
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    width: 100%;
    h1,
    h2,
    h3 {
      margin-top: ${externalContentStyleMargins.mobile.topHeadingMargin};
    }

    h1,
    h2,
    h3,
    p,
    figure {
      margin-bottom: ${externalContentStyleMargins.mobile.bottomMargin};
    }
  }
`

export const externalContentStyle = css`
  ${blockContentStyle}

  ul,
  ol {
    font-family: Roboto-Regular, sans-serif;
    font-size: 1.25rem;
    letter-spacing: 0.29px;
    color: #363845;
    ul,
    ol {
      margin-left: 2rem;
    }
  }

  ul {
    list-style: none;
    padding-left: 0;

    li {
      padding-left: 1em;
      margin-bottom: 0.5rem;
      line-height: 1.8;

      // this affect /privacy-policy/ and blog with bullet points and careers have to handled properly
      &:before {
        content: '•';
        color: #5e20e4;
        padding-right: 0.5rem;
      }
    }
  }

  ol {
    list-style-type: decimal;
    counter-reset: item;
    padding-left: 0;

    li {
      margin-bottom: 0.5rem;
      display: block;
      line-height: 1.8;
    }

    li:before {
      content: counter(item) '. ';
      counter-increment: item;
      color: #5e20e4;
    }
  }
`

export const fullBulletListStyle = css`
  margin-left: 20px;
  padding: 4px 0;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 8px;
    background: linear-gradient(90deg, #8658eb, #6831e4);
    display: inline-block;
    left: -20px;
    transform: translateY(-50%);
    top: 16px;
  }
`
