import React from 'react';
import propTypes from 'prop-types';
import IconArrowBack from '../../../../components/icons/IconArrowBack';

import { BackToLinkStyle } from './styled';

const BackToLink = ({ href, children, className }) => (
  <BackToLinkStyle to={href} className={className}>
    <IconArrowBack />
    {children}
  </BackToLinkStyle>
);

BackToLink.propTypes = {
  href: propTypes.string.isRequired,
  children: propTypes.string.isRequired,
  className: propTypes.string,
};

BackToLink.defaultProps = {
  children: 'Back to Home Page',
  href: '/',
};

export default BackToLink;
