import React from 'react'
import Fade from 'react-reveal/Fade'
import Image from '../../../../Image'

import SanityBlockContent from '../../../../SanityBlockContent'

import CaseStudiesEntryWrapper from './../CaseStudiesEntry/styled'

import { breakpoints } from '../../../../../../constants/style'

const EmptyCaseStudiesEntry = ({
  description,
  title,
  imageBackgroundColorStart,
  imageBackgroundColorEnd,
  tags,
  image: mainImage,
}) => {

  return (
    <CaseStudiesEntryWrapper
      className="casestudies__item"
      imageBackgroundColorStart={imageBackgroundColorStart}
      imageBackgroundColorEnd={imageBackgroundColorEnd}
    >
      <Fade opposite>
        <div className="casestudies__text-wrapper">
          <h3 className="casestudies__item-header">
            {title?.length ? title[0].children[0].text : ''}
          </h3>
          <SanityBlockContent
            className="casestudies__item-description"
            blocks={description?.length ? description[0] : ''}
          />
          {Boolean(tags) && (
            <div className="casestudies__item-tags">
              <div className="casestudies__item-tag--first">{tags[0]}</div>
              <div className="casestudies__item-tag--labels">
                {tags.map((tag, index) =>
                  index > 0 ? (
                    <div key={tag} className="casestudies__item-tag--label">
                      {tag}
                    </div>
                  ) : null,
                )}
              </div>
              <div className="casestudies__shadow">{tags[tags.length - 1]}</div>
            </div>
          )}
        </div>
        <div className="casestudies__image-wrapper">
          <Image
            className="casestudies__image"
            alt={(mainImage)?.alt || `case study image`}
            image={mainImage}
            sizes={`(max-width: ${breakpoints.desktop}) 100vw, 50vw`}
          />
        </div>
      </Fade>
    </CaseStudiesEntryWrapper>
  )
}

export default EmptyCaseStudiesEntry
