import React, { useState } from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

import { colors, breakpoints } from '../../../../../constants/style';
import useWindowSize from '../../../../hooks/useWindowSize';

const StepStyled = styled('div')`
  display: flex;
  justify-content: center;

  .description,
  .fill {
    width: calc(0.5 * (100% - 436px));
    max-width: 500px;
  }

  .description {
    padding-left: 95px;
    max-height: 322px;
    overflow-y: visible;

    .image {
      img {
        max-width: 44px;
        max-height: 44px;
      }
    }

    .position {
      margin-top: 2.1875rem;
      font-family: BioSans-SemiBold, sans-serif;
      font-size: 1.25rem;
      color: #766c91;
    }

    h3 {
      margin-top: 1.5rem;
      margin-bottom: 0;
      font-family: BioSans-Regular, sans-serif;
      font-size: 1.625rem;
      color: ${colors.sectionTitleLight};
      text-align: left;
    }

    p {
      margin-top: 2.4375rem;
      font-family: Roboto-Regular, sans-serif;
      font-size: 1.125rem;
      line-height: 2.11rem;
      color: #9393a7;
      text-align: left;
    }
  }

  .timeline {
    width: 436px;
    padding: 26px 83px;
    background: #0c0121;
  }

  .timeline-box {
    width: 280px;
    height: 280px;
    padding: 32px;
    background: linear-gradient(45deg, #200e47, #2a086a);
    border: 2px solid transparent;

    .timeline-box__image {
      text-align: right;

      img {
        max-width: 75px;
        max-height: 80px;
      }
    }

    .timeline-box__number {
      font-family: BioSans-Regular, sans-serif;
      font-size: 1rem;
      color: #aca2bf;
    }

    .timeline-box__description {
      margin-top: 1.25rem;
      font-family: Roboto-Regular, sans-serif;
      font-size: 1.1875rem;
      line-height: 1.75rem;
      color: #ffffff;
    }
  }
  .timeline-box--light {
    background: linear-gradient(45deg, #ebf4fa, #fbfbfc);

    .timeline-box__number {
      color: #5f38fa;
    }

    .timeline-box__description {
      color: #0f0823;
    }
  }
  .timeline-box--dark {
    background: none;
    border: 2px dashed #422b6e;
  }

  &:nth-child(odd) {
    .description {
      padding-left: 0;
      padding-right: 95px;
    }
  }

  &:nth-child(even) {
    flex-direction: row-reverse;
  }

  &:first-child {
    .timeline {
      padding-top: 4.875rem;
    }
  }

  &:last-child {
    min-height: 322px;

    .timeline {
      position: relative;
      background-image: linear-gradient(178deg, #0c0121 4%, #160837 103%);
    }

    .timeline-box {
      position: absolute;
      left: 0;
      bottom: -55px;
      width: 100%;
      height: 220px;
      padding: 0;
      margin: 0;
      background: none;
      border: none;
      transform: scaleY(0.5) translateY(100%);
      overflow: hidden;

      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        bottom: 65px;
        width: 310px;
        height: 310px;
        background: linear-gradient(164deg, #160837 0%, #170839 103%);
        transform: translateX(-50%) rotate(-45deg);
        transform-origin: 50% 50%;
      }
    }
  }

  @media screen and (max-width: ${breakpoints.desktopLargest}) {
    .description {
      max-height: 240px;
      padding-left: 2rem;

      h3 {
        font-size: 1.2rem;
      }

      p {
        font-size: 1.1rem;
        line-height: 1.6rem;
      }
    }

    &:nth-child(odd) {
      .description {
        padding-left: 0;
        padding-right: 2rem;
      }
    }
  }

  @media screen and (max-width: ${breakpoints.desktop}) {
    .description,
    .fill {
      width: calc(0.5 * (100% - 280px));
    }

    .timeline {
      padding: 20px 40px;
      width: 280px;
    }

    .timeline-box {
      width: 200px;
      height: 200px;
      padding: 20px;

      .timeline-box__image {
        img {
          width: 50px;
          height: 50px;
        }
      }

      .box__description {
        line-height: normal;
        font-size: 1.1rem;
      }
    }

    &:last-child {
      min-height: 240px;

      .timeline-box {
        bottom: -41px;
        height: 165px;

        &::after {
          bottom: 65px;
          width: 200px;
          height: 200px;
        }
      }
    }
  }

  @media screen and (max-width: ${breakpoints.desktopSmall}) {
    justify-content: flex-start;

    .description {
      width: 100%;
      max-width: none;
      max-height: none;
      padding: 2rem 0;
    }

    .timeline,
    .fill {
      display: none;
    }

    &:nth-child(even) {
      flex-direction: row;
      justify-content: flex-start;

      .description {
        padding: 1rem 0;
      }
    }

    &:last-child {
      display: none;
    }
  }
`;

const Step = (props) => {
  const { icon, name, description, type, position } = props;
  const { isSmallerThanDesktopSmall } = useWindowSize();
  const [animatedElementindex, setAnimatedElementIndex] = useState(0);
  const paddedPosition = typeof position !== 'undefined' ? (position + 1).toString().padStart(2, '0') : '';
  return (
    <StepStyled>
      <Fade
        left={position % 2 === 0}
        right={position % 2 !== 0}
        distance="100px"
        appear={!isSmallerThanDesktopSmall}
        onReveal={() => {
          setAnimatedElementIndex(animatedElementindex + 1);
        }}
        duration={500}
        wait={400}
        key={`step-${position}`}
      >
        <div className="description">
          <div className="image">{icon && <img src={icon.asset.url} alt="" />}</div>
          <div className="position">{paddedPosition}</div>
          <h3>{name}</h3>
          <p>{description}</p>
        </div>
      </Fade>

      <div className="timeline">
        <div className={`timeline-box timeline-box--${type}`}>
          <div className="timeline-box__image">{icon && <img src={icon.asset.url} alt="" />}</div>
          <div className="timeline-box__number">{paddedPosition}</div>
          <div className="timeline-box__description">{name}</div>
        </div>
      </div>

      <div className="fill" />
    </StepStyled>
  );
};

export default Step;
