import React from 'react';
import styled from 'styled-components';
import { breakpoints, colors } from '../../../../constants/style';

import WaveSVG from '../../../../static/images/wave-light-2.svg';
import BgWrapperSVG from '../../../../static/images/bg-benefits.svg';
import BgCircleSVG from '../../../../static/images/bg-circle.svg';

const BenefitsSection = styled('section')`
  padding-top: ${({ removeTopPadding }) => (removeTopPadding ? 0 : '17.625rem')};
  padding-bottom: 8.125rem;
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    background: url(${WaveSVG}) no-repeat left bottom;
  }

  .benefits__header {
    position: relative;
    text-align: left;

    &::before {
      content: '';
      display: block;
      margin: 0 auto 2.5rem 0;
      width: 50px;
      height: 5px;
      background: ${colors.primary};
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 3.5rem;
      left: 8.5rem;
      width: 4rem;
      height: 100%;
      background: url(${BgCircleSVG}) no-repeat left top;
    }
  }

  .benefits__wrapper {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 8.875rem;
    margin-bottom: -2.5rem;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 3rem;
      bottom: 11.5rem;
      z-index: 0;
      background: url(${BgWrapperSVG}) no-repeat right top;
    }

    .benefits__item {
      background: #fff;
      box-shadow: 0 42px 58px 0 #f4f4f7;
      width: 49%;
      margin-bottom: 3.125rem;
      padding: 3.625rem 12.375rem 6.25rem 5rem;
      position: relative;
      z-index: 1;

      .benefits__item-header {
        font-size: 1.25rem;
        line-height: 1.25rem;
        font-weight: 600;
        margin-bottom: 2.75rem;
      }

      .benefits__item-header--index {
        color: ${colors.primary};
      }

      .benefits__item-description {
        color: ${colors.mutedDark};
        font-size: 1.125rem;
        line-height: 1.78;
      }

      &:nth-of-type(odd) {
        transform: translateY(-4.375rem);
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    @media only screen and (max-width: ${breakpoints.desktopLargest}) {
      .benefits__item {
        padding: 3.625rem 8rem 3.625rem 5rem;
      }
    }

    @media only screen and (max-width: ${breakpoints.desktop}) {
      .benefits__item {
        padding: 2.5rem 4rem 2.5rem 3rem;

        .benefits__item-description {
          font-size: 1rem;
        }
      }
    }

    @media only screen and (max-width: ${breakpoints.tablet}) {
      margin-top: 4rem;
      flex-direction: column;

      &::before {
        content: none;
      }

      .benefits__item {
        padding: 2.5rem 4rem 2.5rem 3rem;
        margin-bottom: 1.5rem;

        width: 100%;

        .benefits__item-header {
          font-size: 1rem;
        }

        .benefits__item-description {
          font-size: 0.75rem;
        }

        &:nth-of-type(odd) {
          transform: none;
        }
      }
    }
  }
`;

const Benefits = ({ header, benefits, removeTopPadding }) => {
  const getBenefitIndex = (index) => {
    const benefitIndex = index + 1;
    return String(benefitIndex).padStart(2, '0');
  };

  return (
    <BenefitsSection removeTopPadding={removeTopPadding}>
      <h2 className="benefits__header">{header}</h2>
      <div className="benefits__wrapper">
        {benefits.map(({ header, description }, index) => (
          <div className="benefits__item" key={`benefits-item-${index}`}>
            <h3 className="benefits__item-header">
              <span className="benefits__item-header--index">{getBenefitIndex(index)} </span>
              {header}
            </h3>
            <p className="benefits__item-description">{description}</p>
          </div>
        ))}
      </div>
    </BenefitsSection>
  );
};

export default Benefits;
