import React from 'react'
import { Link } from '../../../../components/Link'
import { useDictionaryQuery } from 'hooks/graphql/page'

import SanityBlockContent from '../../../../components/SanityBlockContent'
import InlineList from 'components/InlineList'
import { ColumnIndex, OverviewForCaseStudyProps, OverviewItemsListProps } from '../../types'
import styles from './overviewForCaseStudy.module.scss'
import OverviewItem from './OverviewItem'

const OverviewForCaseStudy = ({
  description,
  timeline,
  websiteName,
  websiteLink,
  country,
  industry,
  servicesList,
  projectType,
  technologies,
}: OverviewForCaseStudyProps) => {
  const {
    caseStudy: {
      websiteLabel,
      servicesLabel,
      typeLabel,
      industryLabel,
      countryLabel,
      technologiesLabel,
      timelineLabel,
    },
  } = useDictionaryQuery()

  const services = servicesList?.split(',')
  const technologiesList = technologies?.map(({ abstract }) => abstract?.name)

  const overviewItems: OverviewItemsListProps = {
    leftColumn: [
      {
        label: websiteLabel,
        value: websiteName,
        renderFunction: () => (
          <Link
            to={websiteLink}
            targetBlank={true}
            className={`${styles.overviewItemText} ${styles.link} p2`}
          >
            {websiteName}
          </Link>
        ),
      },
      {
        label: timelineLabel || 'Timeline',
        value: timeline?.startDate,
        renderFunction: () => (
          <p className={`${styles.overviewItemText} p2`}>
            {timeline?.startDate} - {timeline?.endDate || 'Ongoing'}
          </p>
        ),
      },
      {
        label: typeLabel,
        value: projectType,
      },
      {
        label: countryLabel || 'Country',
        value: country,
      },
      {
        label: industryLabel,
        value: industry,
      },
    ],
    rightColumn: [
      {
        label: '',
        value: description,
        renderFunction: () => (
          <SanityBlockContent
            className={`${styles.overviewItemText} ${styles.overviewDescription}`}
            blocks={description}
          />
        ),
      },
      {
        label: servicesLabel,
        value: servicesList,
        renderFunction: () => (
          <InlineList items={services || []} className={`${styles.inlineList}`} />
        ),
      },
      {
        label: technologiesLabel || 'Technologies',
        value: technologiesList?.join(', '),
        renderFunction: () => (
          <InlineList items={technologiesList} className={`${styles.inlineList}`} />
        ),
      },
    ],
  }

  const visibleOverviewItems = (columnIndex: ColumnIndex) =>
    overviewItems[columnIndex].filter(
      ({ renderFunction, value }) => (renderFunction || value) && !!value,
    )

  return (
    <section className={styles.overviewWrapper}>
      <div className={styles.leftColumn}>
        {visibleOverviewItems('leftColumn').map((item, index) => (
          <OverviewItem key={index} {...item} />
        ))}
      </div>
      <div className={styles.rightColumn}>
        {visibleOverviewItems('rightColumn').map((item, index) => (
          <OverviewItem key={index} {...item} />
        ))}
      </div>
    </section>
  )
}

export default OverviewForCaseStudy
