import styled from 'styled-components'

const commonStyles = `
  font-size: 12px;
  line-height: normal;
  text-align: center;
  font-family: 'BioSans-Regular', sans-serif;
  transition: opacity 0.2s ease-in-out 0s;
 
`

export const StyledCookies = styled.div`
  z-index: 1000;
`

export const CookiesBar = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #2d2b37;
  color: #fff;
  ${commonStyles}
  @media (max-width: 649px) {
    flex-direction: column;
  }
`

export const ButtonsBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
`

export const AcceptButton = styled.button`
  background: linear-gradient(90deg, rgb(127, 79, 234), rgb(97, 40, 227));
  color: #fff;
  padding: 10px 20px;
  margin: 2px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  text-transform: uppercase;
  width: auto;
  text-wrap: nowrap;
  ${commonStyles}
  &:hover {
    opacity: 0.8;
  }
`

export const CookiesDescription = styled.p`
  font-size: 12px;
`

export const CookiesLink = styled.a`
  color: rgb(0, 214, 158);
  transition: all 0.2s ease-in-out 0s;
  &:hover {
    color: rgb(96, 43, 233);
  }
`

export const SettingsModal = styled.div`
  z-index: 1000;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  color: #100727;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  min-width: 600px;
  h1 {
    font-size: 20px;
    font-weight: 700;
  }
  p {
    font-size: 12px;
    margin-bottom: 10px;
  }
  @media (max-width: 649px) {
    min-width: 0;
    width: 96%;
  }
`

export const PreferenceBox = styled.div`
  display: grid;
  grid-template-columns: 0.6fr 1fr 0.2fr;
  grid-template-rows: 0.5fr;
  border: 1px solid #ccc;
  padding: 20px;
  margin-bottom: 4px;
  align-items: center;
  h1 {
    font-size: 16px;
    font-weight: 700;
    grid-area: 1 / 1 / 2 / 2;
  }
  p {
    font-size: 12px;
    margin: 0;
    grid-area: 1 / 2 / 2 / 3;
    @media (max-width: 649px) {
      grid-area: 2 / 1 / 3 / 2;
    }
  }
  @media (max-width: 649px) {
    grid-template-columns: 1fr 0.2fr;
    grid-template-rows: 0.5fr 1fr;
  }
`

export const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  grid-area: 1 / 3 / 2 / 4;
  @media (max-width: 649px) {
    grid-area: 2 / 2 / 3 / 3;
  }
`

export const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => (props.checked ? 'rgb(127, 79, 234)' : '#ccc')};
  transition: 0.4s;
  border-radius: 34px;
  &:hover {
    opacity: 0.8;
  }
  &:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: ${(props) => (props.checked ? '30px' : '4px')};
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
`

export const ConsentInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
  &:checked + ${Slider} {
    background-color: rgb(127, 79, 234);
  }
  &:focus + ${Slider} {
    box-shadow: 0 0 1px rgb(127, 79, 234);
  }
`

export const SettingsButtonsBox = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  button {
    padding: 10px 20px;
    background: linear-gradient(90deg, rgb(127, 79, 234), rgb(97, 40, 227));
    color: white;
    border: none;
    transition: all 0.2s ease-in-out 0s;
    &:hover {
      opacity: 0.8;
    }
  }
  button:first-child {
    margin-right: 10px;
  }
`

export const FloatingButton = styled.img`
  z-index: 2000;
  position: fixed;
  bottom: 5px;
  left: 5px;
  cursor: pointer;
  transition: all 0.2s ease-in-out 0s;
`
