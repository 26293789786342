import React from 'react';
import IconLoading from '../icons/IconLoading';
import styled from 'styled-components';

const LoaderWrapper = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  padding: 20rem;
  justify-content: center;
`;

const LoadingIndicator = () => (
  <LoaderWrapper>
    <IconLoading />
  </LoaderWrapper>
);

export default LoadingIndicator;
