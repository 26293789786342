import { graphql, useStaticQuery } from 'gatsby'

export const useServicesList = () => {
  const data = useStaticQuery(graphql`
    query servicesListQuery {
      sanityServicesPageList(language: { eq: "en" }) {
        hero {
          ...SanityHeroFragment
        }

        contactSection {
          header
          buttonLabel
        }

        releasedServicePages {
          parentService {
            servicesPages {
              id
            }
          }
          slug {
            current
          }
          globalInfo {
            name
            description
            skills
            icon {
              alt
              asset {
                url
                gatsbyImageData(width: 1400)
              }
            }
          }
        }

        seo {
          ...SeoFragment
        }
      }
    }
  `)
  const { hero, contactSection, seo, releasedServicePages } = data.sanityServicesPageList
  return {
    hero,
    contactSection,
    servicesList: releasedServicePages,
    seo,
  }
}
