import React, { useState } from 'react'
import styled from 'styled-components'
import { useDictionaryQuery } from 'hooks/graphql/page'

import Validator from '../../../utils/validator'
import Button from '../../Buttons'
import SanityBlockContent from '../../SanityBlockContent'
import IconLoading from '../../icons/IconLoading'
import { colors, breakpoints } from '../../../../constants/style'
import Checkbox, { StyledCheckbox } from '../../Form/Checkbox'
import InputNew, { StyledInputWrapperNew } from 'components/Form/Input/InputNew'
import Image from 'components/Image'
import TooltipSpan from '../../Tooltip/TooltipSpan';
import infoIcon from '../../../../static/images/icons/info-icon-pale.svg';
import infoIconHover from '../../../../static/images/icons/info-icon.svg';

const defaultFormData = {
  email: '',
  marketingAgreement: false,
}

const validationRules = {
  email: 'required|email',
  marketingAgreement: 'accepted',
}

const errorTexts = {
  responseError: 'There was an error while sending the form. Please try again later.',
  marketingAgreement: 'You need to accept personal data processing.',
}

const themes = {
  light: {
    background: colors.backgroundLight,
    title: colors.titleDark,
    description: colors.textLight,
    links: colors.primary,
    inputBg: '#fff',
  },
  dark: {
    background: `linear-gradient(
      135deg,
      ${colors.backgroundGradients.dark.end} 14.64%,
      ${colors.backgroundGradients.dark.start} 85.36%
    )`,
    title: '#fff',
    description: colors.grayLight,
    links: '#1BFDBF',
    inputBg: 'rgba(114, 114, 146, 0.5)',
    blur: '#7b51e6',
  },
}

export const StyledSubscribeSection = styled('section')`
  background: ${({ theme }) => theme.background};
  padding: 6.75rem 0 4.5rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  column-gap: 125px;

  .blur {
    background-color: ${({ theme }) => theme.blur && theme.blur};
    opacity: 0.2;
    filter: blur(200px);
    position: absolute;
    width: 638px;
    height: 383px;
    top: -150px;
    left: 50%;
    transform: translateX(-50%);
    display: ${({ theme }) => (theme.blur ? 'block' : 'none')};
  }

  .text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 1rem;
    color: ${({ theme }) => theme.title};
    text-align: left;
    width: 100%;
    max-width: 400px;
  }

  .subscribe__description {
    p {
      font-size: 1.313rem;
      letter-spacing: 0.26px;
      color: ${({ theme }) => theme.description};
      max-width: 700px;
    }
  }

  ${StyledCheckbox} {
    * {
      font-size: 0.75rem;
      line-height: 1.25rem;
    }
    a {
      color: ${({ theme }) => theme.links};
    }
    label {
      color: ${({ theme }) => theme.description};
      padding-right: 0;
      &::before {
        margin-right: 10px;
      }
    }
  }

  ${StyledInputWrapperNew} {
    label,
    input {
      color: ${({ theme }) => theme.title};
    }
    label {
      font-size: 0.875rem;
    }
    input {
      background-color: ${({ theme }) => theme.inputBg};
      &::placeholder {
        color: ${({ theme }) => theme.description};
      }
      &:focus {
        border-color: ${({ theme }) => theme.links};
      }
    }
  }

  .subscribe__form {
    display: flex;
    justify-content: space-between;
    column-gap: 1rem;

    .column-left {
      flex-basis: 69%;
    }

    .column-right {
      flex-basis: 29%;
    }

    .submit-button {
      margin-top: 30px;
      height: 49px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
    }

    .mobile-button {
      display: none;
    }

    .success {
      font-size: 2rem;
      color: ${({ theme }) => theme.title};
    }
  }

  &.success-screen {
    justify-content: center;
    padding: 4rem 0;
    .success-screen {
      &__message {
        color: ${({ theme }) => theme.title};
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        row-gap: 1rem;
      }
      &__image {
        width: 7.5rem;
        height: 7.5rem;
        margin-bottom: 2.1rem;
      }
      &__description {
        font-size: 1.313rem;
        line-height: 2.25rem;
        color: ${({ theme }) => theme.description};
      }
    }
    @media only screen and (max-width: ${breakpoints.tablet}) {
      padding: 4rem 2rem;
    }
  }

  @media only screen and (max-width: ${breakpoints.desktopMac}) {
    .text-container {
      flex: 1;
    }
    .subscribe__form-wrapper {
      display: flex;
      justify-content: flex-end;
      flex: 2;
    }
  }

  @media only screen and (max-width: ${breakpoints.desktopSmall}) {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .text-container {
      align-items: center;
      text-align: center;
      margin-bottom: 3.5rem;
      max-width: unset;
    }
    .subscribe__title {
      text-align: center;
    }
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    .subscribe__form {
      display: block;
      width: 100%;

      .column-right {
        display: flex;
        justify-content: center;
      }

      .submit-button {
        width: 15rem;
        margin-left: 0;
      }
    }

    .text-container {
      margin-bottom: 3rem;
    }
  }

  @media only screen and (max-width: ${breakpoints.mobile}) {
    padding: 5rem 2rem !important;

    .subscribe__form {
      .column-left {
        margin-bottom: 1rem;
      }
      .submit-button {
        display: none;
      }
      .mobile-button {
        display: block;
        width: 100% !important;
        margin-bottom: 1.813rem;
      }
    }
  }
`

const SubscribeSectionNew = ({ theme }) => {
  const {
    contactForm: { formInputEmail },
    subscribeForm,
    _rawSubscribeForm,
  } = useDictionaryQuery()

  let usedTheme = theme || _rawSubscribeForm.theme || 'dark'

  const [formData, setFormData] = useState(defaultFormData)

  const [errorMessages, setErrorMessages] = useState(defaultFormData)
  const [isFormSent, setIsFormSent] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const validation = new Validator(formData, validationRules, {
    accepted: 'This field is required.',
    email: errorMessages.email,
  })
  const handleInputChange = (e) => {
    const { type, value, checked, name } = e.target
    setFormData((currentData) => ({
      ...currentData,
      [name]: type === 'checkbox' ? checked : value,
    }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (validation.passes()) {
      setIsLoading(true)

      const data = { email: formData.email, sourcePage: window.location.href }

      try {
        const response = await fetch('/api/subscribe-form', {
          method: 'POST',
          body: JSON.stringify(data),
        })

        if (response.ok) {
          setIsFormSent(true)
        } else {
          setErrorMessages({
            email: errorTexts.responseError,
            marketingAgreement: false,
          })
        }
      } catch (error) {
        setErrorMessages({
          email: error.message,
          marketingAgreement: false,
        })
      } finally {
        setIsLoading(false)
      }
    } else {
      setErrorMessages(validation.errors.all())
    }
  }

  if (isFormSent) {
    return (
      <StyledSubscribeSection id="section-subscribe" theme={themes[usedTheme]} className="success-screen">
        <div className="success-screen__message">
          <Image
            image={subscribeForm?.thankYouMessage?.featuredImage}
            className="success-screen__image"
            alt="Thanks for subscribing"
          />
          <h1 className="success-screen__title">{subscribeForm.thankYouMessage.title}</h1>
          <SanityBlockContent
            blocks={_rawSubscribeForm?.thankYouMessage?.description}
            className="success-screen__description"
          />
        </div>
        <div className="blur"></div>
      </StyledSubscribeSection>
    )
  }

  return (
    <StyledSubscribeSection id="section-subscribe" theme={themes[usedTheme]}>
      <div className="text-container">
        <h2 className="subscribe__title">{subscribeForm.header}</h2>
        <div className="subscribe__description">
          <SanityBlockContent blocks={_rawSubscribeForm.description} />
        </div>
      </div>
      <div className="blur"></div>
      <div className="subscribe__form-wrapper">
        <form onSubmit={handleSubmit}>
          <div className="subscribe__form">
            <div className="column-left">
              <InputNew
                errorMessage={errorMessages.email}
                handleInputChange={handleInputChange}
                label={formInputEmail}
                value={formData.email}
                name="email"
                placeholder="e.g. john.ick@10clouds.com"
              />

              <Button className="submit-button mobile-button" variant="filled" type="submit">
                {subscribeForm.buttonLabel}
              </Button>
              <Checkbox
                name="marketingAgreement"
                variant="purple"
                isChecked={formData.marketingAgreement}
                onChange={handleInputChange}
                errorMessage={errorMessages.marketingAgreement}
              >
                <SanityBlockContent blocks={_rawSubscribeForm.checkboxDescription} />
                <TooltipSpan
                  icon={infoIcon}
                  iconHover={infoIconHover}
                  width={20}
                  height={20}
                  content={'I consent to use of my e-mail for obtaining direct marketing information in the Newsletter and I also consent to receiving such marketing information. The marketing information will concern products, services, events, blog posts and case studies of 10Clouds S.A. You can withdraw your consent at any time by either e-mailing 10Clouds or clicking “unsubscribe” button at the bottom of each Newsletter e-mail. Please however be informed that we will not be able to provide you with Newsletter anymore in such situation.'}
                  passStyle={`
                    max-width: 412px;
                    top: 4px;
                    @media screen and (max-width: 500px) {
                      left: -90px;
                    }
                    @media screen and (max-width: 360px) {
                      left: 88px;
                    }
                    @media screen and (max-width: 330px) {
                      left: 40px;
                    }
                  `}
                />
              </Checkbox>
            </div>
            <div className="column-right">
              <div className="form__submit">
                {isLoading ? (
                  <div className="loading">
                    <IconLoading />
                  </div>
                ) : (
                  <Button className="submit-button" variant="filled" type="submit">
                    {subscribeForm.buttonLabel}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </StyledSubscribeSection>
  )
}

export default SubscribeSectionNew
