import React from 'react';

import StyledAdvantagesEntry from './styled';
import Image from '../../../Image';

const AdvantagesEntry = ({ advantage }) => {
  const { theme, title, subtitle, image } = advantage;

  return (
    <StyledAdvantagesEntry theme={theme || 'light'}>
      <div>
        <div className={'title'}>{title}</div>
        <div className={'subtitle'}>{subtitle}</div>
      </div>
      <Image {...{ image }}></Image>
    </StyledAdvantagesEntry>
  );
};

export default AdvantagesEntry;
