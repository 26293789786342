import { getGatsbyImageData } from 'gatsby-source-sanity';

const getImageFromRefInArray = (array, maxWidth, sanityConfig) => {
  return array.map((item) => {
    const imageRef = item.icon?.asset?._ref ? item.icon.asset._ref : null;
    if (imageRef === null) return item;
    const gatsbyImageData = getGatsbyImageData(imageRef, { maxWidth }, sanityConfig);
    item.icon = { alt: 'icon', asset: gatsbyImageData ? { url: gatsbyImageData.images.fallback.src } : {} };
    return item;
  });
};

export default getImageFromRefInArray;
