import React from 'react';
import styled from 'styled-components';

import OtherServiceEntry from './components/OtherServiceEntry';
import { services } from '../../../../constants/links';
import { colors, breakpoints } from '../../../../constants/style';
import { navbarMinHeight } from '../../../features/layout/components/commonLayoutStyle';
import { NavLinkButton } from '../../Link';
import { parseSlug } from '../../../utils/slug';

const servicesUrl = services.url;

const OtherServicesWithoutSliderSection = styled('section')`
  padding-top: 8.125rem;
  padding-bottom: ${({ isOnMainPage }) => (isOnMainPage ? '8.125rem' : '1.125rem')};

  .services__header {
    margin-bottom: 4.8125rem;
    ${({ isOnMainPage }) => isOnMainPage && 'text-align: center;'}

    &:before {
      content: '';
      display: block;
      margin-bottom: 3.5rem;
      ${({ isOnMainPage }) => isOnMainPage && 'margin-left: auto; margin-right: auto;'}
      width: 50px;
      height: 5px;
      background: ${colors.primary};
    }
  }

  .services {
    display: flex;
    justify-content: space-between;
    margin: 0 -1.125rem;

    &:after {
      content: '';
      margin-right: auto;
    }

    .services__item {
      width: 25%;
      padding: 1.125rem;

      &:nth-child(even) {
        padding-top: 8.75rem;
      }

      &:nth-child(odd) {
        padding-bottom: 8.75rem;
      }
    }
  }

  .button {
    width: 14rem;
    margin: 4rem auto;

    > span {
      color: #0c0121;
    }
  }

  @media only screen and (max-width: ${breakpoints.desktopSmall}) {
    padding-top: ${navbarMinHeight};

    .services {
      flex-wrap: wrap;

      .services__item {
        width: 50%;

        &:nth-child(even),
        &:nth-child(odd) {
          padding: 1.125rem;
        }
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.mobile}) {
    .services {
      display: block;

      .services__item {
        width: 100%;
      }
    }
  }
`;

const compareServicesNames = (service1, service2) => (service1.globalInfo.name < service2.globalInfo.name ? 1 : -1);

const OtherServicesWithoutSlider = ({ header, services, isOnMainPage }) => {
  return (
    <OtherServicesWithoutSliderSection isOnMainPage={isOnMainPage}>
      {Boolean(header) && <h2 className="services__header">{header}</h2>}
      <div className="services">
        {services
          .sort((service1, service2) => compareServicesNames(service1, service2))
          .map((service) => {
            const {
              globalInfo: { name, description, icon },
              slug,
            } = service;
            return (
              <div className="services__item" key={`services-item-${name}`}>
                <OtherServiceEntry to={`/services/${parseSlug(slug.current)}`} icon={icon} name={name} description={description} bordered />
              </div>
            );
          })}
      </div>
      {!isOnMainPage && (
        <NavLinkButton to={servicesUrl} className="button" variant="bordered">
          Back to services
        </NavLinkButton>
      )}
    </OtherServicesWithoutSliderSection>
  );
};

export default OtherServicesWithoutSlider;
