export const checkLink = (query) => {
  return Boolean(query?.startsWith('https://') || query?.startsWith('http://'))
}

export const is10cDomain = (url) => Boolean(url?.startsWith('https://10clouds.com'))

export const urlWithoutProtocol = (url) => url.slice(url.indexOf('//') + 2)

export const isMailTo = (url) => Boolean(url?.startsWith('mailto:'))

export const getFirstPath = (url) => url.replace(/^\/|\/$/g, '').split('/')[0]