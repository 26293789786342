import React from 'react';
import styled from 'styled-components';

import Checkbox from '../../../components/NewForm/Checkbox';
import CheckboxLabel from '../../../components/NewForm/Checkbox/CheckboxLabel';
import SanityBlockContent from '../../../components/SanityBlockContent';
import TooltipSpan from '../../../components/Tooltip/TooltipSpan';
import infoIcon from '../../../../static/images/icons/info-icon-pale.svg';
import infoIconHover from '../../../../static/images/icons/info-icon.svg';

const StyledSpan = styled.span`
  line-height: 0;
  font-size: 0;
`;

const TermsAndAgreements = ({ rawContactUsForm }) => {
  return (
    <>
      <Checkbox
        inputName="isPrivacyPolicyAgreementAccepted"
        label={
          <CheckboxLabel inputName="isPrivacyPolicyAgreementAccepted">
            <SanityBlockContent
              blocks={[
                {
                  ...rawContactUsForm.disclaimer[0],
                  children: [...rawContactUsForm.disclaimer[0].children],
                },
              ]}
            />
          </CheckboxLabel>
        }
        isFieldRequired
        makeValueBoolean
        required
        showError={false}
        isTermsOfUse={true}
      />
      <Checkbox
        inputName="isSignNDAAccepted"
        makeValueBoolean
        isTermsOfUse={true}
        label={
          <StyledSpan>
            <CheckboxLabel inputName="isSignNDAAccepted">I would like to sign the NDA first (optional)</CheckboxLabel>
            <TooltipSpan
              icon={infoIcon}
              iconHover={infoIconHover}
              width={20}
              height={20}
              content={'We will send you an NDA document to sign'}
              passStyle={`
              top: 4px;
              @media screen and (max-width: 500px) {
                left: -90px;
              }
              @media screen and (max-width: 360px) {
                left: 88px;
              }
              @media screen and (max-width: 330px) {
                left: 40px;
              }
            `}
            />
          </StyledSpan>
        }
      />
      <Checkbox
        inputName="isMarketingAgreementAccepted"
        label="Yes! I'd like to be the first to hear the latest news and stories from 10Clouds. (optional)"
        makeValueBoolean
        isTermsOfUse={true}
      />
    </>
  );
};

export default TermsAndAgreements;
