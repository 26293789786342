import React from 'react'
import styled from 'styled-components'

import { breakpoints, colors } from '../../../../../constants/style'

const TestimonialsItem = styled('div').attrs({
  className: 'testimonials',
})`
  max-width: 65rem;
  padding-bottom: 1rem;

  .testimonials {
    &__stars {
      display: flex;
      margin-bottom: 2.1875rem;
      img {
        width: 29px;
        height: 29px;
        &:not(:last-child) {
          margin-right: 2px;
        }
      }
    }

    &__description {
      position: relative;
      font-size: 2.625rem;
      line-height: 3.5rem;
      @media screen and (max-width: ${breakpoints.mobileLarge}) {
        font-size: 2.375rem;
      }
    }

    &__author {
      margin-top: 3rem;
      font-size: 1.1875rem;
      color: ${colors.grayLight}};
      font-family: Roboto-Regular, sans-serif;
    }

    &__name {
      font-weight: 600;
    }

    &__image {
      width: 3.75rem;
      height: 3.75rem;
      position: relative;
      margin-right: 1.5625rem;
      overflow: hidden;
      border-radius: 50%;

      > img,
      .gatsby-image-wrapper,
      .gatsby-image-wrapper > img {
        max-width: 100%;
        height: auto;
        position: absolute;
      }
    }

    img {
      max-width: 100%;
      height: auto;
    }

    @media only screen and (max-width: ${breakpoints.mobileLarge}) {
      &__description {
        font-size: 2.375rem;
      }
    }
  }
`

const TestimonialEntry = ({ description, authorName, authorPosition }) => {
  return (
    <TestimonialsItem key={`testimonial-entry-${description}`}>
      <div className="testimonials__description">{description}</div>
      <div className="testimonials__author">
        <div className="testimonials__name">{authorName}</div>
        {authorPosition && <div className="testimonials__position">{authorPosition}</div>}
      </div>
    </TestimonialsItem>
  )
}

export default TestimonialEntry
