import React, { useState } from 'react'

import styled from 'styled-components'
import Fade from 'react-reveal/Fade'

import { breakpoints, colors } from '../../../../constants/style'
import { horizontalLayoutPadding } from '../../../features/layout/components/commonLayoutStyle'
import SectionHeader from '../../SectionHeader/SectionHeader'
import IconChevron from '../../icons/IconChevron'
import useWindowSize from '../../../hooks/useWindowSize'
import WaveSVG from '../../../assets/images/wave-light-4.png'
import SanityBlockContent from 'components/SanityBlockContent'

const ServicesDropdownListSection = styled('section').attrs({ className: 'servicesList' })`
  background-color: ${colors.backgroundLight};
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    display: block;
    width: 1206px;
    height: 728px;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
    background: url(${WaveSVG}) no-repeat left top;
  }

  .servicesList {
    &__wrapper {
      display: flex;
      gap: ${horizontalLayoutPadding};
      justify-content: center;
    }
    &__categories {
      width: 48.25rem;
    }
    &__names {
      border-right: 1px solid #e6e6ef;
      text-align: end;
    }
    &__name {
      padding: 2.5rem;
      padding-left: 1.875rem;
      color: ${colors.titleDark};
      font-size: 2.25rem;
      transition: all 0.2s;
      cursor: pointer;
      border-right: 1px solid transparent;
      max-width: 18.625rem;
      transform: translateX(2px);
      &:not(:last-child) {
        margin-bottom: 5rem;
      }
      &:hover,
      &--active {
        color: ${colors.primary};
        border-right: 1px solid currentColor;
        box-shadow: 0px 8px 24px rgba(71, 90, 158, 0.1);
      }
      &--mobile {
        color: ${colors.primary};
        margin-bottom: 1.5rem;
        border-left: 2px solid ${colors.primary};
        font-family: BioSans-SemiBold, sans-serif;
        font-size: 1.75rem;
        box-shadow: 0px 8px 24px rgba(71, 90, 158, 0.1);
        padding-top: 1.75rem;
        padding-bottom: 1.75rem;
        padding-left: 1.875rem;
        padding-right: 2.5rem;
      }
    }
    &__mainCategory {
      color: ${colors.titleDark};
      display: flex;
      align-items: center;
      font-family: BioSans-SemiBold, sans-serif;
      font-size: 1.75rem;
      margin-bottom: 3.25rem;
      svg {
        fill: ${colors.purpleGray};
        margin-left: 1.2275rem;
        transition: transform 0.2s;
        transform-origin: center;
      }
      &--active {
        color: ${colors.primary};
        margin-bottom: 1.5rem;
        svg {
          transform: rotate(-180deg);
          fill: ${colors.primary};
        }
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.desktopLargest}) {
    .servicesList {
      &__wrapper {
        gap: 8rem;
      }
      &__name {
        font-size: 1.75rem;
        max-width: 22rem;
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.desktopSmall}) {
    .section-header {
      margin-left: auto;
      margin-right: auto;
      text-align: left;
      &__title {
        align-items: flex-start;
      }
    }
    .servicesList {
      &__names {
        display: none;
      }
      &__wrapper {
        flex-direction: column;
        align-items: center;
      }
      &__categories {
        width: 100%;
        max-width: 34.5rem;
      }
      &__mainCategory {
        margin-bottom: 0;
        font-size: 1.5rem;
      }
    }
    .react-reveal:not(:first-child).servicesList__name--mobile {
      margin-top: 5rem;
    }

    .react-reveal:not(:last-child) .servicesList__mainCategory {
      margin-bottom: 2rem;
    }
  }
`

const StyledSanityBlockContent = styled(SanityBlockContent)`
  border-left: 2px solid var(--s-active);
  padding-left: 1.5rem;
  margin-bottom: 3.25rem;

  p:not(:last-child) {
    margin-bottom: 1.5rem;
  }
`

const ServicesDropdownList = ({ header, listOfServices }) => {
  const [activeName, setActiveName] = useState(0)
  const { isSmallerThanDesktop } = useWindowSize()

  const getActiveCategory = (index) => {
    const servicesDetails = listOfServices[index].servicesDetails
    return servicesDetails.length === 1 && servicesDetails[0]._rawServiceSubCategories?.length > 0
      ? 0
      : null
  }

  const [activeCategory, setActiveCategory] = useState(() => {
    return getActiveCategory(activeName)
  })

  const handleSetName = (index) => {
    setActiveName(index)
    setActiveCategory(getActiveCategory(index))
  }

  const handleSetCategory = (index) => setActiveCategory((prev) => (prev === index ? null : index))

  const hasSiblingsOrChildren = (servicesDetails, serviceSubCategories) =>
    Boolean(servicesDetails?.length > 1 && serviceSubCategories?.length)

  return (
    <ServicesDropdownListSection activeName={activeName}>
      <SectionHeader title={header} />
      <div className="container">
        <div className="servicesList__wrapper">
          <div className="servicesList__names">
            {listOfServices.map(({ serviceName }, index) => (
              <div
                onClick={() => handleSetName(index)}
                key={`service-name-${index}`}
                className={`servicesList__name ${
                  index === activeName ? 'servicesList__name--active' : ''
                }`}
              >
                {serviceName}
              </div>
            ))}
          </div>
          <div className="servicesList__categories">
            {isSmallerThanDesktop
              ? listOfServices.map(({ serviceName, servicesDetails }, index) => {
                  return (
                    <Fade top key={`service-${index}`}>
                      <div className="servicesList__name--mobile">{serviceName}</div>
                      {servicesDetails.map(
                        ({ serviceMainCategory, _rawServiceSubCategories }, indexMainCategory) => {
                          return (
                            <>
                              <div
                                key={`service-category-${indexMainCategory}`}
                                className={`servicesList__mainCategory ${
                                  indexMainCategory === activeCategory
                                    ? 'servicesList__mainCategory--active'
                                    : ''
                                }`}
                                onClick={
                                  hasSiblingsOrChildren(servicesDetails, _rawServiceSubCategories)
                                    ? () => handleSetCategory(indexMainCategory)
                                    : () => {}
                                }
                                style={{
                                  cursor: hasSiblingsOrChildren(
                                    servicesDetails,
                                    _rawServiceSubCategories,
                                  )
                                    ? 'pointer'
                                    : 'text',
                                }}
                              >
                                {serviceMainCategory}{' '}
                                {hasSiblingsOrChildren(
                                  servicesDetails,
                                  _rawServiceSubCategories,
                                ) && <IconChevron />}
                              </div>
                              {Boolean(
                                indexMainCategory === activeCategory ||
                                  !hasSiblingsOrChildren(servicesDetails, _rawServiceSubCategories),
                              ) && <StyledSanityBlockContent blocks={_rawServiceSubCategories} />}
                            </>
                          )
                        },
                      )}
                    </Fade>
                  )
                })
              : listOfServices[activeName].servicesDetails.map(
                  ({ serviceMainCategory, _rawServiceSubCategories }, indexMainCategory) => {
                    return (
                      <Fade top key={`service-category-${indexMainCategory}`}>
                        <>
                          <div
                            className={`servicesList__mainCategory ${
                              indexMainCategory === activeCategory
                                ? 'servicesList__mainCategory--active'
                                : ''
                            }`}
                            onClick={
                              hasSiblingsOrChildren(
                                listOfServices[activeName].servicesDetails,
                                _rawServiceSubCategories,
                              )
                                ? () => handleSetCategory(indexMainCategory)
                                : () => {}
                            }
                            style={{
                              cursor: hasSiblingsOrChildren(
                                listOfServices[activeName].servicesDetails,
                                _rawServiceSubCategories,
                              )
                                ? 'pointer'
                                : 'text',
                            }}
                          >
                            {serviceMainCategory}{' '}
                            {hasSiblingsOrChildren(
                              listOfServices[activeName].servicesDetails,
                              _rawServiceSubCategories,
                            ) && <IconChevron />}
                          </div>
                          {Boolean(indexMainCategory === activeCategory) && (
                            <StyledSanityBlockContent blocks={_rawServiceSubCategories} />
                          )}
                        </>
                      </Fade>
                    )
                  },
                )}
          </div>
        </div>
      </div>
    </ServicesDropdownListSection>
  )
}

export default ServicesDropdownList
