import React from 'react'

import styled from 'styled-components'

import {
  horizontalLayoutPadding,
  horizontalPaddingDesktop,
  horizontalLayoutPaddingMobile,
} from '../../features/layout/components/commonLayoutStyle'
import SectionHeader from '../SectionHeader/SectionHeader'
import HorizontalSlider from '../HorizontalSlider'
import { breakpoints, colors } from '../../../constants/style'
import { NavLinkButton } from 'components/Link'

const GAP_BETWEEN_SLIDES = '2rem'

const LARGE_DESKTOP_MOBILE_BACKGROUND_PADDING = '5rem'
const DESKTOP_BACKGROUND_PADDING = '4.5rem'
const TABLET_BACKGROUND_PADDING = '3.5rem'

const SliderSectionStyled = styled('section')`
  padding-inline: 0 !important;
  max-width: 100vw;
  overflow: hidden;

  .slick-list {
    overflow: visible;

    .slick-slide {
      margin: 0 ${GAP_BETWEEN_SLIDES} 0 0;
      padding: 0;
    }
  }

  .slider {
    position: relative;
    padding: 0;

    &__container {
      --margin-left: ${horizontalLayoutPadding};
      --margin-right: calc(var(--margin-left) - ${GAP_BETWEEN_SLIDES});

      position: relative;
      padding-right: 0 !important;
      padding-left: 0 !important;
      width: calc(100vw - var(--margin-left) - var(--margin-right));
      margin-left: var(--margin-left);

      @media only screen and (max-width: ${breakpoints.desktopLarge}) {
        --margin-left: ${horizontalPaddingDesktop};
      }

      @media only screen and (max-width: ${breakpoints.desktop}) {
        --margin-left: ${horizontalLayoutPaddingMobile};
        --margin-right: 0rem;
      }
    }

    &__button-wrapper {
      margin-top: 1rem;

      @media only screen and (max-width: ${breakpoints.tablet}) {
        margin-right: ${horizontalLayoutPaddingMobile};
        justify-content: center;
      }
    }

    &--with-background {
      margin-top: 7rem;

      .item {
        background: #fff;
        height: 100%;
      }

      .slider__button-wrapper {
        margin-top: 3.5rem;
      }

      .slick-list {
        --background-padding: ${LARGE_DESKTOP_MOBILE_BACKGROUND_PADDING};
        padding: var(--background-padding) 0 var(--background-padding);

        &:before {
          --background-left-margin: 16rem;

          content: '';
          display: block;
          background-color: #a284f7;
          position: absolute;
          top: 0;
          left: var(--background-left-margin);
          width: calc(100% + var(--margin-right) - var(--background-left-margin));
          height: 100%;
        }
      }

      @media only screen and (max-width: ${breakpoints.desktopLarge}) {
        .slick-list {
          --background-padding: ${DESKTOP_BACKGROUND_PADDING};
        }
      }

      @media only screen and (max-width: ${breakpoints.desktop}) {
        margin-top: 5rem;

        .slick-list {
          --background-padding: ${TABLET_BACKGROUND_PADDING};
        }
      }

      @media only screen and (max-width: ${breakpoints.tablet}) {
        margin-top: 3.5rem;

        &:before {
          left: 6rem;
        }

        .slick-list {
          --background-padding: ${LARGE_DESKTOP_MOBILE_BACKGROUND_PADDING};

          &:before {
            --background-left-margin: 6rem;
          }
        }
      }
    }
  }
`

const StyledNavLinkButton = styled(NavLinkButton)`
  width: fit-content;
  margin: 5rem auto 0;

  &:not(:hover) {
    span {
      color: ${colors.titleDark};
    }
  }
`

const SliderSection = ({
  header = '',
  description,
  items,
  children,
  className = '',
  button,
  headerPosition = 'left',
  withBackground = false,
  customSettings = null,
}) => {
  const numOfSlides = items.length > 3 ? 3 : items.length

  const settings = customSettings || {
    slidesToShow: numOfSlides,
    slidesToScroll: 1,
    variableWidth: false,
    responsive: [
      {
        breakpoint: parseInt(breakpoints.desktop, 10),
        settings: {
          variableWidth: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: parseInt(breakpoints.tablet, 10),
        settings: {
          variableWidth: false,
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <SliderSectionStyled className={className}>
      <div className="slider__container">
        {header !== '' && (
          <SectionHeader title={header} subtitle={description} headerPosition={headerPosition} />
        )}
        <div className={`slider ${withBackground ? 'slider--with-background' : ''}`}>
          <HorizontalSlider customSettings={settings}>{children}</HorizontalSlider>
        </div>
      </div>
      {button?.link && (
        <StyledNavLinkButton to={button.link} variant="bordered">
          {button.label || 'See more'}
        </StyledNavLinkButton>
      )}
    </SliderSectionStyled>
  )
}

export default SliderSection
