import { FC } from "react"
import { Link } from "gatsby"
import classNames from "classnames"

import ArrowSVG from 'static/images/icons/arrow-more-right.svg'
import { ReactComponent as FiveStars } from 'static/images/stars-5.svg'
import { ReactComponent as Clutch } from 'static/images/logo/logo-clutch-dark.svg'

import { RecommendationData } from "./types"
import sharedStyles from './Recommendation.module.scss'
import styles from './RecommendationReview.module.scss'

interface RecommendationReviewProps {
  data: RecommendationData
}

export const RecommendationReview: FC<RecommendationReviewProps> = ({ data }) => {
  const { recommendations, sectionTitle, shouldDisplayBrowseAllButton, browseAllButtonLabel, browseAllButtonUrl } = data

  const [recommendation] = recommendations

  return (
    <div className={classNames(sharedStyles.container, styles.container)}>
      <div className={sharedStyles.topText}>
        <p className={sharedStyles.sectionTitle}>{sectionTitle}</p>
        {shouldDisplayBrowseAllButton && (
          <Link to={browseAllButtonUrl!} className={sharedStyles.link}>
            {browseAllButtonLabel}
            <img
              src={ArrowSVG}
              alt=""
              className={sharedStyles.arrowIcon}
              itemProp="image"
              aria-hidden="true"
            />
          </Link>
        )}
      </div>

      <div className={styles.logo}>
        <Clutch />
        <FiveStars />
      </div>
      <div className={sharedStyles.quoteContainer}>
        <p className={sharedStyles.quote}>{recommendation.reviewContent}</p>
        {recommendation.author && (
          <div className={styles.author}>
            <p className={sharedStyles.authorName}>{recommendation.author.name}</p>
            <p className={sharedStyles.authorPosition}>{recommendation.author.position}</p>
          </div>
        )}
      </div>

      {recommendation.link && recommendation.linkText && (
        <Link to={recommendation.link} className={classNames(sharedStyles.link, styles.link)}>
          {recommendation.linkText}
          <img
            src={ArrowSVG}
            alt=">"
            className={sharedStyles.arrowIcon}
            itemProp="image"
            aria-hidden="true"
          />
        </Link>
      )}
    </div>
  )
}
