import React from 'react';
import styled from 'styled-components';

import Image from '../../Image';

import { colors, breakpoints } from '../../../../constants/style';
import SanityBlockContent from '../../SanityBlockContent';

const PillarsWrapper = styled('div')`
  padding: 1.5625rem;

  .pillar__content {
    padding: 5.5rem 2.5625rem;
    border: solid 1px #e5e5e8;
    background-color: #ffffff;
    width: 100%;
    height: 100%;

    .pillar__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 4rem;
      height: 110px;
    }

    .pillar__title {
      margin-bottom: 2.625rem;
      font-family: "BioSans-Regular", sans-serif;
      font-size: 1.625rem;
      color: #000000;
      text-align: center;
    }

    .pillar__description {
      color: ${colors.mutedDark};
      text-align: center;
      font-size: 1.0625rem;

      p {
        margin: 0.375rem 0;
      }

      h3 {
        margin: 1.25rem 0 0.625rem;
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.mobile}) {
    padding: 1rem 0;

    .pillar__content {
      padding: 2rem 1.5rem;

      .pillar__title {
        font-size: 1.5rem;
      }
    }
  }
`;

const PillarsEntry = ({ icon, header, rawDescription }) => {
  return (
    <PillarsWrapper className="pillar">
      <div className="pillar__content">
        <div className="pillar__icon">{icon && <Image image={icon} />}</div>
        <h3 className="pillar__title">{header}</h3>
        <SanityBlockContent className="pillar__description" blocks={rawDescription} />
      </div>
    </PillarsWrapper>
  );
};

export default PillarsEntry;
