import { motion } from 'framer-motion'
import { GatsbyImageProps } from 'gatsby-plugin-image'
import { ReactNode, useState } from 'react'

import Image from 'components/Image'
import scrollStyles from './fullWidthScrollSlider.module.scss'
import { breakpoints } from '../../../../constants/style'

type ItemsTypes = {
  content: ReactNode
  image: { asset: { gatsbyImageData: GatsbyImageProps }; alt?: string }
}

type FullWidthScrollSliderProps = {
  items: ItemsTypes[]
}

export const FullWidthScrollSlider = ({ items }: FullWidthScrollSliderProps) => {
  const [translateX, setTranslateX] = useState('0%')

  const transition = {
    ease: 'easeInOut',
    duration: 0.5,
  }

  return (
    <>
      <div className={scrollStyles.slider}>
        <div className={scrollStyles.leftColumn}>
          {items.map(({ content }, i) => (
            <div className={scrollStyles.contentContainer} key={i}>
              <motion.div
                className={scrollStyles.text}
                onViewportEnter={() => {
                  setTranslateX(`-${i * 100}%`)
                }}
                viewport={{ amount: 'all' }}
                style={{ order: i * 2 }} // we need order to properly display on mobile
              >
                {content}
              </motion.div>
            </div>
          ))}
        </div>
        <div className={scrollStyles.imagesContainer}>
          <motion.div
            className={scrollStyles.imagesSlider}
            animate={{
              translateX,
            }}
            transition={transition}
          >
            {items.map((item, i) => (
              <Image
                image={item.image}
                key={item.image.alt || i}
                alt={item.image.alt || ''}
                className={scrollStyles.image}
                sizes={`(max-width: ${breakpoints.desktop}) 100vw, 50vw`}
                style={{ order: i * 2 + 1 }} // we need order to properly display on mobile
              />
            ))}
          </motion.div>
        </div>
      </div>
    </>
  )
}
