import React, { lazy, Suspense } from 'react'
import { graphql } from 'gatsby'

import { SEO } from 'components/Seo'
import 'hooks/graphql/others'
import 'hooks/graphql/section'
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator'
import sectionsConfig from '../../features/dynamicSection/sections.config'
import Layout from '../../features/layout'

const Hero = lazy(() => import('../../components/sections/Hero'))
const FeaturedOn = lazy(() => import('../../components/sections/FeaturedOn'))
const Clients = lazy(() => import('../../components/sections/Clients'))
const Why10Clouds = lazy(() => import('../../components/sections/Why10Clouds'))
const Awards = lazy(() => import('../../components/sections/Awards'))
const RedirectBanner = lazy(() => import('../../components/sections/RedirectBanner'))
const AdvantagesSection = lazy(() => import('../../components/sections/Advantages'))
const Pillars = lazy(() => import('../../components/sections/Pillars'))
const TableSection = lazy(() => import('../../components/sections/TableSection'))
const Solutions = lazy(() => import('../../components/sections/Solutions'))
const ProcessOfWork = lazy(() => import('../../components/sections/ProcessOfWork'))
const ParagraphSection = lazy(() => import('../../components/sections/Paragraph'))
const BulletParagraph = lazy(() => import('../../components/sections/BulletParagraph'))
const Versus = lazy(() => import('../../components/sections/Versus'))
const EmployeesList = lazy(() => import('../../components/sections/EmployeesList'))
const TechnologyDetails = lazy(() => import('../../components/sections/TechnologyDetails'))
const ProcessOfDevelopment = lazy(() => import('../../components/sections/ProcessOfDevelopment'))
const ImageSection = lazy(() => import('../../components/sections/ImageSection'))
const CaseStudies = lazy(() => import('../../components/sections/CaseStudies'))
const WhatAreYouLookingFor = lazy(() => import('../../components/sections/WhatAreYouLookingFor'))
const Technologies = lazy(() => import('../../components/sections/Technologies'))
const Testimonials = lazy(() => import('../../components/sections/Testimonials'))
const Showcase = lazy(() => import('../../components/sections/Showcase'))
const Articles = lazy(() => import('../../components/sections/Articles'))
const OtherServicesWithoutSlider = lazy(() =>
  import('../../components/sections/OtherServices/OtherServicesWithoutSlider'),
)
const ContactUs = lazy(() => import('../../components/sections/ContactUs'))
const OtherServices = lazy(() => import('../../components/sections/OtherServices'))

//TODO: Rewrite all necessary sections into "sections" and delete them from sanity and query

export const query = graphql`
  query ServicesPageQuery($id: String!, $blogCategoryId: String!, $parentServiceId: String) {
    sanityServicesPage(id: { eq: $id }) {
      parentService {
        servicesPages {
          globalInfo {
            siblingServicesTitle
          }
        }
      }

      globalInfo {
        childServicesTitle
      }

      ...AllServiceSectionsFragment

      _rawSections

      hideOtherServices

      hero {
        ...SanityHeroFragment
      }

      whatAreYouLookingForSection {
        ...WhatAreYouLookingForSection
      }
      _rawWhatAreYouLookingForSection

      technologyDetailsSection {
        ...TechnologyDetailsSection
      }

      processOfDevelopmentSection {
        ...ProcessOfDevelopmentSection
      }

      advantagesSection {
        ...AdvantagesSection
      }

      imageSection {
        ...ImageSection
      }

      clientsSection {
        ...ClientsSection
      }

      whySection {
        ...WhySection
      }

      bulletParagraphSection {
        ...BulletParagraphSection
      }

      _rawBulletParagraphSection

      versusSection {
        ...VersusSection
      }
      _rawVersusSection

      tableSection {
        ...TableSection
      }

      pillarsSection {
        ...PillarsSection
      }
      _rawPillarsSection

      solutionsSection {
        ...SolutionsSection
      }

      processOfWorkSection {
        ...ProcessOfWorkSection
      }
      _rawProcessOfWorkSection

      paragraphSections {
        ...ParagraphSection
      }

      _rawParagraphSections

      employeesListSection {
        ...EmployeesListSection
      }

      caseStudiesSection {
        ...CaseStudiesSection
      }
      _rawCaseStudiesSection

      testimonialsSection {
        ...TestimonialsSection
      }

      showcaseSection {
        ...ShowcaseSection
      }

      awardsSection {
        ...AwardsSection
      }
      _rawAwardsSection

      redirectBannerSection {
        ...RedirectBannerSection
      }
      _rawRedirectBannerSection

      technologiesSection {
        ...TechnologiesSection
      }
      _rawTechnologiesSection

      articlesSection {
        ...ArticlesSection
      }
      _rawArticlesSection

      linkedServicesSection {
        ...LinkedServicesSection
      }

      contactSection {
        ...ContactSection
      }

      seo {
        ...SeoFragment
      }
    }

    subservices: allSanityServicesPage(
      filter: { parentService: { servicesPages: { id: { eq: $id } } } }
    ) {
      nodes {
        ...AllServicesPage
      }
    }

    siblingServices: allSanityServicesPage(
      filter: { parentService: { servicesPages: { id: { eq: $parentServiceId, ne: null } } } }
    ) {
      nodes {
        ...AllServicesPage
      }
    }

    blogPosts: allSanityBlogPost(
      filter: { categories: { elemMatch: { id: { eq: $blogCategoryId } } } }
      limit: 6
      sort: { createdAt: DESC }
    ) {
      nodes {
        ...BlogPostFields
      }
    }
  }
`

const ServicePage = ({
  location,
  pageContext: { id },
  data: { sanityServicesPage, subservices, blogPosts, siblingServices },
}) => {
  const {
    parentService,
    globalInfo,
    sections,
    _rawSections,
    hideOtherServices,
    hero,
    whatAreYouLookingForSection,
    _rawWhatAreYouLookingForSection,
    technologyDetailsSection,
    processOfDevelopmentSection,
    advantagesSection,
    imageSection,
    clientsSection,
    whySection,
    processOfWorkSection,
    _rawProcessOfWorkSection,
    employeesListSection,
    caseStudiesSection,
    _rawCaseStudiesSection,
    testimonialsSection,
    showcaseSection,
    awardsSection,
    _rawAwardsSection,
    redirectBannerSection,
    _rawRedirectBannerSection,
    technologiesSection,
    _rawTechnologiesSection,
    articlesSection,
    _rawArticlesSection,
    linkedServicesSection,
    contactSection,
    paragraphSections,
    _rawParagraphSections,
    versusSection,
    _rawVersusSection,
    _rawPillarsSection,
    pillarsSection,
    tableSection,
    solutionsSection,
    bulletParagraphSection,
    _rawBulletParagraphSection,
  } = sanityServicesPage

  const articlesSectionBlogPosts = articlesSection?.blogPosts && [
    ...articlesSection.blogPosts.filter(({ __typename }) => __typename === 'SanityBlogPost'),
    ...blogPosts.nodes,
  ]
  const otherSiblingServices = siblingServices?.nodes.filter((service) => service.id !== id) || []
  return (
    <Layout>
      {sections.map((section, index) => {
        const currentSection = sectionsConfig[section._type]

        if (!currentSection) {
          console.error('section Type', section)
          throw new Error(
            `Required section not defined. Please add it to the available sections list.`,
          )
        }

        const blogPostsForDynamicSections =
          section._type === 'articlesSection'
            ? [
                ...section.blogPosts.filter(({ __typename }) => __typename === 'SanityBlogPost'),
                ...blogPosts.nodes,
              ]
            : null

        const sectionWithBlogPosts = { ...section, blogPosts: blogPostsForDynamicSections }

        return (
          <Suspense fallback={<LoadingIndicator />} key={index}>
            {currentSection.condition(sectionWithBlogPosts) &&
              currentSection.template({
                section: sectionWithBlogPosts,
                index,
                isPreviousSectionOfSameType: Boolean(
                  sections[index + 1] && sections[index + 1]._type === section._type,
                ),
                rawSection: _rawSections[index],
              })}
          </Suspense>
        )
      })}

      <Suspense fallback={<LoadingIndicator />}>
        {Boolean(hero?.title && hero?.description) && (
          <Hero
            header={hero?.title}
            preheader={hero?.pretitle}
            description={hero.description}
            contactButtonText={hero.contactButtonText}
            rightColumnImage={hero.mainImage}
            showScroll
          />
        )}
      </Suspense>

      {hero?.title && hero.title?.includes('MLOps') ? (
        <Suspense fallback={<LoadingIndicator />}>
          <FeaturedOn fullWidth />
        </Suspense>
      ) : null}
      <Suspense fallback={<LoadingIndicator />}>
        {Boolean(clientsSection?.clientsList?.length) && (
          <Clients header={clientsSection.header} clients={clientsSection.clientsList} />
        )}
        {Boolean(whySection?.reasonsToWorkWith.length) && (
          <Why10Clouds
            header={whySection.header}
            description={whySection.description}
            reasons={whySection.reasonsToWorkWith || []}
          />
        )}
      </Suspense>
      <Suspense fallback={<LoadingIndicator />}>
        {Boolean(awardsSection && awardsSection.awards.length) && (
          <Awards
            header={awardsSection.header}
            imagePosition={awardsSection.imagePosition}
            image={awardsSection.image}
            awards={awardsSection.awards}
            raw={_rawAwardsSection}
          />
        )}
        {Boolean(
          redirectBannerSection &&
            redirectBannerSection.header &&
            redirectBannerSection.mainImage &&
            redirectBannerSection.link &&
            redirectBannerSection.description &&
            redirectBannerSection.label,
        ) && (
          <RedirectBanner
            header={redirectBannerSection.header}
            image={redirectBannerSection.mainImage}
            link={redirectBannerSection.link}
            description={redirectBannerSection.description}
            label={redirectBannerSection.label}
            raw={_rawRedirectBannerSection}
          />
        )}

        {Boolean(advantagesSection?.advantages?.length) && (
          <AdvantagesSection {...advantagesSection} />
        )}

        {Boolean(pillarsSection?.pillars.length) && (
          <Pillars pillars={pillarsSection.pillars} raw={_rawPillarsSection} />
        )}
      </Suspense>

      <Suspense fallback={<LoadingIndicator />}>
        {Boolean(tableSection?.header && tableSection?.table) && (
          <TableSection
            header={tableSection.header}
            description={tableSection.description}
            table={tableSection.table}
            positiveWords={tableSection.positiveWords}
            shouldDisplayPositiveLabel={tableSection.shouldDisplayPositiveLabel}
            negativeWords={tableSection.negativeWords}
            shouldDisplayNegativeLabel={tableSection.shouldDisplayNegativeLabel}
          />
        )}

        {Boolean(solutionsSection && solutionsSection.solutions.length) && (
          <Solutions
            header={solutionsSection?.header}
            description={solutionsSection?.description}
            solutions={solutionsSection?.solutions}
          />
        )}

        {Boolean(
          processOfWorkSection &&
            processOfWorkSection?.header &&
            processOfWorkSection?.items?.length,
        ) && (
          <ProcessOfWork
            header={processOfWorkSection.header}
            description={processOfWorkSection.description}
            items={processOfWorkSection.items}
            raw={_rawProcessOfWorkSection}
          />
        )}

        {Boolean(paragraphSections) &&
          paragraphSections?.map((item, index) => (
            <ParagraphSection
              key={`paragraphsection-${index}`}
              theme={item.theme}
              decorate={item.decorate}
              customBulletListImage={item.customBulletListImage}
              header={item.header}
              headerColored={_rawParagraphSections[index].headerColored}
              description={_rawParagraphSections[index].description}
              removeBottomMargin={item.removeBottomMargin}
              image={item.image}
              position={item.position}
              isFirst={index === 0}
              isLast={index === paragraphSections.length - 1}
              backgroundBorderColor={'#1effc2'}
              buttonLabel={item.buttonLabel}
              url={item.link}
              shouldDisplayButton={item.shouldDisplayButton}
              video={item.video}
            />
          ))}

        {Boolean(bulletParagraphSection && bulletParagraphSection.items.length) && (
          <BulletParagraph
            header={bulletParagraphSection.title}
            description={bulletParagraphSection.description}
            items={bulletParagraphSection.items}
            rawItems={_rawBulletParagraphSection.items}
            rawExample={_rawBulletParagraphSection.example}
            exampleImage={bulletParagraphSection.example && bulletParagraphSection.example.image}
          />
        )}
      </Suspense>

      <Suspense fallback={<LoadingIndicator />}>
        {Boolean(versusSection && versusSection.elements.length) && (
          <Versus
            title={versusSection.title}
            description={versusSection.description}
            elements={versusSection.elements}
            rawElements={_rawVersusSection.elements}
          />
        )}

        {Boolean(employeesListSection?.employees?.length) && (
          <EmployeesList
            header={employeesListSection.header}
            description={employeesListSection.description}
            employees={employeesListSection.employees}
          />
        )}

        {Boolean(
          technologyDetailsSection &&
            technologyDetailsSection.header &&
            technologyDetailsSection.details.length,
        ) && (
          <TechnologyDetails
            header={technologyDetailsSection.header}
            description={technologyDetailsSection.description}
            details={technologyDetailsSection.details}
          />
        )}

        {Boolean(processOfDevelopmentSection && processOfDevelopmentSection.title) && (
          <ProcessOfDevelopment {...processOfDevelopmentSection} />
        )}

        {Boolean(imageSection && imageSection.image) && <ImageSection {...imageSection} />}

        {Boolean(caseStudiesSection && caseStudiesSection.caseStudies.length) && (
          <CaseStudies
            header={caseStudiesSection.header}
            caseStudiesEntries={caseStudiesSection.caseStudies}
            raw={_rawCaseStudiesSection}
          />
        )}

        {Boolean(whatAreYouLookingForSection && whatAreYouLookingForSection.header) && (
          <WhatAreYouLookingFor
            header={whatAreYouLookingForSection.header}
            leftEntry={whatAreYouLookingForSection.leftEntry}
            rightEntry={whatAreYouLookingForSection.rightEntry}
            raw={_rawWhatAreYouLookingForSection}
          />
        )}

        {Boolean(
          technologiesSection &&
            technologiesSection.header &&
            technologiesSection.technologiesList.length,
        ) && (
          <Technologies
            header={technologiesSection.header}
            technologiesList={technologiesSection.technologiesList}
            shouldOverlap={true}
            raw={_rawTechnologiesSection}
          />
        )}

        {Boolean(testimonialsSection && testimonialsSection.testimonials.length) && (
          <Testimonials
            header={testimonialsSection.header}
            testimonials={testimonialsSection.testimonials}
          />
        )}

        {Boolean(showcaseSection && showcaseSection.vimeo.length) && (
          <Showcase
            theme={showcaseSection.theme ? showcaseSection.theme : 'dark'}
            header={showcaseSection.header ? showcaseSection.header : ''}
            // dla mobilki nie ma tu czegoś
            buttonLabel={showcaseSection.button ? showcaseSection.button.label : ''}
            buttonLink={showcaseSection.button ? showcaseSection.button.link : ''}
            vimeoMovieIds={showcaseSection.vimeo}
          />
        )}
      </Suspense>

      <Suspense fallback={<LoadingIndicator />}>
        {Boolean(articlesSectionBlogPosts?.length) && (
          <Articles
            header={articlesSection.header}
            raw={_rawArticlesSection}
            blogPosts={articlesSectionBlogPosts}
          />
        )}

        {Boolean(subservices?.nodes.length && globalInfo?.childServicesTitle) && (
          <OtherServicesWithoutSlider
            services={subservices.nodes}
            header={globalInfo?.childServicesTitle}
            isOnMainPage
          />
        )}

        {Boolean(
          linkedServicesSection && linkedServicesSection.servicesPages.length && !hideOtherServices,
        ) && (
          <OtherServices
            header={linkedServicesSection.header}
            services={linkedServicesSection.servicesPages}
          />
        )}

        {Boolean(otherSiblingServices?.length && !hideOtherServices) && (
          <OtherServicesWithoutSlider
            services={otherSiblingServices}
            isOnMainPage={false}
            header={parentService?.servicesPages?.globalInfo?.siblingServicesTitle}
          />
        )}

        {hero?.title && !hero.title?.includes('MLOps') ? <FeaturedOn /> : null}

        {hero?.title && hero.title?.includes('MLOps') ? (
          <ContactUs
            theme="lightToDark"
            header="Start a Project with 10Clouds"
            buttonLabel="Contact us"
          />
        ) : null}

        {Boolean(contactSection && contactSection?.buttonLabel) && (
          <ContactUs
            theme="lightToDark"
            header={contactSection.header}
            buttonLabel={contactSection.buttonLabel}
            url={contactSection.link}
            description={contactSection.description}
          />
        )}
      </Suspense>
    </Layout>
  )
}

export function Head({ location, data: { sanityServicesPage } }) {
  const { seo = {} } = sanityServicesPage
  return <SEO location={location} {...seo} />
}

export default ServicePage
