import SectionHeader from 'components/SectionHeader/SectionHeader'
import React from 'react'
import styled from 'styled-components'
import { breakpoints } from '../../../../constants/style'

const StyledProjectInNumbers = styled('section')`
  background: var(--fs-bg-1a);
  margin: 0;

  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 5rem;
  overflow: hidden;

  .section-header {
    margin-bottom: 0;
    &__title {
      color: var(--t1-title);
    }
  }

  .blur {
    background-color: var(--primary-50);
    opacity: 0.2;
    filter: blur(200px);
    position: absolute;
    width: 638px;
    height: 383px;
    left: 50%;
    transform: translateX(-50%);
    display: block;
  }

  .numbers-row {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    row-gap: 5rem;
  }

  .number-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    &__value {
      font-size: 8.375rem;
      -webkit-text-stroke: 1px var(--t1-overline);
      text-stroke: 1px var(--t1-overline);
      color: transparent;
    }
    &__label {
      color: var(--t1-body);
      text-transform: uppercase;
    }
  }

  @media only screen and (min-width: ${breakpoints.desktopSmall}) {
    .numbers-row {
      flex-direction: row;
    }
  }
  @media only screen and (max-width: ${breakpoints.mobile}) {
    .number-wrapper {
    &__value {
      font-size: 7rem;
    }
  }
  }
`

type ProjectInNumbersSectionProps = {
  title: string
  numbersList: {
    numberValue: string
    numberLabel: string
  }[]
}

const ProjectInNumbersSection = ({ title, numbersList }: ProjectInNumbersSectionProps) => {
  return (
    <StyledProjectInNumbers className="dark">
      <SectionHeader title={title} theme="lightGreen" />
      <div className="numbers-row">
        {numbersList?.map(({ numberValue, numberLabel }) => (
          <div className="number-wrapper">
            <div className="number-wrapper__value">{numberValue}</div>
            <h5 className="number-wrapper__label">{numberLabel}</h5>
          </div>
        ))}
      </div>
      <div className="blur"></div>
    </StyledProjectInNumbers>
  )
}

export default ProjectInNumbersSection
