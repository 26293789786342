import React from 'react'
import styled, { css } from 'styled-components'

import { breakpoints } from '../../../../../constants/style'

import LeafsSVG from '../../../../../static/images/leafs.svg'

const AwardsEntryWrapper = styled('div').attrs({ className: 'award' })`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  height: 100%;
  background-color: white;
  padding: 4rem 1.719rem;

  .award__leafs {
    display: flex;
    gap: 6rem;
    &::before,
    &::after {
      content: '';
      display: block;
      width: 3.625rem;
      height: 6.75rem;
      background: url(${LeafsSVG}) no-repeat left center;
      background-size: contain;
    }
    &::after {
      transform: scaleX(-1);
    }
  }
  .award__title {
    margin-top: 3rem;
    font-size: 1.75rem;
    line-height: 2.5rem;
    letter-spacing: 0.01rem;
    font-family: 'Bio Sans', sans-serif;
  }
  .award__details {
      font-size: 0.875rem;
      font-family: Roboto-Regular, sans-serif;
      padding: 0rem;
      gap: 0.75rem;
      align-items: center;
      margin-top: 1.5rem;
      display: flex;
      justify-content: center;
    }
  .award__logo {
    max-width: 160px;
  }  
`

const AwardsEntry = ({ title, logo, text }) => {
  return (
    <AwardsEntryWrapper>
      <div className='award__leafs'></div>
        <div className='award__title'>{title}</div>
        {Boolean(logo) && (
          <div className='award__details'>
            <p className='award__text'>{text ? text : 'according to'}{' '}</p>
            <img src={logo.asset.url} className='award__logo' alt={logo.alt || 'Award logo'} />
          </div>
        )}
    </AwardsEntryWrapper>
  )
}

export default AwardsEntry
