import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { blogTitleAnimationStyle, blogCategoryStyle, blogSpacedAccentSizes, blogArrowAnimationStyle } from '../../../commonStyle';
import { getPreviewFromSanityBlock } from '../../../../../components/SanityBlockContent/sanityBlockContentUtils.js';
import { breakpoints } from '../../../../../../constants/style';
import { parseSlug } from '../../../../../utils/slug';

import Image from '../../../../../components/Image';

const StyledMainBlogPost = styled((props) => <Link {...props} />)`
    min-height: 100%;

    .blog-main-post__image {
      margin-bottom: 1.5rem;
    }

    p {
      font-family: "BioSans-Regular", "Roboto-Medium", sans-serif;
      font-size: 1rem;
    }

    .blog-main-post__spaced-accent {
      font-family: "BioSans-SemiBold", "Bitter-SemiBold", "Roboto-Bold", sans-serif;
      font-size: ${blogSpacedAccentSizes.font};
      letter-spacing: 0.4ch;
      color: #602BE9;
      margin-bottom: ${blogSpacedAccentSizes.margin};
      text-transform: uppercase;
    }

    .blog-main-post__category {
      ${blogCategoryStyle}
    }

    .blog-main-post__title {
      font-family: "Bitter-SemiBold", "BioSans-SemiBold", "Roboto-Bold", sans-serif;
      font-size: 2.5rem;
      line-height: 3.125rem;
      color: #100727;
      ${blogTitleAnimationStyle.base};
    }

    .blog-main-post__excerpt {
      font-family: "BioSans-Regular", "Bitter-SemiBold", "Roboto-Regular", sans-serif;
      letter-spacing: 0.2px;
      color: #727292;
      margin-top: 1.875em;
      margin-bottom: 1.875rem;
    }

    .blog-main-post__arrow {
      ${blogArrowAnimationStyle.base};
    }

    &:hover {
      .blog-main-post__title {
        ${blogTitleAnimationStyle.hover};
      }
      .blog-main-post__arrow {
        ${blogArrowAnimationStyle.hover};
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.desktop}) {
    .blog-main-post__spaced-accent {
      line-height: 1.4;
    }
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
      .blog-main-post__title {
        font-size: 2rem;
      }

      .blog-main-post__excerpt {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
      }
    }
`;

const MainBlogPost = ({ blogPost, heroBlogPostLabel }) => {
  const { title, _rawBody, featuredImage, coAuthor, author, slug, categories } = blogPost;
  const excerpt = getPreviewFromSanityBlock(_rawBody[0]);

  return (
    <StyledMainBlogPost to={`/blog/${parseSlug(categories[0].slug.current)}${parseSlug(slug.current)}`}>
      <p className="blog-main-post__spaced-accent">{heroBlogPostLabel}</p>
      <Image image={featuredImage} alt={featuredImage?.alt ?? ''} key={featuredImage} className="blog-main-post__image" />
      {/* do dictionary */}
      <p className="blog-main-post__spaced-accent">{heroBlogPostLabel}</p>
      <h1 className="blog-main-post__title">{title}</h1>
      <p className="blog-main-post__excerpt">{excerpt}</p>
      <div className="blog-main-post__author">
        {author.name}
        <span className="blog-main-post__author__position">{author.position && `, ${author.position}`}</span>
      </div>
      {Boolean(coAuthor) && (
        <div className="blog-main-post__author">
          {coAuthor.name}
          <span className="blog-main-post__author__position">{coAuthor.position && `, ${coAuthor.position}`}</span>
        </div>
      )}
    </StyledMainBlogPost>
  );
};

export default MainBlogPost;
