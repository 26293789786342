import { css } from 'styled-components';
import { colors, breakpoints } from '../../../constants/style';

const commonTooltipStyles = css`
  transform: translateX(-50%);
  position: absolute;
  padding: 1rem;
  font-family: Roboto-Regular, sans-serif !important;
  font-size: 0.6875rem !important;
  line-height: 0.9375rem !important;
  z-index: -20;
  opacity: 0;
  background-color: #210D60;
  width: max-content;

  @media screen and (max-width: ${breakpoints.mobileSmall}) {
    padding: 0.25rem;
    font-size: 0.7rem;
  }
`;

export default commonTooltipStyles;
