import React from 'react';
import StyledVideoWrapper, { StyledVideoPlayer } from './styledVideo';

const StyledVideo = ({ src, onClick, autoPlay, mimeType = 'video/mp4' }) => (
  <StyledVideoWrapper>
    <StyledVideoPlayer controls controlsList="nodownload" autoPlay={autoPlay} preload="none">
      <source src={src} type={mimeType} />
      Your browser does not support the video tag.
    </StyledVideoPlayer>
    <div className="close" onClick={onClick} />
  </StyledVideoWrapper>
);

export default StyledVideo;
