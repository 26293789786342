import React from 'react';
import styled from 'styled-components';

import OtherServiceEntry from './components/OtherServiceEntry';
import { colors, breakpoints } from '../../../../constants/style';
import { parseSlug } from '../../../utils/slug';
import useWindowSize from '../../../hooks/useWindowSize';
import HorizontalSlider from '../../HorizontalSlider';
import { NavLinkButtonWithArrow } from '../../Link';
import { services as servicesObj } from '../../../../constants/links';

const OtherServicesSection = styled('section')`
  + .featuredSection {
    margin-top: 0;
  }

  .slick-track {
    padding: 2rem;
    @media only screen and (max-width: ${breakpoints.tablet}) {
      padding: 0;
    }
  }

  .slick-slide:not(:last-child) {
    margin-right: 2rem;
  }

  .slick-slide {
    @media only screen and (max-width: ${breakpoints.mobile}) {
      margin-right: 0 !important;
    }
  }

  .services__btn {
    margin-bottom: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: ${breakpoints.mobileLarge}) {
      display: block;
      width: 100%;
    }
  }

  .slider__button-wrapper {
    margin-top: 5rem;
    margin-left: 2rem;
    display: ${({ shouldDisplayControls }) => (shouldDisplayControls ? 'flex' : 'none')};
    @media only screen and (max-width: ${breakpoints.tablet}) {
      margin-left: 0;
    }
    @media only screen and (max-width: ${breakpoints.mobileLarge}) {
      justify-content: center;
    }
  }

  .services__header {
    margin-bottom: 3.5rem;
    text-align: center;

    &:before {
      content: '';
      display: block;
      margin: 0 auto 3.5rem auto;
      width: 50px;
      height: 5px;
      background: ${colors.primary};
    }
  }
`;

const OtherServices = ({ header, services }) => {
  const { isSmallerThanDesktop } = useWindowSize();

  const settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: parseInt(breakpoints.desktopSmall, 10),
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: parseInt(breakpoints.mobileLarge, 10),
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <OtherServicesSection shouldDisplayControls={services.length > 3 || isSmallerThanDesktop}>
      {Boolean(header) && <h2 className="services__header">{header}</h2>}
      <div className="services__btn">
        <NavLinkButtonWithArrow className="contact-btn" to={servicesObj.url} variant="filled">
          SEE ALL
        </NavLinkButtonWithArrow>
      </div>
      <div className="container">
        <HorizontalSlider customSettings={settings}>
          {services.map((service) => {
            const {
              globalInfo: { name, description, icon },
              slug,
            } = service;
            return (
              <OtherServiceEntry
                key={`services-item-${name}`}
                to={`/services/${parseSlug(slug.current)}`}
                icon={icon}
                name={name}
                description={description}
              />
            );
          })}
        </HorizontalSlider>
      </div>
    </OtherServicesSection>
  );
};

export default OtherServices;
