import { useStaticQuery, graphql } from 'gatsby'

const useHomeData = () => {
  const data = useStaticQuery(graphql`
    {
      sanityHomePage {
        hero {
          ...SanityHeroFragment
        }
        heroButtonVideo {
          video {
            videoFile {
              asset {
                url
                mimeType
              }
            }
          }
          buttonText
        }
        _rawArticles
        articles {
          ...ArticlesSection
        }
        awardsSection {
          ...AwardsSection
        }
        clients {
          ...ClientsSection
        }
        youtubePosts {
          ...YoutubePostsSection
        }
        mainCaseStudy {
          ...CaseStudiesSection
        }
        sliderCaseStudy {
          ...SliderCaseStudySection
        }
        services {
          ...ServicesRedirectSection
        }
        testimonialsList {
          ...TestimonialsSection
        }
        contactUs
        seo {
          ...SeoFragment
        }
      }
    }
  `)

  return {
    ...data.sanityHomePage,
  }
}

export default useHomeData
