import React from 'react'

import styles from './accordionItem.module.scss'

import ArrowSVG from '../../../static/images/icons/arrow-more-down.svg'

type AccordionItemProps = {
  question: string
  answer: string
  isActive: boolean
  onClick: () => void
}

const AccordionItem = ({ question, answer, isActive, onClick }: AccordionItemProps) => {
  return (
    <div
      className={`${styles.accordionItem} ${isActive ? styles.active : ''}`}
      onClick={onClick}
      itemScope
      itemProp="mainEntity"
      itemType="https://schema.org/Question"
    >
      <div className={styles.questionRow}>
        <h2 className={styles.accordionTitle} itemProp="name">
          {question}
        </h2>
        <img src={ArrowSVG} alt="arrow" className={styles.arrowIcon} itemProp="image" />
      </div>
      <div
        className={styles.accordionContentWrapper}
        itemProp="acceptedAnswer"
        itemType="https://schema.org/Answer"
      >
        <p className={`${styles.accordionContent} p1`} itemProp="text">
          {answer}
        </p>
      </div>
    </div>
  )
}

export default AccordionItem
