import styled from 'styled-components';
import { breakpoints, colors } from '../../../constants/style';
import { StyledCheckbox } from '../Form/Checkbox'

const DownloadFormWrapper = styled('div')`
  width: 100%;
  height: auto;
  padding: 2rem 0.675rem 2rem 10rem;

  .header {
    font-family: BioSans-Regular, sans-serif;
    margin-bottom: 0.9375rem;
    font-size: 2.375rem;
  }

  .subheader {
    font-family: BioSans-Regular, sans-serif;
    margin-bottom: 2rem;
    font-size: 1.375rem;
  }

  .description {
    margin-bottom: 2rem;

    p,
    ul {
      font-family: Roboto-Regular, sans-serif;
      font-size: 0.9375rem;
      color: ${colors.muted};
      line-height: 1.67;
    }

    ul {
      padding-left: 2.1rem;

      li::before {
        content: '';
        display: inline-block;
        width: 7px;
        height: 7px;
        border: 2px solid ${colors.primary};
        border-radius: 50%;
        margin-left: -2.1rem;
        margin-right: 1.3rem;
        vertical-align: middle;
      }
    }
  }

  form {
    display: flex;
    padding: 6.25rem 3.625rem 3.75rem 3.625rem;
    height: 100%;
    flex-direction: column;
    background: #ffffff;

    .form__submit {
      .submit-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 3.125rem;
        padding: 0;
        font-size: 0.9375rem;
        text-transform: capitalize;

        svg {
          margin-right: 0.9375rem;
          vertical-align: middle;
        }
      }
    }

    .form__disclaimer {
      margin-top: 0.5rem;

      p {
        font-size: 0.75rem;
        color: ${colors.muted};
      }

      a {
        color: ${colors.primary};
      }
    }
  }

  ${StyledCheckbox} {
    margin-top: 1.5rem;

    /* targets sanity text */
    * {
      font-size: 0.75rem;
      line-height: 2rem;
    }

    label, input {
      color: ${colors.mutedDark};
    }

    color: ${colors.mutedDark};

    a {
      color: ${colors.primary};
    }
  }

  .success {
    width: 100%;
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: BioSans-Regular, sans-serif;
    font-size: 2rem;
    color: ${colors.primary};
  }

  .loading {
    width: 100%;
    height: 3.125rem;
    display: flex;
    justify-content: center;

    svg {
      height: 100%;
    }
  }

  @media only screen and (max-width: ${breakpoints.desktopSmall}) {
    padding: 0;
  }

  @media only screen and (max-width: ${breakpoints.mobile}) {
    .header {
      font-size: 1.5rem;
    }

    .subheader {
      font-size: 1rem;
      margin-bottom: 1rem;
    }

    form {
      padding: 1rem;
    }
  }
`;

export default DownloadFormWrapper;
