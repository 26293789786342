import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../layout'
import BlogListHero from './components/blogListHero'
import PostCardsContainer from '../components/blogListPostCards'
import Pagination from './components/blogListPagination'
import BlogCategories from './components/blogCategories'
import { SEO } from 'components/Seo'
import { BLOG_POSTS_PER_PAGE, BLOG_POSTS_FIRST_PAGE_LENGTH } from '../../../../constants/blog'
import useBlogCategoriesNavigation from '../hooks/useBlogCategories'
import SubscribeSectionNew from 'components/sections/SubscribeToNewsletter/SubscribeToNewsletterNew'
import { useBlogPostsSettingsQuery } from 'hooks/graphql/blog'
import 'hooks/graphql/blog'

export const query = graphql`
  query BlogListPageQuery($ids: [String!]!) {
    ...allSanityBlogPost
  }
`

const BlogListPage = ({
  data: { allSanityBlogPost },
  pageContext: { currentPage, pagesCount, categorySlug },
  location,
}) => {
  const { heroBlogPostLabel } = useBlogPostsSettingsQuery()
  const blogCategories = useBlogCategoriesNavigation()
  const blogPosts = allSanityBlogPost.nodes.filter((blogPost) => !blogPost.hidden)
  const heroPosts = blogPosts.slice(0, 5)

  const isFirstPage = currentPage === 0
  const remainingPosts = isFirstPage
    ? blogPosts.slice(5, BLOG_POSTS_FIRST_PAGE_LENGTH)
    : blogPosts.slice(5, BLOG_POSTS_PER_PAGE)

  return (
    <Layout shouldNavigationStartTransparent={false}>
      {isFirstPage && <BlogListHero blogPosts={heroPosts} heroBlogPostLabel={heroBlogPostLabel} />}
      <BlogCategories categories={blogCategories} location={location} currentPage={currentPage} />
      <PostCardsContainer
        blogPosts={isFirstPage ? remainingPosts : [...heroPosts, ...remainingPosts]}
        addContainerTopPadding={!isFirstPage}
        smallerContainerTopPadding={false}
      />
      <Pagination currentPage={currentPage} pagesCount={pagesCount} categorySlug={categorySlug} />
      <SubscribeSectionNew theme="light" />
    </Layout>
  )
}

export function Head({ location }) {
  const { seo } = useBlogPostsSettingsQuery()
  return <SEO location={location} {...seo} />
}

export default BlogListPage
