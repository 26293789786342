import { useState } from 'react';

//Components
import Button from '../Buttons';
import Checkbox from '../Form/Checkbox';
import Input from '../Form/Input';
import IconLoading from '../icons/IconLoading';
import Modal, { ModalContent } from '../Modal';

//Utils
import useDownloadPdfModal from './useDownloadPdfModal';

//Styling
import styled from 'styled-components';
import { colors } from '../../../constants/style';
import StyledInput from '../Form/Input/styled';

//Assets
import CloseSVG from '../../../static/images/icons/close-icon-gray.svg';
import RocketSVG from '../../../static/images/icons/Rocket.svg';

const StyledDownloadPdfModal = styled(ModalContent)`
  font-family: Roboto-Regular;
  padding: 3.5rem 8.5rem;
  border-radius: 0;
  position: relative;

  .close-button {
    position: absolute;
    top: 2rem;
    right: 2rem;
    &:hover {
      cursor: pointer;
    }
  }

  .title {
    font-family: BioSans-Regular;
    font-size: 1.75rem;
    letter-spacing: 0.26px;
    color: ${colors.titleDark};
  }
  .description {
    font-size: 1rem;
    color: #4e4d6c;
    font-family: Roboto-Regular;
  }

  .input-wrapper {
    margin: 2rem 0;
  }
  ${StyledInput} {
    margin-top: 1rem;
    label {
      padding: 0.8rem;
      display: flex;
      align-items: center;
      height: 3rem;
      overflow: hidden;
      width: 90%;
    }
    input {
      border: ${({ isError }) => (isError ? `1px solid ${colors.error}` : `1px solid ${colors.grayLight}`)};
      padding: 0 1rem;
      height: 3rem;
    }
    input:focus + label {
      display: none !important;
    }
    input:not(:placeholder-shown) ~ label {
      display: none;
    }
  }

  .link {
    color: ${colors.primary};
  }
  .checkbox-description {
    font-size: 0.88rem;
    margin-top: 0.4rem;
  }

  .submit-button {
    width: 100%;
    margin: 1rem 0;
  }

  .go-back {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    &:hover {
      cursor: pointer;
    }
  }

  .thanks-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .image {
    padding: 3rem 0.5rem 1.5rem;
  }
`;

//TODO:
//Make content dynamic via Sanity

const DownloadPdfModal = ({ fileUrl, isVisible, handleOnClose, isPlaybook = false }) => {
  const [submitComplete, setSubmitComplete] = useState(false);

  const handleExitModal = () => {
    handleOnClose();
    setSubmitComplete(false);
    resetFormData();
  };

  const { formData, handleInputChange, errorMessages, handleSubmit, isLoading, resetFormData } = useDownloadPdfModal({
    setSubmitComplete,
    fileUrl,
    isPlaybook
  });

  return (
    <Modal theme="dark" isVisible={isVisible} onClose={handleExitModal}>
      <StyledDownloadPdfModal onClose={handleExitModal}>
        <div className="close-button" onClick={handleExitModal}>
          <img src={CloseSVG} alt="close message" />
        </div>
        {submitComplete ? (
          <div className="thanks-content">
            <div className="image">
              <img src={RocketSVG} alt="rocket" />
            </div>
            <div className="title">Thank you for downloading</div>
            <div className="description">Make the most of our ebook!</div>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="title">Download the ebook in PDF</div>
            <div className="description">This also means you subscribe to our newsletter.</div>
            <div className="input-wrapper">
              <div>
                <span style={{ color: 'red' }}>*&nbsp;</span>
                <span>Your email</span>
              </div>
              <Input
                errorMessage={errorMessages.email}
                handleInputChange={handleInputChange}
                label="john.snow@gmail.com"
                value={formData.email}
                name="email"
              />
            </div>
            <Checkbox
              name="marketingAgreement"
              isChecked={formData?.marketingAgreement}
              onChange={handleInputChange}
              errorMessage={errorMessages?.marketingAgreement}
              className="checkbox"
            >
              <div className="checkbox-description">
                I have read and accept the Terms of&nbsp;
                <a className="link" target="blank" href="https://10clouds.com/privacy-policy/">
                  Privacy Policy
                </a>
                &nbsp;and&nbsp;
                <a className="link" target="blank" href="https://10clouds.com/terms-of-service/">
                  Terms of Service
                </a>
              </div>
            </Checkbox>
            {isLoading ? (
              <IconLoading />
            ) : (
              <Button className="submit-button" variant="filled" type="submit">
                Download
              </Button>
            )}

            <div className="go-back" onClick={handleExitModal}>
              Go back to the web version
            </div>
          </form>
        )}
      </StyledDownloadPdfModal>
    </Modal>
  );
};

export default DownloadPdfModal;
