import React from 'react'
import styled from 'styled-components'
import Button from '../../../../Buttons'
import { useDictionaryQuery } from 'hooks/graphql/page'
import Modal, { ModalContent } from '../../../../Modal'
import Input from '../../../../Form/Input'
import Checkbox, { StyledCheckbox, CheckboxError } from '../../../../Form/Checkbox'
import SanityBlockContent from '../../../../SanityBlockContent'
import IconLoading from '../../../../icons/IconLoading'
import { colors } from '../../../../../../constants/style'
import useDownloadFileModal from './useDownloadFileModal'

const StyledDownloadFileModal = styled(ModalContent)`
  .description {
    margin-bottom: 1rem;
    p {
      font-family: "BioSans-Regular", sans-serif;
      font-size: 1rem;
      letter-spacing: 0.26px;
      color: ${colors.titleDark};
    }
  }

  ${StyledCheckbox} {
    /* targets sanity text */
    * {
      font-size: 0.75rem;
      line-height: 2rem;
    }

    color: #7b7b8e;

    a {
      color: #7142eb;
    }
  }

  ${CheckboxError} {
    line-height: initial;
    margin-bottom: 1rem;
  }
`

const DownloadFileModal = ({
  selectedFile,
  handleOnClose,
  handleOnComplete,
  titleOfSelectedFile,
}) => {
  const {
    contactForm: { formInputEmail },
    resources: { modalConfirmLabel },
    _rawSubscribeForm,
  } = useDictionaryQuery()
  const { formData, handleInputChange, errorMessages, handleSubmit, isLoading } =
    useDownloadFileModal({
      handleOnComplete,
      fileUrl: selectedFile,
      titleOfSelectedFile,
    })

  return (
    <Modal theme="dark" isVisible={Boolean(selectedFile)} onClose={handleOnClose}>
      <StyledDownloadFileModal onClose={handleOnClose}>
        <div className="description">
          <SanityBlockContent blocks={_rawSubscribeForm.description} />
        </div>
        <Checkbox
          name="marketingAgreement"
          isChecked={formData?.marketingAgreement}
          onChange={handleInputChange}
          errorMessage={errorMessages?.marketingAgreement}
        >
          <SanityBlockContent blocks={_rawSubscribeForm.checkboxDescription} />
        </Checkbox>
        <form onSubmit={handleSubmit}>
          <Input
            errorMessage={errorMessages?.email}
            handleInputChange={handleInputChange}
            label={formInputEmail}
            value={formData?.email}
            name="email"
          />
          <div className="form__submit">
            {isLoading ? (
              <div className="loading">
                <IconLoading />
              </div>
            ) : (
              <Button className="submit-button" variant="filled" type="submit">
                {modalConfirmLabel}
              </Button>
            )}
          </div>
        </form>
      </StyledDownloadFileModal>
    </Modal>
  )
}

// modal do pobrania

export default DownloadFileModal
