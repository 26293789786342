import styled from 'styled-components'
import { colors } from '../../../../constants/style'
import { InputLabelStyle, InputWrapperStyle, InputStyle } from '../commonFormStyle'

export const StyledDiv = styled.div`
  ${InputWrapperStyle}

  .input-label {
    color: ${({ isError }) => (isError ? colors.error : colors.titleDark)};
    width: max-content;
    ${InputLabelStyle}
  }

  input {
    border: ${({ isError }) =>
      isError ? `1px solid ${colors.error} ` : `1px solid ${colors.grayLight}`};
    padding: 10px 16px;
    height: 3.25rem;

    ${InputStyle}

    &:hover {
      border: 1px solid #727292;
    }
  }

  textarea:focus,
  input:focus {
    border: 1px solid ${colors.primary};
    outline: 1px solid ${colors.primary};
  }
`
