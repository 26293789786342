import React from 'react'
import styled from 'styled-components'

const TestimonialsAgileAuthor = styled.div`
  margin-top: 3rem;
  color: var(--t1-body);
`

const TestimonialsAgileEntry = ({ description, authorName, authorPosition }) => {
  return (
    <div>
      <p className="h2">{description}</p>
      <TestimonialsAgileAuthor>
        {authorName && <p className="p2 semi-bold">{authorName}</p>}
        {authorPosition && <p className="p2">{authorPosition}</p>}
      </TestimonialsAgileAuthor>
    </div>
  )
}

export default TestimonialsAgileEntry
