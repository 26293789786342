import styled from 'styled-components';
import { colors } from '../../../../constants/style';
import { InputLabelStyle, InputWrapperStyle, InputStyle } from '../commonFormStyle';

const flagBtnHorizontalPadding = '16px';
const selectedFlagWidth = '38px'; // originally it was fixed 38px as well, turned it into variable for the code clarity
const phoneInputMargin = `calc((2 * ${flagBtnHorizontalPadding}) + ${selectedFlagWidth} + 1px)`;

//"phoneInputInlineStyle", "phoneInputButtonInlineStyle", "phoneInputBorderMouseOver", "phoneInputBorderMouseOut", "phoneInputBorderError" are inline styles used in component InputPhone, because sometimes there is bug with wrong styles, this is workaround. The styles in variables are duplication of below styles
//if you want to change "phoneInputInlineStyle", please change also corresponding properties in "StyledInput .phone-input-ref" below and "InputStyle" in '../commonFormStyle.js'
export const phoneInputInlineStyle = {
  height: '3.25rem',
  borderRadius: '0',
  width: `calc(100% - ${phoneInputMargin}`,
  marginLeft: phoneInputMargin,
  // this part is from InputStyle
  lineHeight: '2rem',
  fontSize: '1rem',
  padding: '10px 16px',
  color: `${colors.titleDark}`,
  fontFamily: 'Roboto-Regular, sans-serif',
};
//if you want to change "phoneInputButtonInlineStyle" please change also corresponding properties in "StyledInput .phone-input-country-btn" below
export const phoneInputButtonInlineStyle = {
  borderRadius: '0',
  background: 'transparent',
  padding: `10px ${flagBtnHorizontalPadding}`,
};

export const phoneInputBorderMouseOver = `1px solid ${colors.purpleGray}`; //if you want to change it, please change also "StyledInput .phone-input-ref:hover"
export const phoneInputBorderMouseOut = `1px solid ${colors.grayLight}`; //if you want to change it, please change also "StyledInput .phone-input-ref {border}"
export const phoneInputBorderError = `1px solid ${colors.error}`; //if you want to change it, please change also "StyledInput .phone-input-ref {border}"

export const StyledInput = styled.div`
  ${InputWrapperStyle}

  .input-label {
    color: ${({ isError }) => (isError ? colors.error : colors.titleDark)};
    ${InputLabelStyle}
  }

  .phone-input-ref,
  input {
    --phone-input-margin: calc((2 * ${flagBtnHorizontalPadding}) + ${selectedFlagWidth} + 1px);

    border: ${({ isError }) => (isError ? `1px solid ${colors.error}` : `1px solid ${colors.grayLight}`)};
    height: 3.25rem;
    border-radius: 0;
    width: calc(100% - var(--phone-input-margin));
    margin-left: var(--phone-input-margin);

    ${InputStyle}

    &:hover {
      border: 1px solid ${colors.purpleGray};
    }
  }

  textarea:focus,
  input:focus {
    border: 1px solid ${colors.primary};
    outline: 1px solid ${colors.primary};
  }

  .phone-input- {
    &wrapper {
      height: auto;
    }

    &country-btn {
      border-radius: 0;
      background: transparent;
      border-left-color: ${({ isError }) => (isError ? `1px solid ${colors.error}` : `1px solid ${colors.grayLight}`)};
      border-top-color: ${({ isError }) => (isError ? `1px solid ${colors.error}` : `1px solid ${colors.grayLight}`)};
      border-bottom-color: ${({ isError }) => (isError ? `1px solid ${colors.error}` : `1px solid ${colors.grayLight}`)};

      &:hover {
        border: 1px solid ${colors.purpleGray};
      }

      padding: 10px ${flagBtnHorizontalPadding};

      .selected-flag {
        width: ${selectedFlagWidth};
      }
    }

    &search {
      border-radius: 0;
      &:hover {
        border: 1px solid #727292;
      }
    }
  }
`;

export default StyledInput;
