import React from 'react';
import mediumIcon from 'static/images/logo/medium.svg';
import { parseSlug } from 'utils/slug';
import { useBlogCategoriesQuery } from 'hooks/graphql/blog';

const useBlogCategoriesNavigation = () => {
  const { allSanityBlogCategory, allSanityBlogSettings } = useBlogCategoriesQuery();

  const getBlogCategoriesNavItems = (queryObj) => [
    { label: 'All articles', url: '/blog/' },
    ...queryObj.map(({ title, slug }) => ({
      label: title,
      url: `/blog/${parseSlug(slug.current)}`,
    })),
    {
      label: <img src={mediumIcon} alt="medium icon" style={{ minWidth: '60px' }} />,
      url: 'https://medium.com/beautiful-code-smart-design-by-10clouds',
    },
  ];

  const isSanitycategoiesWorking =
    allSanityBlogSettings.nodes.length !== 0
      ? getBlogCategoriesNavItems(allSanityBlogSettings.nodes[0].blogCategories)
      : getBlogCategoriesNavItems(allSanityBlogCategory.nodes);

  return isSanitycategoiesWorking;
};

export default useBlogCategoriesNavigation;
