import React from 'react'

import { ArticleCard } from 'components/ArticleCard'
import SliderSection from 'components/SliderSection/SliderSection'
import { caseStudiesUrl } from '../../../../../constants/links'
import { useDictionaryQuery } from 'hooks/graphql/page'
import { useSuggestedCaseStudiesQuery } from 'hooks/graphql/section'
import styles from './suggestedCaseStudies.module.scss'

type CaseStudiesSuggestionsList = {
  url?: string
  slug: {
    current: string
  }
  abstract: {
    projectName: string
    tags: string[]
    description: string
    imageForCaseStudiesList: {
      asset: {
        url: string
      }
    }
  }
}

type SuggestedCaseStudiesProps = {
  title?: string
  description?: string
  numberOfSuggestions?: number
}

const DEFAULT_NUMBER_OF_SUGGESTIONS = 4
const SEE_MORE_BTN = { label: 'See more', link: caseStudiesUrl }

const arrayToString = (arr: string[]) => {
  return arr.join(', ')
}

const SuggestedCaseStudies = ({
  title,
  description,
  numberOfSuggestions,
}: SuggestedCaseStudiesProps) => {
  const moreCaseStudiesList = useSuggestedCaseStudiesQuery()
  const {
    caseStudy: { moreCaseStudies },
  } = useDictionaryQuery()

  const filteredMoreCaseStudiesList: CaseStudiesSuggestionsList[] = moreCaseStudiesList
    .filter((cs: any) => cs.hidden !== true)
    .slice(0, numberOfSuggestions || DEFAULT_NUMBER_OF_SUGGESTIONS)

  return (
    <SliderSection
      header={title || moreCaseStudies?.title}
      description={description || moreCaseStudies?.description}
      items={filteredMoreCaseStudiesList}
      headerPosition="center"
      button={SEE_MORE_BTN}
      className={styles.suggestedCaseStudiesWrapper}
    >
      {filteredMoreCaseStudiesList.map((item, index) => (
        <ArticleCard
          key={index}
          title={item?.abstract?.projectName}
          preTitle={arrayToString(item?.abstract?.tags)}
          description={item?.abstract?.description}
          featuredImage={item?.abstract?.imageForCaseStudiesList}
          link={
            item?.url ||
            `${caseStudiesUrl}${!caseStudiesUrl.endsWith('/') ? '/' : ''}${item?.slug?.current}`
          }
          imageAspectRatio="16/9"
        />
      ))}
    </SliderSection>
  )
}

export default SuggestedCaseStudies
