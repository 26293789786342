import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import HorizontalSlider, { navigationWidth } from '../../HorizontalSlider';
import TechnologyDetail from './TechnologyDetail';

import { breakpoints, colors } from '../../../../constants/style';
import { horizontalLayoutPadding, horizontalLayoutPaddingMobile } from '../../../features/layout/components/commonLayoutStyle';
import useWindowSize from '../../../hooks/useWindowSize';

const sliderNavigationToBackgroundMargin = '3.125rem';
const slidesMargin = '1.1rem';

const TechnoogyDetailsSection = styled('section')`
  padding: 10rem 0 !important;
  background-color: ${colors.titleDark};

  .title {
    padding: 0 ${horizontalLayoutPadding} 3.5625rem ${horizontalLayoutPadding};
    color: ${colors.sectionTitleLight};
    letter-spacing: normal;

    &:before {
      content: '';
      display: block;
      margin-bottom: 2.5rem;
      width: 50px;
      height: 5px;
      background: ${colors.secondary};
    }
  }

  .description {
    padding: 0 ${horizontalLayoutPadding} 6.25rem ${horizontalLayoutPadding};
    color: ${colors.muted};
    max-width: 73.25rem;
    font-size: 1.375rem;
    line-height: 1.91;
  }

  .details {
    padding: 8.5625rem 0 3.75rem 0;
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      width: calc(100% - ${horizontalLayoutPadding} - (${navigationWidth}) - ${sliderNavigationToBackgroundMargin});
      height: 100%;
      background-image: linear-gradient(243deg, #200e47, #2a086a 6%);
    }

    .detail {
      width: calc(33vw - 2.2rem);
      margin: 0 ${slidesMargin};

      &.detail--blank {
        display: block !important;
        /* -3 * 1.1 - because every slide has margins and we want that first visible slide was in same place as navigation arrows */
        width: calc(${horizontalLayoutPadding} - 3 * ${slidesMargin}) !important;
        height: 1px;
      }
    }

    .slider__button-wrapper {
      padding-left: ${horizontalLayoutPadding};
    }
  }

  @media only screen and (max-width: ${breakpoints.desktopSmall}) {
    .details {
      .detail {
        width: auto;
      }

      .slider__button-wrapper {
        padding-left: ${horizontalLayoutPadding};
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    .title {
      padding: 0 ${horizontalLayoutPaddingMobile} 3.5625rem ${horizontalLayoutPaddingMobile};
    }

    .description {
      padding: 0 ${horizontalLayoutPaddingMobile} 6.25rem ${horizontalLayoutPaddingMobile};
    }

    .details {
      padding: 0;

      &:before {
        display: none;
      }

      .slider__button-wrapper {
        padding-left: ${horizontalLayoutPaddingMobile};
      }
    }
  }
`;

const TechnologyDetails = ({ header, description, details }) => {
  const { isSmallerThanDesktop } = useWindowSize();
  const [showBlankSlide, setShowBlankSlide] = useState(false);

  // force re-render of component when window size changes (blank slide depends on window width)
  useEffect(() => {
    setShowBlankSlide(!isSmallerThanDesktop);
  }, [isSmallerThanDesktop]);

  const settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: true,
    responsive: [
      {
        breakpoint: parseInt(breakpoints.desktop, 10),
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: parseInt(breakpoints.desktopSmall, 10),
        settings: {
          variableWidth: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: parseInt(breakpoints.tablet, 10),
        settings: {
          variableWidth: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <TechnoogyDetailsSection>
      <h2 className="title">{header}</h2>
      <p className="description">{description}</p>
      <div className="details">
        <HorizontalSlider theme="dark" customSettings={settings}>
          {showBlankSlide && <div className="detail detail--blank" />}
          {details.map((item, index) => (
            <TechnologyDetail key={index} className="detail" header={item.header} description={item.description} icon={item.icon} />
          ))}
        </HorizontalSlider>
      </div>
    </TechnoogyDetailsSection>
  );
};

export default TechnologyDetails;
