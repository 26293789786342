import React from 'react'
import { GatsbyImageProps } from 'gatsby-plugin-image'

import Image from 'components/Image'
import { convertToBackgroundImage } from 'utils/image'

import {
  Background,
  BackgroundTransparent,
  HeroForCaseStudyWrapper,
  TextBox,
} from './HeroForCaseStudyStyles'
import SanityBlockContent from '../../../components/SanityBlockContent';

type BackgroundImageProps = {
  alt: string
  asset: {
    url: string
    gatsbyImageData: GatsbyImageProps
  }
}

type HeroForCaseStudyProps = {
  logo?: string
  backgroundImage?: BackgroundImageProps
  title: any
}

const HeroForCaseStudy = ({ logo, backgroundImage, title }: HeroForCaseStudyProps) => {
  const bgImage = convertToBackgroundImage(backgroundImage?.asset)
  return (
    <HeroForCaseStudyWrapper>
      <Background fluid={bgImage?.fluid} alt="background" />
      <BackgroundTransparent>
        <TextBox>
          {logo && <Image image={typeof logo === 'object' && logo.asset ? logo.asset.url : logo} alt="logo" className="logo" />}
          {typeof title === 'string' ? <h1 className="title">{title}</h1> : <SanityBlockContent className="title" blocks={title} />}
        </TextBox>
      </BackgroundTransparent>
    </HeroForCaseStudyWrapper>
  )
}

export default HeroForCaseStudy
