import React from 'react';
import Hero from '../Hero';
import DownloadForm from '../../DownloadForm';

const HeroWithContactFormSection = ({
  heroHeader,
  heroDescription,
  formName,
  formHeader,
  formSubheader,
  fileUrl,
  rawForm,
  showProjectDescriptionInput,
  whitePaper
}) => {
  return (
    <Hero header={heroHeader} description={heroDescription} showScroll fullScreen hideRightColumnOnMobile={false}>
      <DownloadForm
        formName={formName}
        header={formHeader}
        subheader={formSubheader}
        fileUrl={fileUrl}
        raw={rawForm}
        showProjectDescriptionInput={showProjectDescriptionInput}
        whitePaper={whitePaper}
      />
    </Hero>
  );
};

export default HeroWithContactFormSection;
