import React, { useCallback } from 'react';
import noIcon from '../../../../../../static/images/icons/no.svg';
import yesIcon from '../../../../../../static/images/icons/yes.svg';

import Slider from '../../../../HorizontalSlider';
import { StyledMobileTable, StyledTable } from './styled';

const transposeTableDataForMobile = (rows) => {
  return rows.reduce(
    (acc, curr, index) => {
      const cells = curr.cells;
      const newLegend = [...acc.legend];

      const newColumns = { ...acc.columns };
      cells.forEach((cell, index) => {
        if (index === 0) {
          newLegend.push(cell);
        } else {
          newColumns[index] = newColumns[index]?.length ? [...newColumns[index], cell] : [cell];
        }
      });
      return { legend: newLegend, columns: newColumns };
    },
    { legend: [], columns: {} },
  );
};

const getCellIconData = (isPositive) => {
  if (isPositive) {
    return {
      icon: yesIcon,
      alt: 'Desired option icon',
    }
  }

  return {
    icon: noIcon,
    alt: 'Not desired option icon',
  }
}

const Table = ({ rows, positiveWords, shouldDisplayPositiveLabel, negativeWords, shouldDisplayNegativeLabel }) => {
  const [headingRow, ...restRows] = rows;
  const { legend, columns } = transposeTableDataForMobile(rows);

  const getCellData = useCallback((cell, index) => {
    const isFirst = index === 0;
    const isPositive = positiveWords?.includes(cell);
    const isNegative = negativeWords?.includes(cell);
    const shouldRenderIcon = !isFirst && (isPositive || isNegative);
    const shouldDisplayLabel = (shouldDisplayPositiveLabel && isPositive) || (shouldDisplayNegativeLabel && isNegative);
  
    return {
      isFirst,
      isPositive,
      isNegative,
      shouldRenderIcon,
      shouldDisplayLabel,
    };
  }, [negativeWords, positiveWords, shouldDisplayNegativeLabel, shouldDisplayPositiveLabel])

  return (
    <>
      <StyledTable>
        <thead>
          <tr>
            {headingRow.cells.map((cell, index) => (
              <th key={index}>{cell}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {restRows.map(({ cells }, rowIndex) => (
            <tr key={rowIndex}>
              {cells.map((cell, index) => {
                const { isFirst, isPositive, shouldRenderIcon, shouldDisplayLabel } = getCellData(cell, index);

                return (
                  <td
                    className={`${isFirst && 'table-column--first'}`}
                    key={index}
                  >
                    {shouldRenderIcon && (
                      <img src={getCellIconData(isPositive).icon} alt={getCellIconData(isPositive).alt} />
                    )}
                    {(!shouldRenderIcon || shouldDisplayLabel) && <p>{cell}</p>}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </StyledTable>

      <StyledMobileTable>
        <div className="table-legend">
          {legend.map((cell, index) => (
            <div className={`table-legend__entry ${index === 1 && 'table-legend__entry--colored'}`} key={index}>
              <p>{cell}</p>
            </div>
          ))}
        </div>
        <Slider>
          {Object.values(columns).map((column, columnIndex) => (
            <div key={columnIndex}>
              {column.map((cell, index) => {
                const { isPositive, shouldRenderIcon, shouldDisplayLabel } = getCellData(cell, index);

                return (
                  <div className="table-column__entry" key={index}>
                    {shouldRenderIcon && (
                      <img src={getCellIconData(isPositive).icon} alt={getCellIconData(isPositive).alt} />
                    )}
                    {(!shouldRenderIcon || shouldDisplayLabel) && <p>{cell}</p>}
                  </div>
                );
              })}
            </div>
          ))}
        </Slider>
      </StyledMobileTable>
    </>
  );
};

export default Table;
