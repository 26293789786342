import { useStaticQuery, graphql } from 'gatsby'

export const useCareerDictionaryQuery = () => {
  const data = useStaticQuery(graphql`
    {
      sanityCareers(language: { eq: "en" }) {
        careerDictionary {
          remoteLabel
          remoteOnlyLabel
        }
      }
    }
  `)
  return data.sanityCareers.careerDictionary
}
