import React from 'react';
import { is10cDomain } from '../../../utils/link';
import Image from '../../Image';

const ClickableImageWithURL = ({ alt, caption, url, asset }) => (
    <a href={url} target="_blank" rel={`${is10cDomain(url) ? '' : 'noopener noreferrer'}`}>
      <figure>
        <Image image={{asset}} alt={alt || ''} style={{ width: '100%' }} />
        <figcaption>{caption}</figcaption>
      </figure>
    </a>
);

export default ClickableImageWithURL;
