import { css } from 'styled-components';
import { colors } from '../../../constants/style';

const bulletListPlaybook = css`
  ul {
    margin-bottom: 1.5rem;
    li {
      font-family: Roboto-Regular, sans-serif;
      font-size: 1.1875rem;
      line-height: 2rem;
      color: #4e4d6c;
      padding: 0;
      margin-left: 2.75rem;
      margin-bottom: 1rem;

      br {
        display: block;
        content: ' ';
        margin-bottom: 0.5rem;
      }

      &:before {
        content: '';
        position: absolute;
        left: -2.75rem;
        top: 1rem;
        transform: translateY(-50%);
        background: ${colors.primary};
        width: 0.75rem;
        height: 0.75rem;
      }
    }
  }
`;

export default bulletListPlaybook;
