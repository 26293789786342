import { useState, useEffect } from 'react';
import throttle from 'lodash.throttle';

const useScroll = (refElement) => {
  const [scrollState, setScrollState] = useState({
    x: 0,
    y: 0,
  });

  const handleScroll = throttle(
    () => {
      if (!refElement?.current) {
        setScrollState({
          x: window.pageXOffset,
          y: window.pageYOffset,
        });
        return;
      }

      setScrollState({
        x: refElement.current.scrollLeft,
        y: refElement.current.scrollTop,
      });
    },
    200,
    { trailing: false },
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refElement]);

  return scrollState;
};

export default useScroll;
