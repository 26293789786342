import React from 'react'
import ContactLayout from '../../features/contactLayout'
import Sidebar from '../../features/contactLayout/components/Sidebar'
import ThankYou from '../../templates/contactUsPage/components/ThankYou'

const ThankYouPage = () => (
  <ContactLayout sidebar={<Sidebar />}>
    <ThankYou />
  </ContactLayout>
)

export function Head() {
  return <meta name="robots" content="noindex" />
}

export default ThankYouPage
