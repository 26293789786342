import SanityBlockContent from 'components/SanityBlockContent'
import itemStyles from './styles.module.scss'

const ScrollItem = ({ title, rawDescription }) => {
  return (
    <div className={itemStyles.container}>
      <h3 className="h1 semi-bold">{title}</h3>
      <SanityBlockContent blocks={rawDescription} className={itemStyles.description} />
    </div>
  )
}

export default ScrollItem
