import React from 'react';
import StyledAdvantagesSection from './styled';
import AdvantagesEntry from './components/AdvantagesEntry';

const AdvantagesSection = ({ advantages }) => {
  return (
    <StyledAdvantagesSection>
      {advantages.map((advantage, index) => (
        <AdvantagesEntry key={index} advantage={advantage} />
      ))}
    </StyledAdvantagesSection>
  );
};

export default AdvantagesSection;
