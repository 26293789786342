import React, { useEffect } from 'react';
import Image from '../../Image';

import { navbarMinHeight } from '../../../features/layout/components/commonLayoutStyle';
import SanityBlockContent from '../../SanityBlockContent';
import useWindowSize from '../../../hooks/useWindowSize';
import arrow from '../../../../static/images/icons/arrow-more-down.svg';

import ProcessOfDeliverySection from './styled';

const ProcessOfDelivery = ({ header, items }) => {
  const [position, setPosition] = React.useState(null);

  const { isSmallerThanDesktop } = useWindowSize();

  const isActive = (index) => {
    if (position === index) {
      return true;
    } else if (!isSmallerThanDesktop) {
      return index === 0 && position === null;
    } else {
      return false;
    }
  };

  const handleClick = (e, index) => {
    e.stopPropagation();

    if (index === position && isSmallerThanDesktop) {
      setPosition(null);
    } else {
      setPosition(index);
    }

    // After text dropdown animation - scroll client to the top of the element
    setTimeout(() => {
      if (!isSmallerThanDesktop) {
        return;
      }
      window.scroll({
        top: e.target.getBoundingClientRect().top + window.scrollY - parseInt(navbarMinHeight) * 16,
      });
    }, 50);
  };

  return (
    <ProcessOfDeliverySection>
      <h1 className="header">
        <SanityBlockContent blocks={header} />
      </h1>
      <div className="content-navigation">
        {items.map((item, index) => (
          <div className="content-navigation__item" onClick={(e) => handleClick(e, index)} key={item.header}>
            <Image className="content-navigation__item-image" image={item.icon} objectFit="contain" />
            <div className={`content-navigation__item-decoration ${position >= index && 'active'} ${isActive(index) && 'chosen'}`}>
              <div className="content-navigation__item-decoration-line"></div>
              <div className="content-navigation__item-decoration-circle"></div>
            </div>
            <button className={`content-navigation__item-button ${isActive(index) && 'active'}`} onClick={(e) => handleClick(e, index)}>
              <span>{item.header}</span>
              <img src={arrow} alt="" />
            </button>
            {isSmallerThanDesktop && <ContentBody items={items} position={index} active={position} />}
          </div>
        ))}
      </div>
      {!isSmallerThanDesktop && <ContentBody items={items} position={position || 0} />}
    </ProcessOfDeliverySection>
  );
};

const ContentBody = ({ items, position, active }) => (
  <div className={`content-body ${active === position && 'active'}`}>
    <div className="content-body__column">
      <h2 className="content-body__header">{items[position].header}</h2>
      <div className="content-body__description">
        <SanityBlockContent blocks={items[position]._rawDescription} />
      </div>
      <Steps items={items} position={position} />
    </div>
    <div className="content-body__column-image-wrapper">
      <Image className="content-body__image" image={items[position].icon} />
    </div>
  </div>
);

const Steps = ({ items, position }) => {
  const [activeItems, setActiveItems] = React.useState([0]);

  const { isSmallerThanDesktop } = useWindowSize();

  useEffect(() => {
    if (position) {
      setActiveItems([]);
    }
  }, [position]);

  return (
    <div className="content-body__steps">
      {items[position].steps.map((step, index) =>
        isSmallerThanDesktop ? (
          <div key={step.header}>
            <h3 className="content-body__steps-header"> {step.header} </h3>
            <div className="content-body__steps-description">
              <SanityBlockContent blocks={items[position].steps[index]?._rawDescription} />
            </div>
          </div>
        ) : (
          <div className="content-body__steps-item" key={step.header}>
            <button
              className={`content-body__steps-button ${activeItems.includes(index) && 'active'}`}
              onClick={() =>
                activeItems.includes(index)
                  ? setActiveItems(activeItems.filter((el) => el !== index))
                  : setActiveItems([...activeItems, index])
              }
            >
              <span>{step.header}</span>
              <img src={arrow} alt="" />
            </button>
            <div className={`content-body__steps-description ${activeItems.includes(index) && 'active'}`}>
              <SanityBlockContent blocks={items[position].steps[index]?._rawDescription} />
            </div>
          </div>
        ),
      )}
    </div>
  );
};

export default ProcessOfDelivery;
