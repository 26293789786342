import React from 'react';
import styled from 'styled-components';

import { breakpoints, colors, targetIE } from '../../../../constants/style';
import SolutionEntry from './SolutionEntry';
import { navbarMinHeight } from '../../../features/layout/components/commonLayoutStyle';

const SolutionsSection = styled('section')`
  padding-top: 8.125rem;
  padding-bottom: 8.125rem;
  background-color: ${colors.titleDark};

  .header {
    color: ${colors.sectionTitleLight};

    &:before {
      content: '';
      display: block;
      margin-bottom: 2.5rem;
      width: 50px;
      height: 5px;
      background: ${colors.secondary};
    }
  }

  .description {
    color: ${colors.mutedLight};
    margin-top: 1.875rem;
  }

  .solutions {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 8.125rem -0.75rem 0 -0.75rem;

    &::after {
      content: '';
      margin-right: auto;
    }

    .solution {
      flex: 0 0 25%;
      padding: 0.75rem;
    }
  }

  // hack for IE11 not respecting box-sizing for flex-basis size
  @media only screen and ${targetIE} {
    .solutions {
      .solution {
        flex: 0 0 22%;
        margin: 0 auto;
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.desktopSmall}) {
    .solutions {
      .solution {
        flex: 0 0 50%;
      }
    }
  }
  // hack for IE11 not respecting box-sizing for flex-basis size
  @media only screen and (max-width: ${breakpoints.desktopSmall}) and (-ms-high-contrast: none),
    only screen and (max-width: ${breakpoints.desktopSmall}) and (-ms-high-contrast: active) {
    .solutions {
      .solution {
        flex: 0 0 45%;
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    padding-top: ${navbarMinHeight};
    padding-bottom: ${navbarMinHeight};

    .solutions {
      align-items: stretch;
    }
  }

  @media only screen and (max-width: ${breakpoints.mobile}) {
    .solutions {
      display: block;
    }
  }
`;

const Solutions = ({ header, description, solutions }) => {
  return (
    <SolutionsSection>
      <h2 className="header">{header}</h2>
      <p className="description">{description}</p>
      <div className="solutions">
        {solutions.map((item, index) => {
          return (
            <div className="solution" key={`solution-${index}`}>
              <SolutionEntry icon={item.icon} title={item.title} description={item.description} />
            </div>
          );
        })}
      </div>
    </SolutionsSection>
  );
};

export default Solutions;
