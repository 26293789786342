import { FC, useEffect, useRef, useState } from 'react'
import classnames from 'classnames'

import { useNavigationQuery } from 'hooks/graphql/others'
import { Link } from 'components/Link';
import logo from 'static/images/10c-logo/10clouds-logo.svg'

import styles from './Navigation.module.scss'
import { MenuLink, MenuExpandable, MenuButton, TopMessageBar } from './components';

interface NavigationProps {
  shouldStartTransparent: boolean
}

export const Navigation: FC<NavigationProps> = props => {
  const { shouldStartTransparent } = props
  const [scrolledFromTop, setScrolledFromTop] = useState(false);
  const pixelRef = useRef<HTMLDivElement>(null)
  const [openElement, setOpenElement] = useState<string | null>(null)

  const data = useNavigationQuery()

  useEffect(() => {
    const el = document.querySelector('#pixel-to-watch')
    if (el || pixelRef.current) {
      const observer = new IntersectionObserver((entries) => setScrolledFromTop(entries[0].boundingClientRect.top < -1));
      if (el) {
        observer.observe(el);
      }
      if (pixelRef.current) {
        observer.observe(pixelRef.current);
      }
    }
  }, []);

  return (
    <>
      <div className={styles.scrollWatchPixel}  id="pixel-to-watch" ref={pixelRef} />
      <div className={classnames(styles.wrapper, { [styles.wrapperTransparent]: shouldStartTransparent && !scrolledFromTop })}>
        <div className={styles.messageBarWrapper}><TopMessageBar /></div>
        <nav className={styles.nav}>
          <Link className={styles.logo} to="/">
            <img src={logo} alt="10clouds logo" />
          </Link>

          {data.map((item, index) => {
            if (item.isButton) {
              const key = `nav-mob-${index}-${item.label.replace(' ', '-')}`
              return <div className={styles.mobButton} key={key}><MenuButton item={item} /></div>
            }
            return null
          })}

          <input id="nav" className={styles.navInput} type="checkbox" name="nav" value="1" />
          <label htmlFor="nav" className={styles.hamburger}><span /></label>

          <div className={styles.mainItemsWrapper}>
            {data.map((item, index) => {
              const key = `nav-${index}-${item.label.replace(' ', '-')}`

              if (item.isButton) {
                return <MenuButton key={key} item={item} />
              }

              if (item.children && item.children.length > 0) {
                return (
                  <MenuExpandable
                    key={key}
                    id={key}
                    item={item}
                    className={styles.label}
                    isOpen={openElement === key}
                    setIsOpen={(id) => setOpenElement(id)}
                  />
                )
              }

              if (item.internalUrl || item.externalUrl) {
                return <MenuLink key={key} item={item} className={classnames(styles.label, styles.link)} />
              }

              return <div key={key} className={styles.label}>{item.label}</div>
            })}
          </div>

        </nav>
      </div>
      {openElement && <div className={styles.fullScreenWrapper}></div>}
    </>
  );
};
