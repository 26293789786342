import React from 'react';
import styled from 'styled-components';

import { colors } from '../../../constants/style';

const StyledInlineListItem = styled('div')`
  border: 2px solid ${colors.primary};
  border-radius: 0.25rem;
  padding: 0.375rem 0.625rem;

  font-family: Roboto-Regular, sans-serif;
  font-size: 1rem;
  line-height: 1.75rem;
  color: #4e4d6c;
`;

const InlineListItem = ({ item }) => {
  return <StyledInlineListItem>{item}</StyledInlineListItem>;
};

export default InlineListItem;
