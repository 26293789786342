import React from 'react'
import SendingFormError from '../../templates/contactUsPage/components/SendingFormError'
import ContactLayout from '../../features/contactLayout'
import Sidebar from '../../features/contactLayout/components/Sidebar'

const SendingFormErrorPage = () => (
  <ContactLayout sidebar={<Sidebar />}>
    <SendingFormError />
  </ContactLayout>
)

export function Head() {
  return <meta name="robots" content="noindex" />
}

export default SendingFormErrorPage
