import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Fade from 'react-reveal/Fade'

import EmployeeEntry from '../../EmployeeEntry'

import { breakpoints, colors, containerMaxWidth } from '../../../../constants/style'
import Button from '../../Buttons'
import useWindowSize from '../../../hooks/useWindowSize'

import WaveSVG from '../../../../static/images/wave-light-1.svg'
import { useDictionaryQuery } from 'hooks/graphql/page'
import SectionHeader from '../../SectionHeader/SectionHeader'

const EmployeesListSection = styled('section')`
  position: relative;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : colors.backgroundLight};

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    background: url(${WaveSVG}) no-repeat left top;
  }

  .employees {
    position: relative;
    z-index: 1;
    max-width: ${containerMaxWidth};
    margin: 5rem auto 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(16rem, 23.125rem));
    justify-content: center;
    gap: 2rem;
    @media only screen and (max-width: ${breakpoints.desktop}) {
      grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
    }
  }

  .more {
    margin: 3.125rem auto 0 auto;
  }

  .react-reveal {
    width: 100%;
  }
`

const VISIBLE_EMPLOYEES_COUNT = 8
const ITEMS_PER_ROW = Math.round(VISIBLE_EMPLOYEES_COUNT / 2)

const EmployeesList = ({
  header,
  description,
  employees,
  backgroundColor = false,
  showLinksAndDesc = false,
}) => {
  const [visibleItems, setVisibleItems] = useState(VISIBLE_EMPLOYEES_COUNT)
  const [itemsPerRow, setItemsPerRow] = useState(ITEMS_PER_ROW)
  const [animatedElementIndex, setAnimatedElementIndex] = useState(0)

  const { isSmallerThanDesktop, isSmallerThanDesktopSmall } = useWindowSize()

  const { showMoreLabel } = useDictionaryQuery()

  const showMore = () => setVisibleItems(visibleItems + itemsPerRow)

  useEffect(() => {
    if (isSmallerThanDesktop) {
      setVisibleItems(employees.length)
      setItemsPerRow(3)
    } else if (isSmallerThanDesktopSmall) {
      setVisibleItems(2)
      setItemsPerRow(1)
    } else {
      setVisibleItems(VISIBLE_EMPLOYEES_COUNT)
      setItemsPerRow(ITEMS_PER_ROW)
    }
  }, [
    setItemsPerRow,
    setVisibleItems,
    isSmallerThanDesktop,
    isSmallerThanDesktopSmall,
    employees.length,
  ])

  return (
    <EmployeesListSection backgroundColor={backgroundColor}>
      <SectionHeader title={header} subtitle={description} />
      <div className="employees">
        {employees.map(
          (item, index) =>
            index < visibleItems && (
              <Fade
                bottom
                distance="100px"
                appear
                onReveal={() => {
                  setAnimatedElementIndex(animatedElementIndex + 1)
                }}
                duration={500}
                wait={400}
                delay={isSmallerThanDesktopSmall ? 0 : (index % itemsPerRow) * 400}
                key={`designer-${index}`}
              >
                <EmployeeEntry
                  key={`designer-${index}`}
                  image={item.image}
                  name={item.name}
                  position={item.position}
                  employeeLinks={item.employeeLinks}
                  description={item.description}
                  showLinksAndDesc={showLinksAndDesc}
                />
              </Fade>
            ),
        )}
      </div>
      {!isSmallerThanDesktopSmall && visibleItems < employees.length && (
        <Button className="more" onClick={showMore} disabled={visibleItems >= employees.length}>
          {showMoreLabel}
        </Button>
      )}
    </EmployeesListSection>
  )
}

export default EmployeesList
