import React, { useState } from 'react';
import styled from 'styled-components';
import commonTooltipStyles from '../TooltipCommonStyles';

const StyledTooltipSpan = styled('span')`
  position: relative;
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
  margin: 0 5px;

  &:hover {
    cursor: help;
    & > span {
      opacity: 1;
      z-index: 20;
      color: #ffffff;
    }
  }
`;

const Tooltip = styled('span')`
  ${commonTooltipStyles};
  top: ${({ height }) => height + 4}px;
  left: calc(-50% + ${({ width }) => width}px);
  transition: opacity 0.3s;

  ${({ passStyle }) => passStyle}
`;

const TooltipSpan = ({ icon, iconHover = icon, content, height, width, passStyle }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <StyledTooltipSpan width={width} height={height}>
      <img
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        src={isHovered ? iconHover : icon}
        alt="tooltip icon"
        style={{ width, height }}
      />
      <Tooltip width={width} height={height} passStyle={passStyle}>
        {content}
      </Tooltip>
    </StyledTooltipSpan>
  );
};

export default TooltipSpan;
