import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

import Slider from 'react-slick';
import './slick.css';

import { breakpoints, colors } from '../../../constants/style';
import IconArrowLong from '../icons/IconArrowLong';

const progressWidth = '202px';
const progressMargin = '3rem';
const buttonWidth = '33px';

export const SliderWrapper = styled('div')`
  .slider__button-wrapper {
    display: flex;
    align-items: center;
    margin-top: 3.5rem;

    @media only screen and (max-width: ${breakpoints.tablet}) {
      margin-top: 3rem;
    }
  }

  .slider__button-left,
  .slider__button-right {
    background-color: transparent;
    height: ${buttonWidth};
    width: ${buttonWidth};
    border: 0;
    padding: 0;
    outline: 0;
    cursor: pointer;
    transform: translateX(0);
    transition: transform 0.2s ease-in-out;

    span {
      font-size: 0;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.3;
    }

    &:focus {
      outline: 0;
    }

    svg {
      fill: ${(props) => props.theme.arrow.fill};
    }
  }

  .slider__button-left {
    svg {
      transform: rotate(180deg);
    }

    &:hover:not(:disabled) {
      transform: translateX(-0.25rem);
    }
  }
  .slider__button-right {
    &:hover:not(:disabled) {
      transform: translateX(0.25rem);
    }
  }
  .slider__progressbar {
    width: ${progressWidth};
    height: 2px;
    background-color: ${colors.muted};
    margin: 0 ${progressMargin};

    .slider__progressbar-state {
      transition: width 200ms ease;
      background-color: ${colors.primary};
      height: 100%;
    }
  }

  @media only screen and (max-width: ${breakpoints.mobile}) {
    .slider__progressbar {
      width: 100%;
    }
  }

  @media only screen and (max-width: ${breakpoints.mobileSmall}) {
    .slider__progressbar {
      margin: 0 2rem;
    }
  }
`;

const themes = {
  light: {
    arrow: {
      fill: '#000000',
    },
  },

  dark: {
    arrow: {
      fill: '#FFFFFF',
    },
  },
};

/**
 *
 * @param children Slides
 * @param theme {('light'|'dark')} theme Layout theme
 * @param startPosition First slide to show
 * @param customSettings Override settings
 * @returns {*}
 * @constructor
 */
const HorizontalSlider = ({ children, theme, startPosition, customSettings, className }) => {
  const slider = useRef();

  const [position, setPosition] = useState(startPosition);
  const [progress, setProgress] = useState('0%');
  const [isPrevDisabled, setIsPrevDisabled] = useState(false);
  const [isNextDisabled, setIsNextDisabled] = useState(false);

  const checkStatus = () => {
    let countChildren = React.Children.toArray(children).length;

    let slidesToShow = slider.current.props.slidesToShow;
    let slidesToScroll = slider.current.props.slidesToScroll;

    if (slider.current.state.breakpoint) {
      const nextScrollUpdateStatus = slider.current.props.responsive.find(
        (responsiveSettings) => responsiveSettings.breakpoint === slider.current.state.breakpoint,
      );
      if (nextScrollUpdateStatus.settings.slidesToShow) {
        slidesToShow = nextScrollUpdateStatus.settings.slidesToShow;
      }
      if (nextScrollUpdateStatus.settings.slidesToScroll) {
        slidesToScroll = nextScrollUpdateStatus.settings.slidesToScroll;
      }
    }

    const maxPage = (countChildren - slidesToShow) / slidesToScroll;
    const currentPage = position / slidesToScroll;
    // +1 to show progress for first page (currentPage starts from 0)
    setProgress(`${(((currentPage + 1) / (maxPage + 1)) * 100).toFixed(2)}%`);

    setIsPrevDisabled(Boolean(currentPage <= 0));
    setIsNextDisabled(Boolean(currentPage >= maxPage));
  };

  const beforeChangeHandler = (oldIndex, newIndex) => {
    setPosition(newIndex);
  };

  const nextSlide = () => {
    slider.current.slickNext();
  };

  const prevSlide = () => {
    slider.current.slickPrev();
  };

  const goToSlide = (index) => {
    slider.current.slickGoTo(index);
  };

  useEffect(() => {
    if (startPosition > 0) {
      goToSlide(startPosition);
    }
  }, [startPosition]);

  // prevent overriding event handlers
  if (customSettings.hasOwnProperty('beforeChange')) {
    delete customSettings.beforeChange;
  }
  if (customSettings.hasOwnProperty('onReInit')) {
    delete customSettings.onReInit;
  }

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: false,
    adaptiveHeight: false,
    touchThreshold: 5,
    beforeChange: beforeChangeHandler,
    onReInit: checkStatus,
    responsive: null,
    ...customSettings,
  };

  return (
    <SliderWrapper theme={themes.hasOwnProperty(theme) ? themes[theme] : 'light'} className={className}>
      <Slider ref={slider} {...settings}>
        {children}
      </Slider>

      {!(isPrevDisabled && isNextDisabled) && (
        <div className="slider__button-wrapper">
          <button className="slider__button-left" onClick={() => prevSlide()} disabled={isPrevDisabled}>
            <span>Previous slide</span>
            <IconArrowLong />
          </button>
          <div className="slider__progressbar">
            <div className="slider__progressbar-state" style={{ width: progress }} />
          </div>
          <button className="slider__button-right" onClick={() => nextSlide()} disabled={isNextDisabled}>
            <span>Next slide</span>
            <IconArrowLong />
          </button>
        </div>
      )}
    </SliderWrapper>
  );
};

HorizontalSlider.defaultProps = {
  customSettings: {},
  startPosition: 0,
  theme: 'light',
};

export default HorizontalSlider;

export const navigationWidth = `${buttonWidth} + ${progressMargin} + ${progressWidth} + ${progressMargin} + ${buttonWidth}`;
