import React from 'react';

const IconGooglePlay = () => {
  return (
    <svg width="17px" height="20px" viewBox="0 0 17 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fillRule="evenodd">
        <g transform="translate(-101.000000, -7599.000000)">
          <g transform="translate(56.000000, 160.000000)">
            <path d="M45,7458.928 L45,7459 L53.5,7454 L56.5956547,7452.185 L54.1323291,7449.705 L45,7458.928 Z M57.4515643,7446.334 L54.8020857,7449 L57.4515643,7451.667 L62,7449 L57.4515643,7446.334 Z M56.5670915,7445.816 L53.5,7444 L45,7439 L45,7439.072 L54.1323291,7448.296 L56.5670915,7445.816 Z M53.469467,7448.967 L45,7457.517 L45,7449 L45,7440.423 L53.469467,7448.967 Z" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconGooglePlay;
