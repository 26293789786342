import React from 'react';
import styled from 'styled-components';
import { NavLink, DisabledLink } from '../../../../../components/Link';
import paginationArrow from '../../../../../../static/images/icons/arrow-black.svg';
// page numbers are 1 indexed
// need to mind that first page is at /blog/, second at /blog/2

const StyledPagination = styled('section')`
  display: flex;
  justify-content: center;
  margin-bottom: 8.75rem;
  font-family: "BioSans-SemiBold", sans-serif;
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  ${NavLink},${DisabledLink},.pagination_dots {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4rem;
    width: 4rem;
    margin-left: 0.25rem;
    color: #727292;
    transition: background 0.2s ease-in-out, transform 0.5s ease-in-out;
  }

  ${NavLink} {
    &:hover {
      background-color: #f4f4fa;
    }
    &:first-child:hover {
      background-color: transparent;
      transform: translate(-10px);
    }
    &:last-child:hover {
      background-color: transparent;
      transform: translate(10px);
    }
  }

  /* navlink wraps everything in span */
  span {
    display: flex;
  }

  .link--active {
    color: #ffffff;
    background: linear-gradient(90deg, #865ee8 0%, #6536e2 100%);
  }

  .pagination_arrow-left {
    transform: rotate(180deg);
  }
`;

export const getPaginationNumber = ({ currentPageNumber, pagesCount }) => {
  if (pagesCount <= 2) {
    return [];
  }
  if (currentPageNumber === 1) {
    return [2];
  }
  if (currentPageNumber === 2) {
    return pagesCount > 3 ? [2, 3] : [2];
  }
  if (currentPageNumber === pagesCount) {
    return [pagesCount - 1];
  }
  if (currentPageNumber === pagesCount - 1) {
    return [currentPageNumber - 1, currentPageNumber];
  }
  return [currentPageNumber - 1, currentPageNumber, currentPageNumber + 1];
};

const PaginationLink = ({ index, slug }) => <NavLink to={`${slug}${index === 1 ? '' : `${index}/`}`}>{index}</NavLink>;

const Pagination = ({ currentPage, pagesCount, categorySlug }) => {
  // currentPage is used so it's indexed at 1, and not at 0
  const currentPageNumber = currentPage + 1;
  const allowedNumbers = getPaginationNumber({ currentPageNumber, pagesCount });
  const needStartingDots = currentPageNumber > 3 && pagesCount > 3;
  const needEndingDots = currentPageNumber < pagesCount - 2;
  const slug = categorySlug ? `/blog/${categorySlug}/` : '/blog/';

  return (
    <StyledPagination>
      <NavLink to={`${slug}${currentPageNumber === 2 ? '' : `${currentPageNumber - 1}/`}`} disabled={currentPageNumber === 1}>
        <img src={paginationArrow} alt="previous page" className="pagination_arrow-left" />
      </NavLink>

      <PaginationLink index={1} slug={slug} />
      {needStartingDots && <span className="pagination_dots">...</span>}

      {allowedNumbers.map((pageNumber) => (
        <PaginationLink index={pageNumber} slug={slug} key={pageNumber} />
      ))}

      {needEndingDots && <span className="pagination_dots">...</span>}
      {pagesCount > 1 && <PaginationLink index={pagesCount} slug={slug} />}

      <NavLink to={`${slug}${`${currentPageNumber + 1}`}/`} disabled={currentPageNumber === pagesCount}>
        <img src={paginationArrow} alt="next page" />
      </NavLink>
    </StyledPagination>
  );
};

export default Pagination;
