import React from 'react';
import styled from 'styled-components';

import { breakpoints } from '../../../../constants/style';
import SanityBlockContent from '../../SanityBlockContent';
import SectionHeader from '../../SectionHeader/SectionHeader';
import bulletListPlaybook from '../../Styled/bulletListPlaybook';
import paragraphAgile from '../../Styled/paragraphPlaybook';

const ParagraphAgileSection = styled('section')`
  overflow: hidden;
  background-color: #fff;
  ${({ isIntersected }) => (isIntersected ? 'padding-bottom: 3.5rem !important;' : '')}
  ${({ isAfterIntersection }) => (isAfterIntersection ? 'padding-top: 3.5rem !important;' : '')}
  .section-header {
    margin-bottom: 3.5rem;
  }
  .paragraph-agile__content {
    ${paragraphAgile}
    ${bulletListPlaybook}

    .process-items-container {
      p {
        margin-bottom: 0;
        font-size: 1rem;
        line-height: 1.75rem;
      }
    }

    .ordered-list {
      .item-list {
        margin-bottom: 1rem;

        &:last-child {
          margin-bottom: 1.5rem;
        }
      }
    }
  }

  figure {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
    margin-left: -5.875rem;
    margin-right: -5.875rem;
  }

  .paragraph-agile {
    &__container {
      max-width: 46rem;
      margin: 0 auto;
    }
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    .section-header {
      margin-bottom: 2.5rem;
    }
  }
`;

const ParagraphAgile = ({ orderNumber, header, subtitle, content, isIntersected = false, isAfterIntersection = false }) => {
  const setSectionId = () => {
    if (orderNumber && !isAfterIntersection) {
      return `agile-${orderNumber}`;
    } else return '';
  };

  const sectionId = setSectionId();
  return (
    <ParagraphAgileSection
      className="paragraph-agile"
      name={sectionId}
      id={sectionId}
      isIntersected={isIntersected}
      isAfterIntersection={isAfterIntersection}
    >
      <div className="paragraph-agile__container">
        {!isAfterIntersection && (
          <SectionHeader orderNumber={orderNumber ? orderNumber : false} title={header} subtitle={subtitle} headerPosition="left" />
        )}
        <SanityBlockContent className="paragraph-agile__content" blocks={content} />
      </div>
    </ParagraphAgileSection>
  );
};

export default ParagraphAgile;
