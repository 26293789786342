import React from 'react';

import styled from 'styled-components';

import SectionHeader from '../../SectionHeader/SectionHeader';
import { breakpoints } from '../../../../constants/style';
import Image from '../../Image';

const WhatYouWillLearnSection = styled('section')`
  background-color: #fff;
  .whatYouWillLearn {
    &__wrapper {
      max-width: 64.75rem;
      margin: 0 auto;
    }
    &__list {
      display: grid;
      padding: 0;
      gap: 2rem;
      row-gap: 1rem;
      grid-template-columns: repeat(2, 1fr);
      justify-content: center;
    }
    &__item {
      display: flex;
      align-items: center;
      padding: 3rem;
      font-family: Roboto-Regular, sans-serif;
      font-size: 1rem;
      line-height: 1.75;
    }
    &__img {
      width: 6.5rem;
      margin-right: 3rem;
    }
    &__title {
      margin-bottom: 1rem;
      font-weight: 600;
    }
    &__description {
      color: #4e4d6c;
    }
    @media only screen and (max-width: ${breakpoints.desktopSmall}) {
      &__wrapper {
        max-width: 34.5rem;
      }
      &__list {
        grid-template-columns: 1fr;
      }
      &__item {
        align-items: flex-start;
      }
    }

    @media only screen and (max-width: ${breakpoints.mobileLarge}) {
      &__img {
        margin-bottom: 3rem;
        margin-right: 0;
      }
      &__item {
        flex-direction: column;
        padding-right: 0.5rem;
      }
    }
  }
`;

const WhatYouWillLearn = ({ header, items }) => {
  return (
    <WhatYouWillLearnSection className="whatYouWillLearn">
      <SectionHeader title={header} />
      <div className="whatYouWillLearn__wrapper">
        <ul className="whatYouWillLearn__list">
          {items.map(({ header, description, icon }, index) => {
            return (
              <li key={`what-you-will-learn-${index}`} className="whatYouWillLearn__item">
                <Image image={icon} className="whatYouWillLearn__img" />
                <div className="whatYouWillLearn__content">
                  <div className="whatYouWillLearn__title">{header}</div>
                  <div className="whatYouWillLearn__description">{description}</div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </WhatYouWillLearnSection>
  );
};

export default WhatYouWillLearn;
