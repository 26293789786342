import styled from 'styled-components';
import { breakpoints } from '../../../constants/style';

const StyledVideoWrapper = styled.div`
  .close {
    top: 60px;
    right: 40px;
    background: #302152;
    position: absolute;
    width: 35px;
    height: 35px;
    cursor: pointer;

    &::after,
    &::before {
      content: '';
      position: absolute;
      height: 1.7px;
      width: 100%;
      top: 50%;
      left: 0;
      margin-top: -1px;
      background: #a7a7a7;
      transition: background 0.2s ease;
    }
    &::after {
      transform: rotate(45deg);
    }
    &::before {
      transform: rotate(-45deg);
    }
  }
`;

export const StyledVideoPlayer = styled.video`
  object-fit: fill;
  min-width: 100%;
  position: relative;

  @media only screen and (max-width: ${breakpoints.desktop}) {
    max-width: 100%;
  }
`;

export default StyledVideoWrapper;
