import React from 'react';

import SanityBlockContent from '../../SanityBlockContent';
import Image from '../../Image';
import { NavLinkButton } from '../../Link';

import WhatAreYouLookingForSection from './styled';

const WhatAreYouLookingFor = ({ header, leftEntry, rightEntry, raw }) => {
  return (
    <WhatAreYouLookingForSection>
      <h2 className="header">{header}</h2>

      <div className="content">
        <div className={`box ${Boolean(raw.leftEntry.longDescription) ? 'show-all' : ''}`}>
          <div className="box__image">
            <Image image={leftEntry.image} />
          </div>

          <div className="box__short-description">
            <h3 className="box__header">{leftEntry.header}</h3>
            <p>{leftEntry.shortDescription}</p>
            <div className="box__more">
              <img src="/images/icons/arrow-more-right.svg" alt="more" />
            </div>
            <div className="box__long-description">
              {raw.leftEntry.longDescription && <SanityBlockContent blocks={raw.leftEntry.longDescription} />}
              {Boolean(leftEntry.button && leftEntry.button.label) && (
                <div>
                  <NavLinkButton className="box__button" to={leftEntry.button.link} variant="filled">
                    {leftEntry.button.label}
                  </NavLinkButton>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className={`box ${Boolean(raw.rightEntry.longDescription || rightEntry.cards.length) ? 'show-all' : ''}`}>
          <div className="box__image">
            <Image image={rightEntry.image} />
          </div>

          <div className="box__short-description">
            <h3 className="box__header">{rightEntry.header}</h3>
            <p>{rightEntry.shortDescription}</p>
            <div className="box__more">
              <img src="/images/icons/arrow-more-right.svg" alt="more" />
            </div>
            <div className="box__long-description">
              {raw.rightEntry.longDescription && <SanityBlockContent blocks={raw.rightEntry.longDescription} />}
              {Boolean(rightEntry.button && rightEntry.button.label) && (
                <div>
                  <NavLinkButton className="box__button" to={rightEntry.button.link} variant="filled">
                    {rightEntry.button.label}
                  </NavLinkButton>
                </div>
              )}
              <div className="box__cards">
                {rightEntry.cards.map((item, index) => {
                  return (
                    <div key={index} className="box__card">
                      <div className="box__card__image">
                        <Image image={item.image} />
                      </div>
                      <div className="box__card__index">{(index + 1).toString().padStart(2, '0')}</div>
                      <div className="box__card__text">
                        <p>{item.shortDescription}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </WhatAreYouLookingForSection>
  );
};

export default WhatAreYouLookingFor;
