import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import { getGatsbyImageData } from 'gatsby-source-sanity'
import getYouTubeId from 'get-youtube-id'

import { is10cDomain } from '../../utils/link'
import EmphasisWithTitle from '../EmphasisWithTitle'
import IconTextGrid from '../IconTextGrid'
import InlineList from '../InlineList'
import OrderedList from '../OrderedList'
import Process from '../Process'
import QuoteWithAuthor from '../quoteWithAuthor'
import { StyledUnorderedList } from './components/list'
import Quote from './components/quote'
import { serializeEnhancedImage } from './sanityBlockContentUtils'
import StyledBlockContent from './styled'
import getImageFromRefInArray from './utils/getImageFromRefInArray'

export const sanityConfig = {
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: process.env.GATSBY_SANITY_DATASET,
}

// https://christianlobaugh.com/blog/2020/01/adding-code-blocks-to-sanity-io-studio-to-improve-blog/
export const blockContentSerializers = {
  marks: {
    strong: ({ mark, children }) => (mark ? <strong>{children}</strong> : children),
    color: (props) => <span style={{ color: props.mark.hex }}>{props.children}</span>,
    link: (props) => {
      const { href: urlLink, isTargetBlank } = props.mark
      const linkTitle = props.children
      const isAnchorLinkToSection =
        !is10cDomain(props.mark.href) && props.mark.href?.startsWith?.('#')
      return isAnchorLinkToSection ? (
        <a
          onClick={() => document.querySelector(urlLink).scrollIntoView({ behavior: 'smooth' })}
          style={{ cursor: 'pointer' }}
        >
          {linkTitle}
        </a>
      ) : (
        <a
          href={urlLink}
          target={`${isTargetBlank ? '_blank' : ''}`}
          rel={`${is10cDomain(urlLink) ? '' : 'noopener noreferrer'}`}
        >
          {linkTitle}
        </a>
      )
    },
  },

  types: {
    youtube: ({ node: { url } }) => {
      const id = getYouTubeId(url)
      const embededURL = `https://www.youtube.com/embed/${id}`
      return (
        <iframe
          style={{ aspectRatio: '16/9' }}
          width="100%"
          loading="lazy"
          src={embededURL}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      )
    },
    code: (props) => {
      // we cannot alias prismjs language so it has to be done here
      const language = props.node.language === 'sh' ? 'bash' : props.node.language
      const code = props.node.code

      return (
        <div className="code-section-wrapper">
          <div className="code-header">
            <span>{language}</span>
          </div>
          <pre
            className="line-numbers code-section"
            // we are removing default text from copy button and changing it to icons in styles
            data-prismjs-copy=""
            data-prismjs-copy-success=""
            data-prismjs-copy-error=""
            data-prismjs-copy-timeout="3000"
          >
            <code className={`language-${language}`}>{code}</code>
          </pre>
        </div>
      )
    },
    enhancedImage: (props) => serializeEnhancedImage(props),
    clickableEnhancedImage: (props) => {
      const figure = serializeEnhancedImage(props)
      if (!figure) {
        return null
      } else if (!props.node.url) {
        return figure
      } else {
        return (
          <a
            href={props.node.url}
            target="_blank"
            rel={`${is10cDomain(props.node.url) ? '' : 'noopener noreferrer'}`}
          >
            {figure}
          </a>
        )
      }
    },
    quote: (props) => {
      const {
        node: { quote, wrappedText },
      } = props
      return <Quote quote={quote} wrappedText={wrappedText} />
    },
    quoteWithAuthor: (props) => {
      const {
        node: { quote, author },
      } = props
      const name = author?.name ? author.name : null
      const position = author?.position ? author.position : null
      const imageRef = author?.image?.asset?._ref ? author.image.asset._ref : null
      const link = author?.link ? author.link : null
      const gatsbyImageData = getGatsbyImageData(imageRef, { maxWidth: 120 }, sanityConfig)
      const image = gatsbyImageData ? { asset: { gatsbyImageData } } : null
      return (
        <QuoteWithAuthor
          quote={quote}
          authorName={name}
          authorPosition={position}
          image={image}
          links={{ link }}
        />
      )
    },
    emphasisWithTitle: (props) => {
      const {
        node: { title, text },
      } = props
      return <EmphasisWithTitle title={title} text={text} />
    },
    orderedList: (props) => {
      const {
        node: { itemsList },
      } = props
      return <OrderedList itemsList={itemsList} />
    },
    inlineList: (props) => {
      const {
        node: { title, itemsList },
      } = props
      return <InlineList title={title} items={itemsList} />
    },
    processList: (props) => {
      const {
        node: { processList },
      } = props
      const processListWithIcons = getImageFromRefInArray(processList, 120, sanityConfig)
      return (
        <Process
          items={processListWithIcons}
          raw={{ items: processListWithIcons }}
          isInsideBlockContent={true}
        />
      )
    },
    iconTextGrid: (props) => {
      const {
        node: { description, iconTextList },
      } = props
      const iconTextListWithIcons = getImageFromRefInArray(iconTextList, 88, sanityConfig)
      return <IconTextGrid description={description} items={iconTextListWithIcons} />
    },
    // it uses the same serializeEnhancedImage for DRY and it covert sanity reference to gatsby image sourceset
    image: (props) => serializeEnhancedImage(props),
    suggestedArticleBlock: () => {
      // temporary turned off blog post recommendation
      return null
      // return <SuggestedArticle />;
    },
  },
  list: (props) => {
    // todo: code added as hotfix -> handle correctly ordered lists
    return <StyledUnorderedList className="p2">{props.children}</StyledUnorderedList>
  },
}

const SanityBlockContent = ({
  blocks,
  className = '',
  renderContainerOnSingleChild = true,
  serializers = null,
  imageOptions = null,
  children = null,
}) => {
  const rawSerializers = {
    ...blockContentSerializers,
    ...serializers,
  }

  return blocks || children ? (
    <StyledBlockContent className="block-content">
      <BlockContent
        className={className}
        blocks={blocks || children}
        renderContainerOnSingleChild={renderContainerOnSingleChild}
        serializers={rawSerializers}
        imageOptions={imageOptions}
        projectId={process.env.GATSBY_SANITY_PROJECT_ID}
        dataset={process.env.GATSBY_SANITY_DATASET}
      />
    </StyledBlockContent>
  ) : null
}

SanityBlockContent.defaultProps = {
  className: '',
  blocks: null,
  renderContainerOnSingleChild: true,
  serializers: {},
  imageOptions: {},
  children: [],
}

export default SanityBlockContent
