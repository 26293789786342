import React, { useState } from 'react'
import styled from 'styled-components'

import md5 from 'md5'

import { useDictionaryQuery } from 'hooks/graphql/page'
import SanityBlockContent from '../../../../../components/SanityBlockContent'

import { breakpoints, colors, targetIE } from '../../../../../../constants/style'
import {
  horizontalLayoutPadding,
  horizontalLayoutPaddingMobile,
  horizontalLayoutPaddingTablet,
} from '../../commonLayoutStyle'

import CloseSVG from '../../../../../../static/images/icons/ico-close-light.svg'

const StyledTopMessageBar = styled('div')`
  width: 100%;
  padding: 0.5rem ${horizontalLayoutPadding};
  font-size: 0.875rem;
  line-height: normal;
  text-align: center;
  color: #ffffff;
  background-color: ${colors.primary};
  max-height: 100%;
  overflow: hidden;
  transition: padding 0.25s ease-in-out, max-height 0.25s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-size: 0.875rem;
    line-height: normal;
  }

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .message__content {
    margin: 0 auto;

    p {
      margin-bottom: 0;
    }

    a {
      color: white;
    }
  }

  .message__close {
    padding-left: 1rem;
    font-size: 0;
    cursor: pointer;
    opacity: 0.8;
    transition: opacity 0.25s ease-in-out;

    img {
      width: 16px;
      height: 16px;
    }

    &:hover {
      opacity: 1;
    }
  }

  &.hide {
    padding: 0 1rem;
    max-height: 0;
  }

  // hack for IE11 not respecting box-sizing for flex-basis size
  @media only screen and ${targetIE} {
    .message__content {
      margin: 0;
    }
    .message__close {
      margin-left: 0;
    }
  }

  @media only screen and (max-width: ${breakpoints.desktop}) {
    padding: 0.5rem ${horizontalLayoutPaddingTablet};
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    padding: 0.5rem ${horizontalLayoutPaddingMobile};

    .message__close {
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
`

const localStorageKey = '10c-top-message-bar'

export const TopMessageBar = () => {
  const { _rawTopMessageBarText } = useDictionaryQuery()
  const hash = md5(JSON.stringify(_rawTopMessageBarText))
  const isClient = typeof window === 'object'
  const [show] = useState(
    isClient && localStorage.getItem(localStorageKey) !== hash && Boolean(_rawTopMessageBarText),
  )
  const [animateHide, setAnimateHide] = useState(
    !isClient || localStorage.getItem(localStorageKey) === hash || !Boolean(_rawTopMessageBarText),
  )

  const onClickHandler = () => {
    if (isClient) {
      localStorage.setItem(localStorageKey, hash)
      setAnimateHide(true)
    }
  }

  return show ? (
    <StyledTopMessageBar className={animateHide ? 'hide' : ''}>
      <SanityBlockContent className="message__content" blocks={_rawTopMessageBarText} />
      <div className="message__close" onClick={onClickHandler}>
        <img src={CloseSVG} alt="close message" />
      </div>
    </StyledTopMessageBar>
  ) : null
}
