import { FC } from 'react'

import { NavLinkButton } from 'components/Link'
import { NavItem } from 'hooks/graphql/others'

import styles from './MenuButton.module.scss'

interface MenuButtonProps {
  item: NavItem
}

export const MenuButton: FC<MenuButtonProps> = props => {
  const { item } = props

  return (
    <NavLinkButton to={item.internalUrl || item.externalUrl} className={styles.menuButton} variant="bordered">{item.label}</NavLinkButton>
  )
}
