import React from 'react'

import styled, { css } from 'styled-components'

import { colors, breakpoints } from '../../../../constants/style'
import WaveSVG from '../../../../static/images/wave-light-3.svg'
import logoBlack from '../../../../static/images/10c-logo/10C-Logo-Black.svg'
import logoWhite from '../../../../static/images/10c-logo/10C-Logo-White.svg'

import {
  horizontalLayoutPadding,
  verticalLayoutPadding,
  horizontalPaddingDesktop,
  horizontalLayoutPaddingTablet,
} from '../../../features/layout/components/commonLayoutStyle'
import orderNumberBordered from '../../Styled/orderNumberBordered'
import HeaderWihLogo from './components/HeaderWithLogo'

const themes = {
  light: {
    backgroundColor: colors.backgroundLight,
    highlightedTitle: colors.primary,
    title: colors.titleDark,
    paragraph: '#4E4D6C',
    logo: logoBlack,
    showBlur: false,
  },
  dark: {
    backgroundColor: colors.titleDark,
    highlightedTitle: '#1BFDBF',
    title: '#fff',
    paragraph: '#C3C2D4',
    logo: logoWhite,
    showBlur: true,
  },
}

const stickyMarginBottom = '42rem'
const marginLeftDesktopMax = '7vw'

const blur = css`
  &::before {
    content: '';
    position: absolute;
    left: 50%;
    width: min(100%, 536px);
    height: 536px;
    background-color: rgba(123, 81, 230, 0.2);
    filter: blur(150px);
    transform: translate(-50%, -50%);
    pointer-events: none;
  }

  &--sticky::before {
    top: 13%;
    left: 120%;
  }
`

const ProcessListSection = styled('section').attrs({ className: 'processList' })`
  position: relative;
  background-color: ${({ theme }) => theme.backgroundColor};
  min-height: 62rem;

  .container {
    max-width: 73rem;
    position: relative;
    margin: 0 auto 0 ${marginLeftDesktopMax};
  }

  .processList {
    &__sticky {
      position: sticky;
      top: 12rem;
      margin-bottom: ${stickyMarginBottom};
      z-index: 10;
    }

    &__img-wrap {
      position: absolute;
      left: -${horizontalLayoutPadding};
      top: -${verticalLayoutPadding};
      width: 100vw;
      overflow: hidden;
    }

    &__waves {
      min-width: 1440px;
    }

    &__header {
      display: none;
      flex-direction: column;
      width: 22.9375rem;
      ${({ theme }) => theme.showBlur && blur};
      z-index: 100;

      &--sticky {
        display: flex;
        position: absolute;
        left: ${marginLeftDesktopMax};
        align-items: flex-end;
        text-align: right;
      }
    }

    &__logo {
      width: 7.09rem;
    }

    &__title {
      font-size: 2.25rem;
      line-height: 3rem;
      margin-top: 1rem;
      color: ${({ theme }) => theme.title};
    }

    &__wrapper {
      max-width: 39.625rem;
      margin: -${stickyMarginBottom} 0 0 auto;
    }

    &__item {
      &:not(:last-child) {
        margin-bottom: 4rem;
      }
    }

    &__item-header {
      margin-bottom: 1.5rem;
    }

    &__number {
      ${orderNumberBordered}
      color: ${({ theme }) => theme.backgroundColor};
      text-shadow: -1px 0 ${({ theme }) => theme.highlightedTitle},
        0 1px ${({ theme }) => theme.highlightedTitle},
        1px 0 ${({ theme }) => theme.highlightedTitle},
        0 -1px ${({ theme }) => theme.highlightedTitle};
      margin-bottom: 2rem;
    }

    &__item-title {
      font-size: 2.625rem;
      letter-spacing: 0.01rem;
      color: ${({ theme }) => theme.title};
      font-family: "BioSans-Regular", sans-serif;
      &--highlighted {
        font-size: 2.25rem;
        color: ${({ theme }) => theme.highlightedTitle};
        font-family: "BioSans-SemiBold", sans-serif;

        & + h3 {
          font-size: 2.25rem;
        }
      }
    }

    &__item-description {
      color: ${({ theme }) => theme.paragraph};
      font-size: 1rem;
      line-height: 1.75rem;
      font-family: "Roboto-Regular", sans-serif;
    }
  }

  @media only screen and (max-width: ${breakpoints.desktopLargest}) {
    .container {
      width: 58rem;
      margin: 0 auto;
    }

    .processList {
      &__header {
        width: 16rem;
        left: 1vw;
      }
      &__wrapper {
        width: 34rem;
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.desktop}) {
    .container {
      width: 72.5vw;
    }

    .processList {
      &__sticky {
        top: 7rem;
      }
      &__img-wrap {
        left: -${horizontalPaddingDesktop};
      }

      &__header {
        left: 5vw;
      }
      &__wrapper {
        width: 58.6%;
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.desktopSmall}) {
    .container {
      width: 100%;
    }

    .processList {
      &__header {
        width: 10.5rem;
        left: 0;
      }
      &__title {
        font-size: 1.75rem;
        line-height: 2.5rem;
      }
      &__wrapper {
        width: 61%;
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    overflow: hidden;
    .processList {
      &__sticky {
        position: absolute;
      }

      &__img-wrap {
        top: -15rem;
        left: -25rem;
        width: unset;
      }

      &__header {
        display: flex;
        width: 100%;
        margin-bottom: 3.5rem;

        &--sticky {
          display: none;
        }
      }

      &__title {
        font-size: 2rem;
        line-height: 2.75rem;
      }

      &__wrapper {
        width: 100%;
        margin: 0;
      }
    }
  }
`

const ProcessList = ({ header, theme, items, shouldDisplayNumbers }) => {
  const currentTheme = themes.hasOwnProperty(theme) ? themes[theme] : themes['light']

  return (
    <ProcessListSection theme={currentTheme}>
      <div className='processList__sticky'>
        <div className='processList__img-wrap'>
          {/*it is necessary to wrap img because of position:sticky and overflow:hidden affect each other*/}
          <img className='processList__waves' src={WaveSVG} alt='' />
        </div>
        <HeaderWihLogo
          imgSrc={currentTheme.logo}
          alt='10C Logo'
          header={header}
          className='processList__header processList__header--sticky'
        />
      </div>
      <div className='container'>
        <div className='processList__wrapper'>
          <HeaderWihLogo
            imgSrc={currentTheme.logo}
            alt='10C Logo'
            header={header}
            className='processList__header processList__header'
          />
          {items.map(({ header, headerHighlighted, description }, index) => (
            <div className='processList__item' key={`process-${index}`}>
              <div className='processList__item-header'>
                {shouldDisplayNumbers && (
                  <div className='processList__number'>{String(index + 1).padStart(2, '0')}</div>
                )}
                {headerHighlighted && (
                  <h3 className='processList__item-title processList__item-title--highlighted'>
                    {headerHighlighted}
                  </h3>
                )}
                {header && <h3 className='processList__item-title'>{header}</h3>}
              </div>
              <div className='processList__item-description'>{description}</div>
            </div>
          ))}
        </div>
      </div>
    </ProcessListSection>
  )
}

export default ProcessList
