import { NavLinkButtonWithArrow } from 'components/Link'
import SectionHeader from 'components/SectionHeader/SectionHeader'
import { RedirectInfoLink } from 'components/RedirectInfoLink'
import React from 'react'
import styled from 'styled-components'
import { breakpoints, colors } from '../../../../constants/style'

const RedirectSectionWrapper = styled.div`
  background-color: ${colors.backgroundLight};
  .section-header__title {
    max-width: 638px;
  }
  .section-header__subtitle {
    max-width: 638px;
  }
  .redirect-link {
    width: fit-content;
    @media only screen and (max-width: ${breakpoints.mobile}) {
      width: 100%;
    }
  }
`

const RedirectContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;

  @media only screen and (max-width: ${breakpoints.desktopSmall}) {
    grid-template-columns: 1fr;
    row-gap: 80px;
  }
`

const RedirectInfoLinkContainer = styled.div`
  display: grid;
  grid-auto-rows: 1fr;
  row-gap: 2rem;
`

const RedirectSection = ({
  sectionTitle,
  sectionSubtitle,
  redirectList,
  redirectButtonLabel,
  redirectButtonUrl,
}) => {
  return (
    <RedirectSectionWrapper>
      <section className="section">
        <RedirectContainer>
          <div>
            <SectionHeader title={sectionTitle} subtitle={sectionSubtitle} headerPosition="left" />
            {redirectButtonLabel && redirectButtonUrl && (
              <NavLinkButtonWithArrow
                to={redirectButtonUrl}
                variant="filled"
                className="redirect-link"
              >
                {redirectButtonLabel}
              </NavLinkButtonWithArrow>
            )}
          </div>
          <RedirectInfoLinkContainer>
            {redirectList &&
              redirectList.map((item, idx) => (
                <RedirectInfoLink
                  key={idx}
                  title={item.title}
                  description={item.description}
                  linkUrl={item.url}
                />
              ))}
          </RedirectInfoLinkContainer>
        </RedirectContainer>
      </section>
    </RedirectSectionWrapper>
  )
}

export default RedirectSection
