import { css } from 'styled-components';
import { colors, breakpoints } from '../../../constants/style';

const emphasis = css`
  position: relative;
  font-family: "BioSans-Regular", "Roboto-Regular", sans-serif;
  font-size: 1.75rem;
  line-height: 2.5rem;
  letter-spacing: 0.01rem;
  color: ${colors.titleDark};
  padding: 1rem 3rem 1rem 3.25rem;
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;

  &:last-child {
    margin-bottom: 0;
  }

  &::before {
    content: '';
    left: 0;
    top: 0;
    display: block;
    position: absolute;
    height: 100%;
    width: 4px;
    background-color: ${colors.primary};
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    padding-right: 0;
  }
`;

export default emphasis;
