import { useState } from 'react';
import Validator from '../../utils/validator';

const defaultFormData = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  projectDescription: '',
  policyAgreement: false,
};

const validationRules = {
  firstName: 'required|string',
  lastName: 'required|string',
  email: 'required|email',
  policyAgreement: 'accepted',
};

const getValidationRules = ({ showProjectDescriptionInput }) => {
  return {
    ...validationRules,
    ...(showProjectDescriptionInput && {
      projectDescription: 'required|string',
    }),

  };
};

export const useHeroContactForm = ({ showProjectDescriptionInput }) => {
  const [formData, setFormData] = useState(defaultFormData);
  const [errorMessages, setErrorMessages] = useState(defaultFormData);
  const [isFormSent, setIsFormSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const validation = new Validator(formData, getValidationRules({ showProjectDescriptionInput }), { accepted: 'You need to accept Privacy Policy' });

  const handleInputChange = (e) => {
    const newFormData = { ...formData };
    const { type, value, checked, name } = e.target;
    newFormData[name] = type === 'checkbox' ? checked : value;
    setFormData(newFormData);
  };

  return {
    formData,
    errorMessages,
    setErrorMessages,
    isFormSent,
    setIsFormSent,
    isLoading,
    setIsLoading,
    validation,
    handleInputChange,
  };
};
