export const extractSalaryFromBenefits = (benefitsHtml) => {
  if (!benefitsHtml) {
    return null
  }

  const b2bContract = 'B2B contract'
  const b2bProject = 'B2B, project-based contract'
  const permamentContract = 'PLN gross'
  const freelancerContract = '+ VAT (on a full-time freelancer contract)'

  const textWithoutHtml = benefitsHtml.replace(/<\/?[^>]+(>|$)|&nbsp;/g, '').replace(/&nbsp;/g, ' ')

  const isB2BContract = textWithoutHtml.includes(b2bContract)
  const isPermamentContract = textWithoutHtml.includes(permamentContract)
  const isB2BProject = textWithoutHtml.includes(b2bProject)

  const getContractType = isB2BContract
    ? b2bContract
    : isPermamentContract
    ? permamentContract
    : isB2BProject
    ? b2bProject
    : freelancerContract

  const skills = 'depending on your skills and experience'
  const engagement = 'for half-time engagement'

  const isSkills = textWithoutHtml.includes(skills)

  const getDependingOn = isSkills ? skills : engagement

  const salary = textWithoutHtml
    ?.split(getDependingOn)[1]
    ?.split(getContractType)[0]
    .replace(/,/, '')
    .trim()

  return salary ? `${salary} ${getContractType}` : null
}
