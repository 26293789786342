import { graphql, useStaticQuery } from 'gatsby'

export const useSuggestedCaseStudiesQuery = () => {
  const data = useStaticQuery(graphql`
    query CaseStudySuggestionsQuery($id: String) {
      moreCaseStudiesList: allSanityCaseStudy(
        filter: { id: { ne: $id } }
        limit: 6
        sort: { _createdAt: DESC }
      ) {
        ...AllCaseStudy
      }
    }
  `)
  return data.moreCaseStudiesList.nodes
}
