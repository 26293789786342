import React from 'react';
import styled from 'styled-components';

import SanityBlockContent from '../../SanityBlockContent';
import Image from '../../Image';
import { NavLinkButton } from '../../Link';

import { breakpoints, targetIE } from '../../../../constants/style';

const StyledExampleSection = styled('section')`
  padding: 11.9375rem 0 0 !important;
  margin-bottom: 6.25rem;
  display: flex;

  a {
    display: inline-block;
    margin-top: 2.8125rem;
  }

  .image {
    max-width: 47.25rem;
    margin-left: 8.5625rem;

    > img,
    .gatsby-image-wrapper {
      width: 100%;
    }
  }

  .example__content {
    max-width: 39.9375rem;
  }

  .example__header {
    padding-top: 3.875rem;
    margin-bottom: 1.875rem;
    font-family: BioSans-SemiBold, sans-serif;
    font-size: 1.375rem;
    color: #fff;
  }

  .example__description {
    p {
      line-height: 2.11;
      color: #9393a7;

      strong {
        color: #fff;
      }

      & + p {
        padding-top: 1.125rem;
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.desktopLargest}) {
    display: flex;
    align-items: center;
    padding-top: 5rem !important;
  }

  @media only screen and (max-width: ${breakpoints.desktopSmall}) {
    flex-direction: column;
    padding-top: 0 !important;

    .image {
      margin-top: 3rem;
      margin-left: 0;
    }
  }

  @media only screen and (max-width: ${breakpoints.desktopSmall}) and ${targetIE} {
    display: block;
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    margin-bottom: 0;

    .image {
      max-width: 90%;
    }
  }
`;

const ExampleSection = ({ example, image }) => {
  const { header, description, button } = example;

  return (
    <StyledExampleSection>
      <div className="example__content">
        {header && <h2 className="example__header">{header}</h2>}
        {description && <SanityBlockContent className="example__description" blocks={description} />}
        {button && (
          <NavLinkButton to={button.link} variant="bordered">
            {button.label}
          </NavLinkButton>
        )}
      </div>
      {image && (
        <div className="image">
          <Image image={image} />
        </div>
      )}
    </StyledExampleSection>
  );
};

export default ExampleSection;
