import React from 'react';

const IconArrowBack = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
    <path stroke="currentColor" d="M1.95 12.0016H21.75" strokeWidth="1.8" strokeLinejoin="round" />
    <path fill="none" stroke="currentColor" d="M6.44922 16.5L1.94922 12L6.44922 7.5" strokeWidth="1.8" strokeLinejoin="round" />
  </svg>
);

export default IconArrowBack;
