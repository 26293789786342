import React from 'react'

const IconStar = ({ fill, width = '32', height = '30', half = false, className }) => {
  if (half) {
    return (
      <svg
        width={width}
        height={height}
        viewBox={`0 0 32 30`}
        fill="none"
        className={className}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.59654 29.6107L16.0005 24.3467L25.4045 29.6107L23.3045 19.0401L31.2179 11.7227L20.5152 10.4534L16.0005 0.666748L11.4859 10.4534L0.783203 11.7227L8.69654 19.0401L6.59654 29.6107Z"
          fill={fill || 'currentColor'}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 24.3468L25.4036 29.6105L23.3036 19.0399L31.2169 11.7225L20.5142 10.4532L16 0.66748V24.3468Z"
          fill="#EDECF8"
        />
      </svg>
    )
  }

  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox={`0 0 32 30`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.59654 29.6107L16.0005 24.3467L25.4045 29.6107L23.3045 19.0401L31.2179 11.7227L20.5152 10.4534L16.0005 0.666748L11.4859 10.4534L0.783203 11.7227L8.69654 19.0401L6.59654 29.6107Z"
        fill={fill || 'currentColor'}
      />
    </svg>
  )
}

export default IconStar
