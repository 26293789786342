import React from 'react';

const IconApple = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="36" viewBox="0 0 27 36">
      <g transform="translate(-33 -28)">
        <g fillRule="nonzero">
          <path
            d="M21.822 17.16c0-4.03 3.386-5.98 3.516-6.11-1.954-2.73-4.819-3.12-5.86-3.25-2.475-.26-4.95 1.43-6.122 1.43-1.302 0-3.255-1.43-5.34-1.43-2.734 0-5.208 1.56-6.64 4.03-2.866 4.94-.782 12.22 1.953 16.25 1.432 1.95 2.995 4.16 5.079 4.03 2.083-.13 2.865-1.3 5.34-1.3 2.474 0 3.125 1.3 5.339 1.3 2.214 0 3.646-1.95 4.949-3.9 1.562-2.21 2.213-4.42 2.213-4.55-.13-.13-4.427-1.69-4.427-6.5zM17.784 5.2c1.042-1.43 1.824-3.25 1.693-5.2-1.562.13-3.646 1.04-4.688 2.47-1.042 1.17-1.953 3.12-1.693 4.94 1.693.13 3.516-.91 4.688-2.21z"
            transform="translate(33 28)"
          />
        </g>
      </g>
    </svg>
  );
};

export default IconApple;
