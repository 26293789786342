import React from 'react';
import styled from 'styled-components';

import emphasis from '../Styled/emphasis';
import { colors } from '../../../constants/style';
import SanityBlockContent from '../SanityBlockContent';

const StyledEmphasisWithTitle = styled('div')`
  ${emphasis}
  line-height: 2.25rem;
  .emphasis__title {
    font-size: 1.5rem;
    text-transform: uppercase;
    color: ${colors.primary};
    margin-bottom: 1.5rem;
  }

  //need selector with two classes to overwrite styles
  .block-content .emphasis__text {
    p {
      font-family: BioSans-Regular;
      font-size: 1.3125rem;
      line-height: 2.25rem;
      color: ${colors.titleDark};
      letter-spacing: 0;
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0rem;
      }
    }
  }
`;

const EmphasisWithTitle = ({ title, text }) => {
  return (
    <StyledEmphasisWithTitle className="emphasis__wrapper">
      <div className="emphasis__title">{title}</div>
      <SanityBlockContent className="emphasis__text" blocks={text} />
    </StyledEmphasisWithTitle>
  );
};

export default EmphasisWithTitle;
