import styled from 'styled-components'
import { fullBulletListStyle } from '../../../../../constants/style'

export const StyledUnorderedList = styled('ul')`
  li {
    ${fullBulletListStyle};
    color: var(--t1-body);
    padding-left: var(--gap-4);
    margin-left: 0;

    &:before {
      top: 0.7em;
      left: 0;
    }
  }
`
