import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import Fade from 'react-reveal/Fade';

import EmployeeEntry from '../../EmployeeEntry';
import { breakpoints, colors } from '../../../../constants/style';
import SectionHeader from '../../SectionHeader/SectionHeader';
import SanityBlockContent from '@sanity/block-content-to-react';
import Image from '../../Image';

const contentFlexColumn = css`
  .guests-content {
    flex-direction: column;
  }

  .guests-list {
    width: 100%;
    margin-bottom: 5rem;
  }

  .right-column {
    width: 100%;
    margin-left: 0;

    .right-column-content {
      div:first-child {
        display: block;
        width: max-content;
        margin: 0 auto;
      }

      max-width: 35rem;
      margin: 0 auto;
    }
  }
`;

const MainGuestSection = styled('section')`
  position: relative;
  background-color: ${colors.titleDark};
  overflow: hidden;

  ::after {
    position: absolute;
    right: 12%;
    top: 4rem;
    content: '';
    width: 34rem;
    height: 34rem;
    background: #7b51e6;
    opacity: 0.2;
    filter: blur(200px);
  }

  .guests-content {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .guests-list {
    width: ${({ numberOfGuests }) => {
      if (numberOfGuests === 1) {
        return '33%';
      } else if (numberOfGuests === 2) {
        return '50%';
      } else {
        return '75%';
      }
    }};
    position: relative;
    z-index: 1;
    display: grid;
    grid-template-columns: ${({ numberOfGuests }) => {
      return `repeat(${numberOfGuests}, minmax(13rem, 23rem))`;
    }};
    justify-content: center;
    gap: 2rem;
  }

  .right-column {
    width: ${({ numberOfGuests }) => {
      if (numberOfGuests === 1) {
        return 'calc(66% - 2rem)';
      } else if (numberOfGuests === 2) {
        return 'calc(50% - 2rem)';
      } else {
        return 'calc(25% - 2rem)';
      }
    }};
    margin-left: 2rem;

    .right-column-content {
      max-width: 40rem;
      margin: 0 auto;
      margin: ${({ numberOfGuests }) => (numberOfGuests === 1 ? '0 auto 0 12%' : 'margin: 0 auto')};

      img {
        height: 3rem;
        margin: 0 0 3.5rem;
      }

      p {
        color: ${colors.grayLight};
        font-size: 1.1875rem;
        line-height: 2rem;
      }
    }
  }

  .react-reveal {
    width: 100%;
  }

  @media only screen and (max-width: ${breakpoints.desktopLarge}) {
    ${({ numberOfGuests }) => {
      if (numberOfGuests === 3) {
        return `
        ${contentFlexColumn}
        `;
      } else {
        return `
        .right-column {
          .right-column-content {
            margin: 0 0 0 auto;
          }
        }`;
      }
    }}
  }

  @media only screen and (max-width: ${breakpoints.desktop}) {
    ::after {
      right: 3%;
    }

    ${contentFlexColumn}
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    ::after {
      right: -10%;
    }

    ${({ numberOfGuests }) => {
      if (numberOfGuests === 3) {
        return `
            .guests-list {
              grid-template-columns: repeat(1, minmax(13rem, 23rem));
            }
          `;
      }
    }}
  }

  ${({ numberOfGuests }) => {
    if (numberOfGuests === 2) {
      return `@media only screen and (max-width: 500px) {
          .guests-list {
            grid-template-columns: repeat(1, minmax(13rem, 23rem));
          }
        }`;
    }
  }}
`;

const MainGuest = ({ header, subtitle, guests, logo, text }) => {
  if (guests.length > 3) {
    guests = guests.slice(0, 3);
  }

  const [animatedElementIndex, setAnimatedElementIndex] = useState(0);
  const [numberOfGuests] = useState(guests.length);

  return (
    <MainGuestSection numberOfGuests={numberOfGuests}>
      <SectionHeader theme="darkGreen" title={header} subtitle={subtitle} />
      <div className="guests-content">
        <div className="guests-list">
          {guests.map((item, index) => (
            <Fade
              bottom
              distance="100px"
              appear
              onReveal={() => {
                setAnimatedElementIndex(animatedElementIndex + 1);
              }}
              duration={500}
              wait={400}
              delay={index * 400}
              key={`designer-${index}`}
            >
              <EmployeeEntry
                key={`designer-${index}`}
                image={item.image}
                name={item.name}
                position={item.position}
                employeeLinks={item.employeeLinks}
                description={item.description}
                darkTheme={true}
                mainGuest={true}
              />
            </Fade>
          ))}
        </div>
        <div className="right-column">
          <div className="right-column-content">
            <Image image={logo} />
            <SanityBlockContent blocks={text} />
          </div>
        </div>
      </div>
    </MainGuestSection>
  );
};

export default MainGuest;
