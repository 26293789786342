/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import './src/styles'

// polyfills for ie11 in development
require('intersection-observer')
require('whatwg-fetch')
