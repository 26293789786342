import React from 'react'

import Layout from '../features/layout'

import BlockSection from '../components/sections/BlockSection'
import { SEO } from 'components/Seo'

const parpAsRawSection = [
  {
    _key: '7a95ae865e2a',
    _type: 'image',
    asset: {
      _ref: 'image-c4190e002b31f318199dcb4c4c9c7c132ebf4f0c-908x144-png',
      _type: 'reference',
    },
  },
  {
    _key: '22b7d69f2bfd',
    _type: 'block',
    children: [
      {
        _key: '22b7d69f2bfd0',
        _type: 'span',
        marks: [],
        text: ' ',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: 'aea29d46a42d',
    _type: 'block',
    children: [
      {
        _key: 'aea29d46a42d0',
        _type: 'span',
        marks: [],
        text: 'PARP Co-financing',
      },
    ],
    markDefs: [],
    style: 'h1',
  },
  {
    _key: '56cac89f883c',
    _type: 'block',
    children: [
      {
        _key: '56cac89f883c0',
        _type: 'span',
        marks: [],
        text: '',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '2a42b451debb',
    _type: 'block',
    children: [
      {
        _key: '2a42b451debb0',
        _type: 'span',
        marks: [],
        text: '',
      },
      {
        _key: '2a42b451debb1',
        _type: 'span',
        marks: ['1e2c4cf87049'],
        text: 'EN',
      },
      {
        _key: '2a42b451debb2',
        _type: 'span',
        marks: [],
        text: '',
      },
    ],
    markDefs: [
      {
        _key: '1e2c4cf87049',
        _type: 'color',
      },
    ],
    style: 'normal',
  },
  {
    _key: '765aa68d62bb',
    _type: 'block',
    children: [
      {
        _key: '765aa68d62bb0',
        _type: 'span',
        marks: [],
        text: ' ',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '87bfacda7676',
    _type: 'block',
    children: [
      {
        _key: '87bfacda76760',
        _type: 'span',
        marks: [],
        text: 'The project is co-financed by the European Union within the Smart Development Operation Program 2014-2020',
      },
    ],
    markDefs: [],
    style: 'h2',
  },
  {
    _key: '4bb2158eb142',
    _type: 'block',
    children: [
      {
        _key: '4bb2158eb1420',
        _type: 'span',
        marks: [],
        text: '',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '5c7204944f4b',
    _type: 'block',
    children: [
      {
        _key: '5c7204944f4b0',
        _type: 'span',
        marks: [],
        text: 'The subject of the project is to participate in the promotional activities foreseen in the IT/ICT business promotion programme.',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: 'ce6e9feba6a6',
    _type: 'block',
    children: [
      {
        _key: 'ce6e9feba6a60',
        _type: 'span',
        marks: [],
        text: '',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: 'ee05c3baf49a',
    _type: 'block',
    children: [
      {
        _key: 'ee05c3baf49a0',
        _type: 'span',
        marks: [],
        text: 'Project goals:',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '14cec83686e5',
    _type: 'block',
    children: [
      {
        _key: '14cec83686e50',
        _type: 'span',
        marks: [],
        text: 'increase the value of sold services',
      },
    ],
    level: 1,
    listItem: 'bullet',
    markDefs: [],
    style: 'normal',
  },
  {
    _key: 'fa51dabc0cad',
    _type: 'block',
    children: [
      {
        _key: 'fa51dabc0cad0',
        _type: 'span',
        marks: [],
        text: 'promote the 10Clouds brand',
      },
    ],
    level: 1,
    listItem: 'bullet',
    markDefs: [],
    style: 'normal',
  },
  {
    _key: 'c94780ba726d',
    _type: 'block',
    children: [
      {
        _key: 'c94780ba726d0',
        _type: 'span',
        marks: [],
        text: 'promote the Polish Economy brand',
      },
    ],
    level: 1,
    listItem: 'bullet',
    markDefs: [],
    style: 'normal',
  },
  {
    _key: 'a74e5bce7767',
    _type: 'block',
    children: [
      {
        _key: 'a74e5bce77670',
        _type: 'span',
        marks: [],
        text: 'increase the sales of the timeION web app in the international market.',
      },
    ],
    level: 1,
    listItem: 'bullet',
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '3d134efb7057',
    _type: 'block',
    children: [
      {
        _key: '3d134efb70570',
        _type: 'span',
        marks: [],
        text: '',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: 'cfb8ebbd79fe',
    _type: 'block',
    children: [
      {
        _key: 'cfb8ebbd79fe0',
        _type: 'span',
        marks: [],
        text: 'Until the end of 2020, it is estimated that 112 foreign commerce contracts will be signed and that the export sales of the products covered by the project as well as the total export sales will increase by 46 million PLN.',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: 'ccfd4f5a5aeb',
    _type: 'block',
    children: [
      {
        _key: 'ccfd4f5a5aeb0',
        _type: 'span',
        marks: [],
        text: '',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: 'd0fd505485c0',
    _type: 'block',
    children: [
      {
        _key: 'd0fd505485c00',
        _type: 'span',
        marks: [],
        text: 'Total project value: 1,129,257,00 PLN\nFinancing from the European Funds: 749,325,00 PLN',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: 'ef2c6dbec063',
    _type: 'block',
    children: [
      {
        _key: 'ef2c6dbec0630',
        _type: 'span',
        marks: [],
        text: '',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '1d8ae61a69cd',
    _type: 'block',
    children: [
      {
        _key: '1d8ae61a69cd0',
        _type: 'span',
        marks: [],
        text: 'PL',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '7fbca89573bf',
    _type: 'block',
    children: [
      {
        _key: '7fbca89573bf0',
        _type: 'span',
        marks: [],
        text: ' ',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: 'f9bc3cb5210d',
    _type: 'block',
    children: [
      {
        _key: 'f9bc3cb5210d0',
        _type: 'span',
        marks: [],
        text: 'Projekt współfinansowany przez Unię Europejską w ramach Programu Operacyjnego Inteligentny Rozwój 2014-2020',
      },
    ],
    markDefs: [],
    style: 'h2',
  },
  {
    _key: '4d9a5a6085b1',
    _type: 'block',
    children: [
      {
        _key: '4d9a5a6085b10',
        _type: 'span',
        marks: [],
        text: '',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: 'f812bf1b823b',
    _type: 'block',
    children: [
      {
        _key: 'f812bf1b823b0',
        _type: 'span',
        marks: [],
        text: 'Przedmiot projektu: udział w działaniach promocyjnych przewidzianych w Programie promocji branży IT/ICT.',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '976b075c3ea3',
    _type: 'block',
    children: [
      {
        _key: '976b075c3ea30',
        _type: 'span',
        marks: [],
        text: '',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: 'd141ce98a192',
    _type: 'block',
    children: [
      {
        _key: 'd141ce98a1920',
        _type: 'span',
        marks: [],
        text: 'Cele projektu:',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '70fbd2ddfa7b',
    _type: 'block',
    children: [
      {
        _key: '70fbd2ddfa7b0',
        _type: 'span',
        marks: [],
        text: '',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '17f18bca7476',
    _type: 'block',
    children: [
      {
        _key: '17f18bca74760',
        _type: 'span',
        marks: [],
        text: 'wzrost wartości sprzedaży usług,',
      },
    ],
    level: 1,
    listItem: 'bullet',
    markDefs: [],
    style: 'normal',
  },
  {
    _key: 'a07461f50d7e',
    _type: 'block',
    children: [
      {
        _key: 'a07461f50d7e0',
        _type: 'span',
        marks: [],
        text: 'promocja marki 10Clouds,',
      },
    ],
    level: 1,
    listItem: 'bullet',
    markDefs: [],
    style: 'normal',
  },
  {
    _key: 'da7e1972f326',
    _type: 'block',
    children: [
      {
        _key: 'da7e1972f3260',
        _type: 'span',
        marks: [],
        text: 'promocja Marki Polskiej Gospodarki,',
      },
    ],
    level: 1,
    listItem: 'bullet',
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '9d9e93721eca',
    _type: 'block',
    children: [
      {
        _key: '9d9e93721eca0',
        _type: 'span',
        marks: [],
        text: 'sprzedaż aplikacji internetowej timeION na rynku międzynarodowym.',
      },
    ],
    level: 1,
    listItem: 'bullet',
    markDefs: [],
    style: 'normal',
  },
  {
    _key: 'e099338020e6',
    _type: 'block',
    children: [
      {
        _key: 'e099338020e60',
        _type: 'span',
        marks: [],
        text: '',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: 'f6112c5b21b4',
    _type: 'block',
    children: [
      {
        _key: 'f6112c5b21b40',
        _type: 'span',
        marks: [],
        text: 'Do końca 2020 r. ma zostać zawartych 112 zagranicznych kontraktów handlowych, a sprzedaż na eksport produktów będących przedmiotem projektu i całkowita sprzedaż eksportowa ma wzrosnąć o kwotę 46 mln zł.',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: '8cf08a6badf3',
    _type: 'block',
    children: [
      {
        _key: '8cf08a6badf30',
        _type: 'span',
        marks: [],
        text: '',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
  {
    _key: 'dd38e3232618',
    _type: 'block',
    children: [
      {
        _key: 'dd38e32326180',
        _type: 'span',
        marks: [],
        text: 'Całkowita wartość projektu: 1.129.257.00 zł\nWysokość dofinansowania z Funduszy Europejskich: 749.325.00 zł',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
]
const ParpPage = () => {
  return (
    <Layout shouldNavigationStartTransparent={false}>
      <BlockSection content={parpAsRawSection} isFirstSection={true} />
    </Layout>
  )
}

export function Head({ location }) {
  return (
    <SEO
      location={location}
      title="PARP Co-financing Process - All You Need To Know"
      description="PARP Co-financing Process - All You Need To Know"
    />
  )
}

export default ParpPage
