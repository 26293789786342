import React, { useState, useEffect } from 'react'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

import { HeroSection } from '../Hero'
import WaveSVG from '../../../../static/images/wave-1.svg'
import getYouTubeID from 'get-youtube-id'
import SignupForm from './components/SignupForm'
import { css } from 'styled-components'
import { breakpoints, colors } from '../../../../constants/style'
import { ButtonWithIconAndSubMenu } from '../../Buttons'
import calendarIcon from '../../../../static/images/icons/calendar.svg'
import logoGoogle from '../../../../static/images/logo/logo-google.svg'
import logoApple from '../../../../static/images/logo/logo-apple.svg'
import logoOffice from '../../../../static/images/logo/logo-office-365.svg'
import logoOutlook from '../../../../static/images/logo/logo-outlook.svg'
import { isSSR } from '../../../../constants/environment'

const customStylesHeroforEvent = css`
  div.column-left {
    flex-basis: 40%;
    margin-right: 10%;
    padding-bottom: 0;

    .hero-text-container {
      margin-bottom: 3rem;
    }

    .hero-preheader {
      font-size: 1.5rem;
      line-height: 2.25rem;
      letter-spacing: 0.16px;
      font-weight: 600;
      color: #1bfdbf;
      margin-bottom: 0.5rem;
    }

    .hero-title {
      margin-bottom: 1.75rem;
    }
  }

  .hero-postheader {
    font-family: "BioSans-Regular", sans-serif;
    font-size: 1.25rem;
    line-height: 2.25rem;
    color: ${colors.grayLight};
    margin-bottom: 1.75rem;
  }
  .hero-extratext {
    font-family: "BioSans-Regular", sans-serif;
    color: white;
    text-transform: uppercase;
    font-size: 1.5rem;
    line-height: 2.25rem;
    letter-spacing: 0;
  }

  .add-to-calendar-button {
    width: 19.75rem;
    margin-bottom: 6rem;

    span > img {
      padding-right: 2rem;
    }
  }

  .column-right {
    justify-content: flex-end;
    padding-bottom: 0;
  }

  @media only screen and (max-width: ${breakpoints.desktop}) {
    div.content {
      padding-top: 4rem;
      display: block;
    }

    div.column-left {
      margin-right: 0;
    }
    .column-right {
      justify-content: flex-start;
    }
  }

  @media only screen and (max-width: ${breakpoints.desktopSmall}) {
    div.column-left {
      max-width: 29rem;
    }
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    .column-right {
      padding-top: 0;
    }
  }

  @media only screen and (max-width: ${breakpoints.mobile}) {
    div.content {
      padding-top: 1rem;
    }
    .add-to-calendar-button {
      width: 15.5rem;

      span > img {
        padding-right: 0.5rem;
      }
    }
  }
`

const styleForImage = {
  backgroundSize: 'cover',
  backgroundPosition: 'center right',
}
const styleForWave = {
  backgroundSize: '100% auto',
  backgroundPosition: 'right bottom',
}

const FORM = 'form'
const ADDTOCALENDARBUTTON = 'addToCalendarButton'
const VIDEO = 'video'

const trackCustomEventData = {
  category: '10cvoices',
  action: 'addToCalendar',
  label: isSSR ? '10cvoices' : document.URL,
}

const HeroForEvent = ({
  backgroundImage,
  alt,
  preheader,
  header,
  postheader,
  extratext,
  eventDate = false,
  currentDate = new Date(),
  fullScreen = true,
  videoUrl = false,
  typeOfRegistration,
  addToGoogleCalendarUrl = false,
  addToAppleCalendarUrl = false,
  addToOffice365CalendarUrl = false,
  addToOutlookCalendarUrl = false,
}) => {
  const [additionalContent, setAdditionalContent] = useState(null)
  typeOfRegistration = typeOfRegistration === 'none' ? false : typeOfRegistration
  eventDate = eventDate ? new Date(eventDate) : eventDate
  const id = videoUrl ? getYouTubeID(videoUrl) : null
  const embededYoutubeURL = `https://www.youtube.com/embed/${id}`

  useEffect(() => {
    if (currentDate < eventDate) {
      if (typeOfRegistration === ADDTOCALENDARBUTTON) {
        setAdditionalContent(ADDTOCALENDARBUTTON)
      } else if (typeOfRegistration === FORM) {
        setAdditionalContent(FORM)
      }
    } else if (Boolean(videoUrl)) {
      setAdditionalContent(VIDEO)
    }
  }, [])

  const trackEvent = () => {
    trackCustomEvent(trackCustomEventData)
  }

  const AddToCalendarSubmenu = [
    {
      name: 'Google Calendar',
      iconSrc: logoGoogle,
      url: addToGoogleCalendarUrl,
      onClick: trackEvent,
    },
    {
      name: 'Apple Calendar',
      iconSrc: logoApple,
      url: addToAppleCalendarUrl,
      onClick: trackEvent,
    },
    {
      name: 'Office 365',
      iconSrc: logoOffice,
      url: addToOffice365CalendarUrl,
      onClick: trackEvent,
    },
    {
      name: 'Outlook',
      iconSrc: logoOutlook,
      url: addToOutlookCalendarUrl,
      onClick: trackEvent,
    },
  ].filter((item) => item.url)

  const backgroundStack = [
    ...(!backgroundImage ? [`url(${WaveSVG})`] : []),
    `linear-gradient(304.45deg, rgba(16, 7, 39, 0.0891644) 3.73%, rgba(16, 7, 39, 0.4) 67.42%), linear-gradient(292.47deg, rgba(16, 7, 39, 0.178329) 0%, rgba(16, 7, 39, 0.8) 57.27%)`,
    ...(backgroundImage ? [backgroundImage] : []),
  ]

  const imageProps = {
    Tag: 'section',
    fluid: backgroundStack,
    alt,
    style: backgroundImage ? styleForImage : styleForWave,
  }

  return (
    <HeroSection
      className="dark"
      {...imageProps}
      fullScreen={fullScreen}
      customStylesHeroforEvent={customStylesHeroforEvent}
    >
      <div className="content">
        <div className="column-left">
          <div className="hero-text-container">
            {preheader && <p className="hero-preheader">{preheader}</p>}
            <h1 className="hero-title">{header}</h1>
            {postheader && <p className="hero-postheader">{postheader}</p>}
            {extratext && <p className="hero-extratext">{extratext}</p>}
          </div>
          {additionalContent === ADDTOCALENDARBUTTON && (
            <ButtonWithIconAndSubMenu
              className="add-to-calendar-button"
              variant="filled"
              subMenuItems={AddToCalendarSubmenu}
              iconSrc={calendarIcon}
            >
              add to calendar
            </ButtonWithIconAndSubMenu>
          )}
        </div>

        {additionalContent === VIDEO || additionalContent === FORM ? (
          <div className="column-right">
            {additionalContent === VIDEO && (
              <iframe
                style={{ aspectRatio: '16/9' }}
                width="100%"
                loading="lazy"
                src={embededYoutubeURL}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            )}
            {additionalContent === FORM && (
              <SignupForm AddToCalendarSubmenu={AddToCalendarSubmenu} />
            )}
          </div>
        ) : null}
      </div>
    </HeroSection>
  )
}

export default HeroForEvent
