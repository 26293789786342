import { FC } from "react"
import { Link } from "gatsby"
import classNames from "classnames"

import Image from "components/Image"
import { RecommendationData } from "./types"
import styles from './RecommendationQuote.module.scss'
import sharedStyles from './Recommendation.module.scss'
import ArrowSVG from 'static/images/icons/arrow-more-right.svg'

interface RecommendationQuoteProps {
  data: RecommendationData
}

export const RecommendationQuote: FC<RecommendationQuoteProps> = ({ data }) => {
  const { recommendations, sectionTitle, shouldDisplayBrowseAllButton, browseAllButtonLabel, browseAllButtonUrl } = data

  const [recommendation] = recommendations

  return (
    <div className={classNames(sharedStyles.container, styles.container)}>
      <div className={sharedStyles.topText}>
        <p className={sharedStyles.sectionTitle}>{sectionTitle}</p>
        {shouldDisplayBrowseAllButton && (
          <Link to={browseAllButtonUrl!} className={sharedStyles.link}>
            {browseAllButtonLabel}
            <img src={ArrowSVG} alt=">" className={sharedStyles.arrowIcon} itemProp="image" aria-hidden="true" />
          </Link>
        )}
      </div>
      
      <div className={sharedStyles.quoteContainer}>
        <p className={sharedStyles.quote}>{recommendation.quote}</p>
        {recommendation.author && (
          <div className={styles.author}>
            <Image image={recommendation.author.image} alt={recommendation.author.name} className={styles.image} />
            <div>
              <p className={sharedStyles.authorName}>{recommendation.author.name}</p>
              <p className={sharedStyles.authorPosition}>{recommendation.author.position}</p>
            </div>
          </div>
        )}
      </div>

      {recommendation.link && recommendation.linkText && (
        <Link to={recommendation.link} className={sharedStyles.link}>
          {recommendation.linkText}
          <img src={ArrowSVG} alt=">" className={sharedStyles.arrowIcon} itemProp="image" aria-hidden="true" />
        </Link>
      )}
    </div>
  )
}