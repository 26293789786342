import React, { useState } from 'react';
import { breakpoints, colors } from '../../../../../../constants/style';
import { NavLink } from '../../../../../components/Link';
import styled from 'styled-components';

import chevronBig from '../../../../../../static/images/icons/chevron--big.svg';
import chevronBigActive from '../../../../../../static/images/icons/chevron--big--active.svg';

const StyledFooterService = styled.div`
  display: flex;
  flex-direction: column;

  .footer-service--header {
    display: flex;
    margin-bottom: 1.75rem;

    h3 {
      flex-grow: 1;
    }

    .chevron--button {
      display: none;
    }
  }

  .footer__link {
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.16px;
    color: ${colors.grayLight};
    margin-bottom: 1rem;
  }

  .footer__link-with-label {
    display: flex;

    .footer__link-label {
      background: ${colors.primary};
      display: inline-flex;
      font-size: 0.6rem;
      line-height: 0.6rem;
      color: #fff;
      margin-left: 0.2rem;
      padding: 0.125rem 0.2rem 0.25rem;
    }
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    .footer-service--header {
      margin-bottom: 0;

      .chevron--button {
        display: block;
      }
    }

    .footer__link {
      display: ${({ mobileSubmenuVisible }) => (mobileSubmenuVisible ? 'block' : 'none')};
      margin-bottom: 2rem;

      &:nth-child(2) {
        margin-top: 2rem;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

const MobileChevronWrapper = styled.div`
  height: 100%;
  width: fit-content;
  display: grid;
  place-items: center;
  background: transparent;
  border: none;
  flex-shrink: 0;
  padding-top: 0.25rem;

  img {
    transition: all 0.1s ease-in;
  }

  ${({ mobileSubmenuVisible }) =>
    mobileSubmenuVisible &&
    `
    img {
      transform: rotate(180deg);
      content: url(${chevronBigActive});
    }
  `}
`;

const FooterService = ({ section }) => {
  const [mobileSubmenuVisible, setMobileSubmenuVisible] = useState(false);

  return (
    <StyledFooterService mobileSubmenuVisible={mobileSubmenuVisible} key={`footer-service-${section.header.name}`}>
      <div className="footer-service--header">
        <h3>{section.header.url ? <a href={section.header.url}>{section.header.name}</a> : section.header.name}</h3>
        <MobileChevronWrapper
          onClick={() => setMobileSubmenuVisible(!mobileSubmenuVisible)}
          mobileSubmenuVisible={mobileSubmenuVisible}
          className="chevron--button"
        >
          <img src={chevronBig} alt="" className="chevron-big" />
        </MobileChevronWrapper>
      </div>
      {section.entries.map(({ url, name, label }) => (
        <NavLink to={url} className={`footer__link ${!!label && 'footer__link-with-label'}`} key={`footer-service-link-${name}`}>
          {name} <span className="footer__link-label">{label}</span>
        </NavLink>
      ))}
    </StyledFooterService>
  );
};

export default FooterService;
