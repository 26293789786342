import React from 'react';

const IconDownload = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="24" viewBox="0 0 22 24">
      <path
        fill="#FFF"
        fillRule="evenodd"
        d="M18.476 10.482c0-.393-.14-.73-.423-1.012-.282-.282-.62-.418-1.013-.407-.393-.01-.731.125-1.014.407l-3.597 3.61V1.42c0-.393-.14-.73-.423-1.012-.282-.282-.62-.418-1.013-.407-.394-.011-.731.125-1.014.407-.282.282-.418.62-.407 1.012v11.66L5.959 9.47c-.283-.282-.62-.418-1.014-.407-.393-.01-.73.125-1.013.407-.282.282-.418.62-.408 1.012-.01.393.126.73.408 1.013l6.047 6.041c.283.282.62.423 1.014.423.393 0 .73-.14 1.013-.423l6.047-6.041c.283-.282.423-.62.423-1.013zM21.5 22.565v-3.02c0-.393-.14-.73-.423-1.013-.282-.282-.62-.418-1.013-.407-.394-.01-.731.125-1.014.407-.282.282-.418.62-.407 1.012v1.602H3.358v-1.602c0-.392-.14-.73-.423-1.012-.282-.282-.62-.418-1.013-.407-.394-.01-.732.125-1.014.407-.282.282-.418.62-.407 1.012v3.021c-.011.393.125.73.407 1.013.282.282.62.422 1.014.422h18.142c.393 0 .73-.14 1.013-.422.282-.282.423-.62.423-1.013z"
      />
    </svg>
  );
};

export default IconDownload;
