import styled from 'styled-components';
import { Link } from 'gatsby';
import { sidebarBackground } from './constants';
import { breakpoints } from '../../../constants/style';

export const Heading = styled.div`
  background: ${sidebarBackground};
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 24px;
  padding-right: 24px;

  .heading__back-to-link {
    display: none;
  }

  @media (min-width: ${breakpoints.mobile}) {
    padding-left: 44px;
    padding-right: 44px;
  }

  @media (min-width: ${breakpoints.tablet}) and (max-width: calc(${breakpoints.desktopMac} - 1px)) {
    flex-direction: row-reverse;
    justify-content: space-between;
    padding-left: 32px;
    padding-right: 32px;
  }

  @media (min-width: ${breakpoints.tablet}) {
    .heading__back-to-link {
      display: flex;
    }
  }

  @media (min-width: ${breakpoints.desktopMac}) {
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    padding-left: 168px;
    padding-top: 16px;
    display: block;

    .heading__back-to-link {
      margin-top: 48px;
    }
  }

  @media (max-width: ${breakpoints.desktopLarge}) {
    padding-left: 80px;
  }

  @media (min-width: ${breakpoints.desktopMax}) {
    width: 424;
    padding-left: 0;
    padding-right: 0;
    margin-left: calc(35% - (424px + 80px));
  }

  @media (max-width: ${breakpoints.tablet}) {
    padding-left: 44px;
  }

  @media (max-width: ${breakpoints.mobile}) {
    padding-left: 24px;
  }
`;

export const Logo = styled(Link)`
  display: inline-block;
  padding: 20px 0;

  img {
    width: 160px;
    height: 25px;
    display: block;
  }

  &:focus-visible {
    outline: 4px solid #00ddaf;
    outline-offset: 4px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${breakpoints.desktopMac}) {
    flex-direction: row;
  }
`;

export const Sidebar = styled.div`
  @media (min-width: ${breakpoints.desktopMax}) {
    width: 35%;
  }
`;

export const Content = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  flex: auto;

  @media (min-width: ${breakpoints.mobile}) {
    padding-left: 44px;
    padding-right: 44px;
  }

  @media (min-width: ${breakpoints.tablet}) {
    padding: 0;
    margin: 0 auto;
  }

  @media (min-width: ${breakpoints.desktopLarge}) {
    padding-left: 104px;
    padding-right: 104px;
    margin: 0;
  }

  @media (min-width: ${breakpoints.desktopMac}) {
    padding-top: 96px;
    padding-left: 72px;
    padding-right: 72px;
    margin: 0;
  }

  @media (min-width: ${breakpoints.desktopLargest}) {
    padding-left: 168px;
    padding-right: 168px;
    margin: 0;
  }

  @media (min-width: ${breakpoints.desktopMax}) {
    max-width: 1248px;
    width: 100%;
  }
`;
