import React, { useState } from 'react';
import styled from 'styled-components';
import { colors, breakpoints, zIndexes } from '../../../../constants/style';

import TransitionGroup from 'react-transition-group/TransitionGroup';
import Fade from 'react-reveal/Fade';
import { horizontalPaddingDesktop } from '../../../features/layout/components/commonLayoutStyle';
import useWindowSize from '../../../hooks/useWindowSize';
import TechnologiesItem from './TechnologiesItem';
import SectionHeader from '../../SectionHeader/SectionHeader';

const backgroundColors = {
  light: '#fff',
  lightPurple: '#f8f8fc',
};

const TechnologiesSection = styled('section')`
  background: ${colors.titleDark};
  padding-bottom: 7.375rem !important;
  ${({ shouldOverlap }) => shouldOverlap && 'padding-top: 0 !important'};
  color: #fff;
  position: relative;
  &::before {
    content: '';
    height: 11rem;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: ${({ previousSectionBackgroundColor }) => previousSectionBackgroundColor};
    display: ${({ shouldOverlap }) => (shouldOverlap ? 'block' : 'none')};
  }

  .section-header {
    // Includes height of the before element
    ${({ shouldOverlap }) => shouldOverlap && 'margin-top: 18rem;'}
    max-width: 35%;
    margin-right: 8rem;
  }

  .container {
    display: flex;
    justify-content: space-between;
  }

  .column-right {
    max-width: 48.25rem;
    height: 100%;
    position: relative;
    gap: 2rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 50%;
    .technologies__wrapper {
      /* to prevent section height staggering during items fade in */
      display: block !important;
      flex-basis: 48%;
      position: relative;
      z-index: ${zIndexes.technologiesItem};
      max-width: 23.125rem;
    }
  }

  @media only screen and (max-width: ${breakpoints.desktopSmall}) {
    padding-top: ${horizontalPaddingDesktop} !important;
    &::before {
      display: none;
    }
    .container {
      flex-direction: column;
    }
    .column-right {
      width: 100%;
      max-width: 100%;
      gap: 1.5rem;
      .technologies__wrapper {
        max-width: 100%;
      }
    }
    .section-header {
      margin-top: 0;
      margin-right: auto;
      margin-left: auto;
      max-width: 34.5rem;
    }
  }

  @media only screen and (max-width: ${breakpoints.mobileLarge}) {
    .column-right {
      grid-template-columns: 1fr;
    }
  }
`;

const Technologies = ({ header, description, technologiesList, previousSectionBackgroundColor, additionalTechnologies }) => {
  const [animatedElementindex, setAnimatedElementIndex] = useState(0);
  const { isSmallerThanDesktopSmall } = useWindowSize();

  const technologiesTransformed = technologiesList?.length
    ? technologiesList.map(({ abstract: { name, description, image } }) => ({ name, description, image }))
    : [];
  const additionalTechnologiesArr = additionalTechnologies?.length ? additionalTechnologies : [];
  const technologiesJoined = [...technologiesTransformed, ...additionalTechnologiesArr];

  const shouldOverlap = technologiesJoined.length > 4;
  let row = 0;
  return (
    <TechnologiesSection
      shouldOverlap={shouldOverlap}
      previousSectionBackgroundColor={
        previousSectionBackgroundColor ? backgroundColors[previousSectionBackgroundColor] : backgroundColors['light']
      }
    >
      <div className="container">
        <SectionHeader title={header} subtitle={description} headerPosition="left" theme="darkGreen" />
        <TransitionGroup className="column-right">
          {technologiesJoined.map(({ name, description, image }, index) => {
            if (index > 0) {
              row += (index - 1) % 2;
            }
            return (
              <Fade
                top
                distance="100px"
                appear={!isSmallerThanDesktopSmall}
                onReveal={() => {
                  setAnimatedElementIndex(animatedElementindex + 1);
                }}
                duration={500}
                wait={400}
                delay={isSmallerThanDesktopSmall ? 0 : (index % 2) * 400}
                key={`technology-${name}`}
              >
                <div
                  className="technologies__wrapper"
                  // don't animate on tablet and mobile
                  style={{ display: !isSmallerThanDesktopSmall && index > animatedElementindex ? 'none' : 'block' }}
                >
                  <TechnologiesItem
                    name={name}
                    description={description}
                    image={image}
                    shouldOverlap={shouldOverlap}
                    index={index}
                    row={row}
                  />
                </div>
              </Fade>
            );
          })}
        </TransitionGroup>
      </div>
    </TechnologiesSection>
  );
};

export default Technologies;
