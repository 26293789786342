import styled from 'styled-components';

import { colors, breakpoints } from '../../../../../../constants/style';
import {
  horizontalLayoutPadding,
  horizontalLayoutPaddingTablet,
  horizontalPaddingDesktop,
} from '../../../../../features/layout/components/commonLayoutStyle';

const linksMarginTop = '2.5rem';
const gridGap = '2rem';

const CaseStudiesEntryWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  column-gap: ${gridGap};

  .casestudies__shadow {
    font-family: "BioSans-Bold", sans-serif;
    text-transform: uppercase;
    color: #f8f8fc;
    white-space: nowrap;
    font-size: 9.875rem;
    position: absolute;
    z-index: -1;
    bottom: 7rem;
    right: 0;
  }

  .casestudies__text-wrapper {
    position: relative;
    max-width: calc(50% - ${gridGap} / 2);
    flex: 0 0 calc(50% - ${gridGap} / 2);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 5rem calc((100% - 2 * ${horizontalLayoutPadding} - 11 * ${gridGap}) / 12 + ${gridGap}) 5rem ${horizontalLayoutPadding};
    /* to cut off text shadow protruding beyond wrapper area */
    overflow: hidden;
  }
  .casestudies__item-header {
    font-family: "BioSans-SemiBold", sans-serif;
    font-size: 2.625rem;
    font-weight: 400;
    line-height: 1.33;
    letter-spacing: 0.16px;
    margin-bottom: 2rem;
    z-index: 1;
  }

  .casestudies__item-tags {
    z-index: 1;
    margin-bottom: 6rem;
  }

  .casestudies__item-tag {
    font-size: 1rem;
    &--first {
      color: #7142eb;
      margin-bottom: 1.5rem;
      text-transform: uppercase;
      font-family: "BioSans-SemiBold", sans-serif;
      letter-spacing: 0.025rem;
    }
    &--labels {
      display: flex;
    }
    &--label {
      padding: 0.375rem 0.75rem;
      color: #4e4d6c;
      border: 2px solid #602be9;
      border-radius: 4px;
      height: 2.75rem;
      font-family: Roboto-Regular, sans-serif;
      display: flex;
      align-items: center;
      justify-content: center;
      &:not(:last-child) {
        margin-right: 0.75rem;
      }
    }
  }

  .casestudies__item-description {
    position: relative;
    z-index: 2;
    margin-bottom: 3rem;

    p {
      color: #4e4d6c;
      font-size: 1rem;
      line-height: 1.75rem;
    }

    b,
    strong {
      color: #565062;
    }
  }

  .casestudies__image-wrapper {
    flex-grow: 1;
    padding: 0;
    background: linear-gradient(
      140deg,
      ${(props) => (props.imageBackgroundColorStart ? props.imageBackgroundColorStart.hex : 'rgba(0, 0, 0, 0)')} 7%,
      ${(props) => (props.imageBackgroundColorEnd ? props.imageBackgroundColorEnd.hex : 'rgba(0, 0, 0, 0)')} 91%
    );

    .casestudies__image {
      aspect-ratio: 1.17;
      min-height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .casestudies__item-buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    z-index: 1;
    margin: auto 0 0;

    .link {
      position: relative;
      color: #100727;
      margin-top: ${linksMarginTop};
      display: block;

      &:not(:last-child) {
        margin-right: 1.875rem;
      }

      .link__icon {
        transition: background-color 0.25s ease-in-out;
        border: 1px solid ${colors.primary};
        svg {
          transition: fill 0.25s ease-in-out;
        }
      }

      &:hover {
        color: ${colors.titleDark};
        text-decoration: underline;

        .link__icon {
          background-color: ${colors.primary};

          svg {
            fill: #ffffff;
          }
        }
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.desktopLarge}) {
    .casestudies__text-wrapper {
      padding: 5rem calc((100% - 2 * ${horizontalPaddingDesktop} - 11 * ${gridGap}) / 12 + ${gridGap}) 5rem ${horizontalPaddingDesktop};
    }
  }

  @media only screen and (max-width: ${breakpoints.desktop}) {
    flex-direction: column;
    .casestudies__text-wrapper {
      max-width: 100%;
      margin-right: 0;
      padding: 5rem ${horizontalLayoutPaddingTablet} 4rem ${horizontalLayoutPaddingTablet};
    }

    .casestudies__image-wrapper {
      width: 100%;
      height: 100vw;
    }

    .casestudies__shadow {
      bottom: 0;
    }

    .casestudies__item-tags {
      margin-bottom: 5rem;
      position: relative;
    }

    .casestudies__item-buttons {
      .link {
        margin-top: 0;
      }
    }
  }
`;

export default CaseStudiesEntryWrapper;
