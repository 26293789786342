import React, { useState } from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'

import Fade from 'react-reveal/Fade'

import Layout from '../../features/layout'
import Hero from '../../components/sections/Hero'
import CaseStudiesItem from './components/CaseStudiesItem'

import { SEO } from 'components/Seo'
import { breakpoints } from '../../../constants/style'
import ContactUs from '../../components/sections/ContactUs'
import { convertToBackgroundImage } from '../../utils/image'
import 'hooks/graphql/page'
import 'hooks/graphql/others'

export const query = graphql`
  query caseStudiesListQuery($language: String!, $validCaseStudyIds: [String!]!) {
    ...sanityCaseStudyPageList

    allSanityCaseStudy(filter: { id: { in: $validCaseStudyIds } }, sort: { _createdAt: DESC }) {
      ...AllCaseStudy
    }
  }
`

const CaseStudiesSection = styled('section')`
  padding-top: 5.3125rem;
  padding-bottom: 7.1875rem;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;

  > * {
    flex: 0 0 31%;
    margin: 2rem 0;
    :nth-child(3n + 2) {
      margin: 2rem 3.5%;
    }
  }

  @media only screen and (max-width: ${breakpoints.desktopSmall}) {
    justify-content: space-between;
    > * {
      flex: 0 0 45%;
    }
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    display: block;
  }
`

const CaseStudiesListPage = (props) => {
  const {
    data: {
      allSanityCaseStudy: { nodes: caseStudiesList },
      sanityCaseStudyPageList: { hero, contactSection, featured },
    },
    pageContext: { totalCaseStudies },
  } = props

  const [animatedElementindex, setAnimatedElementIndex] = useState(0)

  const bgImage = convertToBackgroundImage(hero.mainImage && hero.mainImage.asset)

  const featuredSlugs = featured.map((el) => el && el.slug.current)

  const caseStudiesListSorted = [
    ...featured,
    ...caseStudiesList.filter(
      (cs) => !featuredSlugs.includes(cs.slug.current) && cs.hidden === null,
    ),
  ]

  return (
    <Layout>
      <Hero
        backgroundImage={bgImage && bgImage.fluid}
        preheader={hero.pretitle}
        header={hero.title}
        description={hero.description}
        contactButtonText={hero.contactButtonText}
      />
      <CaseStudiesSection>
        {caseStudiesListSorted.map((item, index) => {
          return (
            item && (
              <Fade
                className="case-study-item"
                distance="10px"
                bottom
                onReveal={() => {
                  setAnimatedElementIndex(animatedElementindex + 1)
                }}
                duration={300}
                wait={200}
                delay={(index % 3) * 200}
                key={`case-studies-list-${index}`}
              >
                <CaseStudiesItem
                  slug={item.slug ? item.slug.current : null}
                  tags={item.abstract ? item.abstract.tags : null}
                  projectName={item.abstract ? item.abstract.projectName : null}
                  description={item.abstract ? item.abstract.description : null}
                  image={item.abstract ? item.abstract.imageForCaseStudiesList : null}
                />
              </Fade>
            )
          )
        })}
      </CaseStudiesSection>
      {Boolean(contactSection && contactSection.buttonLabel) && (
        <ContactUs
          theme="lightToDark"
          header={contactSection.header}
          buttonLabel={contactSection.buttonLabel}
        />
      )}
    </Layout>
  )
}

export function Head({ location, data: { sanityCaseStudyPageList } }) {
  return <SEO location={location} {...sanityCaseStudyPageList.seo} />
}

export default CaseStudiesListPage
