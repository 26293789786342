import React, { useState, useEffect } from 'react';

import { useForm, FormProvider } from 'react-hook-form';
import debounce from 'lodash.debounce';

import Input from '../../../../NewForm/Input';
import Checkbox from '../../../../NewForm/Checkbox';
import IconLoading from '../../../../icons/IconLoading';
import Button from '../../../../Buttons';
import { Link } from '../../../../Link';
import { legal } from '../../../../../../constants/links';
import StyledCandidateReferral from './styled';
import ReferralCodeWindow from '../ReferralCodeWindow/ReferralCodeWindow';
import { fullNameValidation, emailValidation, validationDelay } from '../../../../NewForm/validators';
import TooltipWrapper from '../../../../Tooltip/TooltipWrapper';
import { breakpoints } from '../../../../../../constants/style';
import CheckboxLabel from '../../../../NewForm/Checkbox/CheckboxLabel';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import { isSSR } from '../../../../../../constants/environment';

const trackCustomEventData = {
  category: 'careers',
  action: 'Referral_program_button',
  label: isSSR ? 'careers' : document.URL,
};

const trackEvent = (data) => {
  trackCustomEvent(data);
};

const ReferralForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [referralCode, setReferralCode] = useState(null);
  const [isReferralCodeWindowVisible, setIsReferralCodeWindowVisible] = useState(false);
  const [isSendError, setIsSendError] = useState(false);

  const methods = useForm({ mode: 'onBlur' });
  const {
    handleSubmit,
    formState: { isValid },
    trigger,
    setValue,
    watch,
    reset,
  } = methods;

  watch();

  const trimValue = (event) => {
    setValue(event.target.name, event.target.value.trim());
  };

  const debounceTrigger = debounce((name) => trigger(name), validationDelay, { leading: false, trailing: true });

  useEffect(() => {
    const subscription = watch((_, { name }) => {
      debounceTrigger(name);
    });
    return () => {
      subscription.unsubscribe();
      debounceTrigger.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  useEffect(() => {
    if (referralCode) {
      setIsReferralCodeWindowVisible(true);
    }
  }, [referralCode]);

  const handleGenerateNewCode = () => {
    setIsReferralCodeWindowVisible(false);
    setReferralCode(null);
  };

  const submitFormData = async (formData) => {
    setIsLoading(true);
    const { firstName, lastName, email, phone = '123', category = 'null', privacyPolicyAccepted } = formData;
    const data = {
      firstName,
      lastName,
      email,
      phone,
      privacyPolicyAccepted: !!privacyPolicyAccepted,
      category,
    };

    try {
      const response = await fetch('/api/referral-code-form', {
        method: 'POST',
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const referralCode = await response.json();
        setReferralCode(referralCode);
        setIsLoading(false);
        trackEvent(trackCustomEventData);
        reset();
      } else {
        setIsLoading(false);
        setIsSendError(true);
      }
    } catch (err) {
      setIsSendError(true);
      setIsLoading(false);
      console.log(err);
    }
  };

  return (
    <StyledCandidateReferral isReferralCodeWindowVisible={isReferralCodeWindowVisible}>
      <div className="contact-us__column-right contact-us__column-right--referral">
        {isReferralCodeWindowVisible ? (
          <ReferralCodeWindow referralCode={referralCode} onClick={handleGenerateNewCode} />
        ) : (
          <FormProvider {...methods}>
            <form className="contact-us__form" onSubmit={handleSubmit(submitFormData)}>
              <div className="contact-us__checkboxes-row">
                <Input
                  label="Your first name"
                  placeholder="Enter first name"
                  inputName="firstName"
                  onChange={trimValue}
                  validationOptions={fullNameValidation}
                  required
                />
                <Input
                  label="Your last name"
                  placeholder="Enter last name"
                  inputName="lastName"
                  onChange={trimValue}
                  validationOptions={fullNameValidation}
                  required
                />
              </div>
              <Input
                label="Your email address"
                placeholder="Enter email"
                inputName="email"
                onChange={trimValue}
                validationOptions={emailValidation}
                required
              />
              <Checkbox
                inputName="programParticipationConsent"
                isTermsOfUse={true}
                makeValueBoolean
                showError={false}
                required
                label={
                  <CheckboxLabel inputName="programParticipationConsent">
                    I declare that I have read and accept the{' '}
                    <Link className="candidate__referral__link" to={legal[2].url}>
                      Candidate Referral Program Regulations
                    </Link>{' '}
                    and I agree to participate in that program.
                  </CheckboxLabel>
                }
              />
              <Checkbox
                inputName="privacyPolicyAccepted"
                isTermsOfUse={true}
                makeValueBoolean
                showError={false}
                required
                label={
                  <CheckboxLabel inputName="privacyPolicyAccepted">
                    By sending the form you agree to the processing by 10Clouds limited liability company your personal data is included in
                    the form. Your consent is freely given and may be withdrawn by you at any time. More info about processing personal data
                    by 10Clouds could be found in{' '}
                    <Link className="candidate__referral__link" to={legal[2].url}>
                      Candidate Referral Program Regulations
                    </Link>{' '}
                    or in{' '}
                    <Link className="candidate__referral__link" to={legal[1].url}>
                      Privacy Policy
                    </Link>
                    .
                  </CheckboxLabel>
                }
              />
              <div className="contact-us__button-container">
                {isLoading ? (
                  <div className="loading">
                    <IconLoading />
                  </div>
                ) : isSendError ? (
                  <div className="error">Sorry, but we had a problem saving your submission, please refresh the page and try again</div>
                ) : (
                  <TooltipWrapper
                    position="top"
                    content="All fields must be filled in unless there is an optional tag"
                    passStyle={`
                  width: 100%;
                  @media screen and (max-width: calc(${breakpoints.desktopMac} - 1px)) {
                    width: 100%;
                  }
                `}
                    isVisible={!isValid}
                  >
                    <Button className="contact-us__submit-button" variant="filled" type="submit" disabled={!isValid}>
                      GET YOUR CODE
                    </Button>
                  </TooltipWrapper>
                )}
              </div>
            </form>
          </FormProvider>
        )}
      </div>
    </StyledCandidateReferral>
  );
};

export default ReferralForm;
