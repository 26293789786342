import React from 'react';
import styled from 'styled-components';

import EmployeeEntrySmall from '../EmployeeEntrySmall';
import emphasis from '../Styled/emphasis';

const StyledBlockquote = styled('blockquote')`
  ${emphasis}

  .quote__author-entry {
    margin-top: 1.5rem;
  }
`;

const QuoteWithAuthor = ({ authorName, authorPosition, image, links, quote }) => {
  return (
    <StyledBlockquote>
      {quote}
      {authorName ? (
        <EmployeeEntrySmall className="quote__author-entry" name={authorName} position={authorPosition} image={image} links={links} />
      ) : null}
    </StyledBlockquote>
  );
};

export default QuoteWithAuthor;
