import React from 'react';
import styled from 'styled-components';

import { breakpoints, colors } from '../../../../constants/style';
import Image from '../../Image';

const TechnologyDetailWrapper = styled('div')`
  padding: 4rem;
  background: ${colors.backgroundMilky};
  height: 100%;

  .detail__header {
    display: flex;
    align-items: center;
    margin-bottom: 2.5rem;
  }

  .detail__icon {
    margin-right: 1.875rem;

    .gatsby-image-wrapper,
    > img {
      height: 4.375rem;
    }

    .gatsby-image-wrapper {
      img {
        object-fit: contain !important;
      }
    }
  }

  .detail__title {
    font-family: BioSans-SemiBold, sans-serif;
    font-size: 1.375rem;
    line-height: 1.45;
    color: #3d3b48;
  }

  .detail__description {
    color: ${colors.mutedLighter};
    font-size: 1.0625rem;
    line-height: 1.88;
  }

  @media only screen and (max-width: ${breakpoints.mobile}) {
    padding: 2rem;

    .detail__header {
      margin-bottom: 1rem;
    }

    .detail__icon {
      margin-right: 1rem;
    }
  }
`;

const TechnologyDetail = ({ className, icon, header, description }) => {
  return (
    <TechnologyDetailWrapper className={className}>
      <div className="detail__header">
        <div className="detail__icon">{icon && <Image image={icon} />}</div>
        <h3 className="detail__title">{header}</h3>
      </div>
      <p className="detail__description">{description}</p>
    </TechnologyDetailWrapper>
  );
};

export default TechnologyDetail;
