import React from 'react';

import EmployeesList from '../EmployeesList';

const PeopleList = ({ header, description, employees, people }) => {
  const peopleToShow = [...employees, ...people];
  return (
    <EmployeesList header={header} description={description} employees={peopleToShow} backgroundColor="#fff" showLinksAndDesc={true} />
  );
};

export default PeopleList;
