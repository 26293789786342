import React from 'react';
import propTypes from 'prop-types';
import Layout from '../layout';
import { Heading, Logo, ContentContainer, Content, Sidebar } from './styled';
import logo from '../../../static/images/10c-logo/10clouds-logo.svg';
import BackToLink from './components/BackToLink';

const HOME_PAGE_PATH = '/';

const ContactLayout = ({ children, sidebar }) => (
  <Layout showNavigation={false} showFooter={false}>
    <div>
      <Heading>
        <Logo to={HOME_PAGE_PATH}>
          <img src={logo} alt="10clouds logo" />
        </Logo>

        <BackToLink to={HOME_PAGE_PATH} className="heading__back-to-link">
          Back to Home Page
        </BackToLink>
      </Heading>
      <ContentContainer>
        <Sidebar>{sidebar}</Sidebar>
        <Content>{children}</Content>
      </ContentContainer>
    </div>
  </Layout>
);

ContactLayout.propTypes = {
  children: propTypes.node.isRequired,
  sidebar: propTypes.node.isRequired,
};

export default React.memo(ContactLayout);
