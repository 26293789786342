import React from 'react';
import styled from 'styled-components';

import { breakpoints, colors } from '../../../../constants/style';
import Button, { StyledButton } from '../../Buttons';
import { NavLinkButton } from '../../Link';

const StyledDownloadSection = styled('section')`
  position: relative;
  padding: 0!important;
  text-align: center;

  > div {
    position: relative;
    padding: 0 160px 80px 160px;
    background: ${(props) => props.theme.background};
  }

    h2 {
    font-family: BioSans-Light, sans-serif!important;
    font-size: 2.875rem;
    margin-bottom: 32px;
    color: ${(props) => props.theme.color};
  }

  p {
    color: ${(props) => props.theme.p.color};
    margin-bottom: 32px;
  }

  .link,
  ${StyledButton} {
    display: inline-block;
    padding: 16px 80px;
  }

  @media screen and (max-width: ${breakpoints.desktop}) {
    & > div {
      padding: 0 80px 80px 80px;
    }
  }

  @media screen and (max-width: ${breakpoints.mobile}) {
    & > div {
      padding: 0 40px 80px 40px;
      
      & > button:first-of-type {
        padding: 18px 60px;
      }
    }
    
    h2 {
      font-size: 1.5rem;
    }
    ${NavLinkButton} {
      padding: 16px 0;
      width: 100%;
    }
  }
`;

const themes = {
  light: {
    background: colors.backgroundLight,
    color: colors.titleDark,
    p: {
      color: colors.mutedDark,
    },
  },
};

const DownloadSection = ({ theme, header, buttonLabel, description, file}) => {
  return (
    <StyledDownloadSection
      theme={themes.hasOwnProperty(theme) ? themes[theme] : themes.light}
    >
      <div>
        {Boolean(header) && <h2>{header}</h2>}
        {Boolean(description) && <p>{description}</p>}
        <Button onClick={() => window.open(`${file.asset.url}?dl=`, '_blank')} variant="filled">
          {buttonLabel}
        </Button>
      </div>
    </StyledDownloadSection>
  );
};

export default DownloadSection;
