import React from 'react';
import propTypes from 'prop-types';
import AddressDescription from '../AddressDescription';

const FooterContact = ({ contact }) =>
  contact.map(({ title, email, phone, description }) => (
    <div key={`footer-contact-${title}`}>
      {title}: <AddressDescription email={email} phone={phone} description={description} />
    </div>
  ));

FooterContact.propTypes = {
  contact: propTypes.arrayOf(propTypes.object).isRequired,
};

export default FooterContact;
