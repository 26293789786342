import React from 'react';
import propTypes from 'prop-types';

import FooterService from './FooterService';

const FooterNav = ({ links }) =>
  Object.values(links).map((section, idx) => <FooterService key={`${section?.header?.url}_${idx}`} section={section} />);

FooterNav.propTypes = {
  links: propTypes.object.isRequired,
};

export default FooterNav;
