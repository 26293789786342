import React, { Suspense } from 'react';
import LoadingIndicator from '../../LoadingIndicator/LoadingIndicator';
import SanityBlockContent from '../../SanityBlockContent';
import { ContactUsSection } from '../ContactUs/styled';
import { themes } from '../ContactUs/styled';
import styled from 'styled-components';

const RichTextBoxSection = styled('div')`
  position: relative;
  z-index: 3;
  padding: 120px 0;

  p {
    font-size: 1.5rem;
    margin-bottom: 0;
    a {
      text-decoration: underline;
    }
  }
`;

/**
 * @param {('light'|'dark'|'lightToDark'|'darkToLight'|'purpleToLight')} theme Layout theme
 * @param header string Head line text
 * @param rawDescription array Rich text contents
 * @param middle boolean If it's section in the middle of the page
 */
const RichTextBox = ({ theme, header, middle = false, rawDescription }) => {
    return (
        <>
        <Suspense fallback={<LoadingIndicator />}>
          <ContactUsSection
            theme={themes.hasOwnProperty(theme) ? themes[theme] : middle ? themes['lightMiddle'] : themes['lightToDark']}
            middle={middle}
          >
            <RichTextBoxSection>
              {Boolean(header) && <h2>{header}</h2>}
              <SanityBlockContent blocks={rawDescription} />
            </RichTextBoxSection>
          </ContactUsSection>
        </Suspense>
      </>
    )
}

export default RichTextBox;
