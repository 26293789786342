import React from 'react';
import styled from 'styled-components';

import { navbarMinHeight } from '../../../../layout/components/commonLayoutStyle';
import backgroundImage from '../../../../../assets/images/bg@3x.webp';
import MainBlogPost from './mainBlogPost';
import HeroSecondaryArticle from './blogListHeroSecondaryArticle';
import { blogSpacedAccentSizes, blogHeroPadding } from '../../../commonStyle';
import { breakpoints } from '../../../../../../constants/style';
import bgTablet from '../../../../../assets/images/bg-tablet.webp';
import bgMobile from '../../../../../assets/images/bg-mobile.webp';
import circles from '../../../../../../static/images/circle-shape-1.svg';

const StyledBlogListHero = styled('section')`
  background-image: url(${backgroundImage});
  background-size: cover;
  margin-top: ${navbarMinHeight};
  display: flex;
  position: relative;
  overflow: hidden;
  padding: ${blogHeroPadding.desktop};

  .blog-list-hero {
    &__circles-top {
      position: absolute;
      width: 470px;
      height: 470px;
      top: -110px;
      left: -110px;
      transform: scale(1, -1) rotate(185deg);
    }

    &__left-column {
      width: 50%;
      min-height: 100%;
      z-index: 2;
    }

    &__right-column {
      width: 50%;
      padding-left: 3.125rem;
      padding-top: calc((${blogSpacedAccentSizes.font} * 1.4) + ${blogSpacedAccentSizes.margin});
      padding-bottom: 2.75rem;
      min-height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      hr {
        border: none;
        border-bottom: solid 1px #e2e5f1;
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.desktopSmall}) {
    display: block;
    padding: ${blogHeroPadding.tablet};
    background-image: url(${bgTablet});
    background-size: contain;
    background-repeat: repeat;

    .blog-list-hero {
      &__circles-top {
        transform: scale(1, -1) rotate(180deg);
      }

      &__left-column,
      &__right-column {
        width: 100%;
        padding: 0 0 3.125rem 0;
      }

      &__right-column {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    padding: ${blogHeroPadding.mobile};
    background-image: url(${bgMobile});

    .blog-list-hero {
      &__circles-top {
        width: 300px;
        height: 300px;
        top: -70px;
        left: -70px;
        transform: scale(1, -1) rotate(180deg);
      }
    }
  }
`;

const BlogListHero = ({ blogPosts, heroBlogPostLabel }) => {
  const [firstBlogPost, ...restBlogPosts] = blogPosts;

  return (
    <StyledBlogListHero>
      <img src={circles} alt="" className="blog-list-hero__circles-top" />
      <div className="blog-list-hero__left-column">
        <MainBlogPost blogPost={firstBlogPost} heroBlogPostLabel={heroBlogPostLabel} />
      </div>
      <div className="blog-list-hero__right-column">
        {restBlogPosts.map((blogPost, index) => (
          <React.Fragment key={index}>
            <HeroSecondaryArticle blogPost={blogPost} />
          </React.Fragment>
        ))}
      </div>
    </StyledBlogListHero>
  );
};

export default BlogListHero;
