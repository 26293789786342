import React from 'react'

import styled from 'styled-components'
import { breakpoints } from '../../../../constants/style'
import useWindowSize from '../../../hooks/useWindowSize'
import arrow from '../../../../static/images/icons/arrow-right.svg'

const WhiteBg = styled('div')`
  height: 11.375rem;
  width: 100%;
  position: relative;
  z-index: 4;
`

const Header = styled('h1')`
  font-weight: 400;
  font-size: 2.625rem;
  line-height: 3.5rem;
  letter-spacing: 0.01rem;
  font-family: "BioSans-Regular", sans-serif;
  color: #ffffff;
  width: 39.9375rem;
  @media only screen and (max-width: ${breakpoints.desktopLargest}) {
    width: 27.9375rem;
  }
  @media only screen and (max-width: ${breakpoints.desktop}) {
    text-align: center;
    width: 34.5625rem;
    margin: 0rem 7.96875rem;
  }
  @media only screen and (max-width: ${breakpoints.tablet}) {
    max-width: 90%;
  }
  @media only screen and (max-width: ${breakpoints.mobile}) {
    width: 100%;
    margin: 0;
    font-size: 2.25rem;
    line-height: 3.125rem;
  }
  @media only screen and (max-width: ${breakpoints.mobileSmall}) {
    max-width: 100%;
  }
`

const Blur = styled('div')`
  position: absolute;
  width: 13.125rem;
  height: 13.125rem;
  top: 3rem;
  background: #7b51e6;
  opacity: 0.3;
  filter: blur(10.5rem);
  z-index: 2;
  @media only screen and (max-width: ${breakpoints.desktop}) {
    left: 25rem;
  }
  @media only screen and (max-width: ${breakpoints.tablet}) {
    top: 0;
    left: 60%;
  }
  @media only screen and (max-width: ${breakpoints.mobileLarge}) {
    top: 40%;
  }
`

const Description = styled('p')`
  font-weight: 400;
  font-size: 1.3125rem;
  line-height: 2.25rem;
  color: #c3c2d4;
  margin-bottom: 2rem;
  width: 39.875rem;
  font-family: "BioSans-Regular", sans-serif;
  @media only screen and (max-width: ${breakpoints.desktop}) {
    width: 27.9375rem;

    text-align: center;
  }
  @media only screen and (max-width: ${breakpoints.tablet}) {
    max-width: 70%;
  }
  @media only screen and (max-width: ${breakpoints.mobile}) {
    font-size: 1.1875rem;
    line-height: 2.125rem;
    max-width: 100%;
    width: 21.875rem;
  }
`

const Button = styled('a')`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.25rem 2.5rem;
  gap: 0.5rem;
  height: 3rem;
  background: linear-gradient(90deg, #865ee8 0%, #6536e2 100%);
  .text {
    font-family: "BioSans-Regular", sans-serif;
    color: #ffffff;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
    letter-spacing: 0.025rem;
    white-space: nowrap;
  }
  .arrow {
    background-image: url(${arrow});
    width: 1.25rem;
    height: 1.25rem;
  }
  @media only screen and (max-width: ${breakpoints.tablet}) {
    width: 15.5rem;
  }
  @media only screen and (max-width: ${breakpoints.mobile}) {
    width: 100%;
    .arrow {
      position: absolute;
      right: 2%;
    }
  }
`

const ContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10.75rem;
  gap: 1rem;
  padding: 4rem 0rem;
  @media only screen and (min-width: ${breakpoints.desktopLargest}) {
    min-width: 31.25rem;
    margin-left: 5rem;
  }
  @media only screen and (max-width: ${breakpoints.desktop}) {
    margin-left: 0rem;
    align-items: center;
    max-width: 90%;
    padding: 4rem 0rem;
  }
`

const BannerContainer = styled('div')`
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: flex-end;
  isolation: isolate;
  width: 100%;
  background: linear-gradient(135deg, #100727 14.64%, #070413 85.36%);
  z-index: 3;
  .main-image {
    z-index: 3;
    max-width: 67.25rem;
    height: 34.25rem;
    position: absolute;
    right: 0%;
    bottom: 0%;
  }
  @media only screen and (max-width: ${breakpoints.desktopLargest}) {
    overflow: hidden;
    justify-content: center;
    align-items: center;
    gap: 1.9375rem;
    .main-image {
      position: relative;
      min-width: 45.125rem;
      height: 22.563rem;
    }
  }
  @media only screen and (max-width: ${breakpoints.desktop}) {
    gap: 0rem;
    min-height: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .main-image {
      min-width: 50.25rem;
      height: 25.25rem;
      margin-bottom: 1rem;
    }
  }
  @media only screen and (max-width: ${breakpoints.tablet}) {
    max-height: none;
    gap: 0rem;
    .main-image {
      width: 100%;
      height: 25.25rem;
      margin-bottom: 0.938rem;
      object-fit: contain;
    }
  }
  @media only screen and (max-width: ${breakpoints.mobileLarge}) {
    .main-image {
      height: 18rem;
    }
  }
  @media only screen and (max-width: ${breakpoints.mobile}) {
    .main-image {
      height: 13rem;
    }
  }
  @media only screen and (max-width: ${breakpoints.mobileSmall}) {
    .main-image {
      height: 12rem;
    }
  }
`

const RedirectBanner = ({ header, image, link, description, label }) => {
  const { windowSize } = useWindowSize()
  return (
    <>
      {windowSize.width > 1680 && <WhiteBg />}
      <BannerContainer>
        <Blur />
        <ContentWrapper>
          <Header>{header}</Header>
          <Description>{description}</Description>
          <Button href={link}>
            <p className="text">{label}</p>
            <div className="arrow" />
          </Button>
        </ContentWrapper>
        <>
          <img className="main-image" src={image.asset.url} alt={image.asset.alt} />
        </>
      </BannerContainer>
    </>
  )
}

export default RedirectBanner
