import styled from 'styled-components';
import { colors } from '../../../../constants/style';
import { InputLabelStyle, InputWrapperStyle, InputStyle } from '../commonFormStyle';

export const StyledTextarea = styled.div`
  ${InputWrapperStyle}

  .input-label {
    color: ${({ isError }) => (isError ? colors.error : colors.titleDark)};
    ${InputLabelStyle}
  }

  textarea {
    border: ${({ isError }) => (isError ? `1px solid ${colors.error}` : `1px solid ${colors.grayLight}`)};
    ${({ resize }) => (!resize ? 'resize: none;' : 'resize: vertical;')}
    padding: 10px 16px;
    min-height: 3.25rem;

    ${InputStyle}
    line-height: 1.5rem;

    &:hover {
      border: 1px solid #727292;
    }

    // this handles case when input is filled
    &:not(:placeholder-shown) {
      border: 1px solid #c3c2d4;
    }
  }

  textarea:focus,
  input:focus {
    border: 1px solid ${colors.primary};
    outline: 1px solid ${colors.primary};
  }
`;
