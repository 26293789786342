export const fullNameValidation = {
  minLength: {
    value: 3,
    message: 'Minimal length: 3',
  },
  maxLength: {
    value: 50,
    message: 'Maximal length: 50',
  },
  pattern: {
    value: /^[a-z0-9 \-'À-ž]+$/i,
    message: 'Contains illegal characters',
  },
}

export const emailValidation = {
  pattern: {
    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,18}$/i,
    message: 'The email format is not correct. ',
  },
}

export const phoneValidation = {
  minLength: {
    value: 7,
    message: 'Minimal length: 7',
  },
}

export const howDidYouHearValidation = {
  minLength: {
    value: 3,
    message: 'Minimal length: 3',
  },
  maxLength: {
    value: 50,
    message: 'Maximal length: 50',
  },
}

export const projectDescriptionValidation = {
  minLength: {
    value: 5,
    message: 'Minimal length: 5',
  },
  maxLength: {
    value: 5000,
    message: 'Maximal length: 5000',
  },
}

export const validationDelay = 1000
