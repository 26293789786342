type StructuredDataArticleType = {
  headline: string
  postURL: string
  datePublished: string
  image: string[]
  author?: { name: string }
}
export const StructuredDataArticle = ({
  headline,
  postURL,
  datePublished,
  image,
  author,
}: StructuredDataArticleType) => {
  const images = image.length > 0 ? image : ['https://10clouds.com/images/default-seo-image.jpg']
  const validPostURL = `https://10clouds.com${postURL}`

  return (
    <script type="application/ld+json">
      {JSON.stringify(
        {
          '@context': 'https://schema.org',
          '@type': 'NewsArticle',
          mainEntityOfPage: {
            '@type': 'WebPage',
            '@id': validPostURL,
          },
          headline: headline,
          image: images,
          datePublished: datePublished,
          dateModified: datePublished,
          author: {
            '@type': author ? 'Person' : 'Organization',
            name: author?.name ?? '10Clouds',
            url: 'https://10clouds.com/',
          },
          publisher: {
            '@type': 'Organization',
            name: '10Clouds',
            logo: {
              '@type': 'ImageObject',
              url: 'https://10clouds.com/images/10c-logo/10clouds-logo.svg',
            },
          },
        },
        null,
        2,
      )}
    </script>
  )
}
