import React from 'react';
import SanityBlockContent from '../../SanityBlockContent';
import { colors, breakpoints } from '../../../../constants/style';
import styled from 'styled-components';
import ReferralForm from './components/ReferralForm/ReferralForm';
import SectionHeader from '../../SectionHeader/SectionHeader';

const StyledReferralSection = styled('section')`
  .container {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    background-color: #fff;
    position: relative;
    @media only screen and (max-width: ${breakpoints.tablet}) {
      flex-direction: column;
    }
  }

  .content {
    max-width: 39.875rem;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    @media screen and (max-width: ${breakpoints.desktop}) {
      max-width: 28rem;
    }

    @media screen and (max-width: ${breakpoints.tablet}) {
      margin: 0 auto;
      max-width: 34.5rem;
    }
  }

  .ordered-item {
    display: flex;
    gap: 2rem;
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
    &__number {
      font-family: BioSans-SemiBold, sans-serif;
      margin-top: 4px;
      height: 3rem;
      padding: 0 1.2rem;
      border-radius: 4px;
      color: #3a199e;
      background-color: #ede7fd;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__description {
      p {
        color: #4e4d6c;
        line-height: 2rem;
        font-size: 1.1875rem;
      }
    }
  }

  .extra__checkbox__info {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${colors.titleDark};
    vertical-align: middle;
  }

  .candidate__referral__link {
    color: ${colors.primary};
  }
`;

const ReferralFormSection = ({ header, raw }) => {
  return (
    <StyledReferralSection id="referral-form">
      <div className="container">
        <div className="content">
          <SectionHeader title={header} headerPosition="left" />
          {raw.orderedListItems.map((item, index) => (
            <div key={index} className="ordered-item">
              <div className="ordered-item__number">{index + 1}</div>{' '}
              <SanityBlockContent className="ordered-item__description" blocks={item.description} />
            </div>
          ))}
        </div>
        <ReferralForm />
      </div>
    </StyledReferralSection>
  );
};

export default ReferralFormSection;
