import styled from 'styled-components'
import {
  navbarMinHeight,
  horizontalLayoutPadding,
  horizontalLayoutPaddingTablet,
  horizontalLayoutPaddingMobile,
} from '../../layout/components/commonLayoutStyle'
import { breakpoints, colors, blockContentStyle } from '../../../../constants/style'
import { StyledSubscribeSection } from '../../../components/sections/SubscribeToNewsletter'
import { StyledCheckbox } from '../../../components/Form/Checkbox'
import { BLOG_ARTICLE_MAX_WIDTH } from '../../../../constants/blog'

export const BlogPostContainer = styled('section')`
  padding: calc(6.875rem + (${navbarMinHeight})) ${horizontalLayoutPadding} 0 !important;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 7.5rem;

  .blog {
    &__title {
      font-size: 2.875rem;
      font-family: "DroidSerif", "BioSans-Regular", "Roboto-Regular", sans-serif;
      color: var(--t1-title);
      margin-bottom: 2.875rem;
    }

    &__featured-image {
      margin-bottom: 5rem;
    }
  }

  .post-meta--no-bottom-padding {
    margin-bottom: 0;
  }

  @media only screen and (max-width: ${breakpoints.desktop}) {
    padding-left: ${horizontalLayoutPaddingTablet} !important;
    padding-right: ${horizontalLayoutPaddingTablet} !important;
    margin-bottom: 5rem;
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    padding: calc(5rem + (${navbarMinHeight})) ${horizontalLayoutPaddingMobile} 0 !important;
  }
`

export const BlogPostContent = styled('article')`
  ${blockContentStyle};
  word-break: break-word;
  width: 100%;
  max-width: 1300px;
  * {
    font-family: "BioSans-Regular", "Roboto-Regular", sans-serif !important;
    // !important here is bad but it was the easiest way to fix blog font
  }

  ${StyledSubscribeSection} {
    ${StyledCheckbox} {
      p {
        color: inherit;
      }
    }
  }

  ol,
  ul {
    margin-top: 2.5rem;
  }

  figure {
    text-align: center;

    figcaption {
      margin-top: 1rem;
      font-family: DroidSerif, sans-serif;
      font-size: 0.875rem;
      letter-spacing: 0.2px;
      color: ${colors.mutedLight};
    }
  }
`

export const BlogPostTextWrapper = styled.div`
  margin: 0 auto 20px;
  display: flex;

  .left-column {
    max-width: ${BLOG_ARTICLE_MAX_WIDTH}px;
    width: 100%;
    position: relative;
    p {
      color: var(--t1-body);
      font-weight: 400;
      font-size: 1.188rem;
      line-height: 2rem;
    }
  }

  @media only screen and (min-width: ${breakpoints.desktop}) {
    justify-content: space-between;
    .left-column {
      margin-right: 5rem;
      &:only-child {
        margin-inline: auto;
      }
    }
  }
  @media only screen and (max-width: ${breakpoints.desktop}) {
    justify-content: center;
  }

  > div {
    p,
    ol,
    li {
      font-size: 1.125rem;
      line-height: 2rem;
      letter-spacing: 0.285714px;
    }

    li {
      padding-left: 2rem;
      color: var(--t1-body);
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: ${colors.textDark};
    }

    h2 {
      font-size: 2.5rem;
    }

    h3 {
      font-size: 1.875rem;
    }

    blockquote {
      font-size: 1.5rem;
      line-height: 36px;
      letter-spacing: 0.342857px;
      font-weight: 600;
    }
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    width: 100%;

    > div {
      & > :first-child {
        font-size: 1.125rem;
        line-height: 1.75rem;
      }

      p,
      ol,
      li {
        font-size: 1rem;
        line-height: 1.75;
      }

      h2 {
        font-size: 1.625rem;
      }

      h3 {
        font-size: 1.375rem;
      }

      blockquote {
        font-size: 1.125rem;
        line-height: 1.75rem;
      }

      figure {
        :first-of-type {
          ::after {
            top: -70px;
            right: -60px;
            transform: scale(0.7) rotate(180deg);
          }
        }

        :nth-of-type(2) {
          ::after {
            left: -80px;
            bottom: -70px;
            transform: scale(0.6);
          }
        }
      }
    }
  }
`
