import { css } from 'styled-components';
import { colors } from '../../../constants/style';

export const labelStyle = css`
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  height: 2rem;
  line-height: 1.675rem;
  font-family: "BioSans-SemiBold", sans-serif;
  font-size: 1.0625rem;
  color: ${colors.mutedDark};
  z-index: 0;
  transform: translateY(0);
  transition: transform 0.2s ease-in-out, font-size 0.2s ease-in-out;
`;

export const inputStyle = css`
  display: block;
  position: relative;
  width: 100%;
  padding-bottom: 0.25rem;
  line-height: 2rem;
  font-family: "BioSans-SemiBold", sans-serif;
  font-size: 1.0625rem;
  color: #636370;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid #c1c1d0;
  background: none;
  z-index: 1;
  transition: border-bottom 0.2s ease-in-out;

  &::placeholder {
    opacity: 0;
  }

  // hack for IE11 to show placeholder and hide label because :placeholder-shown is not working
  @media only screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    &:-ms-input-placeholder {
      opacity: 1;
    }
    & + label {
      display: none;
    }
  }

  // hack for Edge to show placeholder and hide label because :placeholder-shown is not working
  @supports (-ms-ime-align: auto) {
    &::placeholder {
      opacity: 1;
    }
    & + label {
      display: none;
    }
  }

  // :foxus and :not(:placeholder-shown) must be separate to work on IE11 and Edge
  &:focus {
    outline: none;
    border-bottom: 1px solid ${colors.primary};

    & + label {
      font-size: 0.75rem;
      transform: translateY(-1.5rem);
    }
  }
  &:not(:placeholder-shown) {
    outline: none;
    border-bottom: 1px solid ${colors.primary};

    & + label {
      font-size: 0.75rem;
      transform: translateY(-1.5rem);
    }
  }
`;

export const errorTextStyle = css`
  height: 1rem;
  font-size: 0.75rem;
  color: ${colors.error};
`;
export const errorBorderStyle = css`
  border-bottom: 1px solid ${colors.error};
`;
