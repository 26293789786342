import React from 'react';
import PropTypes from 'prop-types';

const CheckboxLabel = ({ inputName, children }) => {
  return (
    <label htmlFor={inputName} className="checkbox-label">
      {children}
    </label>
  );
};

export default CheckboxLabel;

CheckboxLabel.propTypes = {
  inputName: PropTypes.string.isRequired,
};
