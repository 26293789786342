import React, { useState, useEffect, useMemo } from 'react';

import styled from 'styled-components';
import { Link } from 'react-scroll';

import IconChevron from '../icons/IconChevron';
import { colors, breakpoints } from '../../../constants/style';
import { navbarMinHeight } from '../../features/layout/components/commonLayoutStyle';
import useScroll from '../../hooks/useScroll';
import useHideOnScroll from '../../hooks/useHideOnScroll';

const Overlay = styled.div`
  top: ${navbarMinHeight};
  position: fixed;
  z-index: 98;
  height: 100%;
  width: 100%;
  display: ${({ isNavigationOpen }) => (isNavigationOpen ? 'block' : 'none')};
  background-color: rgba(16, 7, 39, 0.5);
`;

const AgilePlayBookNavigationStyled = styled.div`
  position: fixed;
  top: ${navbarMinHeight};
  display: ${({ displayNavigation }) => (displayNavigation ? 'block' : 'none')};
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 16px 40px rgba(71, 90, 158, 0.1);
  z-index: 99;

  @media only screen and (max-width: ${breakpoints.desktop}) {
    top: ${({ isMainNavHidden }) => (isMainNavHidden ? '0' : `calc(${navbarMinHeight} - 1.5rem)`)};
  }

  .agile-playbook-navigation {
    &__container {
      width: 46rem;
      margin: 0 auto;
      @media only screen and (max-width: ${breakpoints.tablet}) {
        width: 100%;
        padding: 0 1rem;
      }
    }
    &__number {
      margin-right: 0.5rem;
      color: ${colors.primary};
      font-weight: 600;
    }
    &__progress-bar {
      width: 100%;
      height: 3px;
      background-color: #ede7fd;
      position: relative;
    }
    &__list {
      display: flex;
      position: absolute;
      background-color: #fff;
      padding: 1rem 0;
      top: ${({ isNavigationOpen }) => (isNavigationOpen ? '100%' : '-100vh')};
      visibility: ${({ isNavigationOpen }) => (isNavigationOpen ? 'visible' : 'hidden')};
      transition: top 0.4s ease-in-out, visibility 0.2s ease-out 0.2s;
      width: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 10;
    }
    &__active {
      display: ${({ isNavigationOpen }) => (isNavigationOpen ? 'none' : 'flex')};
      transition: display 0.4s ease-in-out;
      align-items: center;
      justify-content: space-between;
      font-family: Roboto-Regular, sans-serif;
      font-size: 0.875rem;
      padding: 1rem 0;
      cursor: pointer;
      svg {
        fill: ${colors.titleDark};
      }
    }

    &__btn {
      all: unset;
      cursor: pointer;
      transform: ${({ isNavigationOpen }) => (isNavigationOpen ? 'rotate(180deg)' : 'unset')};
    }

    &__link {
      &--active {
        font-weight: 600;
      }
    }

    &__item {
      color: #4e4d6c;
      font-size: 0.875rem;
      font-family: Roboto-Regular, sans-serif;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
      &:first-child {
        svg {
          fill: ${colors.titleDark};
        }
      }
    }
  }
`;

const ProgressBarFilled = styled.div.attrs(({ progress }) => ({
  style: {
    width: `${progress}%`,
  },
}))`
  height: 3px;
  position: absolute;
  transition: width 0.2s ease-out;
  left: 0;
  background: linear-gradient(90deg, #7e55f0 0%, #602be9 100%);
`;

const AgilePlaybookNavigation = ({ items: paragraphSections }) => {
  const [activeItem, setActiveItem] = useState(null);
  const [progress, setProgress] = useState(0);
  const [displayNavigation, setDisplayNavigation] = useState(false);
  const [isNavigationOpen, setIsNavigationOpen] = useState(false);
  const [agileElements, setAgileElements] = useState([]);

  const { y: scrollYPosition } = useScroll();
  const { shouldHideNavigation } = useHideOnScroll();

  const toggleIsNavigationOpen = () => {
    setIsNavigationOpen((prev) => !prev);
  };

  function setupMenuElements(sections) {
    const allAgileElements = sections.map((i, index) => ({
      node: document.querySelector(`[name=${i.id}]`),
      ...i,
      index,
    }));

    setActiveItem(allAgileElements[0]);
    setAgileElements(allAgileElements);
  }

  const agileElementsHeight = useMemo(
    // optional chaning is needed when dev mode, prod build is SSG
    () => {
      if (!agileElements) return;
      if (!agileElements.every((el) => Boolean(el.node))) return;

      return agileElements.reduce((totalHeight, htmlNode) => (totalHeight += htmlNode.node.offsetHeight), 0);
    },
    [agileElements],
  );

  const playbookScrollYPosition = useMemo(() => scrollYPosition - agileElements?.[0]?.node?.offsetTop, [agileElements, scrollYPosition]);

  function resetMenu() {
    setProgress(0);
    setDisplayNavigation(false);
    setIsNavigationOpen(false);
  }

  useEffect(() => {
    if (!paragraphSections || !paragraphSections.length) return;

    if (process.env.NODE_ENV !== 'development') {
      // dev server is ssr while prod build this as ssg
      setupMenuElements(paragraphSections);
      return;
    }

    const setTimeoutId = setTimeout(() => setupMenuElements(paragraphSections), 3000);

    return () => clearTimeout(setTimeoutId);
  }, [paragraphSections]);

  useEffect(() => {
    if (playbookScrollYPosition <= 0) {
      resetMenu();
      return;
    }

    const scrolled = (playbookScrollYPosition / agileElementsHeight) * 100;
    setProgress(scrolled);

    setDisplayNavigation(scrollYPosition >= agileElements[0]?.node?.offsetTop);
  }, [agileElements, agileElementsHeight, playbookScrollYPosition, scrollYPosition]);

  return (
    <>
      <AgilePlayBookNavigationStyled
        displayNavigation={displayNavigation}
        isNavigationOpen={isNavigationOpen}
        isMainNavHidden={shouldHideNavigation}
        className="agile-playbook-navigation"
      >
        <div className="agile-playbook-navigation__progress-bar">
          <ProgressBarFilled progress={progress} />
        </div>
        <div className="agile-playbook-navigation__container">
          <div className="agile-playbook-navigation__active" onClick={toggleIsNavigationOpen}>
            <div className="agile-playbook-navigation__active-item">
              <span className="agile-playbook-navigation__number">{activeItem && String(activeItem.index + 1).padStart(2, '0')}</span>
              {activeItem && activeItem.header}
            </div>
            <IconChevron />
          </div>
        </div>
        <ul className="agile-playbook-navigation__list">
          <div className="agile-playbook-navigation__container">
            {agileElements.length
              ? agileElements.map(({ header, id }, index) => (
                  <li className="agile-playbook-navigation__item" key={`agile-playbook-navigation-item-${id}`}>
                    <Link
                      to={id}
                      onSetActive={(to) => setActiveItem(agileElements.find((el) => el.id === to))}
                      activeClass="agile-playbook-navigation__link--active"
                      spy={true}
                      smooth={true}
                      offset={-50}
                      duration={500}
                      onClick={() => setTimeout(() => setIsNavigationOpen(false), 500)}>
                      <span className="agile-playbook-navigation__number">{String(index + 1).padStart(2, '0')}</span> {header}
                    </Link>
                    <button className="agile-playbook-navigation__btn" onClick={toggleIsNavigationOpen}>
                      <IconChevron />
                    </button>
                  </li>
                ))
              : null}
          </div>
        </ul>
      </AgilePlayBookNavigationStyled>
      <Overlay onClick={toggleIsNavigationOpen} isNavigationOpen={isNavigationOpen} />
    </>
  );
};

export default React.memo(AgilePlaybookNavigation);
