const locations = [
  {
    href: 'https://goo.gl/maps/2wxrXhG7oHFCJgXUA',
    cityName: 'Warsaw',
    address: 'Chmielna 73, 00-801 Warsaw',
  },
  {
    href: 'https://www.google.com/maps/place/10Clouds/@52.3989947,16.9291532,17z/data=!3m1!4b1!4m5!3m4!1s0x47045b3ce5a7eb53:0xe455c33cd57dc13f!8m2!3d52.3989947!4d16.9313419',
    cityName: 'Poznan',
    address: 'Królowej Jadwigi 43, 61-871 Poznań',
  },
  {
    href: 'https://goo.gl/maps/99JkFtWsiQc9nrwu6',
    cityName: 'Wroclaw',
    address: 'Sucha 2, 50-088 Wrocław',
  },
];

export default locations;
