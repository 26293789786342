import styled, { css } from 'styled-components';
import { colors } from '../../../constants/style';

const bioSansFont = css`
  font-family: "BioSans-Regular", sans-serif;
`;

const robotoSansFont = css`
  font-family: "Roboto-Regular", sans-serif;
`;

export const StyledErrorContainer = styled.div`
  min-height: 23px;
`;

export const StyledErrorMsg = styled.span`
  color: ${colors.error};
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 8px;
  ${bioSansFont}
`;

export const errorBorderStyle = css`
  border-bottom: 1px solid ${colors.error};
`;

export const InputLabelStyle = css`
  font-size: 0.875rem;
  line-height: 1.25rem;
  ${robotoSansFont}
`;

export const StyledPreLabel = styled.span`
  color: ${({ isError }) => (isError ? colors.error : colors.primary)};
`;

export const StyledReqAsterisk = styled.span`
  font-size: 0.8125rem;
  color: ${colors.error};
  ${bioSansFont}
`;

export const InputWrapperStyle = css`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 7px;
  width: 100%;
`;

export const InputStyle = css`
  line-height: 2rem;
  font-size: 1rem;
  padding: 10px 16px;
  color: ${colors.titleDark};
  ${robotoSansFont}

  &::placeholder {
    color: ${colors.purpleGray};
    opacity: 1;
  }
`;
