import React from 'react';
import Image from '../Image';

import SocialMediaLink from '../SocialMediaLink';

import { htmlContent } from '../../utils/string';
import linkedInLogo from '../../../static/images/logo/logos-linkedin.svg';
import behanceLogo from '../../../static/images/logo/logos-behance.svg';
import mediumLogo from '../../../static/images/logo/icons-medium-fill.svg';
import dribbleLogo from '../../../static/images/logo/logos-dribbble.svg';
import StyledEmployeeEntry from './styled';

const employeeLinksIconMap = {
  linkedIn: linkedInLogo,
  behance: behanceLogo,
  medium: mediumLogo,
  dribble: dribbleLogo,
};

const EmployeeEntry = ({
  image,
  name,
  position,
  employeeLinks,
  description = false,
  darkTheme = false,
  showLinksAndDesc = false,
  mainGuest = false,
}) => {
  const shouldDisplayIcons = Boolean(employeeLinks && Object.values(employeeLinks).filter(Boolean).length);
  const employeeDescription = <p className="employee__description">{htmlContent(description)}</p>;

  const isUserUseFirefox = typeof InstallTrigger !== 'undefined';

  return (
    <StyledEmployeeEntry darkTheme={darkTheme} mainGuest={mainGuest} isUserUseFirefox={isUserUseFirefox}>
      <div className="employee__container">
        <Image className="employee__image" image={image} />
        <div className="employee__data">
          <div className="employee__name">{name}</div>
          <div className="employee__position">{position}</div>
          {shouldDisplayIcons ? (
            <>
              <div className="employee__icons">
                {Object.keys(employeeLinks)
                  .filter((key) => employeeLinks[key])
                  .map((key, index) => (
                    <SocialMediaLink
                      key={`icon-${index}`}
                      darkTheme={darkTheme}
                      className="employee__link"
                      to={employeeLinks[key]}
                      img={employeeLinksIconMap[key]}
                      alt={`${key} logo`}
                    />
                  ))}
              </div>
              {showLinksAndDesc && employeeDescription}
            </>
          ) : (
            employeeDescription
          )}
        </div>
      </div>
    </StyledEmployeeEntry>
  );
};

export default EmployeeEntry;
