import React from 'react';
import styled from 'styled-components';

import Image from '../../../Image';
import { NavLink } from '../../../Link';

import { colors } from '../../../../../constants/style';

const StyledOtherServiceEntry = styled((props) => <NavLink {...props} />)`
  padding: 3.5rem 3rem;
  position: relative;
  display: block;
  height: 100%;
  text-align: center;
  text-transform: none;
  background-color: transparent;
  transition: background-color 0.2s ease-in-out;
  ${(props) => props.bordered && `border: 1px solid ${colors.backgroundMilky};`}
  box-shadow: 0px 8px 24px rgba(71, 90, 158, 0.1);

  .header {
    font-size: 1.75rem;
    color: ${colors.titleDark};
    margin-bottom: 1.5rem;
  }

  .description {
    font-size: 1rem;
    color: ${colors.purpleGray};
  }

  .icon-wrapper {
    margin-bottom: 6rem;
    height: 224px;

    .gatsby-image-wrapper,
    > img {
      max-height: 100%;
    }
    .gatsby-image-wrapper {
      img {
        object-fit: contain !important;
        // all below prevents image from stretch on IE11
        left: 50% !important;
        top: 50% !important;
        transform: translate(-50%, -50%);
        width: auto !important;
        height: auto !important;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
`;

const OtherServiceEntry = ({ className, to, icon, name, description }) => {
  return (
    <StyledOtherServiceEntry className={className} to={to} variant="bordered">
      <div className="icon-wrapper">
        <Image image={icon} />
      </div>
      <div className="text-wrapper">
        <h2 className="header">{name}</h2>
        <p className="description">{description}</p>
      </div>
    </StyledOtherServiceEntry>
  );
};

OtherServiceEntry.defaultProps = {
  bordered: false,
};

export default OtherServiceEntry;
