import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator'
import React, { lazy, Suspense } from 'react'
import styled, { css } from 'styled-components'

import { colors, breakpoints } from '../../../../constants/style'
import LineDashedSVG from '../../../../static/images/line-dashed.svg'
import Image from '../../Image'
const SanityBlockContent = lazy(() => import('../../SanityBlockContent'))

const insideBlockContentPatch = css`
  cursor: default;
  max-width: none;
  .item {
    &__title {
      font-family: "BioSans-Regular", sans-serif;
    }
    &__icon {
      margin-right: 2.75rem;
      img {
        height: auto;
        width: 7.5rem;
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    .item {
      &__icon {
        margin-right: 1.5rem;
      }
      &__content {
        margin-left: 1.5rem;
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.mobile}) {
    .item {
      &__icon {
        margin-right: 0.5rem;
        img {
          width: 4rem;
        }
      }
      &__content {
        margin-left: 0.5rem;
      }
      &__number {
        &-decoration-oval {
          width: 1rem;
          height: 1rem;
        }
      }
    }
  }
`

const ovalSize = '14px'
const ovalSizeActive = '22px'

const horizontalLayoutWrapper = css`
  margin: 0 auto;
  background-color: ${({ theme }) => theme && theme.perkNotActive};
  width: 100%;
  padding: 0;
  display: flex;
  min-height: 11.5625rem;
  max-width: 34.5rem;
  box-shadow: none;
`

const horizontalLayoutItem = css`
  &__content {
    margin-left: 2.75rem;
    padding: 0;
  }

  &__title {
    text-align: left;
  }

  &:not(:last-child) &__description {
    padding-bottom: 0;
  }

  &__description {
    margin-bottom: 2rem;
    p {
      font-size: 1rem;
      text-align: left;
    }
  }

  &__icon {
    margin-bottom: 0;
    margin-right: 3.75rem;
    img {
      height: auto;
      width: 5rem;
    }
  }

  &__number {
    display: flex;

    &-decoration-dashed-line {
      width: 1px;
      height: 100%;
      align-self: center;
      left: 50%;
      ${({ isFirst }) => isFirst && 'top: 50%'};
      ${({ isLast }) => isLast && 'top: -50%'};
    }
  }
`

const horizontalLayoutWithHiddenDescriptionItem = css`
  &__title {
    color: ${({ isActive }) => !isActive && '#4E4D6C'};
  }
  &__description {
    visibility: ${({ isActive }) => (isActive ? 'visible' : 'hidden')};
  }
  &__number {
    &-decoration-container {
      margin-top: ${({ isActive }) => isActive && '-4px'};
    }
    &-decoration-oval {
      ${({ isActive }) =>
        !isActive &&
        css`
          width: ${ovalSize};
          height: ${ovalSize};
          border: 1px solid #c3c2d4;
          background-color: #f8f8fc;
        `}
    }
  }
`

export const StyledProcessItem = styled('div').attrs({
  className: 'item',
})`
  position: relative;
  padding: 2rem 0;
  width: 30%;
  cursor: pointer;
  background-color: ${({ theme, isActive }) => (isActive ? theme.perkActive : theme.perkNotActive)};
  transition: all 0.2s;
  ${({ isActive }) => isActive && 'box-shadow: 0px 8px 24px rgba(71, 90, 158, 0.1);'};

  ${({ isInsideBlockContent }) =>
    isInsideBlockContent
      ? horizontalLayoutWrapper
      : `
      @media only screen and (max-width: ${breakpoints.desktopSmall}) {
    ${horizontalLayoutWrapper}
  }
  `}

  @media only screen and (max-width: ${breakpoints.mobile}) {
    max-width: 22rem;
  }
  .item {
    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 2.875rem;
      img {
        height: 6.25rem;
        width: 6.25rem;
        max-width: none;
      }
    }

    &__title {
      text-align: center;
      margin: 1rem 0;
      font-family: Roboto-Regular, sans-serif;
      font-weight: 600;
      font-size: 1rem;
      line-height: 2rem;
      letter-spacing: 0.025rem;
      color: ${colors.primary};
      text-transform: uppercase;
    }

    &__number {
      &-decoration-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
      }
      &-decoration-oval {
        border-radius: 50%;
        z-index: 1;
        transition: background-color 0.2s;
        width: ${ovalSizeActive};
        height: ${ovalSizeActive};
        border: 0;
        background-color: ${colors.primary};
      }

      &-decoration-dashed-line {
        background-color: #c3c2d4;
        content: '';
        width: ${({ isFirst, isLast }) => (!isFirst && !isLast ? '100%' : '50%')};
        left: ${({ isLast }) => isLast && '0'};
        height: 1px;
        align-self: flex-end;
        position: absolute;
      }
    }

    &__content {
      padding: 0 2rem;
    }

    &__description {
      font-family: Roboto-Regular, sans-serif;
      line-height: 2;
      color: ${colors.purpleGray};
      text-align: center;

      p {
        font-size: 1rem;
        line-height: 2;
      }

      a {
        color: ${colors.primary};
      }

      ul {
        padding-left: 2.1rem;
        position: relative;

        li::before {
          content: '';
          display: inline-block;
          width: 7px;
          height: 7px;
          border: 2px solid ${colors.primary};
          border-radius: 50%;
          margin-left: -2.1rem;
          margin-right: 1.3rem;
          vertical-align: middle;
        }
      }

      li > ul::before {
        content: '';
        height: 80%;
        width: 1px;
        display: block;
        position: absolute;
        top: 1rem;
        left: calc(-2.1rem + 5px);
        background: url(${LineDashedSVG}) repeat left top;
      }
    }

    @media only screen and (max-width: ${breakpoints.desktopLarge}) {
      &__description {
        p {
          font-size: 1.2rem;
          line-height: 1.88;
        }
      }
    }

    ${({ isInsideBlockContent }) => (isInsideBlockContent ? horizontalLayoutItem : ``)}

    @media only screen and (min-width: ${breakpoints.desktopSmall}) {
      ${({ isInsideBlockContent }) =>
        !isInsideBlockContent && horizontalLayoutWithHiddenDescriptionItem}
    }
    @media only screen and (max-width: ${breakpoints.desktopSmall}) {
      ${horizontalLayoutItem}
    }

    @media only screen and (max-width: ${breakpoints.mobile}) {
      &__title {
        margin-top: 1rem;
        margin-bottom: 1rem;
      }

      &__icon {
        margin-right: 2.5rem;
      }

      &__content {
        margin-left: 1.5rem;
      }
    }
  }

  ${({ isInsideBlockContent }) => (isInsideBlockContent ? insideBlockContentPatch : '')}
`

export const themes = {
  light: {
    perkActive: '#fff',
    perkNotActive: '#fff',
  },
  lightPurple: {
    perkActive: '#fff',
    perkNotActive: colors.backgroundLight,
  },
}

const ProcessItem = ({
  item,
  index,
  items,
  raw,
  theme,
  activeItem,
  setActiveItem,
  isInsideBlockContent = false,
}) => {
  return (
    <StyledProcessItem
      onClick={() => setActiveItem(index)}
      isFirst={index === 0}
      isLast={index === items.length - 1}
      isActive={activeItem === index}
      key={`item-${index}`}
      theme={themes.hasOwnProperty(theme) ? themes[theme] : themes['light']}
      isInsideBlockContent={isInsideBlockContent}
    >
      {item?.icon && (
        <div className="item__icon">
          <Image image={item.icon} alt={item.alt || ''} />
        </div>
      )}
      <div className="item__number">
        <div className="item__number-decoration-container">
          <div className="item__number-decoration-oval"></div>
          <div className="item__number-decoration-dashed-line"></div>
        </div>
      </div>

      <div className="item__content">
        <h2 className="item__title">{item.header}</h2>
        <div className="item__description">
          <Suspense fallback={<LoadingIndicator />}>
            <SanityBlockContent blocks={raw.items[index].description} />
          </Suspense>
        </div>
      </div>
    </StyledProcessItem>
  )
}

export default ProcessItem
