import { NavLink } from '../Link'
import React from 'react'
import Image from 'components/Image'
import styled from 'styled-components'
import { colors } from '../../../constants/style'
import { blogArrowAnimationStyle, blogTitleAnimationStyle } from '../../features/blog/commonStyle'
import IconArrow from '../icons/IconArrow'

const ArticleCardWrapper = styled.div`
  display: block !important;
  width: 100%;
  height: 100%;

  .link {
    text-transform: none;
    color: ${colors.titleDark};
    padding-inline: 0;

    .item__header {
      ${blogTitleAnimationStyle.base};
    }

    &:hover .item__header {
      ${blogTitleAnimationStyle.hover};
    }

    > span {
      display: block;
    }

    svg {
      ${blogArrowAnimationStyle.base}
      display: block;
      margin-top: 2rem;
    }

    &:hover svg {
      ${blogArrowAnimationStyle.hover}
    }
  }

  .item__category {
    display: block;
    color: ${colors.primary};
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 0.5rem;
    letter-spacing: 0.4px;
  }

  .item__header {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2.25rem;
    letter-spacing: 0.16px;
  }

  .item__text {
    display: block;
    padding: 2rem 2rem 3rem 2rem;
    svg {
      width: 23px;
    }
  }
  p {
    font-size: 1rem;
    color: ${colors.textLight};
    margin-top: 1rem;
    line-height: 1.75rem;
  }
  .arrow-link {
    margin-top: 1.5rem;
  }
`

export const ArticleCard = ({
  title,
  preTitle,
  description,
  link,
  featuredImage,
  hasArrow = false,
  isWholeCardClickable = false,
  imageAspectRatio = '16/10',
}) => {
  if (isWholeCardClickable && !!link) {
    return (
      <ArticleCardWrapper className="item">
        <NavLink to={link}>
          <Image
            image={featuredImage}
            style={{
              aspectRatio: imageAspectRatio,
              display: 'block',
              width: '100%',
            }}
          />
          <span className="item__text">
            {!!preTitle && <span className="item__category">{preTitle.toUpperCase()}</span>}

            <h3 className="item__header">{title}</h3>
            {!!description && <p>{description}</p>}
            {!!hasArrow && (
              <span className="arrow-link">
                <IconArrow />
              </span>
            )}
          </span>
        </NavLink>
      </ArticleCardWrapper>
    )
  }
  return (
    <ArticleCardWrapper className="item">
      {!!link ? (
        <NavLink to={link}>
          <Image
            image={featuredImage}
            style={{
              aspectRatio: imageAspectRatio,
              display: 'block',
              width: '100%',
            }}
          />
        </NavLink>
      ) : (
        <Image
          image={featuredImage}
          style={{
            aspectRatio: imageAspectRatio,
            display: 'block',
            width: '100%',
          }}
        />
      )}
      <div className="item__text">
        {!!preTitle && <div className="item__category">{preTitle.toUpperCase()}</div>}
        {!!link ? (
          <NavLink to={link}>
            <h3 className="item__header">{title}</h3>
          </NavLink>
        ) : (
          <h3 className="item__header">{title}</h3>
        )}
        {!!description && <p>{description}</p>}
        {!!hasArrow && (
          <NavLink to={link} className="arrow-link">
            <IconArrow />
          </NavLink>
        )}
      </div>
    </ArticleCardWrapper>
  )
}
