import React from 'react'
import salesAgents from '../../salesAgents'
import SalesAgent from '../SalesAgent'
import {
  SidebarContainer,
  SidebarContent,
  GetInTouchSection,
  SalesAgentsGroup,
  SidebarFooter,
} from './styled'

const Sidebar = () => (
  <SidebarContainer>
    <SidebarContent>
      <h2>
        Start your project <br /> with 10Clouds!
      </h2>
      <p>
        Fill out the form, and tell us more about your business goals. We’ll get back to you within
        1 working day.
      </p>

      <GetInTouchSection>
        <h3>
          Would you rather directly get in touch? <br /> We always have the time for a call or
          email!
        </h3>

        <SalesAgentsGroup>
          {salesAgents.map((agent) => (
            <SalesAgent
              key={agent.uuid}
              name={agent.name}
              avatar={agent.avatar}
              position={agent.position}
              phone={agent.phone}
              usPhone={agent.usPhone}
              email={agent.email}
              nameAs="h4"
            />
          ))}
        </SalesAgentsGroup>
      </GetInTouchSection>
    </SidebarContent>

    <SidebarFooter>
      Have any other questions? Write to us via email{' '}
      <a href="mailto:hello@10clouds.com">hello@10clouds.com</a>
    </SidebarFooter>
  </SidebarContainer>
)

export default Sidebar
