import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Modal from '../../Modal';
import { breakpoints, colors } from '../../../../constants/style';
import { scrollPercent } from '../../../utils/scroll'
import { isSSR } from '../../../../constants/environment.js';
import { checkLink } from '../../../utils/link';
import Button from '../../Buttons';
import { animateScroll as scroll } from 'react-scroll';
import { NavLinkButton } from '../../Link';
import { contact } from '../../../../constants/links';
import useWindowSize from '../../../hooks/useWindowSize';

const ModalSectionWrapper = styled.section`
  font-family: "BioSans-Regular", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  width: 50%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  padding: 26px;

  background-color: #FFFFFF;

  .modalSection__title {
    text-align: center;
    font-size: 2.125rem;
    margin-bottom: 1.5rem;

    color: ${colors.titleDark};
  }

  .modalSection__description {
    margin-bottom: 1.5rem;
    font-size: 1rem;
    letter-spacing: 0.26px;
    color: ${colors.blogText};
  }

  .close {
    top: 20px;
    right: 20px;
    position: absolute;
    width: 35px;
    height: 35px;
    cursor: pointer;

    &::after,
    &::before {
      content: '';
      position: absolute;
      height: 1.7px;
      width: 100%;
      top: 50%;
      left: 0;
      margin-top: -1px;
      background: ${colors.blogText};
      transition: background 0.2s ease;
    }
    &::after {
      transform: rotate(45deg);
    }
    &::before {
      transform: rotate(-45deg);
    }
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    width: 100%;
  }
`;

const ModalSection = ({ header, description, buttonLabel, buttonLink }) => {
  const [isVisible, setIsVisible] = useState(false);

  const isLink = checkLink(buttonLink)

  const { isSmallerThanDesktop } = useWindowSize();

  const openedRecently = isSSR ? true : document.cookie.includes("popupShown=true");

  const onClose = () => {
    setIsVisible(false);
    if (!openedRecently && !isSSR) {
      document.cookie = "popupShown=true; max-age=86400";
    }
  }

  const handleScroll = () => {
    if(scrollPercent() > 35) {
      setIsVisible(true)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <Modal
      onClose={onClose}
      isVisible={isVisible && !openedRecently}
      theme={'dark'}
    >
      <ModalSectionWrapper>
        <h2 className="modalSection__title">{header}</h2>
        <p className="modalSection__description">{description}</p>
        {Boolean(buttonLabel && buttonLink && !isLink && !isSSR) && (
          <Button
            onClick={() => {
              // remove 100px to generate offset for navbar
              const navbarOffset = isSmallerThanDesktop ? 0 : 100;
              onClose()
              scroll.scrollTo(
                document.querySelector(buttonLink).getBoundingClientRect().top - navbarOffset + window.pageYOffset,
              );
            }}
            variant="filled"
          >
            {buttonLabel}
          </Button>
        )}
        {Boolean((buttonLabel && (!buttonLink || isLink)) || isSSR) && (
          <NavLinkButton className="contact-btn" to={isLink ? buttonLink : contact.url} variant="filled">
            {buttonLabel}
          </NavLinkButton>
        )}
        <div className="close" onClick={onClose}></div>
      </ModalSectionWrapper>
    </Modal>
  );
};

export default ModalSection;
