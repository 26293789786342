import React, { useState, lazy, Suspense } from 'react'
import styled from 'styled-components'

import { breakpoints } from '../../../constants/style'

import ContactUs from 'components/sections/ContactUs'
import Modal from 'components/Modal'
import Articles from 'components/sections/Articles'
import Clients from 'components/sections/Clients'
import CaseStudies from 'components/sections/CaseStudies'
import { colors } from '../../../constants/style'
import useHomeData from 'hooks/graphql/section/useHomeData'
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator'
import Awards from 'components/sections/Awards'
import YoutubeSection from 'components/sections/YoutubeSection'
import RedirectSection from 'components/sections/RedirectSection'
import SliderSection from 'components/SliderSection/SliderSection'
import Testimonials from 'components/sections/Testimonials'
import { ArticleCard } from 'components/ArticleCard'
import { parseSlug } from 'utils/slug'
import Hero from 'components/sections/Hero'
import { convertToBackgroundImage } from 'utils/image'

const StyledVideo = lazy(() => import('components/Video'))

const StyledSectionBackground = styled.div`
  background: ${colors.backgroundLight};
`

const SliderSectionStyled = styled(SliderSection)`
  padding-top: 0 !important;
  background-color: #fff;
`

export const Home = () => {
  const [isModalVideoVisible, setIsModalVideoVisible] = useState(false)

  const {
    articles,
    contactUs,
    hero,
    heroButtonVideo,
    awardsSection,
    services,
    clients: { clientsList },
    youtubePosts,
    mainCaseStudy,
    sliderCaseStudy,
    testimonialsList,
    _rawArticles,
  } = useHomeData()

  const bgImage = convertToBackgroundImage((hero?.backgroundImage || hero?.mainImage)?.asset)

  return (
    <>
      {hero && (
        <Hero
          {...hero}
          preheader={hero.pretitle}
          header={hero.title}
          backgroundImage={bgImage?.fluid}
          additionalButton={
            heroButtonVideo?.video?.videoFile
              ? {
                  label: heroButtonVideo.buttonText || 'Watch our video',
                  onClick: () => setIsModalVideoVisible(true),
                }
              : null
          }
          fullScreen
        />
      )}
      <StyledSectionBackground>
        {Boolean(clientsList?.length) && <Clients clients={clientsList} />}
        {awardsSection?.awards && (
          <Awards awards={awardsSection.awards} header={awardsSection.header} />
        )}
        {Boolean(mainCaseStudy?.caseStudies?.length || mainCaseStudy?.header) && (
          <CaseStudies
            header={mainCaseStudy.header}
            subtitle={mainCaseStudy.subtitle}
            caseStudiesEntries={mainCaseStudy.caseStudies}
          />
        )}
        {Boolean(sliderCaseStudy?.items?.length) && (
          <SliderSectionStyled {...sliderCaseStudy}>
            {sliderCaseStudy.items.map((item) => (
              <ArticleCard
                key={item._key}
                preTitle={
                  item.caseStudy.sections?.find(({ _type }) => _type === 'overviewForCaseStudy')
                    ?.industry?.name
                }
                title={
                  item.title?.[0]?.children[0].text ||
                  item.caseStudy.sections?.find(({ _type }) => _type === 'heroForCaseStudy')
                    ?.title?.[0]?.children[0].text ||
                  item.caseStudy.abstract.projectName
                }
                featuredImage={item.image || item.caseStudy.abstract.imageForCaseStudiesList}
                link={
                  item.caseStudy.slug
                    ? `/case-studies/${parseSlug(item.caseStudy.slug.current)}`
                    : null
                }
                isWholeCardClickable
              />
            ))}
          </SliderSectionStyled>
        )}
        <Testimonials
          header={testimonialsList?.header}
          testimonials={testimonialsList?.testimonials}
        />

        {Boolean(services?.servicesList?.length) && (
          <RedirectSection
            sectionSubtitle={services._rawSubtitle}
            sectionTitle={services.title}
            redirectList={services.servicesList}
            redirectButtonLabel={services.redirectButton?.label}
            redirectButtonUrl={services.redirectButton?.link}
          />
        )}

        {Boolean(youtubePosts?.youtubeList?.length) && (
          <YoutubeSection
            header={youtubePosts.header}
            description={youtubePosts._rawDescription}
            posts={youtubePosts.youtubeList}
            seeMoreButton={youtubePosts.button}
          />
        )}
      </StyledSectionBackground>
      {Boolean(articles?.blogPosts.length) && (
        <Articles header={articles.header} raw={_rawArticles} blogPosts={articles.blogPosts} />
      )}
      <ContactUs theme="lightPurpleToDark" header={contactUs} buttonLabel="Contact us" />

      {heroButtonVideo?.video?.videoFile && (
        <Suspense fallback={<LoadingIndicator />}>
          <Modal
            isVisible={isModalVideoVisible}
            onClose={() => {
              setIsModalVideoVisible(false)
            }}
          >
            <StyledVideo
              src={heroButtonVideo.video.videoFile.asset.url}
              mimeType={heroButtonVideo.video.videoFile.asset.mimeType}
              onClick={() => setIsModalVideoVisible(false)}
              autoPlay={true}
            ></StyledVideo>
          </Modal>
        </Suspense>
      )}
    </>
  )
}

export default Home
