import React from 'react';

const StepItem = ({ index, content, comment }) => (
  <>
    <p className="thank-you__step-item">
      <span>{index}</span> {content}
    </p>
    <p className="thank-you__message thank-you__message--indentation">{comment}</p>
  </>
);

export default StepItem;
