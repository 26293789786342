import React, { lazy, Suspense } from 'react'

import LoadingIndicator from '../../LoadingIndicator/LoadingIndicator'
import styled from 'styled-components'

import { breakpoints, colors } from '../../../../constants/style'
import SliderSection from 'components/SliderSection/SliderSection'
const AwardsEntry = lazy(() => import('./components/AwardsEntry'))

const AwardsSection = styled(SliderSection)`
  background-color: ${colors.backgroundLight};

  .slider {
    &__container {
      @media only screen and (max-width: ${breakpoints.desktop}) and (min-width: ${breakpoints.desktopSmall}) {
        --margin-right: -2rem;
      }
      .section-header {
        @media only screen and (min-width: ${breakpoints.desktop}) {
          max-width: 100%;
        }
      }
    }

    &__button-wrapper {
      margin-top: 3.5rem;
    }
  }
`

const Awards = ({ header, awards, className }) => {
  const slidesLength = awards.length > 4 ? 4 : awards.length
  const customSettings = {
    slidesToShow: slidesLength,
    slidesToScroll: 1,
    variableWidth: false,
    responsive: [
      {
        breakpoint: parseInt(breakpoints.desktopLargest, 10),
        settings: {
          variableWidth: false,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: parseInt(breakpoints.desktopSmall, 10),
        settings: {
          variableWidth: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: parseInt(breakpoints.mobileLarge, 10),
        settings: {
          variableWidth: false,
          slidesToShow: 1,
        },
      },
    ],
  }
  return (
    <Suspense fallback={<LoadingIndicator />}>
      {Boolean(awards.length) && (
        <AwardsSection
          items={awards}
          headerPosition='flex-start'
          header={header}
          customSettings={customSettings}
        >
          {awards.map((item, index, arr) => (
            <AwardsEntry
              key={`award-${index}`}
              title={item.title}
              logo={item.logo}
              text={item.text}
            />
          ))}
        </AwardsSection>
      )}
    </Suspense>
  )
}

export default Awards
