import CookieConsent from './CookieConsent'
import { Helmet } from 'react-helmet'

const consentTypes = [
  {
    name: 'ad_storage',
    title: 'Ad storage',
    description: 'Enables storage, such as cookies, related to advertising.',
    default: 'denied',
  },
  {
    name: 'ad_user_data',
    title: 'Ad user data',
    description: 'Sets consent for sending user data related to advertising to Google.',
    default: 'denied',
  },
  {
    name: 'ad_personalization',
    title: 'Ad personalization',
    description: 'Sets consent for personalized advertising.',
    default: 'denied',
  },
  {
    name: 'analytics_storage',
    title: 'Analytics storage',
    description:
      'Enables storage, such as cookies, related to analytics (for example, visit duration).',
    default: 'denied',
  },
]

const settings = {
  title: 'Cookies Preferences',
  description:
    'This website uses cookies and other technology to provide their services and improve user experience. Below you can find detailed information about each category of cookies in use.',
  buttons: {
    save: 'Save settings',
    close: 'Close',
  },
}

const bar = {
  title: 'Cookies & Privacy',
  description:
    'This website uses cookies and other technology to provide their services and improve user experience. You can accept all cookies usage or use settings to accept categories individually.',
  buttons: {
    accept: 'Accept all',
    settings: 'Settings',
    deny: 'Deny all',
  },
}

const Cookies = () => {
  return (
    <>
      {/* <Helmet>
        <script async src="https://consent.google.com/sdk.js"></script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=GTM-W72RJ47"></script>
      </Helmet> */}
      <CookieConsent
        mode="bar"
        wall={true}
        consentTypes={consentTypes}
        settings={settings}
        bar={bar}
        theme="light"
      />
    </>
  )
}

export default Cookies
