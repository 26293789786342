import { insert } from '../../../../utils/array';

const findHeadingIndexes = (sanityRawBodyArray) => {
  return sanityRawBodyArray.reduce((acc, currentBlock, index) => {
    // assumptions for heading block:
    // has only one child
    // the child is of type hx or the child has marks strong
    if (!currentBlock.children || !currentBlock.children.length || currentBlock.style === 'blockquote') {
      return acc;
    }
    const firstChild = currentBlock.children[0];

    const isHeading =
      currentBlock.children.length === 1 &&
      (['h1', 'h2', 'h3', 'h4'].some((heading) => firstChild._type === heading) || firstChild.marks.some((mark) => mark === 'strong'));

    return isHeading ? [...acc, index] : acc;
  }, []);
};

const suggestedArticleBlock = { _type: 'block', children: [{ _type: 'suggestedArticleBlock' }] };

export const getIndexesToInject = (headingIndexes) => {
  const length = headingIndexes.length;

  // this set of ifs is required to allow articles to appear before the last heading without the need to change algorithm
  if (length === 2) {
    return headingIndexes[1];
  }
  if (length === 3) {
    return headingIndexes[2];
  }
  if (length === 4) {
    return headingIndexes[2];
  }

  // we want to spread it evenly on article, meaning about equal distance from start and end for newsletter and injected article
  const middleIndex = Math.floor(headingIndexes.length / 2);
  return headingIndexes[middleIndex + Math.floor(middleIndex / 2)];
};

export const enhanceBlogPost = (rawBlogPostBody) => {
  const headingIndexes = findHeadingIndexes(rawBlogPostBody);
  if (!headingIndexes.length || headingIndexes.length === 1) {
    return rawBlogPostBody;
  }

  const indexToInsertSuggestedArticle = getIndexesToInject(headingIndexes);

  return insert(rawBlogPostBody, indexToInsertSuggestedArticle, suggestedArticleBlock);
};
