import React from 'react';

const IconArrowLong = () => (
  <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.9293 15.2107L17.554 10.3729L18.4459 9.38184L25.6632 15.8774L18.4459 22.3729L17.554 21.3818L22.9293 16.544H6.66663V15.2107H22.9293Z"
      fill="currentColor"
    />
  </svg>
);

export default IconArrowLong;
