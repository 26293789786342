import React from 'react';

import styled from 'styled-components';

import { breakpoints, colors } from '../../../../constants/style';
import OrderedListItems from '../../OrderedListItems';
import SectionHeader from '../../SectionHeader/SectionHeader';

const OrderedListItemsStyled = styled('section').attrs({ className: 'ordered-list-items' })`
  padding: 7rem 0;
  .ordered-list-items {
    .container {
      background-color: #fff;
      position: relative;
    }
    &__description,
    &__extra-description {
      color: #4e4d6c;
    }
    &__description {
      font-size: 1.1875rem;
      margin-bottom: 1.5rem;
    }
    &__extra-description {
      font-size: 1rem;
    }
    &__content {
      max-width: 75rem;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;

      .ordered-item {
        width: 47%;
        margin-bottom: 2.25rem;

        &:nth-child(2n-1) {
          margin-right: 5.9%;
        }
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.desktop}) {
    .ordered-list-items {
      &__content {
        flex-direction: column;
        max-width: 34rem;

        .ordered-item {
          width: 100%;

          &:nth-child(2n-1) {
            margin-right: 0;
          }
        }
      }
    }
  }
`;

const OrderedListItemsSection = ({ header, description, raw }) => {
  return (
    <OrderedListItemsStyled>
      <div className="container">
        <SectionHeader title={header} subtitle={description} />

        <div className="ordered-list-items__content">
          <OrderedListItems arr={raw.orderedListItems} />
        </div>
      </div>
    </OrderedListItemsStyled>
  );
};

export default OrderedListItemsSection;
