// solution I'm using is a modified version of https://github.com/gatsbyjs/gatsby/issues/2583#issuecomment-340722928

// I'm not relying on webpack to load fonts so they have to be added to /static/fonts!

import { createGlobalStyle } from 'styled-components';

const fonts = [
  'BioSans-Bold',
  'BioSans-Light',
  'BioSans-Regular',
  'BioSans-SemiBold',
  'Bitter-SemiBold',
  'DroidSerif',
  'Roboto-Bold',
  'Roboto-Medium',
  'Roboto-Regular',
];

const getFont = (fontName) =>
  `@font-face {
        font-family: ${fontName};
        src: url('/fonts/${fontName}.woff') format('woff');
        font-display: optional;
      }`;

const transformedFonts = fonts.map(getFont).join('\n');

export default createGlobalStyle`
${transformedFonts}
`;
