export const calculateBlogPostBodyWithSmallerImages = (body, width) =>
  body?.reduce((acc, element) => {
    if (element && element.asset) {
      const elementWithReisiedImage = {
        ...element,
        asset: {
          ...element.asset,
          _ref: `${element.asset._ref}?w=${width}`,
        },
      };
      acc.push(elementWithReisiedImage);
      return acc;
    }
    acc.push(element);
    return acc;
  }, []);
