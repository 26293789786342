import React from 'react';
import styled from 'styled-components';

import { Link } from 'gatsby';
import { blogTitleAnimationStyle, blogCategoryStyle, blogArrowAnimationStyle, blogCategoryContainerStyle } from '../../../commonStyle';
import { colors, breakpoints } from '../../../../../../constants/style';
import { parseSlug } from '../../../../../utils/slug';
import Image from '../../../../../components/Image';
import arrow from '../../../../../../static/images/icons/arrow.svg';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import getConvertedGatsbyImgDataToWEBP from '../../../../../utils/images/getConvertedGatsbyImgDataToWEBP';

const StyledHeroSecondaryArticle = styled((props) => <Link {...props} />)`
  height: 22%;
  display: flex;
  position: relative;

  :not(:last-child) {
    margin-bottom: 2rem;
  }

  .hero-secondary__image {
    flex-basis: 35%;
    background-size: cover;
    min-width: 9rem;
  }

  .hero-secondary__right-column {
    height: 100%;
    padding: 0.75rem 0 0.75rem 2rem;
    flex-basis: 70%;
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .hero-secondary__title {
    width: 100%;
    overflow: hidden;

    h2 {
      display: block;
      width: 100%;
      font-family: "Bitter-SemiBold", "BioSans-SemiBold", "Roboto-Bold", sans-serif;
      font-size: 1.375rem;
      color: ${colors.titleDark};
      ${blogTitleAnimationStyle.base};
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .hero-secondary__categories-list {
    ${blogCategoryContainerStyle}
  }

  .hero-secondary__category {
    ${blogCategoryStyle};
    margin-bottom: 0.75rem;
  }

  .hero-secondary__arrow {
    ${blogArrowAnimationStyle.base};
    width: 22px;
  }

  &:hover {
    .hero-secondary__title {
      ${blogTitleAnimationStyle.hover};
    }
    .hero-secondary__arrow {
      ${blogArrowAnimationStyle.hover}
    }
  }

  @media only screen and (max-width: ${breakpoints.desktopLarge}) {
    .hero-secondary__title {
      font-size: 1.3rem;
    }
  }

  @media only screen and (max-width: ${breakpoints.desktopMac}) {
    .hero-secondary__image {
      flex-basis: 45%;
    }
  }

  @media only screen and (max-width: ${breakpoints.desktopSmall}) {
    .hero-secondary__image {
      flex-basis: 35%;
    }

    .hero-secondary__right-column {
      min-height: 10rem;
    }
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    .hero-secondary__image {
      min-height: 10rem;
      width: 100%;
    }
  }

  @media only screen and (max-width: ${breakpoints.mobile}) {
    display: block;

    .hero-secondary__image {
      width: 100%;
    }

    .hero-secondary__right-column {
      padding-left: 0;
    }
  }
`;

const HeroSecondaryArticle = ({ blogPost }) => {
  const { title, featuredImage, categories, slug } = blogPost;
  return (
    <StyledHeroSecondaryArticle to={`/blog/${parseSlug(categories[0].slug.current)}${parseSlug(slug.current)}`}>
      {featuredImage?.asset && (
        <GatsbyImage
          image={getConvertedGatsbyImgDataToWEBP(getImage(featuredImage?.asset))}
          alt={featuredImage?.alt ?? ''}
          className="hero-secondary__image"
        />
      )}
      <div className="hero-secondary__right-column">
        <div>
          <ul className="hero-secondary__categories-list">
            {categories.map((category) => (
              <li key={category.title}>
                <h3 className="hero-secondary__category">{category.title}</h3>
              </li>
            ))}
          </ul>
          <div className="hero-secondary__title">
            <h2>{title}</h2>
          </div>
        </div>
        <Image image={arrow} className="hero-secondary__arrow" alt={title ?? ''} />
      </div>
    </StyledHeroSecondaryArticle>
  );
};

export default HeroSecondaryArticle;
