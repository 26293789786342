import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import Modal from '../index'
import { breakpoints, colors } from '../../../../constants/style'
import { useDictionaryQuery } from 'hooks/graphql/page'
import SanityBlockContent from '../../SanityBlockContent'
import StyledInput from '../../Form/Input/styled'
import Button from '../../Buttons'
import { scrollPercent } from '../../../utils/scroll'
import { isSSR } from '../../../../constants/environment'
import { on } from 'stream';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;

  width: 50%;
  max-width: 770px;
  margin-left: auto;
  margin-right: auto;
  padding: 56px 126px;

  background-color: #ffffff;

  .newsletterModal__title {
    font-size: 2.125rem;
    margin-bottom: 2rem;
    font-family: "BioSans-Regular", sans-serif;
    color: ${colors.titleDark};
  }

  .newsletterModal__description {
    margin-bottom: 2rem;
    p {
      font-family: "BioSans-Regular", sans-serif;
      font-size: 1rem;
      letter-spacing: 0.26px;
      color: ${colors.blogText};
    }
    ul {
      margin-top: 24px;
    }
  }

  .newsletterModal__form-wrapper {
    width: 100%;
  }

  .newsletterModal__form {
    display: flex;
    flex-direction: column;
    background-color: inherit;

    .submit-button {
      width: 100%;
      margin-bottom: 24px;
    }

    .close-button {
      background: none;
      color: ${colors.textLight};
      font-size: 1rem;
      line-height: 1.75rem;
      padding: 0;
      border: none;
    }
  }

  .success {
    text-align: center;
    font-family: "BioSans-Regular", sans-serif;
    font-size: 2rem;
    color: ${colors.primary};
  }

  .close {
    top: 20px;
    right: 20px;
    position: absolute;
    width: 35px;
    height: 35px;
    cursor: pointer;

    &::after,
    &::before {
      content: '';
      position: absolute;
      height: 1.7px;
      width: 100%;
      top: 50%;
      left: 0;
      margin-top: -1px;
      background: ${colors.blogText};
      transition: background 0.2s ease;
    }
    &::after {
      transform: rotate(45deg);
    }
    &::before {
      transform: rotate(-45deg);
    }
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    width: 90%;
    padding: 24px;
  }

  @media only screen and (max-width: ${breakpoints.mobile}) {
    .newsletterModal__form {
      display: block;
      margin-bottom: 1.5rem;
    }
  }
`

const NewsletterModal = () => {
  const {
    newsletterModal,
    _rawNewsletterModal,
  } = useDictionaryQuery()

  const [isVisible, setIsVisible] = useState(false)

  const openedRecently = isSSR ? true : document.cookie.includes('newsletterPopupShown=true')

  const onClose = () => {
    setIsVisible(false)
    if (!openedRecently && !isSSR) {
      document.cookie = 'newsletterPopupShown=true; max-age=86400'
    }
  }

  const goToForm = () => {
    onClose()
    document.getElementById("section-subscribe")?.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
  }

  const handleScroll = () => {
    if (scrollPercent() > 35) {
      setIsVisible(true)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  return (
    <Modal onClose={onClose} isVisible={isVisible && !openedRecently} theme={'dark'}>
      <Wrapper>
        <h2 className="newsletterModal__title">{newsletterModal.header}</h2>
        <div className="newsletterModal__description">
          <SanityBlockContent blocks={_rawNewsletterModal.description} />
        </div>
        <div className="newsletterModal__form-wrapper">
          <div className="newsletterModal__form">
            <Button className="submit-button" variant="filled" type="button" onClick={goToForm}>
              {newsletterModal.buttonLabel}
            </Button>
            <button className="close-button" type="button" onClick={onClose}>Maybe next time</button>
          </div>
        </div>
        <div className="close" onClick={onClose}></div>
      </Wrapper>
    </Modal>
  )
}

export default NewsletterModal
