import React from 'react'
import { SEO } from 'components/Seo'
import ContactLayout from '../../features/contactLayout'
import Sidebar from '../../features/contactLayout/components/Sidebar'
import ContactUs from '../../templates/contactUsPage'

const ContactUsPage = () => (
  <ContactLayout sidebar={<Sidebar />}>
    <ContactUs />
  </ContactLayout>
)

export function Head({ location }) {
  return <SEO location={location} title="Contact us" isStructuredDataAdded={false} />
}

export default ContactUsPage
