import React from 'react';
import { NavLinkButton } from '../../../components/Link';
import StyledContactUsPage from '../styled';
import check from '../../../../static/images/icons/ico_check.svg';
import StepItem from './StepItem';

const ThankYou = () => {
  const expectedSteps = [
    {
      content: 'Making an appointment',
      comment: 'You will receive an email to confirm your meeting with our specialist (via Zoom)',
    },
    {
      content: 'Consultation meeting',
      comment: 'During the meeting we will focus on getting to know your needs in detail',
    },
    {
      content: 'Kick-off plan',
      comment: 'After getting to know your needs, our team will create a plan of action for you',
    },
  ];

  return (
    <StyledContactUsPage>
      <div className="contact-us__column-right">
        <div className="thank-you">
          <div className="thank-you__title">
            <img src={check} alt="check" height={25} width={25} />
            <h2>Message Sent!</h2>
          </div>
          <p className="thank-you__message">Thank you, we'll get back to you within one business day.</p>
          <hr className="thank-you__horizontal-line" />
          <h3 className="thank-you__subtitle">Here's what you can expect next</h3>
          {expectedSteps.map((step, index) => (
            <StepItem key={index} index={String(index + 1).padStart(2, '0')} content={step.content} comment={step.comment} />
          ))}
          <hr className="thank-you__horizontal-line" />
          <NavLinkButton to="/" variant="filled" className="thank-you__button">
            BACK TO HOME PAGE
          </NavLinkButton>
        </div>
      </div>
    </StyledContactUsPage>
  );
};

export default ThankYou;
