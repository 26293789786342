const contact = [
  {
    title: 'New Business',
    phone: '+48 507 096 183',
  },
  {
    title: 'NIP',
    description: '113 285 22 55',
  },
]

export default contact
