import React, { useState } from 'react'
import Image from '../../Image'
import Button from '../../Buttons'
import { useDictionaryQuery } from 'hooks/graphql/page'
import { useWhitepaperResourcesQuery } from 'hooks/graphql/section'
import DownloadFileModal from './components/DownloadFileModal'
import ResourcesSection from './style'

const Resources = ({ header, resources }) => {
  const {
    resources: { downloadResourceLabel },
  } = useDictionaryQuery()
  const whitepapers = useWhitepaperResourcesQuery()

  const [selectedFile, setSelectedFile] = useState(null)
  const [selectedFileTitle, setSelectedFileTitle] = useState('')

  const handleOnClose = () => setSelectedFile(null)
  const handleOnComplete = () => setSelectedFile(null)

  return (
    <ResourcesSection>
      <h2 className="resources__header">{header}</h2>
      <ul className="resources__wrapper">
        {[...whitepapers, ...resources].map(({ title, bullets, file, image, id }, index) => (
          <li key={index} className="resource__wrapper">
            <Image image={image} className="resource__image" />
            <div className="resource__text-wrapper">
              <h3 className="resource__title">{title}</h3>
              <ul className="resource__bullets">
                {bullets.map((bullet, index) => (
                  <li key={index}>{bullet}</li>
                ))}
              </ul>
              <Button
                variant="filled"
                onClick={() => {
                  setSelectedFile(file.asset.url)
                  setSelectedFileTitle(title)
                }}
              >
                {downloadResourceLabel}
              </Button>
            </div>
          </li>
        ))}
      </ul>
      <DownloadFileModal
        selectedFile={selectedFile}
        handleOnClose={handleOnClose}
        handleOnComplete={handleOnComplete}
        titleOfSelectedFile={selectedFileTitle}
      />
    </ResourcesSection>
  )
}

export default Resources
