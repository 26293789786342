import React from 'react'
import { useFormContext } from 'react-hook-form'
import PropTypes from 'prop-types'
import {
  StyledErrorMsg,
  StyledPreLabel,
  StyledErrorContainer,
  StyledReqAsterisk,
} from '../commonFormStyle'
import { StyledDiv } from './styled'

function Input({
  label = '',
  type = 'text',
  placeholder = '',
  preLabel = '',
  inputName = '',
  required = false,
  validationOptions = {},
  showError = true,
  onChange,
}) {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  const dynamicErrorMessage = `Field is required`

  return (
    <StyledDiv isError={showError && !!errors[inputName]}>
      <label className="input-label">
        {required && <StyledReqAsterisk>*&nbsp;</StyledReqAsterisk>}
        {preLabel && (
          <StyledPreLabel isError={showError && !!errors[inputName]}>
            {preLabel}&nbsp;
          </StyledPreLabel>
        )}
        {label}
      </label>
      <input
        type={type}
        {...register(inputName, { required, ...validationOptions })}
        placeholder={placeholder}
        onChange={onChange}
      />
      <StyledErrorContainer>
        {showError && errors[inputName] && (
          <StyledErrorMsg>{errors[inputName].message || dynamicErrorMessage}</StyledErrorMsg>
        )}
      </StyledErrorContainer>
    </StyledDiv>
  )
}

Input.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  preLabel: PropTypes.string,
  inputName: PropTypes.string.isRequired,
  required: PropTypes.bool,
  validationOptions: PropTypes.object,
  showError: PropTypes.bool,
  onChange: PropTypes.func,
}

export default Input
