import React, { lazy } from 'react'
import { convertToBackgroundImage } from '../../utils/image'

const Hero = lazy(() => import('../../components/sections/Hero'))
const Clients = lazy(() => import('../../components/sections/Clients'))
const Advantages = lazy(() => import('../../components/sections/Advantages'))
const FeaturedOn = lazy(() => import('../../components/sections/FeaturedOn'))
const Why10Clouds = lazy(() => import('../../components/sections/Why10Clouds'))
const Awards = lazy(() => import('../../components/sections/Awards'))
const RedirectBanner = lazy(() => import('../../components/sections/RedirectBanner'))
const Pillars = lazy(() => import('../../components/sections/Pillars'))
const TableSection = lazy(() => import('../../components/sections/TableSection'))
const Solutions = lazy(() => import('../../components/sections/Solutions'))
const ProcessOfWork = lazy(() => import('../../components/sections/ProcessOfWork'))
const BulletParagraph = lazy(() => import('../../components/sections/BulletParagraph'))
const Versus = lazy(() => import('../../components/sections/Versus'))
const EmployeesList = lazy(() => import('../../components/sections/EmployeesList'))
const PeopleList = lazy(() => import('../../components/sections/PeopleList'))
const AuthorsList = lazy(() => import('../../components/sections/AuthorsList'))
const MainGuest = lazy(() => import('../../components/sections/MainGuest'))
const TechnologyDetails = lazy(() => import('../../components/sections/TechnologyDetails'))
const ProcessOfDevelopment = lazy(() => import('../../components/sections/ProcessOfDevelopment'))
const ImageSection = lazy(() => import('../../components/sections/ImageSection'))
const CaseStudies = lazy(() => import('../../components/sections/CaseStudies'))
const WhatAreYouLookingFor = lazy(() => import('../../components/sections/WhatAreYouLookingFor'))
const Technologies = lazy(() => import('../../components/sections/Technologies'))
const Testimonials = lazy(() => import('../../components/sections/Testimonials'))
const Showcase = lazy(() => import('../../components/sections/Showcase'))
const Articles = lazy(() => import('../../components/sections/Articles'))
const PastEvents = lazy(() => import('../../components/sections/PastEvents'))
const ContactUs = lazy(() => import('../../components/sections/ContactUs'))
const OtherServices = lazy(() => import('../../components/sections/OtherServices'))
const ProcessOfDelivery = lazy(() => import('../../components/sections/ProcessOfDelivery'))
const Paragraph = lazy(() => import('../../components/sections/Paragraph'))
const ParagraphVideo = lazy(() => import('../../components/sections/ParagraphVideo'))
const ProjectExamples = lazy(() => import('../../components/sections/ProjectExamples'))
const DownloadSection = lazy(() => import('../../components/sections/DownloadSection'))
const Statistics = lazy(() => import('../../components/sections/Statistics'))
const ModalSection = lazy(() => import('../../components/sections/ModalSection'))
const Slogan = lazy(() => import('../../components/sections/Slogan'))
const AgileAproach = lazy(() => import('../../components/sections/AgileAproach'))
const HeroWithContactForm = lazy(() => import('../../components/sections/HeroWithContactForm'))
const JobOffers = lazy(() => import('../../components/sections/JobOffers'))
const BlockSection = lazy(() => import('../../components/sections/BlockSection'))
const ScrollSlider = lazy(() => import('../../components/sections/ScrollSlider'))
const Benefits = lazy(() => import('../../components/sections/Benefits'))
const TechnologySummary = lazy(() => import('../../components/sections/TechnologySummary'))
const Perks = lazy(() => import('../../components/sections/Perks'))
const Resources = lazy(() => import('../../components/sections/Resources'))
const ClickableImageWithURL = lazy(() => import('../../components/sections/ClickableImageWithURL'))
const RichTextBox = lazy(() => import('../../components/sections/RichTextBox'))
const ReferralFormSection = lazy(() => import('../../components/sections/ReferralFormSection'))
const HeroForEvent = lazy(() => import('../../components/sections/HeroForEvent'))
const HeroForPlaybook = lazy(() => import('../../components/sections/HeroForPlaybook'))
const OrderedListItemsSection = lazy(() =>
  import('../../components/sections/OrderedListItemsSection'),
)
const PartnersSection = lazy(() => import('../../components/sections/Partners'))
const ProcessListSection = lazy(() => import('../../components/sections/ProcessList'))
const ServicesDropdownList = lazy(() => import('../../components/sections/ServicesDropdownList/'))
const WhatYouWillLearn = lazy(() => import('../../components/sections/WhatYouWillLearn/'))
const TestimonialsAgile = lazy(() => import('../../components/sections/TestimonialsAgile'))
const ParagraphAgile = lazy(() => import('../../components/sections/ParagraphAgile'))
const AgilePlaybookNavigation = lazy(() => import('../../components/AgilePlaybookNavigation'))
const TilesSection = lazy(() => import('../../components/sections/TilesSection'))
const YoutubeSection = lazy(() => import('../../components/sections/YoutubeSection'))
const RedirectSection = lazy(() => import('../../components/sections/RedirectSection'))
const StickyBadge = lazy(() => import('../../components/StickyBadge'))
const Accordion = lazy(() => import('../../components/sections/Accordion'))
const HeroForCaseStudy = lazy(() =>
  import('../../templates/caseStudyPage/components/HeroForCaseStudy'),
)
const OverviewForCaseStudy = lazy(() =>
  import('../../templates/caseStudyPage/components/OverviewForCaseStudy'),
)
const SuggestedCaseStudies = lazy(() =>
  import('../../templates/caseStudyPage/components/SuggestedCaseStudies'),
)
const ProjectInNumbersSection = lazy(() =>
  import('../../components/sections/ProjectInNumbersSection'),
)
const SubscribeSectionNew = lazy(() => import('../../components/sections/SubscribeToNewsletter/SubscribeToNewsletterNew'));

const sectionsConfig = {
  hero: {
    condition: () => true,
    template: ({ section, index }) => {
      const bgImage = convertToBackgroundImage(
        section?.backgroundImage && section.backgroundImage.asset,
      )
      return (
        <Hero
          header={section.title}
          preheader={section.pretitle}
          description={section.description}
          contactButtonText={section.contactButtonText}
          rightColumnImage={section.mainImage}
          isRightColumnImageOverlapping={section.isRightColumnImageOverlapping}
          backgroundImage={bgImage?.fluid}
          backgroundVideo={section.backgroundVideo}
          showScroll
          fullScreen
          key={index}
          scrollToQuerySelector={section.scrollToQuerySelector}
        />
      )
    },
  },

  clientsSection: {
    condition: (section) => Boolean(section && section.clientsList?.length),
    template: ({ section, index }) => (
      <Clients header={section.header} clients={section.clientsList} key={index} />
    ),
  },
  advantagesSection: {
    condition: (section) => Boolean(section && section.clientsList?.length),
    template: ({ section }) => <Advantages {...section} />,
  },
  whySection: {
    condition: (section) => Boolean(section && section.reasonsToWorkWith.length),
    template: ({ section }) => (
      <Why10Clouds
        header={section.header}
        description={section.description}
        reasons={section.reasonsToWorkWith || []}
      />
    ),
  },
  bulletParagraphSection: {
    condition: (section) => Boolean(section && section.items.length),
    template: ({ section, rawSection }) => (
      <BulletParagraph
        header={section.title}
        description={section.description}
        items={section.items}
        rawItems={rawSection.items}
        rawExample={rawSection.example}
        exampleImage={section.example && section.example.image}
      />
    ),
  },
  versusSection: {
    condition: (section) => Boolean(section && section.elements.length),
    template: ({ section, rawSection }) => (
      <Versus
        title={section.title}
        description={section.description}
        elements={section.elements}
        rawElements={rawSection.elements}
      />
    ),
  },
  solutionsSection: {
    condition: (section) => Boolean(section && section.solutions.length),
    template: ({ section }) => (
      <Solutions
        header={section.header}
        description={section.description}
        solutions={section.solutions}
      />
    ),
  },
  processOfWorkSection: {
    condition: (section) => Boolean(section && section.items.length),
    template: ({ section, rawSection }) => (
      <ProcessOfWork
        theme={section.theme}
        header={section.header}
        description={rawSection.description}
        items={section.items}
        raw={rawSection}
      />
    ),
  },
  processOfDeliverySection: {
    condition: (section) => Boolean(section && section._rawHeader && section.items.length),
    template: ({ section }) => (
      <ProcessOfDelivery header={section._rawHeader} items={section.items} />
    ),
  },
  paragraphSection: {
    condition: () => true,
    template: ({ section, rawSection, isPreviousSectionOfSameType }) => (
      <Paragraph
        theme={section.theme}
        decorate={section.decorate}
        removeBottomMargin={section.removeBottomMargin}
        customBulletListImage={section.customBulletListImage}
        header={section.header}
        description={rawSection.description}
        headerColored={rawSection.headerColored}
        image={section.image}
        position={section.position}
        // potentially to check if there are other adjecent paragraph sections
        isFirst
        isLast
        backgroundBorderColor={'#1effc2'}
        overlap={isPreviousSectionOfSameType}
        shouldDisplayButton={section.shouldDisplayButton}
        shouldButtonOpenForm={section.shouldButtonOpenForm}
        buttonLabel={section.buttonLabel}
        url={section.link}
        file={section.file}
        video={section.video}
        imageWithUrl={section.clickableImage}
      />
    ),
  },
  paragraphVideoSection: {
    condition: () => true,
    template: ({ section, rawSection, isPreviousSectionOfSameType }) => (
      <ParagraphVideo
        theme={section.theme}
        header={section.header}
        description={rawSection.description}
        linkToVideo={section.linkToVideo}
      />
    ),
  },
  referralFormSection: {
    condition: () => true,
    template: ({ section, rawSection }) => (
      <ReferralFormSection theme={section.theme} header={section.header} raw={rawSection} />
    ),
  },
  whatYouWillLearnSection: {
    condition: (section) => Boolean(section.items?.length),
    template: ({ section }) => <WhatYouWillLearn header={section.header} items={section.items} />,
  },
  processListSection: {
    condition: () => true,
    template: ({ section }) => (
      <ProcessListSection
        header={section.header}
        items={section.items}
        theme={section.theme}
        shouldDisplayNumbers={section.shouldDisplayNumbers}
      />
    ),
  },
  servicesDropdownList: {
    condition: () => true,
    template: ({ section }) => (
      <ServicesDropdownList header={section.header} listOfServices={section.listOfServices} />
    ),
  },
  orderedListItemsSection: {
    condition: () => true,
    template: ({ section, rawSection }) => (
      <OrderedListItemsSection
        header={section.header}
        description={section.description}
        raw={rawSection}
      />
    ),
  },
  whatAreYouLookingForSection: {
    condition: () => true,
    template: ({ section, rawSection }) => (
      <WhatAreYouLookingFor
        header={section.header}
        leftEntry={section.leftEntry}
        rightEntry={section.rightEntry}
        raw={rawSection}
      />
    ),
  },
  technologyDetailsSection: {
    condition: (section) => Boolean(section && section.header && section.details.length),
    template: ({ section }) => (
      <TechnologyDetails
        header={section.header}
        description={section.description}
        details={section.details}
      />
    ),
  },
  technologiesSection: {
    condition: (section) =>
      Boolean(
        section &&
          section.header &&
          (section.technologiesList.length || section.additionalTechnologies.length),
      ),
    template: ({ section, rawSection }) => (
      <Technologies
        header={section.header}
        description={rawSection.description}
        previousSectionBackgroundColor={section.previousSectionBackgroundColor}
        technologiesList={section.technologiesList}
        raw={rawSection}
        additionalTechnologies={section.additionalTechnologies}
      />
    ),
  },
  projectExamplesSection: {
    condition: (section) => Boolean(section && section.projectExamples.length),
    component: () => import('../../components/sections/ProjectExamples'),
    template: ({ section }) => (
      <ProjectExamples header={section.header} projectExamplesEntries={section.projectExamples} />
    ),
  },
  caseStudiesSection: {
    condition: (section) => Boolean(section && section.caseStudies.length),
    component: () => import('../../components/sections/CaseStudies'),
    template: ({ section, rawSection }) => (
      <CaseStudies
        header={section.header}
        subtitle={section.subtitle}
        caseStudiesEntries={section.caseStudies}
        raw={rawSection}
      />
    ),
  },
  testimonialsSection: {
    condition: (section) => Boolean(section?.testimonials?.length),
    template: ({ section }) => (
      <Testimonials header={section.header} testimonials={section.testimonials} />
    ),
  },
  testimonialsAgileSection: {
    condition: (section) => Boolean(section && section.testimonials.length),
    template: ({ section, rawSection }) => (
      <TestimonialsAgile
        header={section.header}
        description={rawSection.description}
        testimonials={section.testimonials}
        theme={section.theme}
      />
    ),
  },
  paragraphAgileSection: {
    condition: (section) => Boolean(section),
    template: ({ section, rawSection }) => (
      <ParagraphAgile
        orderNumber={section.orderNumber}
        header={section.header}
        subtitle={rawSection.subtitle}
        content={rawSection.content}
        isIntersected={section.isIntersected}
        isAfterIntersection={section.isAfterIntersection}
      />
    ),
  },
  agilePlaybookNavigation: {
    condition: (section) => section.items.length,
    template: ({ section }) => <AgilePlaybookNavigation items={section.items} />,
  },
  partnersSection: {
    condition: (section) => Boolean(section && section.partners.length),
    template: ({ section }) => (
      <PartnersSection header={section.header} partners={section.partners} />
    ),
  },
  showcaseSection: {
    condition: (section) => Boolean(section && (section.vimeo.length || section.youtube.length)),
    component: () => import('../../components/sections/Showcase'),
    template: ({ section }) => (
      <Showcase
        theme={section.theme ? section.theme : 'dark'}
        header={section.header ? section.header : ''}
        // dla mobilki nie ma tu czegoś
        buttonLabel={section.button ? section.button.label : ''}
        buttonLink={section.button ? section.button.link : ''}
        vimeoMovieIds={section.vimeo}
        youtubeMovieIds={section.youtube}
      />
    ),
  },
  awardsSection: {
    condition: (section) => Boolean(section && section.awards.length),
    template: ({ section, rawSection }) => (
      <Awards
        header={section.header}
        imagePosition={section.imagePosition}
        image={section.image}
        awards={section.awards}
        raw={rawSection}
      />
    ),
  },
  redirectBannerSection: {
    condition: (section) =>
      Boolean(
        section &&
          section.header &&
          section.mainImage &&
          section.link &&
          section.description &&
          section.label,
      ),
    template: ({ section, rawSection }) => (
      <RedirectBanner
        header={section.header}
        image={section.mainImage}
        link={section.link}
        description={section.description}
        label={section.label}
        raw={rawSection}
      />
    ),
  },
  linkedServicesSection: {
    condition: (section) => Boolean(section.servicesPages.length),
    template: ({ section }) => (
      <OtherServices header={section.header} services={section.servicesPages} />
    ),
  },
  featuredOnSectionForCustomPage: {
    condition: (section) => section.isVisible,
    component: () => import('../../components/sections/FeaturedOn'),
    template: ({}) => <FeaturedOn />,
  },
  downloadSection: {
    condition: (section) => Boolean(section.buttonLabel),
    template: ({ section }) => (
      <DownloadSection
        theme={section.theme}
        header={section.header}
        description={section.description}
        buttonLabel={section.buttonLabel}
        file={section.file}
      />
    ),
  },
  contactSection: {
    condition: (section) => Boolean(section.buttonLabel),
    template: ({ section }) => (
      <ContactUs
        theme={section.theme}
        header={section.header}
        description={section.description}
        buttonLabel={section.buttonLabel}
        scrollTopOnClick={section.scrollTop}
        url={section.link}
        middle={section.middle}
        shouldDisplayEmployee={section.shouldDisplayEmployee}
        employee={section.employee}
      />
    ),
  },
  richTextBox: {
    condition: () => true,
    template: ({ section, rawSection }) => (
      <RichTextBox
        theme={section.theme}
        header={section.header}
        middle={section.middle}
        rawDescription={rawSection.description}
      />
    ),
  },
  processOfDevelopmentSection: {
    condition: (section) => Boolean(section.title),
    template: ({ section }) => <ProcessOfDevelopment {...section} />,
  },
  imageSection: {
    condition: (section) => Boolean(section.image),
    template: ({ section }) => <ImageSection {...section} />,
  },
  pillarsSection: {
    condition: (section) => Boolean(section.pillars.length),
    component: () => import('../../components/sections/Pillars'),
    template: ({ section, rawSection }) => <Pillars pillars={section.pillars} raw={rawSection} />,
  },
  statisticSection: {
    condition: () => true,
    template: ({ section }) => (
      <Statistics
        header={section.header}
        description={section.description}
        statisticsWithoutIcon={section.statisticsWithoutIcon}
        statisticsWithIcon={section.statisticsWithIcon}
      />
    ),
  },
  modalSection: {
    condition: () => true,
    template: ({ section }) => (
      <ModalSection
        header={section.header}
        description={section.description}
        buttonLabel={section.buttonLabel}
        buttonLink={section.buttonLink}
      />
    ),
  },
  sloganSection: {
    condition: () => true,
    template: ({ section, rawSection }) => (
      <Slogan
        title={section.title}
        description={section.description}
        rawDescription={rawSection.description}
      />
    ),
  },
  agileApproachSection: {
    condition: (section) => Boolean(section.entries.length),
    template: ({ section }) => (
      <AgileAproach
        title={section.title}
        description={section.description}
        image={section.image}
        entries={section.entries}
      />
    ),
  },
  articlesSection: {
    condition: (section) =>
      Boolean(section.blogPosts && section.blogPosts.length && section.header),
    template: ({ section, rawSection }) => (
      <Articles header={section.header} raw={rawSection} blogPosts={section.blogPosts} />
    ),
  },
  pastEventsSection: {
    condition: (section) => Boolean(section.voices && section.voices.length && section.header),
    template: ({ section, rawSection }) => (
      <PastEvents header={section.header} raw={rawSection} voices={section.voices} />
    ),
  },
  tilesSection: {
    condition: () => true,
    template: ({ section, rawSection }) => (
      <TilesSection
        header={section.header}
        description={rawSection.description}
        tiles={section.tilesList}
      />
    ),
  },
  youtubeSection: {
    condition: () => true,
    template: ({ section, rawSection }) => (
      <YoutubeSection
        header={section.header}
        description={rawSection.description}
        posts={section.youtubeList}
        seeMoreButton={section.button}
      />
    ),
  },
  stickyBadge: {
    condition: () => true,
    template: ({ section }) => (
      <StickyBadge
        header={section.header}
        description={section.description}
        button={section.button}
      />
    ),
  },
  heroWithContactFormSection: {
    condition: () => true,
    template: ({ section, rawSection }) => (
      <HeroWithContactForm
        heroHeader={section.heroTitle}
        heroDescription={section.heroDescription}
        formName={section.form.formName}
        formHeader={section.form.header}
        formSubheader={section.form.subheader}
        showProjectDescriptionInput={section.form.showProjectDescriptionInput}
        fileUrl={section.form.file && section.form.file.asset.url}
        rawForm={rawSection.form}
      />
    ),
  },
  subscribeSectionForCustomPage: {
    condition: (section) => section.isVisible,
    template: ({ section }) => <SubscribeSectionNew theme={section.theme} />,
  },
  jobOffersSection: {
    condition: () => true,
    template: ({ section }) => (
      <JobOffers title={section.title} description={section.description} />
    ),
  },
  blockSection: {
    condition: () => true,
    template: ({ index, rawSection }) => (
      <BlockSection content={rawSection.content} isFirstSection={index === 0} />
    ),
  },
  scrollSliderSection: {
    condition: (section) => Boolean(section && section.items.length),
    template: ({ section }) => {
      return (
        <ScrollSlider
          title={section.title}
          description={section.description}
          items={section.items}
          rawItems={section._rawItems}
        />
      )
    },
  },
  benefitsSection: {
    condition: (section) => Boolean(section && section.benefits.length),
    template: ({ section }) => <Benefits header={section.header} benefits={section.benefits} />,
  },
  technologySummarySection: {
    condition: (section) => Boolean(section && section.summaryElements.length),
    template: ({ section }) => (
      <TechnologySummary header={section.header} items={section.summaryElements} />
    ),
  },
  perksSection: {
    condition: () => true,
    template: ({ section, rawSection }) => (
      <Perks
        header={section.header}
        perks={section.perks}
        rawPerks={rawSection.perks}
        theme={section.theme}
        description={rawSection.description}
      />
    ),
  },
  tableSection: {
    condition: () => true,
    template: ({ section }) => (
      <TableSection
        header={section.header}
        description={section.description}
        table={section.table}
        highlightedWords={section.highlightedWords}
      />
    ),
  },
  employeesListSection: {
    condition: () => true,
    template: ({ section, rawSection }) => (
      <EmployeesList
        header={section.header}
        description={rawSection.description}
        employees={section.employees}
      />
    ),
  },
  peopleListSection: {
    condition: () => true,
    template: ({ section, rawSection }) => (
      <PeopleList
        header={section.header}
        description={rawSection.description}
        employees={section.employees}
        people={section.people}
      />
    ),
  },

  authorsListSection: {
    condition: () => true,
    template: ({ section, rawSection }) => (
      <AuthorsList
        header={section.header}
        description={rawSection.description}
        authorsEmployees={section.authorsEmployees}
        authorsPeople={section.authorsPeople}
        contributorsHeader={section.contributorsHeader}
        contributorsEmployees={section.contributorsEmployees}
        contributorsPeople={section.contributorsPeople}
      />
    ),
  },

  mainGuestSection: {
    condition: () => true,
    template: ({ section, rawSection }) => (
      <MainGuest
        header={section.header}
        subtitle={rawSection.subtitle}
        guests={section.guests}
        logo={section.logo}
        text={rawSection.text}
      />
    ),
  },
  resourcesListSection: {
    condition: () => true,
    template: ({ section }) => <Resources header={section.header} resources={section.resources} />,
  },
  clickableEnhancedImage: {
    condition: () => true,
    template: ({ section }) => (
      <ClickableImageWithURL
        alt={section.alt}
        asset={section.asset}
        url={section.url}
        caption={section.caption}
      />
    ),
  },
  heroForEvent: {
    condition: () => true,
    template: ({ section }) => {
      const bgImage = convertToBackgroundImage(
        section?.backgroundImage && section.backgroundImage.asset,
      )

      return (
        <HeroForEvent
          preheader={section.preTitle}
          header={section.heroTitle}
          postheader={section.postTitle}
          extratext={section.extraText}
          eventDate={section.eventDate}
          videoUrl={section.linkToVideo}
          typeOfRegistration={section.typeOfRegistration}
          addToGoogleCalendarUrl={section.addToGoogleCalendarUrl}
          addToAppleCalendarUrl={section.addToAppleCalendarUrl}
          addToOffice365CalendarUrl={section.addToOffice365CalendarUrl}
          addToOutlookCalendarUrl={section.addToOutlookCalendarUrl}
          backgroundImage={bgImage?.fluid}
        />
      )
    },
  },
  heroForPlaybook: {
    condition: () => true,
    template: ({ section }) => {
      const bgImage = convertToBackgroundImage(
        section?.backgroundImage && section.backgroundImage.asset,
      )

      return (
        <HeroForPlaybook
          backgroundImage={bgImage?.fluid}
          pretitle={section.preTitle}
          title={section.heroTitle}
        />
      )
    },
  },
  heroForCaseStudy: {
    condition: () => true,
    template: ({ section, rawSection }) => {
      return (
        <HeroForCaseStudy
          backgroundImage={section.featuredImage}
          logo={section.logo}
          title={rawSection.title}
        />
      )
    },
  },
  servicesSection: {
    condition: () => true,
    template: ({ section, rawSection }) => (
      <RedirectSection
        sectionSubtitle={rawSection.subtitle}
        sectionTitle={section.title}
        redirectList={section.servicesList}
        redirectButtonLabel={section.redirectButton?.label}
        redirectButtonUrl={section.redirectButton?.link}
      />
    ),
  },
  accordionSection: {
    condition: () => true,
    template: ({ section, rawSection }) => (
      <Accordion
        title={section.title}
        description={rawSection.description}
        accordionList={section.accordionList}
      />
    ),
  },
  overviewForCaseStudy: {
    condition: () => true,
    template: ({ section, rawSection }) => (
      <OverviewForCaseStudy
        description={rawSection.description}
        timeline={section.timeline}
        websiteName={section.website?.label}
        websiteLink={section.website?.url}
        country={section.country}
        industry={section.industry?.name}
        servicesList={section.services}
        projectType={section.type}
        technologies={section.technologies}
      />
    ),
  },
  suggestedCaseStudySections: {
    condition: () => true,
    template: ({ section, rawSection }) => (
      <SuggestedCaseStudies
        title={section.title}
        description={rawSection.description}
        numberOfSuggestions={section.numberOfSuggestions}
      />
    ),
  },
  projectInNumbersSection: {
    condition: () => true,
    template: ({ section }) => (
      <ProjectInNumbersSection title={section.title} numbersList={section.numbersList} />
    ),
  },
}

export default sectionsConfig
