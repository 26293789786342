import React from 'react';
import styled from 'styled-components';
import { breakpoints, colors } from '../../../../constants/style';
import Image from '../../Image';
import SanityBlockContent from '../../SanityBlockContent';

const leftHorizontalSpace = '9.5rem';
const headerFontSize = '1.375rem';

const VersusElementHeader = styled('h3')`
  position: relative;
  display: flex;
  align-items: center;
  background-image: linear-gradient(100deg, #571dc0 -9%, #2f0b7c 74%);
  font-weight: 600;
  color: ${colors.sectionTitleLight};
  margin: 1.3rem 0;
  font-size: 1.6rem;

  img {
    max-height: 3.875rem;
    margin: 1.1875rem 3.0625rem;
  }

  span {
    position: absolute;
    /* for IE only */
    top: calc(50% - (${headerFontSize} / 2));
    left: ${leftHorizontalSpace};
    font-size: ${headerFontSize};
    line-height: 0.91;
    font-family: "BioSans-SemiBold", sans-serif;
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    flex-flow: column nowrap;
    padding-bottom: 2rem;
    font-size: 1.2rem;
    span {
      position: static;
      padding: 0 1rem;
      text-align: center;
    }
  }
`;

const VersusElementContent = styled('div')`
  display: flex;
  flex-flow: row wrap;
  background-color: #1f0c49;
`;

const VersusItem = styled('div')`
  flex-basis: 0px;
  flex-grow: 1;

  margin: 5rem ${leftHorizontalSpace};
  &:first-of-type {
    margin-right: 0;
  }

  h4 {
    color: ${colors.sectionTitleLight};
    font-size: 1.25rem;
    margin-bottom: 1.8rem;
    font-family: "BioSans-SemiBold", sans-serif;
  }

  .item-description {
    color: ${colors.mutedLighter};
    text-align: left;
    > * {
      font-size: 1.0625rem;
      line-height: 2.24;

      &:not(:first-of-type) {
        margin-top: 1rem;
      }
    }
    ul {
      padding-left: 2.1rem;
      position: relative;
      line-height: 2;

      li {
        margin: 0.3rem 0;
      }

      li::before {
        content: '';
        display: inline-block;
        width: 7px;
        height: 7px;
        border: 2px solid ${colors.primary};
        border-radius: 50%;
        margin-left: -2.1rem;
        margin-right: 1.3rem;
        vertical-align: middle;
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.desktop}) {
    flex-basis: 100%;
    margin: 3rem 5rem;

    &:first-of-type {
      margin-right: 5rem;
      margin-bottom: 0;
    }
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    margin: 2rem 1rem;

    &:first-of-type {
      margin-right: 0;
      margin-bottom: 0;
    }
  }
`;

export const VersusElement = ({ icon, header, rawItems }) => (
  <div>
    <VersusElementHeader>
      <Image image={icon} />
      <span>{header}</span>
    </VersusElementHeader>
    <VersusElementContent>
      {rawItems.map(({ header: itemHeader, description }, index) => (
        <VersusItem key={index}>
          <h4>{itemHeader}</h4>
          <SanityBlockContent className="item-description" blocks={description} />
        </VersusItem>
      ))}
    </VersusElementContent>
  </div>
);

export default VersusElement;
