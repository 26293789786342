import React from 'react';

import { NavLinkButton } from '../../../components/Link';
import Image from '../../../components/Image';
import styled from 'styled-components';
import { colors, breakpoints } from '../../../../constants/style';
import { parseSlug } from '../../../utils/slug';
import ArrowSvg from '../../../../static/images/icons/arrow-white-2.svg';
import ArrowDarkSvg from '../../../../static/images/icons/arrow--gray.svg';
import CircleSvg from '../../../../static/images/circle.svg';
import CirclesSvg from '../../../../static/images/circle-shape-3.svg';

const StyledServicesItem = styled.div`
  min-height: 390px;
  height: 100%;
  padding-top: 60px;
  max-width: 550px;
  margin: 0 auto;
  position: relative;

  @media only screen and (max-width: ${breakpoints.mobile}) {
    overflow: hidden;
  }

  ${({ index }) =>
    index === 1 &&
    `
    ::after {
      content: url("${CircleSvg}");
      display: block;
      position: absolute;
      right: -120px;
      top: -30px;
      z-index: -1;
    }
  `}

  ${({ index }) =>
    index === 3 &&
    `
    ::after {
      content: url("${CirclesSvg}");
      display: block;
      position: absolute;
      right: -150px;
      bottom: -140px;
      z-index: -1;
      transform: scaleX(-1);
    }
  `}

${({ index }) =>
    index === 8 &&
    `
    ::after {
      content: url("${CirclesSvg}");
      display: block;
      position: absolute;
      left: -100px;
      bottom: -160px;
      z-index: -1;
    }
  `}

  .link {
    position: relative;

    &:after {
      content: url('${ArrowSvg}');
      display: inline-block;
      position: absolute;
      right: 1rem;
    }
  }

  @media only screen and (min-width: ${breakpoints.desktop}) {
    .link {
      &:after {
        content: url('${ArrowDarkSvg}');
      }
    }

    :hover {
      .link {
        opacity: 1;

        &:after {
          content: url('${ArrowSvg}');
        }
      }

      .link {
        background: linear-gradient(90deg, #8658eb, #6831e4);

        span {
          color: ${colors.sectionTitleLight};
        }
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.desktopLarge}) {
    .service-item__top-wrapper {
      .service-item__header {
        font-size: 2.125rem;
      }

      .service-item__description {
        font-size: 0.9375rem;
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    .service-item__top-wrapper {
      padding: 1.25rem 1.25rem 0;
    }
  }
`;

export const Content = styled.div`
  height: calc(100% - 56px);
  background-color: ${colors.backgroundLight};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 110px 40px 0;
  text-align: center;

  .services-item-img-wrapper {
    top: -80px;
    position: absolute;
    height: 175px;
    margin-top: 15px;

    img {
      height: 175px;
    }
  }
`;

export const H2 = styled.h2`
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 14px;
  color: ${colors.titleDark};
`;

export const Text = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: ${colors.muted};
  margin-bottom: 20px;

  a {
    text-decoration: underline;
    color: ${colors.sectionTitleDark};
  }
`;

export const ButtonWrapper = styled.div`
  height: 56px;

  .link {
    transition: all 0.2s ease-out;

    @media only screen and (max-width: ${breakpoints.desktop}) {
      opacity: 1;
    }
  }
`;

const NavLink = styled(NavLinkButton)`
  @media only screen and (min-width: ${breakpoints.desktop}) {
    background: #f4f4fb;

    span {
      color: ${colors.mutedDark};
    }
  }
`;

// jedynie trzeba teraz dodać animacje pojawiania się na od dołu (jak w technologies)
const ServicesItem = ({ slug, serviceName, serviceDescription, serviceIcon, index }) => {
  return (
    <StyledServicesItem index={index}>
      <Content>
        <Image image={serviceIcon} alt={`${serviceName}-logo`} className="services-item-img-wrapper" />
        <H2 className="service-item__header">{serviceName}</H2>
        <Text className="service-item__description" dangerouslySetInnerHTML={{ __html: serviceDescription }} />
      </Content>
      <ButtonWrapper className="button-wrapper">
        <NavLink to={`/services/${parseSlug(slug)}`} variant="filled">
          Learn more
        </NavLink>
      </ButtonWrapper>
    </StyledServicesItem>
  );
};

export default ServicesItem;
