import { useStaticQuery, graphql } from 'gatsby'

// TODO: this will take first entry, to be changed when languages are introduced
export const useDictionaryQuery = () => {
  const data = useStaticQuery(graphql`
    {
      sanityDictionary(language: { eq: "en" }) {
        language
        caseStudy {
          overviewLabel
          projectNameLabel
          servicesLabel
          websiteLabel
          typeLabel
          industryLabel
          countryLabel
          timelineLabel
          technologiesLabel
          problemLabel
          solutionLabel
          watchTestimonialLabel
          keyFunctionalitiesLabel
          moreCaseStudiesLabel
          contactUsHeader
          contactUsButtonLabel
          showCaseStudyButtonLabel
          downloadLabel
          moreCaseStudies {
            title
            description
          }
        }
        service {
          contactButtonsLabel
        }
        technology {
          heroPretitle
        }
        contactForm {
          formInputFirstName
          formInputLastName
          formInputEmail
          formInputPhone
          downloadButtonLabel
          whitePaperDownloadButtonLabel
          submitButtonLabel
        }
        contactUsForm {
          formInputProjectDescription
          formInputHowDidYouHearAboutUs
          sendButtonLabel
          button {
            label
            link
          }
        }
        blogDictionary {
          contactUsHeader
          contactUsButtonLabel
        }
        subscribeForm {
          header
          buttonLabel
          thankYouMessage {
            title
            featuredImage {
              asset {
                url
              }
            }
          }
        }
        newsletterModal {
          header
          buttonLabel
        }
        showMoreLabel
        cookies {
          acceptButtonLabel
        }
        resources {
          downloadResourceLabel
          modalConfirmLabel
        }
        _rawCookies
        _rawTopMessageBarText
        _rawContactForm
        _rawSubscribeForm
        _rawNewsletterModal
        _rawContactUsForm
      }
    }
  `)
  return data.sanityDictionary
}
