import React from 'react';
import styled from 'styled-components';

import SanityBlockContent from '../../SanityBlockContent';
import PillarsEntry from './PillarsEntry';

import { colors, breakpoints, targetIE } from '../../../../constants/style';
import { navbarMinHeight } from '../../../features/layout/components/commonLayoutStyle';

const PillarsSection = styled('section')`
  padding-top: 5rem;
  padding-bottom: ${ ({ removeBottomMargin }) => removeBottomMargin ? 0 : 5 }rem;

  .header {
    p {
      font-family: "BioSans-Regular", sans-serif;
      font-size: 3.125rem;
      line-height: 1.32;
      color: ${colors.titleDark};
      text-align: center;
    }

    &::before {
      content: '';
      display: block;
      margin: 0 auto 2.5rem auto;
      width: 50px;
      height: 5px;
      background: ${colors.primary};
    }
  }

  .description {
    font-family: "BioSans-Regular", sans-serif;
    text-align: center;
    margin-top: 1rem;
  }

  .pillars {
    max-width: 91.875rem;
    margin: 5rem auto 0 auto;
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;

    .pillar {
      flex: 0 0 33%;
    }
  }

  // hack for IE11 not respecting box-sizing for flex-basis size
  @media only screen and ${targetIE} {
    .pillars {
      .pillar {
        flex: 0 0 29%;
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.desktopSmall}) {
    .pillars {
      .pillar {
        flex: 0 0 50%;
      }
    }
  }
  // hack for IE11 not respecting box-sizing for flex-basis size
  @media only screen and (max-width: ${breakpoints.desktopSmall}) and (-ms-high-contrast: none),
    only screen and (max-width: ${breakpoints.desktopSmall}) and (-ms-high-contrast: active) {
    .pillars {
      .pillar {
        flex: 0 0 43%;
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    padding-top: ${navbarMinHeight};

    .header {
      p {
        font-size: 2.5rem;
        line-height: 1.32;
      }
    }

    .pillars {
      margin-top: 3rem;
      .pillar {
        flex: 0 0 100%;
      }
    }
  }
  // hack for IE11 not respecting box-sizing for flex-basis size
  @media only screen and (max-width: ${breakpoints.tablet}) and (-ms-high-contrast: none),
    only screen and (max-width: ${breakpoints.tablet}) and (-ms-high-contrast: active) {
    .pillars {
      .pillar {
        flex: 0 0 92%;
      }
    }
  }
`;

const Pillars = ({ pillars, raw }) => {
  return (
    <PillarsSection removeBottomMargin={raw.removeBottomMargin}>
      <div className="header">
        <SanityBlockContent blocks={raw.header} />
      </div>

      {Boolean(raw.description) && (
        <div className="description">
          <SanityBlockContent blocks={raw.description} />
        </div>
      )}

      <div className="pillars">
        {pillars.map((item, index) => {
          return (
            <PillarsEntry icon={item.icon} header={item.header} rawDescription={raw.pillars[index].description} key={`pillar-${index}`} />
          );
        })}
      </div>
    </PillarsSection>
  );
};

export default Pillars;
