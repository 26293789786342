import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../features/layout'
import FeaturedOn from '../../components/sections/FeaturedOn'
import ContactUs from '../../components/sections/ContactUs'

import Pillars from '../../components/sections/Pillars'
import StatisticSection from '../../components/sections/Statistics'
import Testimonials from '../../components/sections/Testimonials'
import ParagraphSection from '../../components/sections/Paragraph'
import HeroWithContactFormSection from '../../components/sections/HeroWithContactForm'
import 'hooks/graphql/others'
import 'hooks/graphql/section'
import { SEO } from 'components/Seo'

export const query = graphql`
  query WhitePaperPageQuery($id: String!) {
    sanityWhitePaperPage(id: { eq: $id }) {
      hero {
        title
        description
      }

      downloadForm {
        ...DownloadForm
      }
      _rawDownloadForm

      pillarsSection {
        ...PillarsSection
      }
      _rawPillarsSection

      paragraphSections {
        ...ParagraphSection
      }

      _rawParagraphSections

      testimonialsSection {
        ...TestimonialsSection
      }

      statisticSection {
        ...StatisticSection
      }

      contactSection {
        ...ContactSection
      }
      seo {
        ...SeoFragment
      }
    }
  }
`

const WhitePaperPage = ({ data: { sanityWhitePaperPage } }) => {
  const {
    hero,
    downloadForm,
    _rawDownloadForm,
    pillarsSection,
    _rawPillarsSection,
    paragraphSections,
    testimonialsSection,
    statisticSection,
    contactSection,
    seo,
    _rawParagraphSections,
  } = sanityWhitePaperPage
  return (
    <Layout>
      <HeroWithContactFormSection
        heroHeader={hero ? hero.title : null}
        heroDescription={hero ? hero.description : null}
        formName={downloadForm.formName}
        formHeader={downloadForm.header}
        formSubheader={downloadForm.subheader}
        fileUrl={downloadForm.file && downloadForm.file.asset.url}
        rawForm={_rawDownloadForm}
        whitePaper={true}
      />

      {Boolean(pillarsSection && pillarsSection.pillars.length) && (
        <Pillars pillars={pillarsSection.pillars} raw={_rawPillarsSection} />
      )}

      {Boolean(paragraphSections) &&
        paragraphSections.map((item, index) => (
          <ParagraphSection
            key={`paragraphsection-${index}`}
            theme={item.theme}
            decorate={item.decorate}
            customBulletListImage={item.customBulletListImage}
            header={item.header}
            description={_rawParagraphSections[index].description}
            image={item.image}
            position={item.position}
            overlap={true}
            isFirst={index === 0}
            isLast={index === paragraphSections.length - 1}
            buttonLabel={item.buttonLabel}
            url={item.link}
            removeBottomMargin={item.removeBottomMargin}
            shouldDisplayButton={item.shouldDisplayButton}
            video={item.video}
            headerColored={_rawParagraphSections[index].headerColored}
          />
        ))}

      {Boolean(testimonialsSection && testimonialsSection.testimonials.length) && (
        <Testimonials
          header={testimonialsSection.header}
          testimonials={testimonialsSection.testimonials}
          showFirstWide
        />
      )}

      {Boolean(statisticSection) && (
        <StatisticSection
          header={statisticSection.header}
          description={statisticSection.description}
          statisticsWithoutIcon={statisticSection.statisticsWithoutIcon}
          statisticsWithIcon={statisticSection.statisticsWithIcon}
        />
      )}

      <FeaturedOn />

      {Boolean(contactSection && contactSection.buttonLabel) && (
        <ContactUs
          theme="lightToDark"
          header={contactSection.header}
          buttonLabel={contactSection.buttonLabel}
          scrollTopOnClick={true}
        />
      )}
    </Layout>
  )
}

export function Head({ location, data: { sanityWhitePaperPage } }) {
  return <SEO location={location} {...sanityWhitePaperPage.seo} />
}

export default WhitePaperPage
