import React from 'react'
import styled from 'styled-components'

import { colors, breakpoints } from '../../../../constants/style'
import IcoArrow from '../../../../static/images/icons/arrow-white-thin.svg'
import { Link } from '../../../components/Link'
import Image from '../../../components/Image'

const StyledCaseStudiesItem = styled((props) => <Link {...props} />)`
  display: flex;
  flex-direction: column;

  .case-study-item__image {
    position: relative;
    width: 100%;
    height: 17.5rem;
    margin-bottom: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center center;

    img,
    .gatsby-image-wrapper {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .background-hover {
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: rgba(0, 0, 0, 0.7);
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
      z-index: 99;
    }

    &::after {
      content: url('${IcoArrow}');
      display: block;
      width: 66px;
      height: 30px;
      position: absolute;
      left: 50%;
      top: 50%;
      opacity: 0;
      transform: translate(-50%, -50%);
      transition: opacity 0.2s ease-in-out;
      z-index: 100;
    }

    &:hover {
      &::before,
      &::after {
        opacity: 1;
      }
    }
  }

  .case-study-item__tags {
    margin-bottom: 1.25rem;
    font-size: 0.6875rem;
    letter-spacing: 0.05rem;
    color: ${colors.primary};
    text-transform: uppercase;
    font-family: "BioSans-SemiBold", sans-serif;

    span {
      margin-right: 0.5rem;
    }
  }
  .case-study-item__title {
    margin-bottom: 1.25rem;
    font-family: "BioSans-Regular", sans-serif;
    font-size: 1.625rem;
    letter-spacing: -0.0375rem;
    color: #000000;
  }
  .case-study-item__description {
    color: ${colors.mutedDark};
    font-size: 0.9375rem;
    font-family: Roboto-Regular, sans-serif;
    line-height: 1.5625rem;
  }

  @media only screen and (max-width: ${breakpoints.desktop}) {
    .case-study-item__image {
      height: 14.375rem;
    }
  }

  @media only screen and (max-width: ${breakpoints.desktopSmall}) {
    .case-study-item__image {
      height: 18.75rem;
    }
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    .case-study-item__image {
      height: 20.625rem;
    }
  }
`

const CaseStudiesItem = ({ slug, tags, projectName, description, image, url }) => {
  const caseStudySlug = slug?.endsWith('/') ? slug : `${slug}/`
  const link = url ? url : `/case-studies/${caseStudySlug}`

  return (
    <StyledCaseStudiesItem to={link}>
      <Image
        image={image}
        alt={image.alt || projectName}
        className="case-study-item__image background-hover"
      />
      {Boolean(tags) && (
        <div className="case-study-item__tags">
          {tags.map((tag, index) => (
            <span key={index}>{tag}</span>
          ))}
        </div>
      )}
      <div className="case-study-item__title">{projectName}</div>
      <div className="case-study-item__description">{description}</div>
    </StyledCaseStudiesItem>
  )
}

export default CaseStudiesItem
