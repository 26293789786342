import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import { useContactForm } from '../../hooks/useContactForm'
import { useDictionaryQuery } from 'hooks/graphql/page'
import Button from '../../components/Buttons'
import StyledContactUsPage, { StyledHRLine } from './styled'
import IconLoadingButton from '../../components/icons/IconLoadingButton'
import InputPhone from '../../components/NewForm/Phone'
import debounce from 'lodash.debounce'

import { useForm, FormProvider } from 'react-hook-form'
import Input from '../../components/NewForm/Input'
import FileInput from '../../components/NewForm/FileInput' // temporary not using since salesforce integration is not ready
import Textarea from '../../components/NewForm/Textarea'
import TooltipWrapper from '../../components/Tooltip/TooltipWrapper'
import { breakpoints } from '../../../constants/style'
import TermsAndAgreements from './components/TermsAndAgreements'
import {
  fullNameValidation,
  emailValidation,
  phoneValidation,
  howDidYouHearValidation,
  projectDescriptionValidation,
  validationDelay,
} from '../../components/NewForm/validators'

// this from have to be connected with cms -> https://cms-10c-www.netlify.app/desk/dictionary;9042fae5-8e3c-40ce-912b-f3dfac91d752

const ContactUsPage = ({ location }) => {
  // im not deleting this, this will be handled in second iteration, this has to be first adjusted in cms
  const {
    contactForm: { formInputFirstName, formInputLastName, formInputEmail, formInputPhone },
    contactUsForm: { formInputProjectDescription, formInputHowDidYouHearAboutUs, sendButtonLabel },
    _rawContactUsForm,
  } = useDictionaryQuery()
  const { isFormSent, isLoading, submitFormData, errorMessages } = useContactForm()

  const methods = useForm({ mode: 'onBlur' })
  const {
    handleSubmit,
    formState: { isValid },
    trigger,
    setValue,
    watch,
  } = methods

  watch()

  const debounceTrigger = debounce((name) => trigger(name), validationDelay, {
    leading: false,
    trailing: true,
  })

  useEffect(() => {
    const subscription = watch((_, { name }) => {
      debounceTrigger(name)
    })
    return () => {
      subscription.unsubscribe()
      debounceTrigger.cancel()
    }
  }, [watch])

  useEffect(() => {
    if (!isFormSent && !errorMessages) {
      return
    }

    if (isFormSent) {
      navigate('/contact-us/thank-you/')
    } else {
      navigate('/contact-us/sending-form-error/')
    }
  }, [isFormSent, errorMessages])

  const trimValue = (event) => {
    event.target.name === 'email'
      ? setValue(event.target.name, event.target.value.trim())
      : setValue(event.target.name, event.target.value.trimStart())
  }

  const onSubmit = async (data) => {
    // this is treated this way because in salesforce there are the same fields
    // it have to be backwards compatible with old contact form
    const [firstName, ...lastNameParts] = data.fullName.split(' ')
    const lastName = lastNameParts.join(' ')

    const formattedFormData = {
      ...data,
      firstName,
      lastName,
    }

    submitFormData(formattedFormData)
  }

  return (
    <StyledContactUsPage>
      <div className="contact-us__column-right">
        <h2 className="contact-us__form__subtitle">CONTACT FORM</h2>
        <h1 className="contact-us__title">Contact details</h1>
        <FormProvider {...methods}>
          {/* // TODO Import this Data trough cms trough graphql query */}

          <form className="contact-us__form" onSubmit={handleSubmit(onSubmit)}>
            <StyledHRLine />
            <div className="contact-us__checkboxes-row">
              <Input
                label="Full name"
                placeholder="Enter full name"
                preLabel="01."
                inputName="fullName"
                required
                validationOptions={fullNameValidation}
                onChange={trimValue}
              />
              <Input
                label="Business e-mail"
                placeholder="Enter e-mail"
                preLabel="02."
                inputName="email"
                required
                validationOptions={emailValidation}
                onChange={trimValue}
              />
            </div>
            <div className="contact-us__checkboxes-row">
              <InputPhone
                placeholder="Enter phone number"
                preLabel="03."
                label={formInputPhone}
                inputName="phone"
                required
                validationOptions={phoneValidation}
              />
              <Input
                label="How did you hear about us?"
                placeholder="e.g. Google, Facebook, LinkedIn"
                preLabel="04."
                inputName="howDidYouHear"
                required
                validationOptions={howDidYouHearValidation}
                onChange={trimValue}
              />
            </div>

            <Textarea
              label="Tell us more about the project"
              placeholder="My project is about..."
              preLabel="05."
              inputName="projectDescription"
              required
              validationOptions={projectDescriptionValidation}
              onChange={trimValue}
              resize={false}
            />

            <p className="required-field-text">* Required fields</p>

            <StyledHRLine />

            <TermsAndAgreements rawContactUsForm={_rawContactUsForm} />

            <TooltipWrapper
              position="top"
              content="All fields must be filled in unless there is an optional tag"
              passStyle={`
                  width: calc(50% - 3rem);
                  @media screen and (max-width: calc(${breakpoints.desktopMac} - 1px)) {
                    width: 100%;
                  }
                `}
              isVisible={!isValid}
            >
              {isLoading ? (
                <div className="loading">
                  <Button className="contact-us__submit-button" variant="filled" type="button">
                    <IconLoadingButton />
                  </Button>
                </div>
              ) : (
                <Button
                  className="contact-us__submit-button"
                  variant="filled"
                  type="submit"
                  disabled={!isValid}
                >
                  {sendButtonLabel}
                </Button>
              )}
            </TooltipWrapper>
          </form>
        </FormProvider>
      </div>
    </StyledContactUsPage>
  )
}

export default ContactUsPage
