import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import { breakpoints } from '../../../../constants/style'
import {
  horizontalLayoutPadding,
  horizontalPaddingDesktop,
  horizontalLayoutPaddingMobile,
  horizontalLayoutPaddingTablet,
} from 'features/layout/components/commonLayoutStyle'

const MOBILE_HEADER_HEIGHT = 88

// coefficients are resposible for the gap below the bg image
// the bigger the coefficient, the bigger the gap
const BG_IMAGE_GAP_COEFFICIENT_DESKTOP = 2
const BG_IMAGE_GAP_COEFFICIENT_DESKTOP_LARGE = 1.5

const heroSectionGap = (coefficient: number) => `var(--navbarHeight) * ${coefficient}`

export const HeroForCaseStudyWrapper = styled.section`
  padding: 0 !important;

  .logo {
    max-height: 45px;
  }
`

export const Background = styled(BackgroundImage)`
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  margin-top: ${MOBILE_HEADER_HEIGHT}px;
  width: 100vw;
  height: 50vh;

  @media only screen and (min-width: ${breakpoints.mobileLarge}) {
    height: calc(100vh - (${heroSectionGap(BG_IMAGE_GAP_COEFFICIENT_DESKTOP)}));
  }

  @media only screen and (min-width: ${breakpoints.desktop}) {
    margin-top: 0;
  }
  @media only screen and (min-width: ${breakpoints.desktopLarge}) {
    height: calc(100vh - (${heroSectionGap(BG_IMAGE_GAP_COEFFICIENT_DESKTOP_LARGE)}));
  }
`

export const BackgroundTransparent = styled.div`
  position: relative;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media only screen and (min-width: ${breakpoints.mobileLarge}) {
    margin-top: calc(${heroSectionGap(BG_IMAGE_GAP_COEFFICIENT_DESKTOP)} * -1);
    height: auto;
  }
  @media only screen and (min-width: ${breakpoints.desktopLarge}) {
    margin-top: calc(${heroSectionGap(BG_IMAGE_GAP_COEFFICIENT_DESKTOP_LARGE)} * -1);
  }
`

export const TextBox = styled.div`
  background-color: var(--fs-bg-1a);
  padding: 5rem 2rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 1.5rem;
  margin: 0;

  .title {
    color: var(--t1-title);
    max-width: 736px;
    margin: 0 auto;
  }

  @media only screen and (min-width: ${breakpoints.mobile}) {
    margin: 0 ${horizontalLayoutPaddingMobile};
  }

  @media only screen and (min-width: ${breakpoints.mobileLarge}) {
    margin: 0 ${horizontalLayoutPaddingTablet};
    padding: 5rem 6rem;
  }

  @media only screen and (min-width: ${breakpoints.tablet}) {
    margin: 0 ${horizontalPaddingDesktop};
  }

  @media only screen and (min-width: ${breakpoints.desktopLarge}) {
    margin: 0 ${horizontalLayoutPadding};
  }
`
