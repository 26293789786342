import React, { useState } from 'react';
import { animateScroll as scroll } from 'react-scroll';

import { contact } from '../../../../constants/links';
import { colors } from '../../../../constants/style';
import downloadIcon from '../../../../static/images/icons/download-icon.svg';
import WaveSVG from '../../../../static/images/wave-light-2.svg';
import DownloadPdfModal from '../../../components/DownloadPdfModal';
import useWindowSize from '../../../hooks/useWindowSize';
import { checkLink, is10cDomain } from '../../../utils/link';
import { ButtonWithArrow, ButtonWithIcon } from '../../Buttons';
import Image from '../../Image';
import { NavLinkButtonWithArrow } from '../../Link';
import SanityBlockContent from '../../SanityBlockContent';
import SectionHeader from '../../SectionHeader/SectionHeader';
import StyledParagraphSection from './StyledParagraphSection';


const themes = {
  light: {
    backgroundColor: '#fff',
    before: {
      background: `url(${WaveSVG}) no-repeat left top`,
    },
    content: {
      header: {
        color: colors.sectionTitleDark,
        before: {
          backgroundColor: colors.primary,
        },
      },
      paragraph: {
        color: '#4e4d6c',
      },
    },
  },

  lightPurple: {
    backgroundColor: colors.backgroundLight,
    before: {
      background: `url(${WaveSVG}) no-repeat left top`,
    },
    content: {
      header: {
        color: colors.sectionTitleDark,
        before: {
          backgroundColor: colors.primary,
        },
      },
      paragraph: {
        color: '#4e4d6c',
      },
    },
  },

  dark: {
    backgroundColor: colors.titleDark,
    before: {
      background: `url(${WaveSVG}) no-repeat left top`,
    },
    content: {
      header: {
        color: colors.sectionTitleLight,
        before: {
          backgroundColor: colors.secondary,
        },
      },
      paragraph: {
        color: '#7b7b8e',
      },
    },
  },
}

// TODO: cases for both values 'true' or 'false'
const positionStyles = (isFirst, isLast) => ({
  left: {
    flexDirection: 'row-reverse',
    image: {
      before: {
        left: '-25px',
        top: isFirst ? '-25px' : 'unset',
        right: 'unset',
        bottom: isLast ? '-25px' : 'unset',
      },
    },
  },
  right: {
    flexDirection: 'row',
    image: {
      before: {
        left: 'unset',
        top: isFirst ? '-25px' : 'unset',
        right: '-25px',
        bottom: isLast ? '-25px' : 'unset',
      },
    },
  },
})

const getFileUrl = (file) => {
  return file.asset.url
}

const ParagraphSection = ({
  theme,
  header,
  headerColored,
  description,
  image,
  position,
  isFirst,
  isLast,
  backgroundBorderColor = colors.primary,
  overlap = false,
  decorate,
  removeBottomMargin,
  customBulletListImage,
  video,
  file,
  shouldDisplayButton = false,
  shouldButtonOpenForm = false,
  buttonLabel,
  url,
  imageWithUrl = null,
}) => {
  const isLink = checkLink(url)
  const { isSmallerThanDesktop } = useWindowSize()

  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <StyledParagraphSection
      className="paragraph"
      theme={themes.hasOwnProperty(theme) ? themes[theme] : themes['light']}
      position={
        Boolean(position)
          ? positionStyles(isFirst, isLast)[position]
          : positionStyles(false, false).left
      }
      {...{
        overlap,
        isLast,
        backgroundBorderColor,
        decorate,
        customBulletListImage,
        removeBottomMargin,
      }}
    >
      <div className="container">
        <div className="content">
          {Boolean(headerColored) && (
            <h2 className="header">
              <SanityBlockContent className="headerColored" blocks={headerColored} />
            </h2>
          )}
          {!Boolean(headerColored) && (
            <SectionHeader
              title={header}
              headerPosition="left"
              theme={theme === 'dark' ? 'darkPurple' : undefined}
            />
          )}
          <SanityBlockContent className="description" blocks={description} />
          {shouldDisplayButton && !shouldButtonOpenForm && (
            <div className="navigate-button">
              {url && !isLink ? (
                <ButtonWithArrow
                  onClick={() => {
                    // we remove 100px to generate offset for navbar
                    const navbarOffset = isSmallerThanDesktop ? 0 : 100
                    scroll.scrollTo(
                      document.querySelector(url).getBoundingClientRect().top -
                        navbarOffset +
                        window.pageYOffset,
                    )
                  }}
                  variant="filled"
                >
                  {buttonLabel}
                </ButtonWithArrow>
              ) : (
                <NavLinkButtonWithArrow
                  to={Boolean(url?.startsWith('http')) ? url : contact.url}
                  variant="filled"
                >
                  {buttonLabel}
                </NavLinkButtonWithArrow>
              )}
            </div>
          )}
          {shouldDisplayButton && shouldButtonOpenForm && (
            <div className="navigate-button">
              <ButtonWithIcon
                iconSrc={downloadIcon}
                iconPosition="right"
                variant="filled"
                onClick={() => {
                  setIsModalVisible(true)
                }}
              >
                {buttonLabel}
              </ButtonWithIcon>
            </div>
          )}
        </div>
        <div className="image">
          {imageWithUrl?.asset ? (
            <a
              href={imageWithUrl.url}
              target="_blank"
              rel={`${is10cDomain(imageWithUrl.url) ? '' : 'noopener noreferrer'}`}
            >
              <Image image={imageWithUrl} />
            </a>
          ) : video?.videoFile ? (
            <video
              width="100%"
              loop
              autoPlay
              muted
              poster={video.poster?.gatsbyImageData?.images?.fallback?.src ?? ''}
              loading="lazy"
            >
              <source src={video.videoFile?.asset?.url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <Image image={image} />
          )}
        </div>
      </div>

      {shouldButtonOpenForm && file && (
        <DownloadPdfModal
          isVisible={isModalVisible}
          handleOnClose={() => {
            setIsModalVisible(false)
          }}
          fileUrl={getFileUrl(file)}
        />
      )}
    </StyledParagraphSection>
  )
}

export default ParagraphSection
