import React from 'react';
import styled from 'styled-components';

import { breakpoints, colors } from '../../../../constants/style';

import Table from './components/table';

const StyledTableSection = styled('section')`
  padding-top: 11.25rem;
  padding-bottom: 22.5rem;

  background-position: left top;
  background-size: cover;
  position: relative;

  .table-section__header {
    margin-bottom: 2rem;
    font-family: "BioSans-Regular", sans-serif;
    text-align: center;
    font-size: 2.625rem;
    color: #100727;

    &::before {
      content: '';
      display: block;
      margin: 0 auto 2.5rem;
      width: 50px;
      height: 5px;
      background: ${colors.primary};
    }
  }

  .table-section__description {
    font-family: "Roboto-Regular", sans-serif;
    font-size: 1.125rem;
    line-height: 1.78;
    text-align: center;
    color: ${colors.mutedLighter};
    margin-bottom: 7.875rem;
  }

  .decoration-circle {
    position: absolute;
    border-radius: 50%;
  }

  .decoration-circle--grey-top {
    width: 40px;
    height: 40px;
    border: solid 1px ${colors.mutedLighter};
    top: 12rem;
    right: 29%;
  }

  .decoration-circle--grey-bottom {
    width: 24px;
    height: 24px;
    border: solid 1px ${colors.mutedLighter};
    bottom: 11rem;
    left: 14%;
  }

  .decoration-circle--green {
    width: 80px;
    height: 80px;
    border: solid 1px #1effc2;
    bottom: 8.5rem;
    right: 11%;
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    padding-top: 6rem;
    padding-bottom: 8rem;
    background-image: none;

    .table-section__header {
      font-size: 2rem;
    }

    .table-section__description {
      margin-bottom: 3rem;
    }

    .decoration-circle--grey-top {
      top: 5rem;
    }

    .decoration-circle--grey-bottom {
      display: none;
    }

    .decoration-circle--green {
      width: 32px;

      height: 32px;
      bottom: 5rem;
      left: 11%;
    }
  }
`;

const TableSection = ({ header, description, table, positiveWords, shouldDisplayPositiveLabel, negativeWords, shouldDisplayNegativeLabel }) => {
  return (
    <StyledTableSection>
      <h1 className="table-section__header">{header}</h1>
      <p className="table-section__description">{description}</p>
      <Table
        rows={table.rows}
        positiveWords={positiveWords}
        shouldDisplayPositiveLabel={shouldDisplayPositiveLabel}
        negativeWords={negativeWords}
        shouldDisplayNegativeLabel={shouldDisplayNegativeLabel}
      />
      <span className="decoration-circle decoration-circle--grey-top"></span>
      <span className="decoration-circle decoration-circle--grey-bottom"></span>
      <span className="decoration-circle decoration-circle--green"></span>
    </StyledTableSection>
  )
};

export default TableSection;
