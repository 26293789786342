import React from 'react';
import styled from 'styled-components';

import { colors, breakpoints } from '../../../../constants/style';
import {
  horizontalLayoutPadding,
  horizontalLayoutPaddingTablet,
  horizontalLayoutPaddingMobile,
} from '../../../features/layout/components/commonLayoutStyle';

import { VersusElement } from './VersusElement';

import BackgroundLogo from '../../../../static/images/10c-logo/10c-icon-background.svg';

const VersusSection = styled('section')`
  padding: 8.125rem 18.9375rem !important;
  background: url(${BackgroundLogo}) no-repeat right 222px, linear-gradient(221deg, #180045 4%, #180f2b 103%);
  position: relative;

  .header {
    margin-bottom: 130px;
  }

  .title {
    font-size: 2.5rem;
    padding: 0 ${horizontalLayoutPadding};
    margin-bottom: 3.5625rem;
    color: ${colors.sectionTitleLight};
    letter-spacing: normal;
    text-align: center;

    @media only screen and (max-width: ${breakpoints.desktop}) {
      padding: 0 ${horizontalLayoutPaddingTablet} 3.5625rem ${horizontalLayoutPaddingTablet};
    }

    @media only screen and (max-width: ${breakpoints.tablet}) {
      padding: 0 ${horizontalLayoutPaddingMobile} 3.5625rem ${horizontalLayoutPaddingMobile};
    }

    &:before {
      content: '';
      display: block;
      margin-bottom: 2.5rem;
      margin-left: auto;
      margin-right: auto;
      width: 50px;
      height: 5px;
      background: ${colors.secondary};
    }
  }

  .description {
    color: ${colors.mutedLight};
    text-align: center;
    font-size: 1.375rem;
  }

  @media only screen and (max-width: ${breakpoints.desktopLargest}) {
    padding: 8.125rem 10rem !important;
  }

  @media only screen and (max-width: ${breakpoints.desktopSmall}) {
    padding: 8.125rem 5rem !important;
  }
`;

const Versus = ({ title, description, elements, rawElements }) => {
  return (
    <VersusSection>
      <div className="header">
        <h2 className="title">{title}</h2>
        {description && <p className="description">{description}</p>}
      </div>

      {elements.map(({ icon, header }, index) => (
        <VersusElement icon={icon} header={header} rawItems={rawElements[index].items} key={`versus-element-${index}`} />
      ))}
    </VersusSection>
  );
};

export default Versus;
