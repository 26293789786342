import { NavLinkButtonWithArrow } from 'components/Link'
import classNames from 'classnames'
import React, { useState } from 'react'
import styled from 'styled-components'
import { breakpoints, zIndexes } from '../../../constants/style'
import Logo from '../../../static/images/10c-logo/10c-logo-badge.svg'
import {
  horizontalLayoutPaddingMobile,
  horizontalLayoutPaddingTablet,
} from '../../features/layout/components/commonLayoutStyle'

const StickyBadgeWrapper = styled.div`
  position: sticky;
  position: -webkit-sticky;
  padding: 2rem;
  background: white;
  box-shadow: var(--shadow-m);
  height: fit-content;
  top: 8rem;
  z-index: ${zIndexes.testimonialsHeader - 1};

  .badge-title {
    margin-bottom: 0.75rem !important;
    margin-top: 0 !important;
  }
  p {
    margin-bottom: 1.5rem;
  }
  .badge-link {
    padding: 0;
    text-transform: none;
    & > span {
      justify-content: flex-start;
      color: var(--t1-overline);
      font-size: 1.188rem;
      svg {
        fill: var(--t1-overline);
      }
    }
  }

  @media only screen and (min-width: ${breakpoints.desktop}) {
    min-width: 270px;
    max-width: 368px;
  }
  @media only screen and (max-width: ${breakpoints.desktop}) {
    position: fixed;
    right: 8%;
    max-width: 315px;
    margin-bottom: 0;
    top: unset;
    bottom: -100%;

    &.badge__visible {
      top: unset;
      bottom: 4%;
      opacity: 1;
      visibility: visible;
      transition: 0.3s;
    }

    &.badge__hidden {
      opacity: 0;
      visibility: hidden;
      transition: 0.6s;
    }
  }
  @media only screen and (max-width: ${breakpoints.desktopSmall}) {
    right: 10%;
  }
  @media only screen and (max-width: ${breakpoints.tablet}) {
    right: 13%;
    padding: 1.5rem;
  }
  @media only screen and (max-width: ${breakpoints.mobileLarge}) {
    right: 16%;
  }
  @media only screen and (max-width: ${breakpoints.mobile}) {
    max-width: 100%;
    right: ${horizontalLayoutPaddingMobile};
    left: ${horizontalLayoutPaddingMobile};

    &.badge__visible {
      bottom: 90px;
    }
  }
`

const StickyBubble = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 50%;
  background: black;
  position: fixed;
  bottom: 1.25rem;
  right: 1.25rem;
  cursor: pointer;
  border-color: transparent;
  z-index: ${zIndexes.testimonialsHeader};

  .notifications-counter {
    border-radius: 50%;
    width: 19px;
    height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--t1-error);
    color: var(--white);
    position: absolute;
    top: -3px;
    right: -3px;
    font-size: 10px;
    font-weight: bold;
  }

  @media only screen and (min-width: ${breakpoints.desktop}) {
    display: none;
  }
  @media only screen and (max-width: ${breakpoints.tablet}) {
    right: ${horizontalLayoutPaddingTablet};
  }
`

const StickyBadge = ({ title, description, button }) => {
  const [isBubbleClicked, setIsBubbleClicked] = useState(false)
  const [isBadgeVisible, setIsBadgeVisible] = useState(false)

  const bubbleClickHandler = () => {
    setIsBadgeVisible((isVisible) => !isVisible)
    setIsBubbleClicked(true)
  }

  return (
    <>
      <StickyBadgeWrapper
        className={classNames(isBadgeVisible ? 'badge__visible' : 'badge__hidden')}
      >
        <h4 className="badge-title">{title}</h4>
        {description && <p className="p2">{description}</p>}
        {button && (
          <NavLinkButtonWithArrow to={button.link} variant="outlined" className="badge-link">
            {button.label}
          </NavLinkButtonWithArrow>
        )}
      </StickyBadgeWrapper>
      <StickyBubble onClick={bubbleClickHandler}>
        <div>
          <img src={Logo} alt="logo" />
        </div>
        {!isBubbleClicked && <span className="notifications-counter">1</span>}
      </StickyBubble>
    </>
  )
}

export default StickyBadge
