import { css } from 'styled-components';

const orderNumberBordered = css`
  font-family: "BioSans-Regular", "Roboto-Regular", sans-serif;
  font-weight: 700;
  font-size: 5.25rem;
  line-height: 6.5rem;
  letter-spacing: 0.01rem;
  color: #fff;
  text-shadow: -1px 0 #a284f7, 0 1px #a284f7, 1px 0 #a284f7, 0 -1px #a284f7;
`;

export default orderNumberBordered;
