import { useState } from 'react'

export const useContactForm = () => {
  const [errorMessages, setErrorMessages] = useState()
  const [isFormSent, setIsFormSent] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const submitFormData = (formData) => {
    setIsLoading(true)

    fetch('/api/contact-us-form', {
      method: 'POST',
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.status === 200) {
          window.dataLayer.push({
            event: 'submitContactForm',
            email: formData.email,
          })
          setIsFormSent(true)
        } else {
          setErrorMessages(response.statusText)
        }
      })
      .catch((error) => setErrorMessages(error.message))
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    errorMessages,
    setErrorMessages,
    isFormSent,
    setIsFormSent,
    isLoading,
    setIsLoading,
    submitFormData,
  }
}
