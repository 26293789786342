import React from 'react'
import styled from 'styled-components'
import { breakpoints } from '../../../../constants/style'
import Image from '../../Image'

const LIST_VERTICAL_PADDING = '2.75rem'
const IMAGE_HEIGHT = '4.25rem'

const ClientsSection = styled('section')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  @media only screen and (max-width: ${breakpoints.desktop}) {
    padding: 0 !important;
    padding-bottom: 2rem !important;
  }

  .clients__header {
    font-family: 'BioSans-SemiBold', sans-serif;
    font-size: 1rem;
    text-transform: uppercase;
    margin-bottom: 2rem;
  }

  .clients__logos {
    max-width: unset;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 2rem;
    padding: ${LIST_VERTICAL_PADDING} 8.625rem;
    box-shadow: 0px 8px 24px rgba(71, 90, 158, 0.1);
    transform: translateY(-50%);
    margin-bottom: calc(
      -1 * (${IMAGE_HEIGHT} + 2 * ${LIST_VERTICAL_PADDING}) / 2
    ); /* minus margin to remove 50% of element height from page space */

    @media only screen and (max-width: ${breakpoints.desktopLargest}) {
      padding: ${LIST_VERTICAL_PADDING} 5rem;
    }

    @media only screen and (max-width: ${breakpoints.desktop}) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 5rem;
      align-items: center;
      justify-items: center;
      padding: ${LIST_VERTICAL_PADDING} 7.96875rem;
      transform: translateY(0);
      margin-bottom: 0;
    }

    @media only screen and (max-width: ${breakpoints.mobileLarge}) {
      display: flex;
      flex-direction: column;
      padding: ${LIST_VERTICAL_PADDING} 0;
    }

    .clients__image-wrapper {
      max-height: ${IMAGE_HEIGHT};
      height: ${IMAGE_HEIGHT};
      img {
        object-fit: contain;
        height: 100%;
      }
    }
  }
`

const Clients = ({ clients }) => (
  <ClientsSection>
    <div className="clients__logos container">
      {clients.map(({ logo, name }) => (
        <div className="clients__image-wrapper" key={`clients-logo-${name}`}>
          <Image image={logo?.asset?.url} alt={logo.alt || `Logo ${name}`} />
        </div>
      ))}
    </div>
  </ClientsSection>
)

export default Clients
