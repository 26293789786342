import React from 'react';
import Fade from 'react-reveal/Fade';
import BackgroundImage from 'gatsby-background-image';

import { convertToBackgroundImage } from '../../../../utils/image';
import SanityBlockContent from '../../../SanityBlockContent';

import ProjectExamplesEntryWrapper from './styled';

const ProjectExamplesEntry = (props) => {
  const {header, rawDescription, image, tags = [], category} = props
  const bgImage = convertToBackgroundImage(image && image.asset);

  return (
    <ProjectExamplesEntryWrapper
      className="projectExamples__item"
    >
      <Fade opposite>
        <div className="projectExamples__text-wrapper">
          <h3 className="projectExamples__item-header">{header}</h3>
          <div className="projectExamples__item-tags">
            {tags.map((tag) => (
              <span key={tag}>{tag}</span>
            ))}
          </div>
          <SanityBlockContent className="projectExamples__item-description" blocks={rawDescription} />
          <div className="projectExamples__item-buttons">
            <div className="projectExamples__shadow">{Boolean(category) && category}</div>
          </div>
        </div>
        <div className="projectExamples__image-wrapper">
          <BackgroundImage className="projectExamples__image" tag="div" alt={image?.alt} fluid={bgImage && bgImage.fluid} />
        </div>
      </Fade>
    </ProjectExamplesEntryWrapper>
  );
};

export default ProjectExamplesEntry;
