import React from 'react';
import ItemList from './components/ItemList';

const OrderedList = ({ itemsList }) => {
  return (
    <div className="ordered-list">
      {itemsList.map((item, index) => (
        <ItemList key={`ordered-list-item-${index}`} index={index + 1} blocks={item.description} />
      ))}
    </div>
  );
};

export default OrderedList;
