import styled, { css } from 'styled-components'
import { StyledCheckbox } from '../../components/Form/Checkbox'
import StyledInput from '../../components/Form/Input/styled'
import { colors, breakpoints } from '../../../constants/style'
import { StyledSelect } from '../../components/Form/Select'

const buttonStyle = css`
  width: calc(50% - 3rem);
  min-width: 200px;
  height: 52px;
  line-height: 100%;
`

const StyledContactUsPage = styled('section')`
  padding: 0 !important;
  display: flex;
  min-height: 100%;

  .contact-us__title {
    margin-top: 16px;
    margin-bottom: 32px;

    color: ${colors.titleDark};
    font-size: 1.625rem;
    line-height: 2.25rem;
    letter-spacing: 0.18px;
  }

  .contact-us__form__subtitle {
    color: ${colors.primary};
    letter-spacing: 0.43px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  .contact-us__column-right {
    width: 100%;
    padding-bottom: 5rem;
  }

  .contact-us__form {
    width: 100%;
    margin: 0;

    .select--mobile {
      font-size: 1rem;
    }

    .files__to__upload {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .files__delete {
      cursor: pointer;
    }

    ${StyledSelect} {
      border-bottom: 1px solid #c1c1d0;

      button::before {
        top: 0.85rem;
        right: 0;
      }
    }

    .contact-us__email-phone-row {
      display: flex;
      justify-content: space-between;

      ${StyledInput} {
        width: 48%;
      }
    }

    .contact-us__checkboxes-row {
      display: flex;
      justify-content: space-between;
      gap: 30px;
      ${StyledCheckbox} {
        p {
          font-size: 0.75rem;
        }
        a {
          color: #7142eb;
        }
      }
    }

    .contact-us__submit-button {
      ${buttonStyle};
      width: 100%;
      margin-top: 14px;
      border: 0;
    }
  }

  .thank-you {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;

    .thank-you__title {
      height: 2.25rem;
      display: flex;
      align-items: center;
      margin-bottom: 1rem;

      & h2 {
        font-size: 1.625rem;
      }

      & img {
        margin-right: 0.9rem;
      }
    }

    &__message {
      font-size: 1rem;
      line-height: 2rem;
      color: #727292;

      &--indentation {
        margin-left: 1.8rem;
      }
    }

    &__subtitle {
      font-size: 1rem;
      line-height: 1.5rem;
      letter-spacing: 0.43px;
      color: #602be9;
      font-weight: 600;
      margin-bottom: 0.375rem;
      text-transform: uppercase;
    }

    &__step-item {
      font-size: 1rem;
      line-height: 2rem;
      margin: 1.5rem 0 0.5rem;

      & span {
        color: #602be9;
        margin-right: 0.5rem;
      }
    }

    &__horizontal-line {
      margin: 1.875rem 0;
      border: 0;
      border-top: 1px solid #e6e6ef;
    }

    &__button {
      ${buttonStyle};
    }
  }

  .required-field-text {
    font-size: 0.875rem;
    margin: 0 0 1.875rem;
    color: ${colors.error};
  }

  @media screen and (max-width: calc(${breakpoints.desktopMac} - 1px)) {
    .contact-us__column-right {
      padding: 48px 0 64px;
    }

    .contact-us__form {
      .contact-us__checkboxes-row {
        display: block;
      }
      .contact-us__submit-button {
        max-width: 100%;
        width: 100%;
      }
    }

    .thank-you__button {
      width: 100%;
    }
  }

  @media screen and (max-width: ${breakpoints.tablet}) {
    .contact-us__column-right {
      width: 100%;
    }
  }

  @media screen and (max-width: ${breakpoints.mobileLarge}) {
    .contact-us__column-right {
      padding: 40px 0;
    }
  }

  @media screen and (max-width: ${breakpoints.mobile}) {
    .contact-us__column-right {
      padding: 24px 0 30px;

      .contact-us__form {
        padding-left: 0;
        padding-right: 0;
      }

      .thank-you {
        margin: 0 24px;
      }

      .contact-us__email-phone-row,
      .contact-us__checkboxes-row {
        display: block;

        ${StyledInput},${StyledCheckbox} {
          width: 100%;
        }
      }
    }

    .thank-you {
      height: 100%;
    }
  }
`

export const StyledHRLine = styled.hr`
  border: none;
  border-bottom: 1px solid #e6e6ef;
  margin-top: 2px;
  margin-bottom: 1.875rem;
`

export default StyledContactUsPage
