import React, { useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import { labelStyle } from '../commonFormStyle.js';
import { breakpoints } from '../../../../constants/style';
import arrowDownIcon from '../../../../static/images/icons/arrowDownIcon.svg';
import useWindowSize from '../../../hooks/useWindowSize';

export const StyledSelect = styled('div')`
  position: relative;
  display: flex;
  align-items: flex-end;
  margin-bottom: 1.5rem;

  button,
  select {
    background: transparent;
    border: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    padding: 1rem 0 1rem;
    display: flex;
    justify-content: space-between;

    /* hides default arrow */
    appearance: none;

    &::before {
      content: '';
      background-image: url(${arrowDownIcon});
      height: 8px;
      width: 14px;
      display: block;
      position: absolute;
      vertical-align: -50%;
      right: 1.5rem;
    }

    &:focus {
      outline: none;
    }
  }

  .select--desktop .hidden-label {
    font-size: 0;
  }

  label {
    ${labelStyle};
    top: 0;
    z-index: -1;
  }

  .label--has__value {
    top: -1rem;
  }

  ul {
    transform: scale(0);
    opacity: 0;
    ${({ isVisible }) =>
      isVisible &&
      css`
        transform: scale(1);
        opacity: 1;
      `};
    position: absolute;
    left: 0;
    right: 0;
    background: white;
    width: 100%;
    z-index: 20;
    padding-left: 0;
    min-height: 100%;
    box-shadow: 0px 0px 6px -2px rgba(66, 68, 90, 1);

    li {
      font-size: 1rem;
      font-family: "BioSans-Regular", sans-serif;
      font-style: normal;
      font-weight: 400;
      color: #202020;
      padding: 10px 15px;
      cursor: pointer;
      &:hover {
        background-color: #e7e7ec;
      }
    }
  }

  .select--mobile {
    display: none;
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    .select--desktop {
      display: none;
    }

    .select--mobile {
      display: block;
      position: relative;
      line-height: 2rem;
      font-family: "BioSans-Regular", sans-serif;
      font-size: ${({ hasValue }) => (hasValue ? '1.0625rem' : '0.875rem')};
      color: #100727;

      option[value=''][disabled] {
        display: none;
      }
    }

    label {
      display: none;
    }

    .label--has__value {
      display: block;
    }

    &::before {
      content: '';
      background-image: url(${arrowDownIcon});
      height: 8px;
      width: 14px;
      display: block;
      position: absolute;
      top: 1.75rem;
      right: 1.5rem;
      // z-index: 2;
    }
  }
`;

const Select = ({ label, value, options, handleSelect }) => {
  const selectListRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const { isSmallerThanDesktopSmall } = useWindowSize();

  useOnClickOutside(selectListRef, () => {
    setIsVisible(false);
  });

  return (
    <StyledSelect isVisible={isVisible} hasValue={Boolean(value)}>
      <label className={Boolean(value) || !isSmallerThanDesktopSmall ? 'label--has__value' : ''}>{label}</label>
      <button type="button" onClick={() => setIsVisible(true)} className="select--desktop">
        <span>{value}</span>
        <span className={'hidden-label'}>{label}</span>
      </button>
      <ul ref={selectListRef}>
        {options.map(({ label, value }, index) => (
          <li
            key={index}
            onClick={() => {
              !isSmallerThanDesktopSmall && handleSelect(value);
              setIsVisible(false);
            }}
          >
            {label}
          </li>
        ))}
      </ul>
      <select className="select--mobile" value={value ?? ''} onChange={(e) => handleSelect(e.target.value)}>
        <option disabled hidden value={''}>
          {label ?? 'Choose here'}
        </option>
        {options.map(({ label, value }, index) => (
          <option value={value || ''} key={index}>
            {label}
          </option>
        ))}
      </select>
    </StyledSelect>
  );
};

export default Select;
