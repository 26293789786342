import React from 'react';

import styled from 'styled-components';

import { colors, breakpoints } from '../../../../constants/style';
import SanityBlockContent from '../../SanityBlockContent';

const SloganSection = styled('section')`
  padding-top: 8.4375rem;
  padding-bottom: 8.4375rem;

  h2 {
    margin-bottom: 1.5rem;
    font-family: "BioSans-SemiBold", sans-serif;
    font-size: 0.8rem;
    color: ${colors.primary};
    text-transform: uppercase;
    text-align: center;
  }

  p {
    margin: auto;
    max-width: 76.5625rem;
    font-family: "BioSans-Light", sans-serif;
    font-size: 2.25rem;
    line-height: 1.56;
    color: ${colors.muted};
    text-align: center;
  }

  @media screen and (max-width: ${breakpoints.tablet}) {
    padding-top: 5rem;
    padding-bottom: 5rem;

    h2 {
      margin-bottom: 1.5rem;
      font-size: 0.8rem;
    }

    p {
      max-width: unset;
      font-size: 2.2rem;
    }
  }

  @media screen and (max-width: ${breakpoints.mobile}) {
    h2 {
      margin-bottom: 1.5rem;
      font-size: 1rem;
    }

    p {
      max-width: unset;
      font-size: 1.8125rem;
    }
  }
`;

const Slogan = (props) => {
  const { title, children, rawDescription } = props;

  return (
    <SloganSection>
      {title && <h2>{title}</h2>}
      <SanityBlockContent blocks={rawDescription} />
      {children}
    </SloganSection>
  );
};

export default Slogan;
