import React from 'react';
import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';
import ReactDOM from 'react-dom';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import { useRef } from 'react';
import { zIndexes } from '../../../constants/style';

const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

const themeStyles = {
  black: {
    backgroundColor: '#000',
  },
  dark: {
    backgroundColor: 'rgba(0,0,0,0.4)',
  },
  veryDark: {
    backgroundColor: 'rgba(0,0,0,.85)',
  },
};

const ModalBackground = styled.div`
  background-color: ${({ theme }) => themeStyles[theme].backgroundColor};
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: ${zIndexes.modal.background};
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-container {
    max-height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Modal = ({ isVisible, children, theme }) => {
  return isVisible
    ? ReactDOM.createPortal(
        <>
          <ModalBackground theme={theme ? theme : 'black'}>
            <GlobalStyle />
            <div className="modal-container">{children}</div>
          </ModalBackground>
        </>,
        document.body,
      )
    : null;
};

export const StyledModalContent = styled('div')`
  max-width: 820px;
  margin: 0 auto;
  background: #fff;
  padding: 3rem;
  border-radius: 1rem;
`;
export const ModalContent = ({ children, className, onClose }) => {
  const modalRef = useRef(null);

  useOnClickOutside(modalRef, () => {
    return onClose(false);
  });

  return (
    <StyledModalContent ref={modalRef} className={className}>
      {children}
    </StyledModalContent>
  );
};

Modal.propTypes = {
  theme: PropTypes.oneOf([undefined, 'dark', 'black', 'veryDark']),
  isVisible: PropTypes.bool,
  children: PropTypes.any,
  onClose: PropTypes.func
};

export default Modal;
