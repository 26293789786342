import React from 'react'
import styled from 'styled-components'

import avatarPlaceholder from '../../../../../../static/images/icons/avatar-placeholder.svg'
import { colors, breakpoints } from '../../../../../../constants/style'
import TwitterLogo from '../../../../../../static/images/logo/logos-twitter.svg'
import LinkedInLogo from '../../../../../../static/images/logo/logos-linkedin.svg'
import FacebookLogo from '../../../../../../static/images/logo/logos-facebook.svg'
import SocialMediaLink from '../../../../../components/SocialMediaLink'
import EmployeeEntrySmall from 'components/EmployeeEntrySmall'
import { shareToFacebook, shareToLinkedIn, shareToTwitter } from 'utils/shareTo'
import { isSSR } from '../../../../../../constants/environment'

export const StyledPostMeta = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ isDescriptionVisible }) => (isDescriptionVisible ? '0 1.5rem' : '0')};
  width: 100%;
  @media only screen and (max-width: ${breakpoints.tablet}) {
    padding: 0;
  }
`

const Meta = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-top: ${({ isDescriptionVisible }) => (isDescriptionVisible ? '8.5rem' : '0')};
  @media only screen and (max-width: ${breakpoints.tablet}) {
    flex-direction: column;
    row-gap: 2.5rem;
  }
`

const Authors = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${({ isDescriptionVisible }) => (isDescriptionVisible ? '2rem' : '0.75rem')};

  .entry-small {
    &__name {
      display: flex;
      align-items: center;
      font-size: 1rem;
      line-height: 20px;
      font-weight: 600;
      color: var(--t1-title);
      text-transform: uppercase;
      margin-bottom: 2px;
    }
    &__image {
      width: 3.625rem;
      height: 3.625rem;
    }
    &__link {
      margin-left: 0.25rem;
    }
    &__position {
      font-size: 1rem;
      line-height: 28px;
      color: var(--t1-body);
      max-width: 612px;
    }
  }
  .author-details {
    gap: 1rem;
  }
  @media only screen and (max-width: ${breakpoints.tablet}) {
    width: 100%;
    margin-bottom: 1rem;
    ${({ isDescriptionVisible }) => !isDescriptionVisible && 'align-items: center;'}
  }
`

const SocialWrapper = styled.div`
  display: flex;
  align-items: center;
  color: var(--t1-title);

  > a {
    margin-left: 1rem;
  }

  span {
    font-size: 1.188rem;
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    width: 100%;
    justify-content: center;
  }
`

const PostMeta = ({ author, coAuthor, className, hasShareView = false, isDescriptionVisible }) => {
  const { name, image, position, description, employeeLinks } = author || {}
  const {
    name: coAuthorName,
    image: coAuthorImage,
    position: coAuthorPosition,
    description: coAuthorDescription,
    employeeLinks: coAuthorEmployeeLinks,
  } = coAuthor || {}

  const currentURL = isSSR ? '' : window.location.href

  const socialLinks = [
    {
      url: shareToLinkedIn(currentURL),
      icon: LinkedInLogo,
      alt: 'Linkedin',
    },
    {
      url: shareToTwitter(currentURL),
      icon: TwitterLogo,
      alt: 'Twitter',
    },
    {
      url: shareToFacebook(currentURL),
      icon: FacebookLogo,
      alt: 'Facebook',
    },
  ]

  return (
    <StyledPostMeta className={className} isDescriptionVisible={isDescriptionVisible}>
      <Meta isDescriptionVisible={isDescriptionVisible}>
        <Authors isDescriptionVisible={isDescriptionVisible}>
          <EmployeeEntrySmall
            name={name}
            image={image || avatarPlaceholder}
            position={position}
            links={employeeLinks}
            className="author-details"
            description={isDescriptionVisible && description}
          />
          {Boolean(coAuthor) && (
            <EmployeeEntrySmall
              name={coAuthorName}
              image={coAuthorImage || avatarPlaceholder}
              position={coAuthorPosition}
              links={coAuthorEmployeeLinks}
              className="author-details"
              description={isDescriptionVisible && coAuthorDescription}
            />
          )}
        </Authors>

        {hasShareView && (
          <SocialWrapper>
            <span>Share</span>
            {socialLinks.map(({ icon, url, alt }) => (
              <SocialMediaLink key={`social-link-${alt}`} to={url} img={icon} alt={alt} />
            ))}
          </SocialWrapper>
        )}
      </Meta>
    </StyledPostMeta>
  )
}

export default PostMeta
