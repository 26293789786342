import { FC } from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'
import { RecommendationData, RecommendedArticle } from './types'

import Image from 'components/Image'
import { parseSlug } from 'utils/slug'
import ArrowSVG from 'static/images/icons/arrow-more-right.svg'

import sharedStyles from './Recommendation.module.scss'
import styles from './RecommendationArticles.module.scss'

interface RecommendationArticleProps {
  article: RecommendedArticle
}

const RecommendationArticle: FC<RecommendationArticleProps> = ({ article }) => {
  if (!article) return null

  const {
    fullArticle: { categories, slug },
  } = article

  const blogPostUrl = `/blog/${parseSlug(categories[0].slug.current)}${parseSlug(slug.current)}`

  return (
    <Link to={blogPostUrl} className={styles.articleContainer}>
      <Image image={article.image} alt={article.title} className={styles.image} />
      <div className={styles.articleTitleContainer}>
        <p className={styles.articleTitle}>{article.title}</p>
        <p className={classNames(sharedStyles.link, styles.link)}>
          {article.linkText}
          <img
            src={ArrowSVG}
            alt=">"
            className={classNames(sharedStyles.arrowIcon, styles.arrowIcon)}
            itemProp="image"
            aria-hidden="true"
          />
        </p>
      </div>
    </Link>
  )
}

interface RecommendationArticlesProps {
  data: RecommendationData
}

export const RecommendationArticles: FC<RecommendationArticlesProps> = ({ data }) => {
  const {
    recommendations,
    sectionTitle,
    shouldDisplayBrowseAllButton,
    browseAllButtonLabel,
    browseAllButtonUrl,
  } = data
  const [recommendation] = recommendations
  const { articles } = recommendation

  return (
    <div className={classNames(sharedStyles.container)}>
      <div className={sharedStyles.topText}>
        <p className={sharedStyles.sectionTitle}>{sectionTitle}</p>
        {shouldDisplayBrowseAllButton && (
          <Link to={browseAllButtonUrl!} className={sharedStyles.link}>
            {browseAllButtonLabel}
            <img
              src={ArrowSVG}
              alt=">"
              className={sharedStyles.arrowIcon}
              itemProp="image"
              aria-hidden="true"
            />
          </Link>
        )}
      </div>
      {articles && (
        <div className={styles.articlesContainer}>
          <RecommendationArticle article={articles[0]} />
          <RecommendationArticle article={articles[1]} />
        </div>
      )}
    </div>
  )
}
