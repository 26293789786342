import React from 'react';
import Hero from '../../../components/sections/Hero';
import heroBgImage from '../../../../static/images/bg-hero.svg';
import TechnologyLogo from './TechnologyHeroLogo';
import styled from 'styled-components';
import { breakpoints } from '../../../../constants/style';

const StyledTechnologyHero = styled('div')`
  .column-right > img {
    margin-top: -10rem;

    @media only screen and (max-width: ${breakpoints.desktopLargest}) {
      margin-top: -14rem;
    }

    @media only screen and (max-width: ${breakpoints.desktop}) {
      margin-top: -16rem;
    }
  }
`;

const TechnologyHero = ({ header, description, preheader, contactButtonText, technologyImage, technologyName }) => {
  return (
    <StyledTechnologyHero>
      <Hero
        header={header}
        description={description}
        preheader={preheader}
        contactButtonText={contactButtonText}
        fullScreen
        showScroll
        hideRightColumnOnMobile
        rightColumnImage={{ asset: { extension: 'svg', url: heroBgImage } }}
        rightColumnImageFullSize
      >
        <TechnologyLogo image={technologyImage} header={technologyName} />
      </Hero>
    </StyledTechnologyHero>
  );
};

export default TechnologyHero;
