import styled from 'styled-components';
import { colors } from '../../../../constants/style';
import checkbox from '../../../../static/images/icons/checkbox.svg';
import checkboxActive from '../../../../static/images/icons/checkbox-active.svg';
import checkboxChecked from '../../../../static/images/icons/checkbox-checked.svg';

export const StyledCheckbox = styled.div`
  display: flex;
  font-family: Roboto-Regular, sans-serif;
  padding: ${({ isTerms }) => (isTerms ? '0 0 16px' : '10px 16px')};
  cursor: pointer;

  .checkbox-label,
  p {
    font-style: normal;
    font-weight: normal;
    font-size: ${({ isTerms }) => (isTerms ? '14px' : '16px')};
    line-height: ${({ isTerms }) => (isTerms ? '20px' : '32px')};
    margin-left: 8px;
    color: ${colors.titleDark};
    vertical-align: middle;
    cursor: pointer;
  }

  .checkbox-label {
    vertical-align: top;
  }

  p {
    margin-left: 0;
  }

  input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: ${({ isTerms }) => (isTerms ? '20px' : '24px')};
    width: ${({ isTerms }) => (isTerms ? '20px' : '24px')};
    margin-top: ${({ isTerms }) => (isTerms ? '0' : '4px')};

    &:focus ~ .checkmark {
      content: url(${checkboxActive});
    }
  }

  .checkmark {
    height: ${({ isTerms }) => (isTerms ? '20px' : '24px')};
    width: ${({ isTerms }) => (isTerms ? '20px' : '24px')};
    margin-top: ${({ isTerms }) => (isTerms ? '0' : '4px')};
    content: url(${checkbox});
    cursor: pointer;
  }

  &:hover {
    background-color: ${({ isTerms }) => (isTerms ? 'none' : colors.backgroundLight)};
    input ~ .checkmark {
      content: url(${checkboxActive});
    }
  }

  input[type='checkbox']:checked ~ .checkmark {
    content: url(${checkboxChecked});
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 6px;
    top: 3px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  a {
    // for links from sanity cms
    color: ${colors.primary};
  }
`;
