import React, { useState } from 'react'
import styled from 'styled-components'
import TransitionGroup from 'react-transition-group/TransitionGroup'
import Fade from 'react-reveal/Fade'

import Layout from '../../features/layout'
import { HeroSection } from '../../components/sections/Hero'
import ServicesItem from './components/ServicesItem'
import { breakpoints } from '../../../constants/style'
import {
  horizontalLayoutPadding,
  horizontalLayoutPaddingMobile,
  horizontalLayoutPaddingTablet,
} from '../../features/layout/components/commonLayoutStyle'
import ContactUs from '../../components/sections/ContactUs'
import HeroNew from '../../components/HeroNew'
import { parseSlug } from '../../utils/slug'
import { convertToBackgroundImage } from '../../utils/image'
import { useServicesList } from 'hooks/graphql/others/useServicesList'

const ServiceListHero = styled('div')`
  ${HeroSection} {
    .column-left {
      flex-basis: 70%;
      h1 {
        font-family: "BioSans-Light", sans-serif;
        font-size: 4rem;
      }
      .hero-copy {
        font-family: "BioSans-Light", sans-serif;
        color: #ffffff;
        font-size: 1.625rem;
        width: 65%;
        letter-spacing: 0.3px;
      }
    }

    @media screen and (max-width: ${breakpoints.desktopLarge}) {
      .column-left {
        h1 {
          font-size: 3.375rem;
        }
      }
    }

    @media screen and (max-width: ${breakpoints.desktop}) {
      .column-left {
        .hero-copy {
          font-size: 1.25rem;
          margin-top: 1rem;
        }
      }
    }

    @media screen and (max-width: ${breakpoints.tablet}) {
      min-height: 80vh;
      /* to focus image on dude's face */
      &:before {
        background-position: right !important;
      }
      .column-left {
        .hero-copy {
          width: 500%;
        }
      }
    }

    @media screen and (max-width: ${breakpoints.mobile}) {
      .column-left {
        h1 {
          font-size: 2.5rem;
        }
        .hero-copy {
          width: 100%;
        }
      }
    }
  }
`

const ServicesListSection = styled((props) => <TransitionGroup {...props} />)`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 80px;
  grid-auto-rows: 1fr;
  padding-left: ${horizontalLayoutPaddingMobile};
  padding-right: ${horizontalLayoutPaddingMobile};
  padding-top: 50px;
  padding-bottom: 60px;

  @media only screen and (max-width: ${breakpoints.desktop}) {
    overflow: hidden;
  }

  @media only screen and (min-width: ${breakpoints.tablet}) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 60px 30px;
    padding-left: ${horizontalLayoutPaddingTablet};
    padding-right: ${horizontalLayoutPaddingTablet};
    padding-bottom: 120px;
  }

  @media only screen and (min-width: ${breakpoints.desktop}) {
    grid-template-columns: repeat(3, 1fr);
    padding-left: calc(${horizontalLayoutPadding});
    padding-right: calc(${horizontalLayoutPadding});
    padding-top: 130px;
    padding-bottom: 170px;
  }

  @media only screen and (min-width: ${breakpoints.desktopLarge}) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 100px 50px;
  }
`

const ServicesList = () => {
  const { hero, servicesList, contactSection } = useServicesList()

  const [animatedElementIndex, setAnimatedElementIndex] = useState(0)
  const bgImage = convertToBackgroundImage(hero.mainImage && hero.mainImage.asset)

  return (
    <Layout>
      <ServiceListHero>
        <HeroNew
          bgImg={bgImage ? bgImage.fluid : null}
          alt={hero.mainImage ? hero.mainImage.alt : null}
          preheader={hero.pretitle}
          header={hero.title}
          desc={hero.description}
          scrollToQuerySelector={hero.scrollToQuerySelector}
          btnText={hero.contactButtonText || 'work with us'}
        />
      </ServiceListHero>
      <ServicesListSection>
        {servicesList.map(
          ({ parentService, slug, globalInfo: { name, description, skills, icon } }, i) => {
            // show only top level services (without selected parent)
            if (parentService === null || parentService.servicesPages === null) {
              return (
                <Fade
                  bottom
                  distance="100px"
                  appear
                  onReveal={() => {
                    setAnimatedElementIndex(animatedElementIndex + 1)
                  }}
                  duration={500}
                  wait={400}
                  key={`services-list-${parseSlug(slug.current)}`}
                >
                  <ServicesItem
                    slug={slug.current}
                    serviceName={name}
                    serviceDescription={description}
                    serviceIcon={icon}
                    skills={skills}
                    index={i}
                  />
                </Fade>
              )
            } else {
              return null
            }
          },
        )}
      </ServicesListSection>

      {Boolean(contactSection && contactSection.buttonLabel) && (
        <ContactUs
          theme="dark"
          header={contactSection.header}
          buttonLabel={contactSection.buttonLabel}
        />
      )}
    </Layout>
  )
}

export default ServicesList
