import React, { useRef, useState } from 'react';
import propTypes from 'prop-types';
import styled, { css } from 'styled-components';

import IconArrow from '../icons/IconArrow';
import { colors } from '../../../constants/style';
import useOnClickOutside from '../../hooks/useOnClickOutside';

const filledButtonGradient = `linear-gradient(90deg,#8658eb,#6831e4)`;
const filledButtonGradientHover = `linear-gradient(90deg,#7f4fea,#6128e3)`;
const filledButtonGradientGreen = `linear-gradient(90deg,#3DE1B4,#1BFEC0)`;
const filledButtonGradientGreenHover = `linear-gradient(90deg,#21D4A4,#01f4b3)`;
export const buttonWithBorderStyle = css`
  border-image-source: ${({ variant }) => (Boolean(variant === 'green') ? filledButtonGradientGreen : filledButtonGradient)};
  border-image-slice: 1;

  /* fixes bug in safari that doesn't show border with border-color transparent */
  border-color: inherit;
`;

const variantStyle = {
  default: { background: 'transparent', color: '#000000' },
  bordered: { background: 'transparent', color: '#000000', border: buttonWithBorderStyle },
  filled: {
    background: filledButtonGradient,
    color: '#FFFFFF',
    border: buttonWithBorderStyle,
    disabled: css`
      background: #edecf8;
      opacity: 1;
      & > span {
        color: #727292;
      }
      &::before {
        background: #edecf8;
      }
    `,
  },
  green: { background: filledButtonGradientGreen, hover: filledButtonGradientGreenHover, color: '#FFFFFF', border: buttonWithBorderStyle },
  muted: {
    background: ' #edec8',
    color: '#a3a3b8',
    border: css`
      border-color: #edecf8;
    `,
  },
};

const buttonVerticalPadding = '0.7rem';
const buttonHorizontalPadding = '2rem';

export const GeneralButtonStyle = css`
  position: relative;
  box-sizing: border-box;
  font-family: "BioSans-SemiBold", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 0.025rem;
  line-height: 1.5rem;
  text-transform: uppercase;
  display: block;
  text-align: center;
  padding: ${buttonVerticalPadding} ${buttonHorizontalPadding};
  border-style: solid;
  border-width: 0.125rem;
  cursor: pointer;
  border-color: transparent;
`;

export const StyledButton = styled('button')`
  ${GeneralButtonStyle}
  background: ${({ variant }) =>
    Boolean(variant && variantStyle[variant]?.background) ? variantStyle[variant].background : variantStyle.default.background};
  ${({ variant }) => Boolean(variant && variantStyle[variant]?.border) && variantStyle[variant].border};

  // use ::before pseudo element together with span as workaround for linear background animation
  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    background: ${({ variant }) =>
      Boolean(variant && variantStyle[variant].hover) ? variantStyle[variant].hover : filledButtonGradientHover};
    z-index: 0;
    transition: opacity 0.2s ease-in-out;
  }
  > span {
    position: relative;
    z-index: 1;
    color: ${({ variant }) =>
      Boolean(variant && variantStyle[variant]?.color) ? variantStyle[variant].color : variantStyle.default.color};
    transition: color 0.2s ease-in-out;
  }
  &:hover {
    &::before {
      opacity: 1;
    }
    > span {
      color: #ffffff;
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.3;

    ${({ variant }) => Boolean(variant && variantStyle[variant]?.disabled) && variantStyle[variant]?.disabled}
  }
`;

const Button = ({ children, filled, className, onClick, disabled, type, variant }) => {
  return (
    <StyledButton type={type} className={className} onClick={onClick} disabled={disabled} variant={variant}>
      <span>{children}</span>
    </StyledButton>
  );
};

const StyledButtonWithIcon = styled(Button)`
  span {
    display: flex;
    align-items: center;
    img {
      ${({ iconPosition }) => (iconPosition === 'left' ? 'margin-right: 0.5rem;' : 'margin-left: 0.5rem;')}
    }
  }
`;

export const ButtonWithIcon = ({ children, className, onClick, disabled, type, iconSrc, variant, iconPosition = 'left' }) => {
  return (
    <StyledButtonWithIcon
      type={type}
      className={className}
      onClick={onClick}
      disabled={disabled}
      variant={variant}
      iconPosition={iconPosition}
    >
      {iconPosition !== 'right' ? <img src={iconSrc} alt="" /> : null}
      {children}
      {iconPosition === 'right' ? <img src={iconSrc} alt="" /> : null}
    </StyledButtonWithIcon>
  );
};

const StyledButtonWithIconAndSubMenu = styled('div')`
  width: max-content;

  button {
    padding-left: 1.1rem;
  }
`;

const StyledSubmenu = styled('div')`
  background-color: white;
  position: absolute;
  top: 190%;
  left: -1.1rem;
  width: calc(2 * ${buttonHorizontalPadding} + 100% - (${buttonHorizontalPadding} - 1.1rem));

  .submenu-link {
    display: flex;
    font-size: 1rem;
    line-height: 1.75rem;
    text-transform: none;
    color: ${colors.titleDark};
    padding: 0.5rem 1rem;

    &:not(:last-child) {
      border-bottom: 1px solid #e6e6ef;
    }

    &:hover {
      background-color: ${colors.backgroundLight};
    }
  }
`;

export const ButtonWithIconAndSubMenu = ({ children, className, disabled, type, iconSrc, variant, subMenuItems = [] }) => {
  const submenuRef = useRef(null);
  const [isSubmenuVisible, setIsSubmenuVisible] = useState(false);

  const toggleSubmenu = () => {
    setIsSubmenuVisible((prevState) => !prevState);
  };

  useOnClickOutside(submenuRef, () => {
    setIsSubmenuVisible(false);
  });

  return (
    <StyledButtonWithIconAndSubMenu ref={submenuRef}>
      <ButtonWithIcon type={type} className={className} onClick={toggleSubmenu} disabled={disabled} variant={variant} iconSrc={iconSrc}>
        {children}
        {isSubmenuVisible && (
          <StyledSubmenu>
            {subMenuItems.map((item, index) => (
              <a className="submenu-link" key={index} href={item.url} target="_blank" rel="noreferrer" onClick={item.onClick}>
                <img src={item.iconSrc} alt="" />
                <span>{item.name}</span>
              </a>
            ))}
          </StyledSubmenu>
        )}
      </ButtonWithIcon>
    </StyledButtonWithIconAndSubMenu>
  );
};

const StyledButtonWithArrow = styled(Button)`
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: ${({ arrowPosition }) => arrowPosition === 'left' && 'row-reverse;'};
  }
  svg {
    ${({ arrowPosition }) => (arrowPosition === 'right' ? 'margin-left: 12px;' : 'margin-right: 12px')};
    width: 13px;
    height: 13px;
    fill: #fff;
    ${({ arrowPosition }) => arrowPosition === 'left' && 'transform: rotate(180deg);'};
  }
`;

export const ButtonWithArrow = ({ children, className, onClick, disabled, type, arrowPosition = 'right', variant }) => {
  return (
    <StyledButtonWithArrow
      type={type}
      className={className}
      onClick={onClick}
      disabled={disabled}
      variant={variant}
      arrowPosition={arrowPosition}
    >
      {children}
      <IconArrow />
    </StyledButtonWithArrow>
  );
};

Button.defaultProps = {
  className: '',
  type: 'button',
  variant: null,
};

Button.propTypes = {
  className: propTypes.string,
  variant: propTypes.oneOf(['filled', 'muted', 'bordered', 'green', null]),
};

export default Button;
