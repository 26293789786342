import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import Slider from 'react-slick';

import logoCluth from '../../../../../assets/images/logo/logo-clutch.png';
import logoDeloitte from '../../../../../assets/images/logo/logo-deloitte.webp';
import logoFinancialTimes from '../../../../../assets/images/logo/logo-financial-times.webp';
import foundingpartnerLightRemovebg from '../../../../../assets/images/logo/foundingpartner_light-removebg.png';
import { breakpoints } from '../../../../../../constants/style';

const StyledCarouselContainer = styled('div')`
  div.li-container {
    display: flex;
    align-items: center;
    width: 23vw;

    div.li-img {
      opacity: 55%;
    }

    div.li-label {
      opacity: 50%;
      font-size: 0.75rem;
      line-height: 1.125rem;
      margin-left: 2rem;
    }

    div.li-img,
    div.li-label {
      width: fit-content;
    }

    img {
      display: inline;
      height: 2.5rem;
      object-fit: contain;
    }
  }

  div {
    width: 600px;
  }

  // this part is styling external component (src/external/react-slick/track.js). This is workaround of an issue that this component load css after some time (on builded app)
  div.slick-slider div.slick-list div.slick-track {
    display: flex;
  }

  @media only screen and (max-width: ${breakpoints.desktop}) {
    div.li-container {
      width: 40vw;
    }
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    div.li-container {
      width: 80vw;
    }
  }
`;

const FooterAward = ({ awards }) => {
  const getPath = (name) => {
    // this is workaround for slick carousel - it got issue when using GatsbyImage
    switch (name) {
      case 'logo-clutch.png':
        return logoCluth;
      case 'logo-deloitte.webp':
        return logoDeloitte;
      case 'logo-financial-times.webp':
        return logoFinancialTimes;
      case 'foundingpartner_light-removebg.png':
        return foundingpartnerLightRemovebg;
      default:
        return logoCluth;
    }
  };

  const settings = {
    arrows: false,
    draggable: false,
    swipe: false,
    vertical: false,
    responsive: null,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 800,
  };

  const awardsList = awards.map(({ title, alt, fileName }) => (
    <li key={title}>
      <div className="li-container">
        <div className="li-img">
          <img src={getPath(fileName)} alt={alt} />
        </div>
        <div className="li-label">{title}</div>
      </div>
    </li>
  ));

  return (
    <StyledCarouselContainer>
      <Slider {...settings}>{awardsList}</Slider>
    </StyledCarouselContainer>
  );
};

FooterAward.propTypes = {
  awards: propTypes.arrayOf(propTypes.object).isRequired,
};

export default FooterAward;
