import { useHeroContactForm } from '../HeroContactForm/useHeroContactForm';

// https://zapier.com/app/editor/139632854/nodes/139632854/sample this one zap is connected
const triggerZapierWebhook = (data) =>
  fetch('/api/service-mvp-40-days-form', {
    method: 'POST',
    body: JSON.stringify(data),
  }).then((res) => ({ status: res.status, response: res }));

export const useDownloadForm = ({ formName, fileUrl, showProjectDescriptionInput }) => {
  const {
    formData,
    errorMessages,
    setErrorMessages,
    isFormSent,
    setIsFormSent,
    isLoading,
    setIsLoading,
    validation,
    handleInputChange,
  } = useHeroContactForm({ showProjectDescriptionInput });

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (validation.fails()) return setErrorMessages(validation.errors.all());

    setIsLoading(true);
    const data = { formName: formName, ...formData };

    if (formName.trim() === 'MVP_V3') {
      // This run only on /services/building-an-mvp/ - 40 days mvp estimate project form
      const { status, response } = await triggerZapierWebhook(data);
      if (status !== 200)
        return response
          .json()
          .then(({ errors }) => setErrorMessages({ firstName: errors }))
          .then(() => setIsLoading(false));
    }

    fetch('/api/send-whitepaper-form', {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 200) {
          setIsFormSent(true);
          if (fileUrl) window.open(`${fileUrl}?dl=`, '_blank');
        } else {
          response.json().then((data) => setErrorMessages(data.errors));
        }
      })
      .finally(() => setIsLoading(false));
  };

  return { handleFormSubmit, isFormSent, errorMessages, formData, handleInputChange, isLoading };
};
