import React from 'react'
import SliderSection from 'components/SliderSection/SliderSection'
import { ArticleCard } from '../../ArticleCard'

const TilesSection = ({ header, description, tiles }) => {
  if (!tiles) {
    return
  }
  const lastThreeTiles = tiles.slice(0, 3)
  return (
    <SliderSection
      header={header}
      description={description}
      items={lastThreeTiles}
      headerPosition='center'
    >
      {lastThreeTiles.map((item, idx) => (
        <ArticleCard
          key={idx}
          title={item.title}
          preTitle={item.postCategory}
          featuredImage={item.featuredImage}
          link={item.href}
          description={item.description}
        />
      ))}
    </SliderSection>
  )
}

export default TilesSection
