import { useStaticQuery, graphql } from 'gatsby'

export const useCareer = () => {
  const data = useStaticQuery(graphql`
    {
      sanityCareers(language: { eq: "en" }) {
        careerDictionary {
          remoteLabel
          remoteOnlyLabel
          applyLabel
        }
        careerAdPage {
          contactSection {
            ...ContactSection
          }
          heroImage {
            asset {
              gatsbyImageData(width: 1400)
            }
          }
          seoDescription
        }
      }
    }
  `)
  return data.sanityCareers
}
