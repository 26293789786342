import { useState } from 'react';
import Validator from '../../../../../utils/validator';

const defaultFormData = {
  email: '',
  marketingAgreement: false,
};

const validationRules = {
  email: 'required|email',
  marketingAgreement: 'accepted',
};

const useDownloadFileModal = ({ fileUrl, handleOnComplete, titleOfSelectedFile }) => {
  const [formData, setFormData] = useState(defaultFormData);

  const [errorMessages, setErrorMessages] = useState(defaultFormData);
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    const { type, value, checked, name } = e.target;
    const newFormData = { ...formData };
    newFormData[name] = type === 'checkbox' ? checked : value;
    setFormData(newFormData);
  };

  const validation = new Validator(formData, validationRules, { accepted: 'You need to accept terms of service' });

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validation.fails()) return setErrorMessages(validation.errors.all());
    setIsLoading(true);

    const data = { email: formData.email, sourcePage: window.location.href, titleOfSelectedFile, name: 'Whitepaper downloads' };

    fetch('/api/resources-download-webhook', {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          response?.json()?.then((data) => setErrorMessages(data?.errors));
        }
      })
      .finally(() => {
        setIsLoading(false);
        window.open(`${fileUrl}?dl=`, '_blank');
        setFormData(defaultFormData);
        handleOnComplete();
      });
  };

  return { formData, handleInputChange, errorMessages, handleSubmit, isLoading };
};

export default useDownloadFileModal;
