import throttle from 'lodash.throttle';
import { useEffect, useState } from 'react';

const useHideOnScroll = () => {
  const [shouldHideNavigation, setShouldHideNavigation] = useState(false);
  const [scrollYState, setScrollYState] = useState({
    prev: 0,
    current: 0,
  });

  useEffect(() => {
    const hideOnScrollDown = throttle(() => {
      // check for pageYOffset is to fix issue on iphone where the scroll goes outside of bounds and comes back
      // https://10clouds.atlassian.net/browse/WWWNEW-538
      const currentScrollPosition = window.pageYOffset;
      if (currentScrollPosition) {
        setScrollYState((prevState) => ({
          prev: prevState.current,
          current: currentScrollPosition,
        }));
      }
    }, 200);

    window.addEventListener('scroll', hideOnScrollDown);
    return () => {
      window.removeEventListener('scroll', hideOnScrollDown);
    };
  }, []);

  useEffect(() => {
    if (scrollYState.current === scrollYState.prev) return;

    const shouldHide = scrollYState.prev < scrollYState.current && scrollYState.current > 100;
    setShouldHideNavigation(Boolean(shouldHide));
  }, [scrollYState]);

  return { shouldHideNavigation };
};

export default useHideOnScroll;
