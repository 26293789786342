import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import Prism from 'prismjs'

import { SEO } from 'components/Seo'
import Layout from '../../layout'
import SanityBlockContent from '../../../components/SanityBlockContent'
import { getTimeToReadFromSanityBlock } from '../../../components/SanityBlockContent/sanityBlockContentUtils'
import PostIntro from './components/postIntro'
import PostMeta from './components/postIntro/postMeta'
import ContactUs from '../../../components/sections/ContactUs'
import { enhanceBlogPost } from './utils/enhanceBlogPost'
import { BlogPostContainer, BlogPostContent, BlogPostTextWrapper } from './styled'
import { calculateBlogPostBodyWithSmallerImages } from '../../../utils/blog'
import NewsletterModal from '../../../components/Modal/NewsletterModal'
import SubscribeSectionNew from 'components/sections/SubscribeToNewsletter/SubscribeToNewsletterNew'
import StickyBadge from 'components/StickyBadge'
import Articles from 'components/sections/Articles'
import { StructuredDataArticle } from 'components/StructuredDataArticle'
import { useBlogPostsSettingsQuery } from 'hooks/graphql/blog'
import 'hooks/graphql/blog'

export const query = graphql`
  query BlogPostPageQuery($id: String!, $categorySlug: [String]!) {
    ...sanityBlogPost
    ...blogPostSuggestions
    ...allSanityBlogCategory
  }
`

const BlogPostPage = (props) => {
  const { contactSection } = useBlogPostsSettingsQuery()
  const {
    data: {
      sanityBlogPost,
      allSanityBlogPost: { nodes: blogPostSuggestions },
      allSanityBlogCategory: { nodes: blogCategoryBadge },
    },
  } = props
  const { title, featuredImage, categories, author, coAuthor, createdAt, _rawBody, stickyBadge } =
    sanityBlogPost

  const stickyBadgeByCategory = blogCategoryBadge[0]?.stickyBadge
  const stickyBadgeData = stickyBadge || stickyBadgeByCategory

  const rawBodyWithSmallerImages = calculateBlogPostBodyWithSmallerImages(_rawBody, 1200)
  const timeToRead = getTimeToReadFromSanityBlock(rawBodyWithSmallerImages)

  useEffect(() => {
    if (_rawBody.length) {
      const hasCodeExamples = _rawBody.some((section) => section._type === 'code')

      if (hasCodeExamples) {
        Prism.highlightAll()
      }
    }
  }, [])

  return (
    <Layout shouldNavigationStartTransparent={false}>
      <BlogPostContainer>
        <BlogPostContent>
          <PostIntro
            categories={categories}
            title={title}
            createdAt={createdAt}
            author={author}
            coAuthor={coAuthor}
            timeToRead={timeToRead}
            featuredImage={featuredImage}
          />
          <BlogPostTextWrapper>
            <div className="left-column">
              <SanityBlockContent blocks={enhanceBlogPost(rawBodyWithSmallerImages)} />
              <PostMeta author={author} coAuthor={coAuthor} isDescriptionVisible />
            </div>
            {!!stickyBadgeData && (
              <StickyBadge
                title={stickyBadgeData.title}
                description={stickyBadgeData.description}
                button={stickyBadgeData.button}
              />
            )}
          </BlogPostTextWrapper>
        </BlogPostContent>
      </BlogPostContainer>
      <SubscribeSectionNew theme="dark" />

      {blogPostSuggestions.length > 0 && (
        <Articles blogPosts={blogPostSuggestions} header="You may also like these posts" raw="" />
      )}
      <ContactUs
        header={contactSection.header}
        buttonLabel={contactSection.buttonLabel}
        theme="lightPurpleToDark"
      />
      <NewsletterModal />
    </Layout>
  )
}

export function Head({ location, data: { sanityBlogPost } }) {
  const { author, createdAt, featuredImage, seoImage, seo, title } = sanityBlogPost
  return (
    <SEO
      description={seo?.meta_description}
      location={location}
      image={seoImage?.asset ? seoImage : featuredImage}
      title={seo?.seo_title || title}
    >
      <StructuredDataArticle
        headline={title}
        postURL={location.pathname}
        datePublished={createdAt}
        image={[featuredImage?.asset?.url]}
        author={author}
      />
    </SEO>
  )
}

export default BlogPostPage
