import { useEffect, useState } from 'react'

export const useTraffit = () => {
  const [jobPosts, setJobPosts] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const getJobPosts = async () => {
    setIsLoading(true)
    fetch('/api/get-traffit-data', {
      method: 'GET',
    })
      .then((r) => r.json())
      .then((response) => {
        setJobPosts(response)
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    getJobPosts()
  }, [])

  return {
    jobPosts,
    isLoading,
  }
}
