import React, { useState, useEffect, useRef } from 'react'
import classNames from 'classnames'

import styled, { css } from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

import Image from '../../Image'
import { NavLinkButtonWithArrow } from '../../Link'

import { navbarMinHeight } from '../../../features/layout/components/commonLayoutStyle'
import {
  horizontalLayoutPadding,
  horizontalLayoutPaddingMobile,
} from '../../../features/layout/components/commonLayoutStyle'
import { colors, breakpoints } from '../../../../constants/style'
import { contact } from '../../../../constants/links'

import GridSVG from '../../../../static/images/grid.svg'

import { animateScroll as scroll } from 'react-scroll'
import { ButtonWithArrow, StyledButton as Button } from '../../Buttons'
import { isSSR } from '../../../../constants/environment.js'
import { checkLink } from '../../../utils/link'
import useWindowSize from '../../../hooks/useWindowSize.js'
import useIsMounted from '../../../hooks/useIsMounted'

const rightColumnMobileVisible = css`
  padding-top: 3rem;
  flex-basis: 100%;
  padding-bottom: 0;
`
const rightColumnMobileHidden = css`
  display: none;
`

const styleForImage = {
  backgroundSize: 'cover',
  backgroundPosition: 'top center',
}

const styleForGrid = {
  backgroundSize: '100% auto',
  backgroundPosition: 'right bottom',
}

const styleForGridMedium = {
  backgroundSize: '150% auto',
  backgroundPosition: 'center bottom',
}

const styleForGridMobile = {
  backgroundSize: '600% bottom',
  backgroundPosition: 'center bottom',
}

const overlappingImage = {
  image: {
    zIndex: '-1',
    position: 'static',
  },
  imageSmall: {
    position: 'relative',
    transform: 'translate(-37%, 15%) scale(1.4)',
  },
}

export const HeroSection = styled((props) => {
  // extract non html props to prevent browser errors
  const {
    isVideo,
    rightColumnImageFullSize,
    hideRightColumnOnMobile,
    fullScreen,
    isRightColumnImageOverlapping,
    ...restProps
  } = props
  return isVideo ? <section {...restProps} /> : <BackgroundImage {...props} />
})`
  min-height: ${(props) => (props.fullScreen ? '100vh' : '80vh')};
  font-family: "BioSans-Light", sans-serif;
  font-weight: 400;
  padding-top: ${navbarMinHeight};
  background-color: ${colors.titleDark};
  display: flex;
  align-items: center;

  ${(props) =>
    props.isVideo &&
    css`
      position: relative;

      .backgroundVideoWrapper,
      .backgroundVideo {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .backgroundVideoWrapper {
        &:after {
          content: '';
          position: absolute;
          background: linear-gradient(
            297deg,
            rgba(16, 7, 28, 0.13) 0%,
            rgba(16, 7, 39, 0.87) 52%,
            rgba(16, 7, 39, 1) 100%
          );
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        }
      }
    `};

  .content {
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 5rem;
    position: ${({ isRightColumnImageOverlapping }) =>
      isRightColumnImageOverlapping ? 'static' : 'relative'};
    margin: ${({ fullScreen }) => (!fullScreen ? '3rem 0' : '0')};

    @media only screen and (max-width: ${breakpoints.desktop}) {
      margin: 0;
    }
    @media only screen and (max-width: ${breakpoints.desktopSmall}) {
      flex-direction: column;
    }
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    min-height: unset;
    padding: ${navbarMinHeight} ${horizontalLayoutPaddingMobile};

    &,
    .content {
      display: block;
    }
  }

  .column-left {
    flex: 1 0 50%;
    max-width: 70vw;
    margin-top: 5.5rem;

    &:only-child {
      & > h1:first-child {
        font-size: clamp(2.75rem, calc(4.375vw + 1rem), 5.125rem);
        line-height: 1.2;
      }
    }

    h1 {
      color: var(--t1-title);
      margin-bottom: 0;
    }

    .textContainer {
      max-width: 640px;
    }

    @media only screen and (max-width: ${breakpoints.desktopSmall}) {
      max-width: 100%;
    }

    @media only screen and (max-width: ${breakpoints.tablet}) {
      margin-top: 1.5rem;
      padding-bottom: 0;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
    }

    .hero-preheader {
      color: var(--t1-overline);
      text-transform: uppercase;
      font-family: "BioSans-SemiBold", sans-serif;
    }
    .hero-preheader + h1 {
      margin-top: 0.5rem;
    }
    .hero-copy {
      font-family: "BioSans-Regular", sans-serif;
      margin-top: 1.75rem;
      font-size: 1.3125rem;
      color: var(--t1-body);
      line-height: 1.71;
      white-space: pre-line;
      @media only screen and (max-width: ${breakpoints.desktopSmall}) {
        font-size: 1rem;
        line-height: 1.5;
      }
      @media only screen and (max-width: ${breakpoints.tablet}) {
        margin-top: 2rem;
      }
    }
  }

  .column-left:has(.animatedTitle) {
    max-width: 100vw;
  }

  .column-right {
    flex-basis: 50%;
    margin-right: ${(props) =>
      props.rightColumnImageFullSize ? `-${horizontalLayoutPadding}` : 0};
    padding-bottom: ${(props) => (props.rightColumnImageFullSize ? 0 : navbarMinHeight)};
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;

    > img,
    .gatsby-image-wrapper {
      max-width: 48.5rem;
      margin-left: 3rem;
      margin-top: 2rem;
      ${({ fullScreen }) =>
        fullScreen &&
        css`
          @media only screen and (min-width: ${breakpoints.desktopMax}) {
            position: absolute;
            top: -4.375rem;
          }
        `}
    }

    .gatsby-image-wrapper img {
      ${({ isRightColumnImageOverlapping }) =>
        isRightColumnImageOverlapping &&
        css`
          max-width: 105rem;
          top: 50%;
          left: 55%;
          transform: translate(-50%, -50%);
          @media only screen and (max-width: ${breakpoints.desktopMax}) {
            max-width: 75%;
          }
          @media only screen and (max-width: ${breakpoints.desktop}) {
            left: 50%;
          }
        `};
    }

    @media only screen and (max-width: ${breakpoints.tablet}) {
      ${({ hideRightColumnOnMobile }) =>
        hideRightColumnOnMobile ? rightColumnMobileHidden : rightColumnMobileVisible}
    }
  }

  .hero-buttons {
    display: flex;
    align-items: center;
    justify-content: stretch;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 3rem;

    @media only screen and (max-width: ${breakpoints.tablet}) {
      & > * {
        flex: 1 1 auto;
      }
    }
  }

  .animatedTitle {
    font-size: 72px !important;
  }

  @media only screen and (max-width: ${breakpoints.desktopMax}) {
    .animatedTitle {
      font-size: 62px !important;
    }
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    .animatedTitle {
      font-size: 40px !important;
    }
  }

  @media only screen and (max-width: ${breakpoints.mobile}) {
    .animatedTitle {
      font-size: 32px !important;
    }
  }

  @media only screen and (max-width: ${breakpoints.mobileSmall}) {
    .animatedTitle {
      font-size: 28px !important;
    }
  }

  @media only screen and (max-width: ${breakpoints.desktopLargest}) {
    .rotatingWord {
      display: block;
    }
  }

  .tickingLine {
    display: inline-block;
    width: 10px;
    height: 2px;
    background-color: black;
    animation: ticking 0.5s infinite;
  }

  @keyframes ticking {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .rotatingWord {
    color: #1bfdbf;
  }
  .rotatingWord.lastWord {
    color: white;
  }

  ${(props) => props.customStylesHeroforEvent}
`

const StyledButton = styled(Button)`
  &,
  &:hover {
    span {
      color: #fff;
      font-weight: 600;
    }
  }
`

const Hero = ({
  backgroundImage,
  backgroundVideo,
  alt,
  preheader,
  header,
  animatedTitle,
  description,
  contactButtonText,
  rightColumnImage,
  isRightColumnImageOverlapping = false,
  rightColumnImageFullSize = false,
  fullScreen = false,
  hideRightColumnOnMobile = true,
  children,
  scrollToQuerySelector,
  additionalButton = null, // { label: string, onClick: void }
}) => {
  const { isSmallerThanDesktop, isSmallerThanDesktopSmall, windowSize } = useWindowSize()
  const isMounted = useIsMounted()
  const isVideo = Boolean(isMounted && !isSmallerThanDesktop && backgroundVideo?.videoFile?.asset)
  const isLink = checkLink(scrollToQuerySelector)
  const childElements = React.Children.toArray(children)
  const isShortenedText = !!preheader

  const [currentWordIndex, setCurrentWordIndex] = useState(0)
  const [rotatingWord, setRotatingWord] = useState('')
  const [showTickingLine, setShowTickingLine] = useState(true)
  const [lastWord, setLastWord] = useState(false)
  const timeoutsRef = useRef([])

  useEffect(() => {
    if (animatedTitle?.dynamicWords?.length > 0) {
      const totalWords = animatedTitle.dynamicWords.length
      const isLastWord = currentWordIndex === totalWords - 1
      setLastWord(isLastWord)
    }
  }, [animatedTitle?.dynamicWords?.length, currentWordIndex])

  useEffect(() => {
    const rotateWord = (word, index, letterIndex, isDeleting) => {
      const wordLength = word.length
      const timeouts = []

      if (isDeleting) {
        if (letterIndex >= 0) {
          const timeoutId = setTimeout(() => {
            setRotatingWord(word.substring(0, letterIndex))
            rotateWord(word, index, letterIndex - 1, true)
          }, 50)
          timeouts.push(timeoutId)
        } else {
          setShowTickingLine(true)
          const timeoutId = setTimeout(() => {
            setCurrentWordIndex((index + 1) % animatedTitle.dynamicWords.length)
            rotateWord(
              animatedTitle.dynamicWords[(index + 1) % animatedTitle.dynamicWords.length],
              (index + 1) % animatedTitle.dynamicWords.length,
              0,
              false,
            )
          }, 1000)
          timeouts.push(timeoutId)
        }
      } else {
        if (letterIndex <= wordLength) {
          const timeoutId = setTimeout(() => {
            setRotatingWord(word.substring(0, letterIndex))
            rotateWord(word, index, letterIndex + 1, false)
          }, 100)
          timeouts.push(timeoutId)
        } else {
          setShowTickingLine(false)
          const timeoutId = setTimeout(() => {
            rotateWord(word, index, wordLength, true)
          }, 1000)
          timeouts.push(timeoutId)
        }
      }

      timeoutsRef.current = timeouts
    }
    animatedTitle?.header && animatedTitle?.dynamicWords.length > 0 && rotateWord(animatedTitle.dynamicWords[currentWordIndex], currentWordIndex, 0, false)

    return () => {
      timeoutsRef.current.forEach((timeoutId) => clearTimeout(timeoutId))
    }
  }, [animatedTitle?.dynamicWords, animatedTitle?.header, currentWordIndex])

  const scrollToSectionHandler = () => {
    // we remove 100px to generate offset for navbar
    const navbarOffset = isSmallerThanDesktop ? 0 : 100
    scroll.scrollTo(
      document.querySelector(scrollToQuerySelector)?.getBoundingClientRect().top -
        navbarOffset +
        window.pageYOffset,
    )
  }

  const backgroundStack = [
    ...(!backgroundImage ? [`url(${GridSVG})`] : []),
    `linear-gradient(297deg, rgba(16,7,28,0.13) 0%, rgba(16,7,39,0.87) 52%, rgba(16,7,39,1) 100%)`,
    ...(backgroundImage ? [backgroundImage] : []),
  ]

  let styleForGridCurr = {}

  if (isSmallerThanDesktopSmall) {
    styleForGridCurr = styleForGridMobile
  } else if (!isSmallerThanDesktopSmall && windowSize.width <= 1800) {
    styleForGridCurr = styleForGridMedium
  } else if (windowSize.width > 1800) {
    styleForGridCurr = styleForGrid
  }

  const imageProps = {
    Tag: 'section',
    fluid: backgroundStack,
    alt,
    style: backgroundImage ? styleForImage : styleForGridCurr,
  }

  return (
    <HeroSection
      isVideo={isVideo}
      {...(!isVideo ? imageProps : {})}
      rightColumnImageFullSize={rightColumnImageFullSize}
      hideRightColumnOnMobile={hideRightColumnOnMobile}
      fullScreen={fullScreen}
      isRightColumnImageOverlapping={isRightColumnImageOverlapping}
      // in the future className value should be dynamic
      className="dark"
    >
      {isVideo && (
        <div className="backgroundVideoWrapper">
          <video className="backgroundVideo" loop autoPlay muted>
            <source
              src={backgroundVideo.videoFile.asset.url}
              type={backgroundVideo.videoFile.asset.mimeType || 'video/mp4'}
            />
            Sorry, your browser doesn't support embedded videos.
          </video>
        </div>
      )}

      <div className="content container">
        <div className="column-left">
          {preheader && <p className="hero-preheader">{preheader}</p>}
          {animatedTitle?.header && animatedTitle?.dynamicWords.length > 0 ? (
            <h1 className="animatedTitle">
              {animatedTitle.header}
              <span className={`rotatingWord ${lastWord ? 'lastWord' : ''}`}> {rotatingWord}{showTickingLine && <span className="tickingLine">|</span>}</span>

            </h1>
          ) : (
            <h1 className={classNames(isShortenedText && 'textContainer')}>{header}</h1>
          )}
          <p className={classNames('hero-copy', isShortenedText && 'textContainer')}>
            {description}
          </p>
          <div className="hero-buttons">
            {Boolean(contactButtonText && scrollToQuerySelector && !isLink && !isSSR) && (
              <ButtonWithArrow
                onClick={scrollToSectionHandler}
                variant="filled"
                className="contact-btn"
              >
                {contactButtonText}
              </ButtonWithArrow>
            )}
            {/* if scrollToQuerySelector is empty, link isn't empty and redirects to contact-us page */}
            {Boolean((contactButtonText && (!scrollToQuerySelector || isLink)) || isSSR) && (
              <NavLinkButtonWithArrow
                className="contact-btn"
                to={isLink ? scrollToQuerySelector : contact.url}
                variant="filled"
              >
                {contactButtonText}
              </NavLinkButtonWithArrow>
            )}
            {additionalButton && (
              <StyledButton variant="bordered" onClick={additionalButton.onClick}>
                <span>{additionalButton.label}</span>
              </StyledButton>
            )}
          </div>
          {Boolean(isSmallerThanDesktopSmall) && Boolean(rightColumnImage) && (
            <Image
              style={isRightColumnImageOverlapping ? overlappingImage.imageSmall : {}}
              objectFit={isRightColumnImageOverlapping ? 'contain' : 'cover'}
              image={rightColumnImage}
            />
          )}
        </div>

        {Boolean(rightColumnImage || childElements?.length) && (
          <div className="column-right">
            {Boolean(rightColumnImage) && (
              <Image
                style={isRightColumnImageOverlapping ? overlappingImage.image : {}}
                objectFit={isRightColumnImageOverlapping ? 'contain' : 'cover'}
                image={rightColumnImage}
              />
            )}
            {Boolean(childElements?.length) && children}
          </div>
        )}
      </div>
    </HeroSection>
  )
}

export default Hero
