import React from 'react'
import styled, { css } from 'styled-components'
import { IconLink, NavLinkButton } from 'components/Link'
import { breakpoints, colors } from '../../../constants/style'
import IconArrow from '../icons/IconArrow'

const ROUNDED_LINK_WIDTH = '72px'

const redirectLinkContainerStyles = `
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  gap: 1.563rem;
  @media only screen and (max-width: ${breakpoints.mobileLarge}) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const RedirectInfoLinkIconWrapper = styled.span`
  font-family: Roboto-Medium, sans-serif;
  font-size: 1.125rem;

  > span {
    display: flex;
    white-space: nowrap;
    align-items: center;
  }

  .link__icon {
    display: flex;
    flex: 0 0 4.5rem;
    width: 4.5rem;
    height: 4.5rem;
    justify-content: center;
    align-items: center;
    border: 1px solid ${colors.primary};
    border-radius: 50%;
    margin-right: 1.875rem;

    svg {
      width: 30%;
    }
  }
`

const RedirectInfoLinkWrapper = styled.div`
  background-color: white;

  .redirect-link__item {
    height: 100%;
    padding: 54px 48px;
    border-bottom: 1px solid ${colors.shadow};
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${({ linkUrl }) =>
      !linkUrl &&
      css`
        ${redirectLinkContainerStyles}
      `}

    span {
      ${redirectLinkContainerStyles}
    }

    .title {
      text-transform: capitalize;
      font-weight: 400;
      text-align: left;
      flex: 1;
      transition: 0.25s ease-in-out;
      color: var(--t1-title);
      @media only screen and (max-width: ${breakpoints.desktopLargest}) {
        flex: 0.7;
      }
      @media only screen and (max-width: ${breakpoints.mobileLarge}) {
        margin-bottom: 1rem;
      }
    }

    .description {
      position: relative;
      transform: scale(1);
      opacity: 1;
      visibility: visible;
      text-align: left;
      color: var(--t1-body);
      text-transform: none;
      font-weight: 400;
      flex: 1;
      transition: 0.25s ease-in-out;
      @media only screen and (max-width: ${breakpoints.mobileLarge}) {
        max-height: fit-content;
      }
    }

    &:hover {
      ${({ linkUrl }) =>
        linkUrl &&
        css`
          border-bottom: 1px solid ${colors.blurple};

          @media only screen and (min-width: ${breakpoints.tablet}) {
            .rounded-arrow-link {
              transition: 0.25s ease-in-out;
              opacity: 1;
              visibility: visible;
              left: calc(100% - ${ROUNDED_LINK_WIDTH});
              transition-delay: 0.2s;
            }
            .description {
              transition: 0.25s ease-in-out;
              transform: scale(0);
              opacity: 0;
              visibility: hidden;
            }
          }
        `}
    }

    @media only screen and (max-width: ${breakpoints.desktopLarge}) {
      padding: 54px 30px;
    }
    @media only screen and (max-width: ${breakpoints.desktopSmall}) {
      padding: 62px 48px;
    }
    @media only screen and (max-width: ${breakpoints.mobileLarge}) {
      padding: 32px;
    }
  }

  .rounded-arrow-link {
    display: block !important;
    transition: 0.25s ease-in-out;
    opacity: 0;
    visibility: hidden;
    width: ${ROUNDED_LINK_WIDTH};
    position: absolute;
    left: 70%;
    transition-timing-function: ease;

    .link__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      transition: 0.25s ease-in-out;
    }

    &:hover {
      .link__icon {
        background: linear-gradient(
          90deg,
          ${colors.backgroundGradients.blurple.start} 0%,
          ${colors.backgroundGradients.blurple.end} 100%
        );
        svg {
          fill: var(--white);
        }
      }
    }

    @media only screen and (max-width: ${breakpoints.tablet}) {
      display: none;
    }
  }
`

export const RedirectInfoLink = ({ title, description, linkUrl }) => {
  return (
    <RedirectInfoLinkWrapper linkUrl={!!linkUrl}>
      {linkUrl ? (
        <NavLinkButton to={linkUrl} className="redirect-link__item">
          <h5 className="title">{title}</h5>
          <p className="description p3">{description}</p>
          <RedirectInfoLinkIconWrapper className="rounded-arrow-link">
            <span className="link__icon"><IconArrow /></span>
          </RedirectInfoLinkIconWrapper>
        </NavLinkButton>
      ) : (
        <div className="redirect-link__item">
          <h5 className="title">{title}</h5>
          <p className="description p3">{description}</p>
        </div>
      )}
    </RedirectInfoLinkWrapper>
  )
}
