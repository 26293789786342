import React from 'react';
import styled from 'styled-components';
import Image from '../../../components/Image';
import { breakpoints, colors } from '../../../../constants/style';

const TechnologySummarySection = styled('section')`
  padding-top: 8.125rem;
  padding-bottom: 8.125rem;
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: calc(100% - 13rem);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    background-image: linear-gradient(116deg, #190a3f 10%, #0c0121 82%);
  }

  .technology-summary__header {
    position: relative;
    text-align: center;
    color: #fff;
    margin-bottom: 6.25rem;

    &:before {
      content: '';
      display: block;
      margin: 0 auto 2.5rem auto;
      width: 50px;
      height: 5px;
      background: ${colors.secondary};
    }
  }

  .technology-summary__wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 0 6.25rem;

    .technology-summary__item {
      max-width: calc((100% / 3) - 2.125rem);
      background-image: linear-gradient(214deg, #2a086a, #200e47 5%);
      color: #fff;

      padding: 4.75rem 6.75rem 7rem;

      .technology-summary__item-header {
        font-size: 3.5rem;
        font-weight: 600;
        color: #8b64ee;
        text-align: center;
        margin-bottom: 1.25rem;
      }

      .technology-summary__item-description {
        font-size: 1.375rem;
        line-height: 1.41;
        text-align: center;
        min-height: 7.875rem;
      }
    }

    @media only screen and (max-width: ${breakpoints.desktopLargest}) {
      padding: 0 3rem;
    }

    @media only screen and (max-width: ${breakpoints.desktopLarge}) {
      padding: 0;
      .technology-summary__item {
        padding: 3.75rem 3.25rem 5rem;

        .technology-summary__item-header {
          font-size: 3rem;
        }

        .technology-summary__item-description {
          font-size: 1.25rem;
          min-height: 6rem;
        }
      }
    }

    @media only screen and (max-width: ${breakpoints.desktop}) {
      .technology-summary__item {
        padding: 3rem 2.75rem 4rem;

        .technology-summary__item-header {
          font-size: 2.5rem;
        }

        .technology-summary__item-description {
          font-size: 1.125rem;
          min-height: 5.5rem;
        }
      }
    }

    @media only screen and (max-width: ${breakpoints.tablet}) {
      padding: 0;
      flex-direction: column;

      .technology-summary__item {
        padding: 0.25rem 1rem 1rem;
        margin-bottom: 0.75rem;
        max-width: 100%;

        .technology-summary__item-header {
          font-size: 2.5rem;
          margin-bottom: 0.25rem;
        }

        .technology-summary__item-description {
          font-size: 1.125rem;
          line-height: 1.41;
          text-align: center;
          min-height: 2.5rem;
        }

        .technology-summary__icon-container {
          margin: 0 auto;
          width: 6rem;
        }
      }
    }
  }
`;

const TechnologySummary = ({ header, items }) => {
  return (
    <TechnologySummarySection>
      <h2 className="technology-summary__header">{header}</h2>
      <div className="technology-summary__wrapper">
        {items.map(({ header, description, icon }, index) => (
          <div className="technology-summary__item" key={`technology-summary-item-${index}`}>
            <h3 className="technology-summary__item-header">{header}</h3>
            <p className="technology-summary__item-description">{description}</p>
            <div className="technology-summary__icon-container">
              <Image image={icon} />
            </div>
          </div>
        ))}
      </div>
    </TechnologySummarySection>
  );
};

export default TechnologySummary;
