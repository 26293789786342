import React from 'react'
import styled from 'styled-components'
import { breakpoints } from '../../../../constants/style'
import SectionHeader from '../../SectionHeader/SectionHeader'
import VerticalSlider from '../../VerticalSlider'
import { Blur } from '../ContactUs/styled'
import TestimonialsAgileEntry from './components/TestimonialsAgileEntry'

const TestimonialsAgileSection = styled('section')`
  background-color: var(--fs-bg-1a);
  color: var(--t1-title);
  position: relative;

  .testimonials-agile {
    &__container {
      max-width: 46rem;
      margin: 0 auto;
    }
  }
  .section-header {
    margin-bottom: 3.5rem;
    &__title {
      margin-bottom: 5rem;
      @media only screen and (max-width: ${breakpoints.mobileLarge}) {
        margin-bottom: 2.5rem;
      }
    }
  }
`

const TestimonialsAgile = ({ header, description, testimonials, theme = 'light' }) => {
  const [position, setPosition] = React.useState(0)

  const handleSlideChange = (index) => {
    setPosition(index)
  }

  const sliderSettings = {
    adaptiveHeight: true,
  }

  return (
    <TestimonialsAgileSection className={`testimonials-agile ${theme === 'dark' ? 'dark' : ''}`}>
      {theme === 'dark' && <Blur />}
      <div className="testimonials-agile__container">
        <SectionHeader title={header} subtitle={description} headerPosition="left" />
        <VerticalSlider
          startPosition={position}
          onSlideChange={handleSlideChange}
          customSettings={sliderSettings}
          testimonial={testimonials[position]}
        >
          {testimonials.map(({ description, authorName, authorPosition }, index) => (
            <TestimonialsAgileEntry
              key={`testimonials-agile-entry-${index}`}
              description={description}
              authorName={authorName}
              authorPosition={authorPosition}
            />
          ))}
        </VerticalSlider>
      </div>
    </TestimonialsAgileSection>
  )
}

export default TestimonialsAgile
