import React from 'react';
import StyledTextarea from './styled';

const Textarea = ({ errorMessage, label, value, handleInputChange, name, rows = 2 }) => {
  return (
    <StyledTextarea className={`${errorMessage ? ' has-error' : ''}`}>
      <textarea id={name} placeholder={label} type="text" name={name} value={value} onChange={handleInputChange} rows={rows} />
      <label htmlFor={name}>{label}</label>
      <div className="error">{errorMessage && errorMessage[0]}</div>
    </StyledTextarea>
  );
};

export default Textarea;
