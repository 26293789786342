import React from 'react';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import { StyledErrorMsg, StyledPreLabel, StyledErrorContainer, StyledReqAsterisk } from '../commonFormStyle';
import { StyledTextarea } from './styled';

function Textarea({
  inputName = '',
  label = '',
  placeholder = '',
  rows = 4,
  cols = 20,
  preLabel = '',
  required = false,
  validationOptions = {},
  showError = true,
  height = '',
  onChange,
  resize = true,
}) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const dynamicErrorMessage = `Field is required`;

  return (
    <StyledTextarea isError={showError && !!errors[inputName]} resize={resize}>
      <label className="input-label">
        {required && <StyledReqAsterisk>*&nbsp;</StyledReqAsterisk>}
        {preLabel && (
          <StyledPreLabel isError={showError && !!errors[inputName]} resize={resize}>
            {preLabel}&nbsp;
          </StyledPreLabel>
        )}
        {label}
      </label>
      <textarea
        id={inputName}
        placeholder={placeholder}
        type="text"
        name={inputName}
        {...register(inputName, { required, ...validationOptions })}
        rows={rows}
        cols={cols}
        style={{ height }}
        onChange={onChange}
      />
      <StyledErrorContainer>
        {showError && errors[inputName] && <StyledErrorMsg>{errors[inputName].message || dynamicErrorMessage}</StyledErrorMsg>}
      </StyledErrorContainer>
    </StyledTextarea>
  );
}

Textarea.propTypes = {
  label: PropTypes.string.isRequired,
  inputName: PropTypes.string.isRequired,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  cols: PropTypes.number,
  preLabel: PropTypes.string,
  validationOptions: PropTypes.object,
  showError: PropTypes.bool,
  height: PropTypes.string,
  onChange: PropTypes.func,
  resize: PropTypes.bool,
};

export default Textarea;
