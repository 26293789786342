import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';

import { NavLink, DisabledLink } from '../../../../../components/Link';
import { navbarMinHeight } from '../../../../../features/layout/components/commonLayoutStyle';
import { colors } from '../../../../../../constants/style';

import { breakpoints } from '../../../../../../constants/style';

const StyledBlogCategories = styled('section')`
  padding-top: ${({ $isFirstPage }) => ($isFirstPage ? '6.25rem' : `calc(6.25rem + ${navbarMinHeight})`)} !important;
  padding-bottom: 3.75rem !important;

  .blog-categories {
    &__wrapper--desktop {
      display: flex;
      flex-wrap: wrap;
    }

    &__wrapper--mobile {
      display: none;
    }

    &__header {
      font-weight: 600;
      font-size: 1rem;
      text-decoration-line: underline;
      color: ${colors.titleDark};
      margin-bottom: 2rem;
      text-transform: uppercase;
    }
  }

  ${NavLink},${DisabledLink} {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.125rem;
    padding: 0 2rem;
    color: #727292;
    transition: background 0.2s ease-in-out, transform 0.5s ease-in-out;
    white-space: nowrap;
    background-color: #f8f8fc;
    margin-bottom: 1rem;
    text-transform: none;

    :not(:last-child) {
      margin-right: 1rem;
    }
  }

  ${NavLink} {
    &:hover {
      background-color: #f4f4fa;
    }
  }

  .link--active {
    color: #ffffff;
    background: linear-gradient(90deg, #865ee8 0%, #6536e2 100%);
  }

  @media only screen and (max-width: ${breakpoints.desktop}) {
    padding-top: ${({ $isFirstPage }) => ($isFirstPage ? '6.25rem' : '9rem')} !important;
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    .blog-categories {
      &__wrapper--desktop {
        display: none;
      }

      &__wrapper--mobile {
        display: block;
      }
    }
  }
`;

const SelectWrapper = styled.div`
  position: relative;

  select {
    width: 324px;
    height: 58px;
    border: 1px solid #e3e2ee;
    padding: 0 20px;
    @media only screen and (max-width: ${breakpoints.mobile}) {
      width: 100%;
    }
  }
`;

const BlogCategories = ({ location, categories, currentPage }) => {
  const [category, setCategory] = useState(0);

  const handleSelect = (i) => {
    setCategory(i);
    navigate(categories[i].url);
  };

  useEffect(() => {
    const activeIndex = categories.findIndex(({ url }) => isActive(url));
    setCategory(activeIndex);
  }, []);

  const isActive = (url) => {
    /* 
      we can't use activeClassName & partiallyActive params to distinguish if link is active 
      due to all articles category url `/blog/`
      it would be always selected -> `/blog/` is part of `/blog/${category}/`
    */

    const pageNumberRegex = /^[0-9]+\/$/m;
    return location.pathname === url || pageNumberRegex.test(location.pathname.replace(url, ''));
  };

  return (
    <StyledBlogCategories $isFirstPage={currentPage === 0}>
      <h3 className="blog-categories__header">Categories</h3>
      <div className="blog-categories__wrapper--desktop">
        {categories.map(({ label, url }) => (
          <NavLink key={label} className={`${isActive(url) ? 'link--active' : ''}`} to={url}>
            {label}
          </NavLink>
        ))}
      </div>
      <div className="blog-categories__wrapper--mobile">
        <SelectWrapper>
          <select aria-label="categories" value={category} onChange={(e) => handleSelect(e.target.value)}>
            {categories.map((e, i) => (
              <option key={i} value={i}>
                {typeof e.label === 'string' ? e.label : 'Medium'}
              </option>
            ))}
          </select>
        </SelectWrapper>
      </div>
    </StyledBlogCategories>
  );
};

export default BlogCategories;
