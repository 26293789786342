import React from 'react'

import styled from 'styled-components'

import { breakpoints } from '../../../../constants/style'
import Image from '../../../components/Image'
import SliderSection from 'components/SliderSection/SliderSection'

const SliderSectionStyled = styled(SliderSection)`
  .reasons__item {
    box-shadow: 0px 8px 24px rgba(71, 90, 158, 0.1);
    padding: 3rem 2rem;
    padding-top: 3.75rem;
    text-align: center;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .reasons__icon {
    .gatsby-image-wrapper {
      max-width: 6.25rem;
    }
    margin-bottom: 3rem;
  }

  .reasons__title {
    font-size: 1.625rem;
    margin-bottom: 1.5rem;
  }

  .reasons__description {
    p {
      font-size: 1rem;
      color: #4e4d6c;
      line-height: 1.75;
    }
  }
`

const Why10Clouds = ({ header, description, reasons }) => {
  return (
    <SliderSectionStyled header={header} description={description} items={reasons} withBackground>
      {reasons.map(({ title, description, icon }, index) => (
        <div className='item reasons__item' key={`reasons-item-${index}`}>
          <div className='reasons__icon'>
            <Image image={icon} />
          </div>
          <div className='reasons__title'>{title}</div>
          <div className='reasons__description'>
            <p>{description}</p>
          </div>
        </div>
      ))}
    </SliderSectionStyled>
  )
}

export default Why10Clouds
