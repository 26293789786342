import React from 'react';
import styled from 'styled-components';

import Image from '../../Image';
import { colors } from '../../../../constants/style';

const StyledIconTextItem = styled('div')`
  padding: 1rem 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon-text-item__icon {
    flex-shrink: 0;
    margin-bottom: 1rem;
    width: 5.5rem;
  }

  .icon-text-item__text {
    text-align: center;
    font-family: Roboto-Regular, sans-serif;
    font-weight: 600;
    line-height: 1.5rem;
    color: ${colors.titleDark};
  }

  @media only screen and (max-width: 35rem) {
    padding: 1rem;
  }
`;

const IconTextItem = ({ item }) => {
  return (
    <StyledIconTextItem>
      {item?.icon && <Image className="icon-text-item__icon" image={item.icon} alt={item.alt || ''} />}
      <div className="icon-text-item__text">{item.text}</div>
    </StyledIconTextItem>
  );
};

export default IconTextItem;
