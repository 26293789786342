import React from 'react';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import { StyledErrorMsg, StyledPreLabel } from '../commonFormStyle';
import { StyledDiv } from './styled';
import attachmentIcon from '../../../../static/images/icons/attachment.svg';
import { formatBytes } from '../../../templates/careerPage/components/Form/helpers/functions';

const acceptedDocsTypes =
  '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf, .png, .jpg, .jpeg';

function FileInput({
  label = '',
  placeholder = '',
  preLabel = '',
  inputName = '',
  required,
  validationOptions = {},
  showError = true,
  multiple = false,
  isFileUploadError = false,
  showFiles = false,
}) {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext();

  const files = watch(inputName);

  const dynamicPreLabel = preLabel || 'Attach files';
  const dynamicErrorMessage = `${dynamicPreLabel} ${label} field is required`;
  const fileUploadErrorMsg = 'Unfortunately, we could not upload your files, please try again';

  return (
    <StyledDiv isError={showError && !!errors[inputName]}>
      <label className="input-label" htmlFor={inputName}>
        <img src={attachmentIcon} alt="attachment clip" />
        <StyledPreLabel>{dynamicPreLabel}&nbsp;</StyledPreLabel>
        {label}
      </label>
      <input
        type="file"
        id={inputName}
        {...register(inputName, { required, ...validationOptions })}
        placeholder={placeholder}
        accept={acceptedDocsTypes}
        multiple={multiple}
      />

      {files &&
        showFiles &&
        Object.values(files).map(({ name, size }) => (
          <StyledPreLabel key={name}>
            {name} - {formatBytes(size)}
          </StyledPreLabel>
        ))}
      {showError && errors[inputName] && <StyledErrorMsg>{errors[inputName].message || dynamicErrorMessage}</StyledErrorMsg>}
      {isFileUploadError && <StyledErrorMsg>{fileUploadErrorMsg}</StyledErrorMsg>}
    </StyledDiv>
  );
}

FileInput.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  preLabel: PropTypes.string,
  inputName: PropTypes.string.isRequired,
  required: PropTypes.bool,
  validationOptions: PropTypes.object,
  showError: PropTypes.bool,
  multiple: PropTypes.bool,
  isFileUploadError: PropTypes.bool,
  showFiles: PropTypes.bool,
};

export default FileInput;
