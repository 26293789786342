import React from 'react'
import Layout from '../../features/layout'
import { graphql } from 'gatsby'
import { SEO } from 'components/Seo'
import { StructuredDataArticle } from 'components/StructuredDataArticle'
import SectionsContainer from 'components/SectionsContainer'
import 'hooks/graphql/section'
import 'hooks/graphql/others'

export const query = graphql`
  query CaseStudyPageQuery($id: String) {
    ...sanityCaseStudy
  }
`

const CaseStudyPage = ({ data: { sanityCaseStudy } }) => {
  const { sections, _rawSections } = sanityCaseStudy
  const isBrowser = () => typeof window !== "undefined"
  isBrowser() && sections.find((item) => {
    if (item.redirectUrl) {
      window.location.replace(item.redirectUrl)
    }
    return null
  })
  return (
    <Layout shouldNavigationStartTransparent={false}>
      <SectionsContainer sections={sections} _rawSections={_rawSections} />
    </Layout>
  )
}

export function Head({ location, data: { sanityCaseStudy } }) {
  const { seo, _createdAt, sections } = sanityCaseStudy
  const heroForCaseStudyData =
    sections?.filter((section) => section?._type === 'heroForCaseStudy')[0] || {}

  return (
    <SEO location={location} {...seo}>
      <StructuredDataArticle
        headline={heroForCaseStudyData?.title}
        postURL={location.pathname}
        datePublished={_createdAt}
        image={[heroForCaseStudyData?.featuredImage?.asset?.url]}
      />
    </SEO>
  )
}

export default CaseStudyPage
