import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';

const StyledLocationLink = styled.a`
  display: flex;
  align-items: flex-start;

  img {
    margin-right: 0.5rem;
    width: 24px;
    height: 24px;
  }
`;

const FooterLocations = ({ locations }) => {
  const renderOffices = locations.map(({ href, cityName, address }) => {
    const addressParts = address.split(', ');
    const addressPart1 = addressParts.shift();
    const addressPart2 = addressParts.join(', ');
    return (
      <StyledLocationLink className="link" href={href} target="_blank" rel="noopener noreferrer" key={`footer-adress-${cityName}`}>
        <img src="/images/icons/ico-location.svg" alt="10Clouds Office Marker" loading="lazy" />
        <div>
          <h3>{cityName}</h3>
          <address>{`${addressPart1}${addressPart1.slice(-1).match(/\d/) ? ',' : ''}`}</address>
          <address>{addressPart2}</address>
        </div>
      </StyledLocationLink>
    );
  });

  return (
    <>
      <h3>Our Offices</h3>
      {renderOffices}
    </>
  );
};

FooterLocations.propTypes = {
  locations: propTypes.arrayOf(propTypes.object).isRequired,
};

export default FooterLocations;
