import { v4 as uuidv4 } from 'uuid'
import SylwiaAvatar from '../../assets/images/sylwia-masłowska.png'

const salesAgents = [
  {
    name: 'Sylwia Masłowska',
    position: 'Head of Business Development',
    phone: '+48 508 169 387',
    usPhone: '+1 (888) 341-2098',
    email: 'hello@10clouds.com',
    avatar: SylwiaAvatar,
    uuid: uuidv4(),
  },
]

export default salesAgents
