import React from 'react';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import { StyledErrorMsg } from '../commonFormStyle';
import { StyledCheckbox } from './styled';
import CheckboxLabel from './CheckboxLabel';

function Checkbox({ label, inputName, required, makeValueBoolean, showError, isTermsOfUse }) {
  const {
    register,
    getValues,
    formState: { errors },
  } = useFormContext();

  const errorMessage = required ? 'This field is required' : 'You have to select at least one option';

  const checkboxValidation = (inputNameKey) => (getValues(inputNameKey).length ? true : errorMessage);
  const makeCheckboxValue = makeValueBoolean ? !!label : label;

  return (
    <>
      <StyledCheckbox isTerms={isTermsOfUse}>
        <input
          type="checkbox"
          value={makeCheckboxValue}
          id={inputName}
          {...register(inputName, {
            validate() {
              if (required) return checkboxValidation(inputName);
            },
          })}
        />
        <span className="checkmark" />
        {typeof label === 'string' ? <CheckboxLabel inputName={inputName}>{label}</CheckboxLabel> : <>{label}</>}
      </StyledCheckbox>
      {showError && errors[inputName] && <StyledErrorMsg>{errors[inputName].message}</StyledErrorMsg>}
    </>
  );
}

Checkbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  inputName: PropTypes.string.isRequired,
  required: PropTypes.bool,
  makeValueBoolean: PropTypes.bool,
  showError: PropTypes.bool,
  isTermsOfUse: PropTypes.bool,
};

Checkbox.defaultProps = {
  label: '',
  inputName: '',
  required: false,
  makeValueBoolean: false,
  showError: true,
  isTermsOfUse: false,
};

export default Checkbox;
