import styled from 'styled-components';

import BackgroundImage from '../../../assets/images/bg-3.png';
import { breakpoints } from '../../../../constants/style';

const StyledAdvantagesSection = styled('section')`
  background: no-repeat 45% 90%/95rem url(${BackgroundImage});
  padding-top: 7rem;
  padding-bottom: 7rem;
  margin-bottom: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: ${breakpoints.desktopSmall}) {
    flex-direction: column;
  }

  @media screen and (max-width: ${breakpoints.tablet}) {
    padding-bottom: 0;
  }
`;

export default StyledAdvantagesSection;
