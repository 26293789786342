import { graphql, useStaticQuery } from 'gatsby';

export const useBlogCategoriesQuery = () => {
  return useStaticQuery(graphql`
    query MyBlogCategories {
      allSanityBlogCategory(filter: { shouldDisplayInNav: { eq: true } }) {
        nodes {
          title
          slug {
            current
          }
        }
      }
      allSanityBlogSettings {
        nodes {
          blogCategories {
            slug {
              current
            }
            title
          }
        }
      }
    }
  `)
}
