export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const convertToBase64String = async (file, isOriginalWithType) => {
  const resumeBase64 = await toBase64(file); // if you move this file to seperate file remember to import toBase64

  if (isOriginalWithType) return resumeBase64;

  const indexOfFirst = resumeBase64.indexOf('base64');

  const correctBase64String = resumeBase64.slice(indexOfFirst + 7);

  return correctBase64String;
};

export const isUploadedFileValidator = (file) => (!!file ? null : { code: 'no-selected-file', message: 'Please select a file' });

// Helper function
export const getKeyByValue = (object, value) => Object.keys(object).find((key) => object[key] === value);

/**
 * Returns data with changed keys to fit SelectInput api.
 *
 * @param {Object[]} data Original, unchanged data of possible to select english levels.
 * @return {Object[]} data with changed keys name.
 */
export const getRenamedKeysForDropdown = (data) => data?.reduce((acc, { body }) => [...acc, { value: body, label: body }], []);

/**
 * Returns new array of objects with formatated form answers output to match api workable
 *
 * @param {Object{}} object Original data of all answered fom fileds.
 * @param {String} questionsPreInputName String that is included in register method for question form to
 * recognise with formfields are from which category.
 * @param {String} dropdownPreInputName String that is included in register method for ONLY dropdown input form to
 * recognise it and covert it output to String[] for workable api
 * @return {Object[]} acc with changed keys name.
 */
export const getFormatedQuestionFormOutput = (object, questionsPreInputName, dropdownPreInputName) =>
  Object.entries(object).reduce((acc, [key, value]) => {
    if (key.includes(questionsPreInputName)) {
      const answerBody =
        typeof value === 'object'
          ? { choices: value }
          : value.includes(dropdownPreInputName)
          ? { choices: [value.replace(dropdownPreInputName, '')] }
          : value === 'true' || value === 'false' // checking boolean inputs
          ? { checked: !!value.replace(dropdownPreInputName, '') }
          : { body: value };

      const question_key = key.replace(questionsPreInputName, '');

      acc = [...acc, { question_key, ...answerBody }];
    }

    return acc;
  }, []);

// Returning cookie value

export const getCookieValueByName = (cookieStartWith) => {
  const cookieValue = document?.cookie
    ?.split('; ')
    ?.find((row) => row.startsWith(cookieStartWith))
    ?.split('=')[1];

  return cookieValue;
};

export const getCandidateSourcePage = (arrayOfCookiesNames) => {
  const result = arrayOfCookiesNames.map((cookie) => getCookieValueByName(cookie));
  const [candidateConnectingSource] = result.filter((item) => item !== undefined);
  const finalCandidateConnectinSource =
    candidateConnectingSource === '(direct)' || candidateConnectingSource === undefined ? '10clouds.com' : candidateConnectingSource;
  return finalCandidateConnectinSource;
};

export const formatBytes = (bytes, decimals = 2) => {
  // function transfers bytes to easy to read size i.e. kb, mb,gb
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const convertBytesInString = ({ charsBeforeCutedString, charsAfterCutedString, originalString }) => {
  const getBytesFromString = originalString?.split(charsBeforeCutedString)[1]?.split(charsAfterCutedString)[0].trim();
  const formatedBytesErrorMsg = originalString.replace(getBytesFromString, formatBytes(getBytesFromString));
  return formatedBytesErrorMsg;
};
