import { useStaticQuery, graphql } from 'gatsby'
import { parseSlug } from 'utils/slug'
import { footerLinks } from '../../../../constants/links'

type SlugType = {
  current: string
}

type NameType = {
  name: string
}

type ServicePageType = {
  globalInfo: NameType
  slug: SlugType
}

type TechnologyPageType = {
  abstract: NameType
  page: {
    slug: SlugType
  }
}

type CaseStudyPageType = {
  abstract: {
    projectName: string
  }
  slug: SlugType
}

const transformServicePageToLink = ({ globalInfo, slug }: ServicePageType) => {
  return { name: globalInfo?.name, url: `/services/${parseSlug(slug.current)}` }
}

const transformTechnologyPageToLink = ({ abstract, page }: TechnologyPageType) => {
  return { name: abstract?.name, url: `/technologies/${parseSlug(page?.slug?.current)}` }
}

const transformCaseStudyPageToLink = ({ abstract, slug }: CaseStudyPageType) => {
  return { name: abstract?.projectName, url: `/case-studies/${parseSlug(slug.current)}` }
}

export const useFooterLinksQuery = () => {
  const data = useStaticQuery(graphql`
    query FooterLinksQuery {
      sanityServicesPageList(language: { eq: "en" }) {
        releasedServicePages {
          slug {
            current
          }
          globalInfo {
            name
          }
        }
      }
      # all of that data is needed to validate case study
      allSanityCaseStudy {
        nodes {
          id
          slug {
            current
          }
          abstract {
            projectName
            description
            tags
            imageForCaseStudiesList {
              asset {
                url
              }
            }
          }
        }
      }
      allSanityTechnology {
        nodes {
          page {
            slug {
              current
            }
          }
          abstract {
            name
          }
        }
      }
    }
  `)
  const servicesLinks = data.sanityServicesPageList.releasedServicePages.map(
    transformServicePageToLink,
  )
  const caseStudiesLinks = data.allSanityCaseStudy.nodes
    .slice(0, 10)
    .map(transformCaseStudyPageToLink)
  const technologyLinks = data.allSanityTechnology.nodes.map(transformTechnologyPageToLink)

  return {
    ...footerLinks,
    Services: {
      ...footerLinks.Services,
      entries: servicesLinks,
    },
    CaseStudies: {
      ...footerLinks.CaseStudies,
      entries: caseStudiesLinks,
    },
    Technology: {
      ...footerLinks.Technology,
      entries: technologyLinks,
    },
  }
}
