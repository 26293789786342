import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

import EmployeeEntry from '../../EmployeeEntry';
import EmployeeEntrySmall from '../../EmployeeEntrySmall';

import { colors, breakpoints, containerMaxWidth } from '../../../../constants/style';
import useWindowSize from '../../../hooks/useWindowSize';

import SectionHeader from '../../SectionHeader/SectionHeader';

const AuthorsListSection = styled('section')`
  position: relative;
  background-color: #fff;

  .authors {
    position: relative;
    z-index: 1;
    max-width: ${containerMaxWidth};
    margin: 5rem auto 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(16rem, 22rem));
    justify-content: center;
    gap: 2rem;
  }

  .contributors__header {
    margin-top: 5rem;
    margin-bottom: 3.5rem;
    text-align: center;
    font-size: 2.25rem;
    line-height: 3rem;
    color: ${colors.titleDark};
  }

  .contributors__content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem 1.5rem;
    max-width: 86rem;
    margin: 0 auto;

    & > div {
      padding: 1.5rem;
    }
  }

  .react-reveal {
    width: 100%;
  }

  .section-header {
    margin-bottom: 3.5rem;
  }

  @media only screen and (max-width: ${breakpoints.desktopLargest}) {
    .authorsList__container {
      width: 46rem;
      margin: 0 auto;
    }

    .contributors__header {
      margin-top: 3.5rem;
      text-align: left;
    }

    .contributors__content {
      grid-template-columns: 1fr 1fr;
      gap: 1rem 0.5rem;

      & > div {
        padding: 1.5rem 1rem;
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.desktopSmall}) {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    .authorsList__container {
      width: 22rem;
    }
    .contributors__content {
      grid-template-columns: 1fr;
    }
  }

  @media only screen and (max-width: ${breakpoints.mobile}) {
    .authorsList__container {
      width: 100%;
    }
  }
`;

const AuthorsList = ({
  header,
  description,
  authorsEmployees,
  authorsPeople,
  contributorsHeader,
  contributorsEmployees,
  contributorsPeople,
}) => {
  const [itemsPerRow, setItemsPerRow] = useState(3);
  const [animatedElementIndex, setAnimatedElementIndex] = useState(0);

  const { isSmallerThanDesktop, isSmallerThanDesktopSmall, isMaxDesktopLargest } = useWindowSize();

  const authors = [...authorsEmployees, ...authorsPeople];
  const contributors = [...contributorsEmployees, ...contributorsPeople];

  useEffect(() => {
    if (isSmallerThanDesktop) {
      setItemsPerRow(3);
    } else if (isSmallerThanDesktopSmall) {
      setItemsPerRow(1);
    } else {
      setItemsPerRow(4);
    }
  }, [setItemsPerRow, isSmallerThanDesktop, isSmallerThanDesktopSmall, authors.length]);

  return (
    <AuthorsListSection>
      <div className="authorsList__container">
        <SectionHeader
          title={header ? header : 'About authors'}
          headerPosition={isMaxDesktopLargest ? 'left' : 'center'}
          subtitle={description}
        />
        <div className="authors">
          {authors.map((item, index) => (
            <Fade
              bottom
              distance="100px"
              appear
              onReveal={() => {
                setAnimatedElementIndex(animatedElementIndex + 1);
              }}
              duration={500}
              wait={400}
              delay={isSmallerThanDesktopSmall ? 0 : (index % itemsPerRow) * 400}
              key={`author-${index}`}
            >
              <EmployeeEntry
                key={`author-${index}`}
                image={item.image}
                name={item.name}
                position={item.position}
                employeeLinks={item.employeeLinks}
                description={item.description}
                showLinksAndDesc={true}
              />
            </Fade>
          ))}
        </div>
        {contributors ? (
          <div className="contributors">
            <h3 className="contributors__header">{contributorsHeader ? contributorsHeader : 'Contributors'}</h3>
            <div className="contributors__content">
              {contributors.map((item, index) => (
                <EmployeeEntrySmall
                  key={`contributor-${index}`}
                  image={item.image}
                  name={item.name}
                  position={item.position}
                  links={item.employeeLinks ? item.employeeLinks : {}}
                />
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </AuthorsListSection>
  );
};

export default AuthorsList;
