import { css } from 'styled-components';
import { colors } from '../../../constants/style';

export const blogHeroInnerPadding = { vertical: '12.25rem', horizontal: '3.1875rem' };
export const blogSpacedAccentSizes = { margin: '1.875rem', font: '1.25rem' };
export const firstBlogSectionTopPadding = {
  desktopMax: `5rem`,
  desktopLarge: `5rem`,
};

export const blogHeroPadding = {
  mobile: '3.125rem 0 4.0625rem',
  tablet: '3.75rem 0 5rem',
  desktop: '5rem 0 6.25rem',
};

export const blogTitleAnimationStyle = {
  base: css`
    display: inline;
    position: relative;
    background-size: 0 100%;
    background-repeat: no-repeat;
    background-image: linear-gradient(180deg, transparent 96%, ${colors.primary} 0);
    outline: 0;
    text-decoration: none;
    transition: all 0.5s cubic-bezier(0.5, 0.61, 0.355, 1);
  `,
  hover: css`
    background-size: 100% 100%;
  `,
};

export const blogArrowAnimationStyle = {
  base: css`
    transition: all 0.5s;
  `,
  hover: css`
    transform: translate(10px);
  `,
};

export const blogTimeDataStyle = css`
  font-family: "BioSans-Regular", "Roboto-Regular", sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.2px;
  color: ${colors.mutedDark};
  margin-bottom: 0.75rem;
`;

export const blogCategoryContainerStyle = css`
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  column-gap: 1rem;
`;

export const blogCategoryStyle = css`
  font-family: "BioSans-SemiBold", "BioSans-Regular", "Roboto-Bold", sans-serif;
  font-size: 0.8125rem;
  color: ${colors.primary};
  margin-bottom: 0.875rem;
  text-transform: uppercase;
`;

export const highlightedCategoryBottomMargin = '2rem';

export const cardHorizontalPadding = '8%';
