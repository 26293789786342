import React from 'react'

import styled from 'styled-components'
import Image from '../../../components/Image'
import { breakpoints } from '../../../../constants/style'

const StyledTechnologyLogo = styled('article')`
  position: absolute;
  right: 12rem;
  top: 5rem;
  width: 25.25rem;
  height: 25.25rem;
  background-image: linear-gradient(224deg, #2a086a, #200e47 5%);

  .technology-logo__wrapper {
    height: 13rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    max-width: 25rem;
    padding: 0 2rem;
    transform: translate(-50%, -50%);
    transition: top 0.3s ease-in-out;

    img {
      height: 80%;
      width: 100%;
      max-height: 120px;
      object-fit: contain !important;
    }
  }

  .gatsby-image-wrapper {
    margin-left: 0 !important;
  }

  .technology-logo__header {
    margin-top: 1rem;
    font-size: 1.5rem;
    text-align: center;
    color: #fff;
  }

  @media only screen and (max-width: ${breakpoints.desktopLargest}) {
    width: 22rem;
    height: 22rem;
    right: 6rem;
    .technology-logo__wrapper {
      height: 12rem;
    }
  }

  @media only screen and (min-width: ${breakpoints.desktopMax}) {
    .gatsby-image-wrapper {
      position: relative !important;
      top: 0 !important;
    }
  }

  @media only screen and (max-width: ${breakpoints.desktopMac}) {
    right: 4rem;
  }

  @media only screen and (max-width: ${breakpoints.desktop}) {
    width: 19rem;
    height: 19rem;
    right: 2rem;
    .technology-logo__wrapper {
      height: 11rem;
    }
  }

  @media only screen and (max-width: ${breakpoints.desktopSmall}) {
    width: 17rem;
    height: 17rem;
    .technology-logo__wrapper {
      height: 9rem;
    }
    right: 0;
  }
`

const TechnologyHeroLogo = ({ image, header }) => {
  return (
    <StyledTechnologyLogo>
      <div className="technology-logo__wrapper">
        <Image image={image} />
        {header && <h2 className="technology-logo__header">{header.toLocaleUpperCase()}</h2>}
      </div>
    </StyledTechnologyLogo>
  )
}

export default TechnologyHeroLogo
