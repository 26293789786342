import React from 'react'
import styled from 'styled-components'
import { SEO } from 'components/Seo'
import { externalContentStyle } from '../../constants/style'
import Layout from '../features/layout'

const StyledSmogdog = styled('div')`
  margin: 160px 168px 0;
  ${externalContentStyle}
`

const SmogdogPage = () => {
  return (
    <Layout shouldNavigationStartTransparent={false}>
      <StyledSmogdog>
        <h2>Privacy Policy</h2>
        <p>
          This privacy policy has been compiled to better serve those who are concerned with how
          their ‘Personally Identifiable Information’ (PII) is being used online. PII, as described
          in US privacy law and information security, is information that can be used on its own or
          with other information to identify, contact, or locate a single person, or to identify an
          individual in context. Please read our privacy policy carefully to get a clear
          understanding of how we collect, use, protect or otherwise handle your Personally
          Identifiable Information in accordance with our website.
        </p>
        <p>
          <strong>
            What personal information do we collect from the people that visit our blog, website or
            app?
          </strong>
        </p>
        <p>We do not collect information from users of our app.</p>
        <p>
          <strong>When do we collect information?</strong>
        </p>
        <p>We collect information from you when you enter information in our app.</p>
        <p>
          <strong>How do we use your information? </strong>
        </p>
        <p>
          {' '}
          We may use the information we collect from you when you register, make a purchase, sign up
          for our newsletter, respond to a survey or marketing communication, surf the app, or use
          certain other app features in the following ways:
        </p>
        <p>
          <strong>•</strong> To personalize your experience and to allow us to deliver the type of
          content and product offerings in which you are most interested.
        </p>
        <p>
          <strong>•</strong> To improve our app in order to better serve you.
        </p>
        <p>
          <strong>•</strong> To allow us to better service you in responding to your customer
          service requests.
        </p>
        <p>
          <strong>•</strong> To ask for ratings and reviews of services or products
        </p>
        <p>
          <strong>How do we protect your information?</strong>
        </p>
        <p>We do not use vulnerability scanning and/or scanning to PCI standards.</p>
        <p>We only provide articles and information. We never ask for credit card numbers.</p>
        <p>We do not use Malware Scanning.</p>
        <p>
          Your personal information is contained behind secured networks and is only accessible by a
          limited number of persons who have special access rights to such systems, and are required
          to keep the information confidential. In addition, all sensitive/credit information you
          supply is encrypted via Secure Socket Layer (SSL) technology.
        </p>
        <p>
          We implement a variety of security measures when a user enters, submits, or accesses their
          information to maintain the safety of your personal information.
        </p>
        <p>
          All transactions are processed through a gateway provider and are not stored or processed
          on our servers.
        </p>
        <p>
          <strong>Do we use ‘cookies’?</strong>
        </p>
        <p>We do not use cookies for tracking purposes </p>
        <p>
          <strong>Third-party disclosure</strong>
        </p>
        <p>
          We do not sell, trade, or otherwise transfer to outside parties your Personally
          Identifiable Information unless we provide users with advance notice. This does not
          include website hosting partners and other parties who assist us in operating our website,
          conducting our business, or serving our users, so long as those parties agree to keep this
          information confidential. We may also release information when it’s release is appropriate
          to comply with the law, enforce our site policies, or protect ours or others’ rights,
          property or safety.
        </p>
        <p>
          {' '}
          However, non-personally identifiable visitor information may be provided to other parties
          for marketing, advertising, or other uses.{' '}
        </p>
        <p>
          <strong>Third-party links</strong>
        </p>
        <p>We do not include or offer third-party products or services in our app.</p>
        <p>
          <strong>Contacting Us</strong>
        </p>
        <p>
          If there are any questions regarding this privacy policy, you may contact us using the
          information below.
        </p>
        <p>Smogdog</p>
        <p>Finlandzka 10/10</p>
        <p>Warsaw, 03-903</p>
        <p>Poland</p>
        <p>smogdog@10clouds.com</p>
      </StyledSmogdog>
    </Layout>
  )
}

export function Head({ location }) {
  return <SEO location={location} title="Terms and contitions" />
}

export default SmogdogPage
