import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import checkedTickGrey from '../../../../static/images/icons/checked-grey.svg';
import checkedTickWhite from '../../../../static/images/icons/checked-white.svg';
import { colors } from '../../../../constants/style';

const checkboxSize = '1.125rem';

export const StyledCheckbox = styled('div')`
  label {
    display: flex;
    cursor: pointer;
    padding-right: 0.8rem;

    &::before {
      background-position: center center;
      background-image: ${({ isChecked, variant }) => {
        if (isChecked) {
          return variant === 'purple' ? `url(${checkedTickWhite})` : `url(${checkedTickGrey})`;
        }
        return 'none';
      }};
      background-color: ${({ variant, isChecked }) => (variant === 'purple' && isChecked ? `#7142eb` : 'transparent')};
      background-repeat: no-repeat;
      background-size: 75% auto;
      border: 2px solid
        ${({ isFocused, variant, hasError, isChecked }) => {
          if (variant === 'purple' && isChecked) {
            return `#7142eb`;
          }
          if (isFocused) {
            return colors.secondary;
          }
          if (hasError) {
            return '#c10000';
          }
          return '#c1c1d0';
        }};
      border-radius: 2px;
      content: '';
      display: inline-block;
      height: ${checkboxSize};
      margin-right: 0.875rem;
      position: relative;
      top: 2px;
      min-width: ${checkboxSize};
    }

    .checkbox {
      cursor: pointer;
      left: -200%;
      opacity: 0;
      position: absolute;
    }

    p {
      /* this targets description from sanity */
      padding-bottom: 0;
      line-height: 1rem;
      font-family: "BioSans-Regular", sans-serif;
    }
  }
`;

export const CheckboxError = styled('div')`
  height: 1rem;
  font-size: 0.75rem;
  color: #c10000;
  margin-bottom: 0.5rem;
`;

const Checkbox = ({ name, isChecked, onChange, children, errorMessage, variant }) => {
  const [isFocused, setIsFocused] = useState(false);
  const id = uuidv4();
  const onFocus = () => {
    setIsFocused(true);
  };

  const onBlur = () => {
    setIsFocused(false);
  };
  return (
    <StyledCheckbox isChecked={isChecked} isFocused={isFocused} hasError={Boolean(errorMessage?.length)} variant={variant}>
      <label htmlFor={id}>
        <input
          type="checkbox"
          id={id}
          checked={isChecked}
          className="checkbox"
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          name={name}
        />
        {children}
      </label>
      <CheckboxError>{errorMessage && errorMessage[0]}</CheckboxError>
    </StyledCheckbox>
  );
};

Checkbox.propTypes = {
  variant: propTypes.oneOf(['purple', undefined]),
};

export default Checkbox;
