import React, { useMemo } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { getAllExtraSrcSets } from 'gbimage-bridge'
import getConvertedGatsbyImgDataToWEBP from '../utils/images/getConvertedGatsbyImgDataToWEBP'

// component that provides fallback for optimized and unoptimized images
const Image = ({
  image,
  alt = '',
  style = {},
  className = '',
  objectFit = 'cover',
  compress = null,
  sizes = '',
}) => {
  const isGatsbyImage = Boolean(image?.asset?.gatsbyImageData)
  const svgSrcSet =
    (isGatsbyImage && getAllExtraSrcSets(image.asset.gatsbyImageData)?.srcSetSvg) || null

  const gatsbyImage = useMemo(() => {
    if (!image || !isGatsbyImage || svgSrcSet) {
      return null
    }

    return getConvertedGatsbyImgDataToWEBP(getImage(image.asset), compress)
  }, [image])

  if (!Boolean(image)) {
    return null
  }

  if (isGatsbyImage) {
    return svgSrcSet ? (
      <img
        srcSet={svgSrcSet}
        alt={image.alt ? image.alt : alt}
        style={style}
        className={className}
        sizes={sizes}
      />
    ) : (
      <GatsbyImage
        style={style}
        image={gatsbyImage}
        alt={image.alt ? image.alt : alt}
        loading="lazy"
        className={className}
        objectFit={objectFit}
        sizes={sizes}
      />
    )
  }

  return (
    <img
      src={image.asset?.url || image.publicURL || image}
      alt={image.alt ? image.alt : alt}
      style={style}
      className={className}
      sizes={sizes}
    />
  )
}

export default Image
