import React from 'react';
import styled from 'styled-components';
import SanityBlockContent from '../../SanityBlockContent';
import { navbarMinHeight } from '../../../features/layout/components/commonLayoutStyle';
import { externalContentStyle } from '../../../../constants/style';

const StyledBlockSection = styled('section')`
  padding: ${({ isFirstSection }) => (isFirstSection ? `calc(${navbarMinHeight} + 5rem)` : '5rem')};
  padding-bottom: 5rem;

  ${externalContentStyle}

  ul li:before {
    content: '';
    padding-right: 0;
  }
`;

const BlockSection = ({ content, isFirstSection }) => (
  <StyledBlockSection isFirstSection>
    <SanityBlockContent blocks={content} />
  </StyledBlockSection>
);

export default BlockSection;
