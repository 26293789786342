import { useStaticQuery, graphql } from 'gatsby'

export const useFeaturedOnQuery = () => {
  const data = useStaticQuery(graphql`
    {
      sanityCommonSections(language: { eq: "en" }) {
        featuredOn {
          header
          featuredList {
            name
            logo {
              asset {
                url
                gatsbyImageData(height: 36)
              }
            }
          }
        }
      }
    }
  `)
  return data.sanityCommonSections.featuredOn
}
