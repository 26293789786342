const getConvertedGatsbyImgDataToWEBP = (imagesFallbackData, compress = true) => {
  // This util add to every link on images object '&fm=webp ' - works as rest api this param is from docs for converting them to webp format
  // https://www.sanity.io/docs/image-urls#fm-048ba39d9e88
  // it saves tons of data transfered i.e img 110kb -> 9kb - after converting it losses a bit of quality but perforamce of the page improved a lot
  //
  const imgLinkToProcess = imagesFallbackData?.gatsbyImageData?.images?.fallback || imagesFallbackData?.images?.fallback;

  if (!imgLinkToProcess) return;

  const convertedGatsbyImageDataToWEBP = Object.entries(imgLinkToProcess);

  const convertedImgLinksFallback = convertedGatsbyImageDataToWEBP.reduce((acc, [key, value]) => {
    if (key === 'src' && value.startsWith('https://cdn.sanity')) {
      const convertedToWEBP = value.concat('&fm=webp');
      return { ...acc, [key]: convertedToWEBP };
    }

    if (key === 'srcSet' && value.startsWith('https://cdn.sanity')) {
      let srcSetLinks = value.split('\n');
      const convertedToWEBP = srcSetLinks.map((link) => {
        const splittedLink = link.split(' ');
        // space here is important for gatsbyimg src set
        splittedLink.splice(Number(splittedLink.length - 1), 0, `${compress ? '&fm=webp ' : '&fm=webp&q=100 '}`);
        splittedLink.push('\n');
        const imageConvertedToWEBP = splittedLink.join('');
        return imageConvertedToWEBP;
      });

      return { ...acc, [key]: convertedToWEBP.join('') };
    }
    return { ...acc, [key]: value };
  }, {});

  return {
    ...imagesFallbackData,
    ...(convertedImgLinksFallback && { images: { ...imagesFallbackData.images, fallback: convertedImgLinksFallback } }),
  };
};

export default getConvertedGatsbyImgDataToWEBP;
