import React from 'react';
import styled from 'styled-components';

import { colors, breakpoints } from '../../../../../constants/style';

const EntryStyled = styled('div')`
  width: 50%;
  padding: 3.25rem;

  .dash {
    width: 35px;
    height: 5px;
    font-size: 0;
    line-height: 0;
  }

  .title {
    margin-top: 3.0625rem;
    height: 5.6875rem;
    display: flex;
    font-family: "BioSans-SemiBold", sans-serif;
    color: #ffffff;
  }
  .title--big {
    font-size: 4.625rem;
    line-height: normal;
    margin-right: 2.5rem;
  }
  .title--small {
    font-size: 1.375rem;
    white-space: pre-line;
    line-height: 1.82;
    display: flex;
    align-items: center;
  }

  .description {
    margin-top: 2rem;
    font-family: Roboto-Regular, sans-serif;
    font-size: 1.125rem;
    line-height: 2.33;
    max-width: 571px;
    color: ${colors.mutedLight};
  }

  @media screen and (max-width: ${breakpoints.desktopSmall}) {
    width: 100%;
    padding: 2rem;

    .title {
      margin-top: 1.0625rem;
    }

    .description {
      margin-top: 1.75rem;
    }
  }

  @media screen and (max-width: ${breakpoints.mobile}) {
    padding: 2rem 0;

    .title {
      height: auto;
    }

    .title--big {
      font-size: 4.2rem;
      line-height: 4rem;
      margin-right: 1rem;
    }
    .title--small {
      font-size: 1rem;
      white-space: unset;
    }

    .description {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
`;

const Entry = (props) => {
  const { dashColor, bigTitle, smallTitle, description } = props;

  return (
    <EntryStyled>
      <div className="dash" style={{ background: `${dashColor ? dashColor.hex : 'none'}` }} />
      <div className="title">
        {bigTitle && <div className="title--big">{bigTitle}</div>}
        <div className="title--small">{smallTitle}</div>
      </div>
      <div className="description">{description}</div>
    </EntryStyled>
  );
};

export default Entry;
