import { SEO } from 'components/Seo'
import useHomeData from 'hooks/graphql/section/useHomeData'
import Layout from '../features/layout'
import Home from '../templates/homePage'

const IndexPage = () => {
  return (
    <Layout>
      <Home />
    </Layout>
  )
}

export function Head({ location }) {
  const { seo } = useHomeData()
  return (
    <SEO location={location} {...seo}>
      <meta name="facebook-domain-verification" content="26vz48rt11hxhtn4ql4wr8x58dm68r" defer />
    </SEO>
  )
}

export default IndexPage
