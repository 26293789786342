import { Link } from 'gatsby';
import styled from 'styled-components';

export const BackToLinkStyle = styled(Link)`
  --bth-icon-size: 32px;

  color: #fff;
  font-family: Roboto-Regular, sans-serif;
  display: flex;
  align-items: center;
  gap: 20px;

  &:hover,
  &:focus {
    color: #00ddaf;
  }

  &:focus-visible {
    outline: 4px solid #00ddaf;
    outline-offset: 8px;
  }

  svg {
    width: var(--bth-icon-size);
    height: var(--bth-icon-size);
    color: #00ddaf;
  }
`;
