import React from 'react';

const HeaderWihLogo = ({ imgSrc, alt, header, className }) => {
  return (
    <div className={className}>
      <img src={imgSrc} className="processList__logo" alt={alt} />
      <h2 className="processList__title">{header}</h2>
    </div>
  );
};

export default HeaderWihLogo;
