import styled from 'styled-components';
import { labelStyle, inputStyle, errorTextStyle, errorBorderStyle } from '../commonFormStyle';

export const StyledInput = styled('div')`
  position: relative;
  margin-bottom: 1.5rem;

  label {
    ${labelStyle}
  }

  textarea {
    ${inputStyle}
  }

  .error {
    ${errorTextStyle}
  }

  &.has-error {
    textarea,
    textarea:focus {
      ${errorBorderStyle};
    }
  }
`;

export default StyledInput;
