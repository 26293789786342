import { css } from 'styled-components';
import { breakpoints, colors } from '../../../constants/style';

const paragraphAgile = css`
  p {
    font-size: 1.1875rem;
    line-height: 2rem;
    margin-bottom: 1.5rem;
    color: #4e4d6c;

    br {
      display: block;
      content: ' ';
      margin-bottom: 0.5rem;
    }

    &:last-child {
      margin-bottom: 0;
    }

    a {
      font-weight: 600;
      color: ${colors.primary};
    }
  }

  h3 {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.025rem;
    color: ${colors.primary};
    margin-bottom: 0.5rem;
    margin-top: 7rem;
  }
  h3 + h3 {
    text-transform: none;
    font-weight: 400;
    font-size: 2.25rem;
    line-height: 3rem;
    letter-spacing: normal;
    color: ${colors.titleDark};
    margin-bottom: 3.5rem;
    margin-top: 0rem;

    @media only screen and (max-width: ${breakpoints.tablet}) {
      font-size: 2rem;
      line-height: 2.75rem;
    }
  }

  h4 {
    font-size: 1.5rem;
    line-height: 2.25rem;
    letter-spacing: 0.01rem;
    color: ${colors.titleDark};
    margin-top: 3.5rem;
    margin-bottom: 1rem;
  }
`;

export default paragraphAgile;
