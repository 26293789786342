import styled from 'styled-components';

import { colors, breakpoints } from '../../../../constants/style';
import { navbarMinHeight } from '../../../features/layout/components/commonLayoutStyle';

import LineDashedSVG from '../../../../static/images/line-dashed.svg';

const StyledParagraphSection = styled('section')`
  background-color: ${({ theme }) => theme.backgroundColor};
  position: relative;

  .container {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @media only screen and (max-width: ${breakpoints.tablet}) {
      flex-direction: column;
    }
  }

  .content {
    flex: 0 0 46%;
    padding-bottom: 7rem;
    position: relative;
    z-index: 1;
    flex-direction: row;
    padding-right: 3rem;
    .header {
      margin-bottom: 5rem;
      color: ${({ theme }) => theme.content.header.color};
    }

    .description {
      color: ${({ theme }) => theme.content.paragraph.color};
      h3 {
        font-size: 1.5rem;
        line-height: 3rem;
      }
      p {
        font-size: 1.1875rem;
        line-height: 2rem;
        &:not(:last-child) {
          margin-bottom: 1.5rem;
        }
      }

      a {
        color: ${colors.primary};
      }

      ul {
        font-family: Roboto-Regular, sans-serif;
        color: ${colors.muted};
        line-height: 1.67;
        padding: 1rem 0 1rem 2.1rem;
        position: relative;
      }

      li > ul::before {
        content: '';
        height: 80%;
        width: 1px;
        display: block;
        position: absolute;
        top: 1rem;
        left: calc(-2.1rem + 5px);
        background: url(${LineDashedSVG}) repeat left top;
      }
    }
  }

  .video {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    margin: 0;
    align-items: center;
    z-index: 1;
  }

  @media only screen and (max-width: ${breakpoints.desktopSmall}) {
    padding-top: ${navbarMinHeight};
    padding-bottom: ${navbarMinHeight};

    .content {
      padding-top: 0;
    }
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    display: block;
    padding-bottom: 5rem;
    & + section:not(.paragraph) {
      margin-top: 0;
    }
    .content {
      padding: 3rem 6rem;
    }
    .video {
      transform: translateY(0);
      margin: 0;
      width: 100%;
    }
  }

  @media only screen and (max-width: ${breakpoints.mobileLarge}) {
    .content {
      padding: 0;
    }
  }
`;

export default StyledParagraphSection;
