import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { useDictionaryQuery } from 'hooks/graphql/page'

import Validator from '../../../utils/validator'
import Button from '../../Buttons'
import Input from '../../Form/Input'
import SanityBlockContent from '../../SanityBlockContent'
import IconLoading from '../../icons/IconLoading'
import { colors, breakpoints } from '../../../../constants/style'
import StyledInput from '../../Form/Input/styled'
import Checkbox, { StyledCheckbox } from '../../Form/Checkbox'
import outlineGroup from '../../../../static/images/outline-group.svg'
import shapesGroup from '../../../../static/images/circle-shape-4.svg'
import { useEnvelopImageQuery } from 'hooks/graphql/section'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const defaultFormData = {
  email: '',
  marketingAgreement: false,
}

const imageSize = (min, max) =>
  `calc(${min}px + (100vw - ${breakpoints.mobile}) * (${max - min} / ${
    parseInt(breakpoints.desktopLargest, 10) - parseInt(breakpoints.mobile, 10)
  }))`
const imageSizeNegative = (min, max) =>
  `calc(0px - (${min}px + (100vw - ${breakpoints.mobile}) * (${max - min} / ${
    parseInt(breakpoints.desktopLargest, 10) - parseInt(breakpoints.mobile, 10)
  })))`

const validationRules = {
  email: 'required|email',
  marketingAgreement: 'accepted',
}

const themeStyles = {
  dark: {
    background: 'background-image: linear-gradient(101deg, #190a3f 6%, #0c0121 78%);',
    titleColor: '#fff',
    formBackground: '#150b2f',
    decorationColor: 'rgb(30, 255, 194)',
    fontColor: '#b3b8c7',
    linkColor: '#1effc2',
  },
  white: {
    titleColor: '#100727',
    background: 'background-color: #fff',
    formBackground: '#fff',
    decorationColor: '#fff',
    fontColor: '#7b7b8e',
    linkColor: '#7142eb',
  },
  light: {
    titleColor: '#100727',
    background: 'background-color: #F8F8FC',
    formBackground: '#F8F8FC',
    decorationColor: '#F8F8FC',
    fontColor: '#9595A8',
    linkColor: '#7142eb',
  },
}

export const StyledSubscribeSection = styled('section')`
  ${({ theme }) => themeStyles[theme].background};
  padding-top: 6.75rem;
  padding-bottom: 4.5rem;
  margin-top: 15rem;
  position: relative;

  .subscribe__title {
    text-align: center;
    font-size: 2.125rem;
    margin-bottom: 2.5rem;
    font-family: BioSans-Regular;

    color: ${({ theme }) => themeStyles[theme].titleColor};
  }

  .subscribe__description {
    margin-bottom: 1rem;
    p {
      font-family: BioSans-Regular;
      font-size: 1rem;
      letter-spacing: 0.26px;
      color: ${({ theme }) => themeStyles[theme].fontColor};
    }
  }

  ${StyledCheckbox} {
    /* targets sanity text */
    * {
      font-size: 0.75rem;
      line-height: 2rem;
    }

    label,
    input {
      color: ${colors.mutedDark};
    }

    color: ${({ theme }) => themeStyles[theme].fontColor};

    a {
      color: ${({ theme }) => themeStyles[theme].linkColor};
    }
  }

  .subscribe__form-wrapper {
    & > form {
      display: flex;
      justify-content: center;
    }
  }

  .subscribe__form {
    display: flex;
    width: 45%;
    justify-content: space-between;
    background-color: inherit;

    .column-left {
      flex-basis: 69%;
    }

    .column-right {
      flex-basis: 29%;
    }

    ${StyledInput} {
      margin-top: 1.1rem;
      flex-grow: 1;
      label,
      input {
        color: ${colors.mutedLighter};
      }
    }

    .submit-button {
      width: 100%;
      margin-left: 1rem;
    }

    .success {
      font-family: BioSans-Regular, sans-serif;
      font-size: 2rem;
      color: ${colors.primary};
    }
  }

  .subscribe__decoration {
    position: absolute;
  }

  .subscribe__main-image {
    width: ${imageSize(200, 300)};
    height: ${imageSize(200, 300)};
    left: 0;
    right: 0;
    top: ${imageSizeNegative(100, 165)};
    margin-left: auto;
    margin-right: auto;
  }

  .subscribe__shapes-group {
    width: ${imageSize(150, 290)};
    height: ${imageSize(150, 290)};
    right: ${imageSize(-40, 160)};
    top: ${imageSizeNegative(90, 150)};
  }

  .subscribe__outline-group {
    width: ${imageSize(250, 370)};
    height: ${imageSize(360, 540)};
    left: 0;
    bottom: -50%;
    z-index: 1;
  }

  ${({ variant }) =>
    variant === 'inline' &&
    css`
      display: block;
      position: relative;

      .column-right {
        position: static;
        background-color: inherit;
      }

      .subscribe__title {
        margin: 0;
      }
      .subscribe__form {
        padding: 0;
      }
    `}

  ${({ variant }) =>
    variant === 'blogPostPage' &&
    css`
      background: ${colors.backgroundLight};
      margin: 12rem -5rem 0;

      .subscribe {
        &__form {
          width: 100%;
          max-width: 710px;
        }

        &__outline-group {
          display: none;
        }

        &__shapes-group {
          right: 0;
          top: -80px;
        }
      }
    `}

  @media only screen and (max-width: ${breakpoints.desktopLargest}) {
    .subscribe__form {
      display: flex;
      width: 65%;
    }
  }

  @media only screen and (max-width: ${breakpoints.desktop}) {
    .subscribe__outline-group {
      top: 70%;
    }

    ${({ variant }) =>
      variant === 'blogPostPage' &&
      css`
        margin: 10rem 0 0;

        .subscribe {
          &__shapes-group {
            right: -30px;
            top: -140px;
          }
        }
      `}
  }

  @media only screen and (max-width: ${breakpoints.desktopSmall}) {
    display: block;

    .subscribe__form {
      display: flex;
      width: 85%;

      .column-left {
        margin-bottom: 2rem;
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    .subscribe__form-wrapper {
      padding: 2rem 1.5rem;

      .subscribe__form {
        display: block;
        width: 100%;

        ${StyledInput} {
          flex-grow: 1;
        }

        .column-right {
          display: flex;
          justify-content: center;
        }

        .submit-button {
          width: 15rem;
        }
      }
    }

    .subscribe__outline-group {
      top: 75%;
    }
  }

  @media only screen and (max-width: ${breakpoints.mobile}) {
    padding: 5rem 2rem !important;

    .subscribe__shapes-group {
      display: none;
    }

    .subscribe__form {
      padding: 0;

      .column-left {
        margin-bottom: 1rem;
      }

      form {
        .submit-button {
          width: 100%;
        }
      }
    }

    ${StyledCheckbox} {
      label {
        padding-right: 0;
      }

      p {
        margin-bottom: 0;
      }
    }
  }
`

const SubscribeSection = ({ theme = 'light', showDescription = false, variant }) => {
  const {
    contactForm: { formInputEmail },
    subscribeForm,
    _rawSubscribeForm,
  } = useDictionaryQuery()
  const contactPostImage = useEnvelopImageQuery()
  const [formData, setFormData] = useState(defaultFormData)

  const [errorMessages, setErrorMessages] = useState(defaultFormData)
  const [isFormSent, setIsFormSent] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const validation = new Validator(formData, validationRules, {
    accepted: 'You need to accept terms of service',
  })
  const handleInputChange = (e) => {
    const { type, value, checked, name } = e.target
    const newFormData = { ...formData }
    newFormData[name] = type === 'checkbox' ? checked : value
    setFormData(newFormData)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (validation.passes()) {
      setIsLoading(true)

      const data = { email: formData.email, sourcePage: window.location.href }

      fetch('/api/subscribe-form', {
        method: 'POST',
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.ok) {
            setIsFormSent(true)
          } else {
            response.json().then((data) => {
              setErrorMessages(data.errors)
            })
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
      setIsFormSent(true)
    } else {
      setErrorMessages(validation.errors.all())
    }
  }

  return (
    <StyledSubscribeSection theme={theme} variant={variant}>
      <h2 className="subscribe__title">{subscribeForm.header}</h2>
      {showDescription && (
        <div className="subscribe__description">
          <SanityBlockContent blocks={_rawSubscribeForm.description} />
        </div>
      )}
      <div className="subscribe__form-wrapper">
        {isFormSent ? (
          <SanityBlockContent className="success" blocks={_rawSubscribeForm.thankYouMessage} />
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="subscribe__form">
              <div className="column-left">
                <Input
                  errorMessage={errorMessages.email}
                  handleInputChange={handleInputChange}
                  label={formInputEmail}
                  value={formData.email}
                  name="email"
                />
                <Checkbox
                  name="marketingAgreement"
                  variant="purple"
                  isChecked={formData.marketingAgreement}
                  onChange={handleInputChange}
                  errorMessage={errorMessages.marketingAgreement}
                >
                  <SanityBlockContent blocks={_rawSubscribeForm.checkboxDescription} />
                </Checkbox>
              </div>
              <div className="column-right">
                <div className="form__submit">
                  {isLoading ? (
                    <div className="loading">
                      <IconLoading />
                    </div>
                  ) : (
                    <Button className="submit-button" variant="filled" type="submit">
                      Subscribe
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
      <GatsbyImage
        image={getImage?.(contactPostImage)}
        className="subscribe__decoration subscribe__main-image"
        alt=""
      />
      <img src={outlineGroup} className="subscribe__decoration subscribe__outline-group" alt="" />
      <img src={shapesGroup} className="subscribe__decoration subscribe__shapes-group" alt="" />
    </StyledSubscribeSection>
  )
}

export default SubscribeSection
