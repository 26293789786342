import styled, { css } from 'styled-components';
import { breakpoints, colors } from '../../../../../../constants/style';
import StyledInput from '../../../../Form/Input/styled';

const StyledCandidateReferral = styled('div')`
  display: flex;
  max-width: 48.25rem;
  padding: 3.5rem 8.5rem;
  box-shadow: 0px 8px 24px #475a9e1a;
  margin-left: 2rem;

  @media screen and (max-width: ${breakpoints.desktopLargest}) {
    padding: 3.5rem 4rem;
  }

  @media screen and (max-width: ${breakpoints.desktop}) {
    max-width: 34rem;
  }

  @media screen and (max-width: ${breakpoints.tablet}) {
    margin-top: 5rem;
    margin-left: 0;
    max-width: 100%;
    padding: 3.5rem 6rem;
  }

  @media screen and (max-width: ${breakpoints.tablet}) {
    padding: 3.5rem 2rem;
  }

  .contact-us__column-right--referral {
    max-width: 31.25rem;
    margin: 0 auto;
    ${({ isReferralCodeWindowVisible }) =>
      isReferralCodeWindowVisible &&
      css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      `}
  }

  .contact-us__title {
    margin-bottom: 2.5rem;
  }

  .error {
    margin-top: 2rem;
    color: ${colors.error};
  }

  .contact-us__form {
    .contact-us__email-phone-row {
      display: flex;
      justify-content: space-between;

      ${StyledInput} {
        width: 48%;
      }
    }

    .contact-us__checkboxes-row {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      @media screen and (max-width: ${breakpoints.desktop}) {
        flex-direction: column;
        gap: 0;
      }
    }

    .contact-us__submit-button {
      width: 100%;
      border: 0;
    }
  }
  .contact-us__button-container {
    margin-top: 1.5rem;
    .loading {
      display: flex;
      justify-content: center;
    }
  }

  .thank-you_container {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    .thank-you__title {
      height: 2.25rem;
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      & h2 {
        font-size: 1.625rem;
      }
      & img {
        margin-right: 0.9rem;
      }
    }
    &__message {
      font-size: 1rem;
      line-height: 2rem;
      color: #727292;
      &--indentation {
        margin-left: 1.8rem;
      }
    }
    &__subtitle {
      font-size: 1rem;
      line-height: 1.5rem;
      letter-spacing: 0.43px;
      color: #602be9;
      font-weight: 600;
      margin-bottom: 0.375rem;
      text-transform: uppercase;
    }
    &__step-item {
      font-size: 1rem;
      line-height: 2rem;
      margin: 1.5rem 0 0.5rem;
      & span {
        color: #602be9;
        margin-right: 0.5rem;
      }
    }
    &__horizontal-line {
      margin: 1.875rem 0;
      border: 0;
      border-top: 1px solid #e6e6ef;
    }
    &__button {
      width: calc(50% - 1rem);
      min-width: 250px;
    }
  }
`;

export default StyledCandidateReferral;
