import styled from 'styled-components';
import { colors, breakpoints, targetIE } from '../../../../../constants/style';

const themes = {
  light: {
    color: colors.titlePurple,
    colorSecondary: colors.titleDark,
    backgroundColor: colors.backgroundGrey,
  },
  dark: {
    color: '#fff',
    colorSecondary: '#fff',
    backgroundColor: colors.backgroundPurple,
  },
};

const basicPaddingValue = '3.125rem';

const StyledAdvantagesEntry = styled('div')`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => themes[theme].backgroundColor};
  padding: 5.5625rem ${basicPaddingValue} ${basicPaddingValue};
  position: relative;
  z-index: 1;
  width: 22.5rem;
  min-height: 25.8125rem;
  justify-content: space-between;

  & {
    margin-left: 4rem;
  }

  .title {
    font-size: 3.25rem;
    font-family: "BioSans-SemiBold", sans-serif;
    font-weight: 600;
    color: ${({ theme }) => themes[theme].color};
    margin-bottom: 5px;
  }

  .subtitle {
    font-family: "Roboto-Medium", sans-serif;
    font-size: 1.375rem;
    font-weight: 500;
    line-height: 1.64;
    color: ${({ theme }) => themes[theme].colorSecondary};
  }

  img {
    max-width: 58%;
  }

  &:nth-child(2) {
    transform: translateY(10rem);
  }

  &:nth-child(3) {
    transform: translateY(5rem);
  }

  @media screen and (max-width: ${breakpoints.desktopSmall}) {
    min-height: 24rem;
    width: 20rem;

    .title {
      font-size: 2.5rem;
    }

    .subtitle {
      font-size: 1rem;
    }

    &:nth-child(2) {
      transform: none;
    }

    &:nth-child(3) {
      transform: none;
    }

    & {
      margin-left: 0;
      margin-top: 3rem;
    }
  }

  @media screen and (max-width: ${breakpoints.mobile}) {
    min-height: 20rem;
    width: 16rem;

    .title {
      font-size: 2rem;
    }

    .subtitle {
      font-size: 0.8rem;
    }

    & {
      margin-top: 2rem;
    }
  }

  @media screen and ${targetIE} {
    img {
      position: absolute;
      bottom: ${basicPaddingValue};
      left: ${basicPaddingValue};
      max-width: 38%;
    }
  }
`;

export default StyledAdvantagesEntry;
