import React from 'react'
import styled from 'styled-components'
import { NavLink } from '../../../../../components/Link'
import { colors, breakpoints } from '../../../../../../constants/style'
import PostMetaData from './postMeta'
import Image from '../../../../../components/Image'
import { parseSlug } from '../../../../../utils/slug'
import { formatDate } from '../../../../../utils/date'

const StyledPostIntro = styled('div')`
  margin-bottom: 6.25rem;
  text-align: center;

  @media only screen and (max-width: ${breakpoints.desktopSmall}) {
    margin-bottom: 5rem;
  }
`

const HeadingSection = styled.div`
  width: 80%;
  margin: 0 auto 3.625rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: ${breakpoints.desktopSmall}) {
    width: 100%;
    margin-bottom: 2.5rem;
  }
`

const Tags = styled.div`
  display: flex;
  margin-bottom: 1.25rem;

  ${NavLink} {
    :not(:last-child) {
      margin-right: 10px;
    }
  }
  .article-tag {
    color: var(--t1-overline);
  }
`

const Title = styled.h1`
  display: block;
  width: 90%;
  font-family: Bitter-SemiBold, serif;
  font-weight: 400;
  font-size: 3.25rem !important;
  line-height: 4.2rem;
  margin: 0 0 1.25rem !important;
  text-align: center;
  letter-spacing: 0.4px;
  color: var(--t1-title);

  @media only screen and (max-width: ${breakpoints.desktopSmall}) {
    width: 100%;
  }

  @media only screen and (max-width: ${breakpoints.mobile}) {
    font-size: 2.875rem !important;
    line-height: 4rem;
  }
`

const MainImg = styled(Image)`
  display: block;
  height: auto;
`

const Subheading = styled.p`
  color: var(--t1-title);
  margin-bottom: 2.625rem !important;
`

const PostIntro = ({
  categories,
  title,
  createdAt,
  author,
  coAuthor,
  timeToRead,
  featuredImage,
}) => {
  return (
    <StyledPostIntro>
      <HeadingSection>
        <Tags>
          {categories.map(({ slug, title, id }) => (
            <NavLink to={`/blog/${parseSlug(slug.current)}`} key={id}>
              <span className="h5 article-tag">{title}</span>
            </NavLink>
          ))}
        </Tags>
        <Title>{title}</Title>
        <Subheading className="p2">
          {Boolean(createdAt && timeToRead) ? (
            <span>
              {formatDate(createdAt)} | {timeToRead}
            </span>
          ) : (
            <span>{author.description}</span>
          )}
        </Subheading>
        <PostMetaData author={author} coAuthor={coAuthor} hasShareView />
      </HeadingSection>
      <MainImg objectFit="contain" image={featuredImage} />
    </StyledPostIntro>
  )
}

export default PostIntro
