import React from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

export const CustomSwitchTransition = ({ transitionKey, transitionClassName = 'fade', children }) => (
  <SwitchTransition>
    <CSSTransition classNames={transitionClassName} key={transitionKey} addEndListener={endListener}>
      {children}
    </CSSTransition>
  </SwitchTransition>
);

const endListener = (node, done) => {
  node.addEventListener('transitionend', done, false);
};

export default CustomSwitchTransition;
