import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';

// Temporary helper for transition from Gatsby v2 into Gatsby v3
// https://www.gatsbyjs.com/plugins/gatsby-background-image/#gatsby-3--gatsby-plugin-image
// TODO: check periodically if gatsby-background-image got updated to support V3 out of the box
export const convertToBackgroundImage = (entryImage) => {
  if (!entryImage) {
    return null;
  }

  return entryImage.gatsbyImageData ? convertToBgImage(getImage(entryImage)) : entryImage;
};
