import React from 'react'
import styled from 'styled-components'

import SanityBlockContent from '../SanityBlockContent'
import InlineListItem from './InlineListItem'

const StyledInlineList = styled('div')`
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;

  &:last-child {
    margin-bottom: 0rem;
  }

  .block-content {
    margin-bottom: 1.5rem;
  }

  .inline-list__content {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
`

const InlineList = ({ title = '', items, className = '' }) => {
  return (
    <StyledInlineList className={`inline-list ${className ? className : ''}`}>
      <SanityBlockContent blocks={title} className="inline-list__title" />
      <div className="inline-list__content">
        {items.map((item, index) => {
          return <InlineListItem key={index} item={item} />
        })}
      </div>
    </StyledInlineList>
  )
}

export default InlineList
