import { useEffect, useState } from 'react'
import { breakpoints } from '../../constants/style'

function getSize() {
  const isClient = typeof window === 'object'
  return {
    width: isClient ? window.innerWidth : undefined,

    height: isClient ? window.innerHeight : undefined,
  }
}
const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState(getSize())

  const isSmallerThanDesktopSmall =
    windowSize.width <= breakpoints.tablet.substring(0, breakpoints.tablet.length - 2)
  const isSmallerThanDesktop =
    windowSize.width <= breakpoints.desktopSmall.substring(0, breakpoints.desktopSmall.length - 2)
  const isMaxDesktopLargest =
    windowSize.width <=
    breakpoints.desktopLargest.substring(0, breakpoints.desktopLargest.length - 2)

  useEffect(() => {
    function handleResize() {
      setWindowSize(getSize())
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return { windowSize, isMaxDesktopLargest, isSmallerThanDesktop, isSmallerThanDesktopSmall }
}

export default useWindowSize
