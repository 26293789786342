import React from 'react'
import styled from 'styled-components'
import { colors } from '../../../../constants/style'
import { errorTextStyle } from '../commonFormStyle'

export const StyledInputWrapperNew = styled.div`
  position: relative;
  margin-bottom: 0.8rem;

  label {
    display: block;
    color: white;
    margin-bottom: 0.5rem;
    & > span {
      color: #ff4163;
      margin-right: 3px;
      font-size: 13px;
      line-height: 20px;
    }
  }

  input {
    display: block;
    width: 100%;
    background: rgba(114, 114, 146, 0.5);
    color: ${colors.grayLight};
    border: 1px solid rgba(114, 114, 146, 0.7);
    margin-bottom: 0.5rem;
    padding: 10px 0 10px 16px;
    font-size: 1rem;
    line-height: 28px;
    transition: 0.3s;
    border-radius: 0;
    -webkit-appearance: none;

    &:focus {
      outline: none;
      border: 1px solid #1bfdbf;
    }
  }

  .error {
    ${errorTextStyle};
  }

  &.has-error {
    input,
    input:focus {
      border: 1px solid ${colors.error};
    }
  }
`

const InputNew = ({ errorMessage, label, name, value, handleInputChange, placeholder = '' }) => {
  return (
    <StyledInputWrapperNew className={`${errorMessage ? ' has-error' : ''}`}>
      <label htmlFor={name}>
        <span>*</span>
        {label}
      </label>
      <input
        id={name}
        placeholder={placeholder}
        type="text"
        name={name}
        value={value}
        onChange={handleInputChange}
      />

      <div className="error">{errorMessage}</div>
    </StyledInputWrapperNew>
  )
}

export default InputNew
