import React from 'react';
import styled from 'styled-components';

import { breakpoints } from '../../../constants/style';
import ProcessItem from './components/ProcessItem';

const StyledProcess = styled('div').attrs({ className: 'process-items-container container' })`
  display: flex;
  justify-content: center;
  ${({ isInsideBlockContent }) =>
    isInsideBlockContent
      ? `
      flex-direction: column;
      margin-bottom: 1.5rem !important;
    `
      : null}

  @media only screen and (max-width: ${breakpoints.desktopSmall}) {
    padding: 0;
    flex-direction: column;
  }
`;

const Process = ({ items, raw, theme, isInsideBlockContent = false }) => {
  const [activeItem, setActiveItem] = React.useState(0);
  return (
    <StyledProcess isInsideBlockContent={isInsideBlockContent}>
      {items.map((item, index, arr) => {
        return (
          <ProcessItem
            key={index}
            item={item}
            index={index}
            items={arr}
            raw={raw}
            theme={theme}
            activeItem={activeItem}
            setActiveItem={setActiveItem}
            isInsideBlockContent={isInsideBlockContent}
          />
        );
      })}
    </StyledProcess>
  );
};

export default Process;
