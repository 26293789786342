import React from 'react'
import styled from 'styled-components'

import Image from '../../Image'
import { colors, breakpoints } from '../../../../constants/style'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useFeaturedOnQuery } from 'hooks/graphql/section'

const FeaturedOnSection = styled('section')`
  margin-top: 3rem;
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  @media only screen and (max-width: ${breakpoints.tablet}) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .featured__h2-title {
    font-family: "BioSans", sans-serif;
    font-size: 34px;
    font-style: normal;
    line-height: 1.35;
    margin: 69px 0 29px;

    @media only screen and (max-width: ${breakpoints.desktopSmall}) {
      font-size: 25px;
      margin-top: 0;
    }

    @media only screen and (max-width: ${breakpoints.tablet}) {
      margin-left: 2rem;
    }
  }

  > div {
    background-color: ${colors.backgroundMilky};
    padding: 3.25rem 2rem 3.5rem 2rem;
  }

  .featured__header {
    font-family: "BioSans-SemiBold", sans-serif;
    margin-bottom: 1.8125rem;
    font-size: 1rem;
    text-align: center;
    text-transform: uppercase;
  }

  .featured__logos {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;

    .featured__image-wrapper {
      height: 2.625rem;
      margin: 1rem;
      display: flex;
      align-items: center;
    }
  }

  .featured__on-image-wrapper {
    width: 8rem;
    height: auto;
  }

  ${({ fullWidth }) =>
    fullWidth &&
    `
    margin: 0;
    padding: 0 !important;
    min-height: 20vh;

    > div {
      height: 100%;
    }
  `}

  @media only screen and (max-width: ${breakpoints.tablet}) {
    .featured__logos {
      .featured__image-wrapper {
        flex-basis: 40%;
        display: flex;
        justify-content: center;
        margin: 2.2rem;
      }
    }

    ${({ fullWidth }) =>
      fullWidth &&
      `
        height: auto;

        > div {
          height: auto;
        }
      `}
  }

  @media only screen and (max-width: ${breakpoints.mobile}) {
    .featured__logos {
      display: block;
    }
  }
`

const FeaturedOn = ({ fullWidth, title, photosList, sectionTitle }) => {
  const { header, featuredList } = useFeaturedOnQuery()

  const headerTitle = title || header
  const gatsbyImageDataList = photosList || featuredList

  return (
    <FeaturedOnSection className="featuredSection" fullWidth={fullWidth}>
      {sectionTitle && <h2 className="featured__h2-title">{sectionTitle}</h2>}
      <div className="container">
        <h2 className="featured__header">{headerTitle}</h2>
        <div className="featured__logos">
          {gatsbyImageDataList.map(({ logo, name, alt }) => (
            <div className="featured__image-wrapper" key={`featured-logo-${name}`}>
              {logo.childImageSharp ? (
                <GatsbyImage
                  image={getImage(logo)}
                  alt={`10clouds - ${alt}`}
                  className="featured__on-image-wrapper"
                />
              ) : (
                <Image image={logo} alt={`logo-${name}`} style={{ maxWidth: '100%' }} />
              )}
            </div>
          ))}
        </div>
      </div>
    </FeaturedOnSection>
  )
}

export default FeaturedOn
