import React from 'react';
import styled from 'styled-components';

import { colors, breakpoints } from '../../../../constants/style';
import {
  horizontalLayoutPadding,
  navbarMinHeight,
  horizontalLayoutPaddingMobile,
  horizontalLayoutPaddingTablet,
} from '../../../features/layout/components/commonLayoutStyle';
import SanityBlockContent from '../../SanityBlockContent';
import Image from '../../Image';

import IconArrow from '../../icons/IconArrow';

import backgroundCircle from '../../../../static/images/background-circle.svg';

import CustomSwitchTransition from './CustomSwitchTransition';

import ExampleSection from './ExampleSection';

const StyledSection = styled('section')`
  padding: 3.75rem 0;
  background-image: linear-gradient(242deg, #180045 30%, #180f2b 117%);

  .title {
    color: ${colors.sectionTitleLight};
    letter-spacing: normal;

    &:before {
      content: '';
      display: block;
      margin-bottom: 2.5rem;
      margin-left: 0;
      width: 50px;
      height: 5px;
      background: ${colors.primary};
    }
  }

  .description {
    color: ${colors.mutedLight};
    font-size: 1.125rem;
    line-height: 1.89;
    max-width: 850px;

    margin-top: 1.875rem;
    margin-bottom: 8rem;
  }

  .content {
    transform: translateX(-${horizontalLayoutPadding});
    position: relative;

    min-width: 100vw;
    padding: 8.125rem ${horizontalLayoutPadding} 8.125rem ${horizontalLayoutPadding};

    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    &-navigation {
      flex: 0 0 17%;
    }
    &-body {
      flex: 0 0 43%;
      padding-right: 135px;
      @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        /* IE10+ specific styles go here */
        flex: 0 0 40%;
        padding-right: 1rem;
      }
    }
    &-bullets {
      flex: 0 0 40%;
    }

    @media only screen and (max-width: ${breakpoints.desktopLarge}) {
      flex-flow: row wrap;
      padding-bottom: 3rem;

      &-navigation {
        flex: 0 0 25%;
      }

      &-body {
        flex: 0 0 70%;
      }

      &-bullets {
        flex: 0 0 70%;
        margin-left: auto;
        transform: translateY(4.375rem);
      }
    }

    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) and (max-width: ${breakpoints.desktopLarge}) {
      /* IE10+ specific styles go here */
      &-body {
        flex: 0 0 67%;
      }
    }

    @media only screen and (max-width: ${breakpoints.desktop}) {
      padding: 8.125rem ${horizontalLayoutPaddingTablet} 8.125rem ${horizontalLayoutPaddingTablet};
      transform: translateX(-${horizontalLayoutPaddingTablet});
    }

    @media only screen and (max-width: ${breakpoints.tablet}) {
      padding: 0 ${horizontalLayoutPaddingMobile} 8.125rem ${horizontalLayoutPaddingMobile};
      transform: translateX(-${horizontalLayoutPaddingMobile});

      &-body {
        flex: 0 0 100%;
        padding-right: 0;
      }
      &-bullets {
        flex: 0 0 100%;
      }
    }

    ::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 70vw;
      height: 100%;
      background: ${colors.backgroundDark};
      z-index: -1;

      @media only screen and (max-width: ${breakpoints.desktopLarge}) {
        width: 100vw;
        height: calc(100% - 2.6rem);
      }

      @media only screen and (max-width: ${breakpoints.tablet}) {
        height: 100%;
      }
    }

    ::after {
      content: '';
      display: block;
      width: 15.08rem;
      height: 11.1rem;
      background-image: url(${backgroundCircle});
      background-repeat: no-repeat;
      position: absolute;
      left: calc(70vw - 8rem);
      top: -4rem;
      z-index: -2;

      @media only screen and (max-width: ${breakpoints.desktopLarge}) {
        z-index: 1;
        left: 75vw;
      }

      @media only screen and (max-width: ${breakpoints.tablet}) {
        display: none;
      }
    }
  }

  .content-navigation {
    @media only screen and (max-width: ${breakpoints.tablet}) {
      display: none;
    }
  }

  .content-body {
    color: ${colors.sectionTitleLight};

    .body-title {
      color: ${colors.sectionTitleLight};
      font-size: 2.625rem;
      margin-bottom: 2.188rem;
      @media only screen and (max-width: ${breakpoints.tablet}) {
        font-size: 1.75rem;
        margin-bottom: 1.875rem;
      }
    }

    .body-description {
      color: ${colors.mutedLight};
      * {
        font-size: 1.125rem;
        line-height: 2.33;
        @media only screen and (max-width: ${breakpoints.tablet}) {
          line-height: 1.85;
        }
      }
      strong {
        color: ${colors.sectionTitleLight};
      }

      ul {
        padding-left: 0;

        li {
          position: relative;
          margin-left: 2rem;

          :before {
            content: '';
            position: absolute;
            width: 5px;
            height: 5px;
            border-radius: 8px;
            border: 2px solid #7142eb;
            left: -2rem;
            top: 1rem;
          }
        }
      }
    }
  }

  .content-bullets {
    display: flex;
    flex-flow: column nowrap;
    color: ${colors.sectionTitleLight};
    justify-content: flex-start;

    .bullet {
      flex: 0 0 120px;
      background-image: linear-gradient(111deg, #4d19b0 0%, #2f0a7c 100%);

      font-size: 1.125rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-right: 2.5rem;

      .image-container {
        flex: 0 0 8.625rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      img {
        max-height: 3.875rem;
        max-width: 3.875rem;
      }

      span {
        max-width: 100%;
      }

      :not(:first-of-type) {
        margin-top: 20px;
      }

      @media only screen and (max-width: ${breakpoints.desktopLarge}) {
        max-width: 40rem;
      }

      @media only screen and (max-width: ${breakpoints.tablet}) {
        flex-flow: column nowrap;
        padding: 2.875rem 1.875rem 2.5rem;
        text-align: center;
        flex: 0 0 auto;
        img {
          margin-right: 0;
          margin-bottom: 1.438rem;
        }
      }
    }
  }

  .navigation {
    position: sticky;
    left: 0;
    top: calc(${navbarMinHeight} + 2rem);
    padding-right: 2rem;

    .navigation__position {
      font-family: BioSans-SemiBold, sans-serif;
      font-size: 1rem;
      color: ${colors.primary};

      &::before {
        content: '';
        display: inline-block;
        width: 0;
        height: 3px;
        margin-right: 0;
        vertical-align: middle;
        background: ${colors.primary};
        font-size: 0;
        line-height: 0;
        transition: width 0.2s ease-in-out, margin-right 0.2s ease-in-out;
      }
    }

    .navigation__item {
      cursor: pointer;
      margin-bottom: 1.56rem;

      .navigation__title {
        color: #3d3b48;
        margin-top: 0.75rem;
        font-size: 1.375rem;
      }

      &.active,
      &:hover {
        .navigation__position {
          &::before {
            width: 1.875rem;
            margin-right: 0.625rem;
          }
        }
      }

      &.active {
        .navigation__title {
          color: ${colors.mutedLighter};
        }
      }
    }
  }

  .fade-enter {
    opacity: 0;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-enter-active {
    opacity: 1;
  }
  .fade-exit-active {
    opacity: 0;
  }
  .fade-enter-active,
  .fade-exit-active {
    transition: opacity 500ms;
  }
`;
const SliderWrapper = styled('div')`
  display: none;
  @media only screen and (max-width: ${breakpoints.tablet}) {
    display: block;
  }

  flex-basis: 100%;
  margin-bottom: 2.5rem;
  margin-top: 3.2rem;
  .slider__button-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  .slider__button-left,
  .slider__button-right {
    width: 30px;
    height: 50px;
    background-color: transparent;
    border: 0;
    padding: 0;
    outline: 0;
    cursor: pointer;
    transform: translateX(0);
    transition: transform 0.2s ease-in-out;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.3;
    }

    &:focus {
      outline: 0;
    }

    svg {
      width: 1.1875rem;
      fill: #fff;
    }
  }

  .slider__button-left {
    svg {
      transform: rotate(180deg);
    }

    &:hover:not(:disabled) {
      transform: translateX(-0.25rem);
    }
  }
  .slider__button-right {
    &:hover:not(:disabled) {
      transform: translateX(0.25rem);
    }
  }
`;

const BulletParagraph = ({ header, description, items, rawItems, rawExample, exampleImage }) => {
  const [position, setPosition] = React.useState(0);
  return (
    <StyledSection>
      <h2 className="title">{header}</h2>
      <p className="description">{description}</p>
      <div className="content">
        <SliderWrapper>
          <div className="slider__button-wrapper">
            <button className="slider__button-left" onClick={() => setPosition(position - 1)} disabled={position === 0}>
              <IconArrow />
            </button>
            <button className="slider__button-right" onClick={() => setPosition(position + 1)} disabled={position === items.length - 1}>
              <IconArrow />
            </button>
          </div>
        </SliderWrapper>
        <div className="content-navigation navigation">
          {items.map((item, index) => {
            return (
              <div key={index} className={`navigation__item ${index === position && 'active'}`} onClick={() => setPosition(index)}>
                <div className="navigation__position">{(index + 1).toString().padStart(2, '0')}</div>
                <div className="navigation__title">{item.header}</div>
              </div>
            );
          })}
        </div>
        <CustomSwitchTransition transitionKey={position}>
          <BodyContent header={items[position].header}>
            <SanityBlockContent blocks={rawItems[position].description} />
          </BodyContent>
        </CustomSwitchTransition>
        <CustomSwitchTransition transitionKey={position}>
          <BulletsContent bullets={items[position].bullets} index={position} />
        </CustomSwitchTransition>
      </div>

      {rawExample && <ExampleSection example={rawExample} image={exampleImage}></ExampleSection>}
    </StyledSection>
  );
};

const BodyContent = ({ header, children }) => (
  <div className="content-body">
    <h3 className="body-title">{header}</h3>
    <div className="body-description">{children}</div>
  </div>
);

const BulletsContent = ({ bullets }) => (
  <div className="content-bullets">
    {bullets.map(({ description, icon }, index) => (
      <div className="bullet" key={`bullet-${index}`}>
        <div className="image-container">
          <Image image={icon} alt={icon?.alt || ''} />
        </div>
        <span>{description}</span>
      </div>
    ))}
  </div>
);

export default BulletParagraph;
