import React from 'react';
import styled from 'styled-components';

import ProjectExamplesEntry from './ProjectExamplesEntry';

import {
  horizontalLayoutPadding,
  horizontalLayoutPaddingTablet,
  horizontalLayoutPaddingMobile,
  navbarMinHeight,
} from '../../../features/layout/components/commonLayoutStyle';
import { colors, breakpoints } from '../../../../constants/style';

const ProjectExamplesSection = styled('section')`
  padding-top: 7rem;
  padding-left: 0 !important;
  padding-right: 0 !important;

  h2 {
    padding: 0 ${horizontalLayoutPadding};
    margin-bottom: 7.75rem;

    &:before {
      content: '';
      display: block;
      margin-bottom: 2.5rem;
      width: 50px;
      height: 5px;
      background: ${colors.primary};
    }
  }

  @media only screen and (max-width: ${breakpoints.desktop}) {
    h2 {
      padding: 0 ${horizontalLayoutPaddingTablet};
    }
  }

  @media only screen and (max-width: ${breakpoints.desktopSmall}) {
    h2 {
      margin-bottom: 4rem;
    }
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    padding-top: ${navbarMinHeight};

    h2 {
      padding: 0 ${horizontalLayoutPaddingMobile};
      margin-bottom: 2rem;
    }
  }
`;

const Index = ({ header, projectExamplesEntries }) => {
  return (
    <ProjectExamplesSection>
      {header && <h2>{header}</h2>}
      {projectExamplesEntries.map((item, index) => {
        return (
          <div className="projectExample" key={`project-example-item-${index}`}>
            {Boolean(item.header && item.image) && (
              <ProjectExamplesEntry
                header={item.header}
                image={item.image}
                tags={item.tags}
                category={item.category}
                rawDescription={item._rawDescription}
              />
            )}
          </div>
        );
      })}
    </ProjectExamplesSection>
  );
};

export default Index;
