import styled from 'styled-components';
import { breakpoints, colors, fullBulletListStyle } from '../../../../constants/style';
import waveBottom from '../../../../static/images/wave-bottom.svg';
import waveTop from '../../../../static/images/wave-top.svg';

const ResourcesSection = styled('section')`
  padding-top: 8.125rem;
  padding-bottom: 8.125rem;
  background-image: url(${waveTop}), url(${waveBottom});
  background-position: 0 -90%, right 100%;
  background-repeat: no-repeat, no-repeat;
  background-size: 100%, 80%;

  .resources__header {
    font-size: 2.625rem;
    margin-bottom: 3.9375rem;
    text-align: center;

    &:before {
      content: '';
      display: block;
      margin: 0 auto 2.1875rem;
      width: 50px;
      height: 5px;
      background: #7142eb;
    }
  }

  .resources__wrapper {
    padding-left: 0;
  }

  .resource__wrapper {
    padding: 2.125rem 2.75rem;
    margin-bottom: 1.5rem;
    background: #fff;
    display: flex;
    box-shadow: 0 42px 64px 0 rgba(101, 101, 121, 0.1);
  }

  .resource__image {
    margin-right: 3.5rem;
    width: 400px;
    flex-shrink: 0;

    border-radius: 1rem;
    &:before,
    &:after {
      border-radius: 1rem;
    }
  }

  .resource__text-wrapper {
    padding-top: 1.625rem;
  }

  .resource__title {
    font-family: "BioSans-Regular", sans-serif;
    font-size: 1.75rem;
    color: ${colors.titleDark};
    margin-bottom: 2rem;
  }
  .resource__bullets {
    padding-left: 0;
    margin-bottom: 2rem;

    li {
      ${fullBulletListStyle}
      font-size: 1.125rem;
      color: ${colors.mutedDark};
    }
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    .resource__wrapper {
      flex-direction: column;
    }
  }

  @media only screen and (max-width: ${breakpoints.mobile}) {
    .resource__image {
      width: 100% !important;
      height: 270px !important;
    }

    .resource__title {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
    .resource__bullets {
      margin-bottom: 1.5rem;

      li {
        font-size: 1rem;
      }
    }
  }
`;

export default ResourcesSection;
