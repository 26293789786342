import React from 'react';
import styled from 'styled-components';

import Image from '../../Image';

import { breakpoints } from '../../../../constants/style';

const TechnologiesItemWrapper = styled('div')`
  text-align: center;
  background-color: #160940;

  &:hover {
    background: linear-gradient(289.26deg, #210d60 0%, #3a199e 100%);
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 73.6%;
    position: absolute;
    left: 0;
    bottom: 0;
    background: transparent;
    transition: height 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    z-index: -2;
  }
  /* this is to make item a square */
  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
    position: relative;
    z-index: -1;
    background-color: #160940;
    transition: opacity 0.3s ease-in-out;
  }

  .technologies__logo-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 12rem;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    transition: top 0.3s ease-in-out;
    img {
      max-height: 4.06rem;
      margin-bottom: 5rem;
    }
  }
  h3 {
    display: block;
    font-family: "BioSans-Regular", sans-serif;
    font-size: 1.625rem;
    color: #fff;
    transition: color 0.3s ease-in-out;
    &::first-letter {
      text-transform: uppercase;
    }
  }
  p {
    position: absolute;
    top: 80%;
    font-family: "Roboto-Regular", sans-serif;
    line-height: 1.75;
    font-size: 1rem;
    width: 100%;
    opacity: 0;
    transition: top 0.3s ease-in-out, opacity 0.3s ease-in-out;
    padding: 0 1.25rem;
    color: #c3c2d4;
  }
  &:hover {
    .technologies__logo-wrapper {
      top: 35%;
      img {
        margin-bottom: 5rem;
      }
      h3 {
        display: none;
      }
    }
    p {
      top: 55%;
      opacity: 1;
    }
  }
  @media only screen and (max-width: ${breakpoints.desktopLargest}) {
    height: 23.25rem;
  }

  @media only screen and (max-width: ${breakpoints.desktopSmall}) {
    height: auto;
  }
`;

const TechnologiesItem = ({ theme, name, description, image, row, shouldOverlap }) => {
  return (
    <TechnologiesItemWrapper row={row} shouldOverlap={shouldOverlap}>
      <div className="technologies__logo-wrapper">
        <Image image={image} alt={`${name} logo`} />
        <h3>{name}</h3>
      </div>
      <p>{description}</p>
    </TechnologiesItemWrapper>
  );
};

export default TechnologiesItem;
