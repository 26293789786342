import { useStaticQuery, graphql } from 'gatsby'

export const useFooterAwardsQuery = () => {
  const data = useStaticQuery(graphql`
    {
      allFooterJson {
        nodes {
          awardsSection {
            awards {
              title
              imagePath {
                childImageSharp {
                  gatsbyImageData
                }
              }
              alt
              fileName
            }
            title
          }
        }
      }
    }
  `)
  return data.allFooterJson.nodes[0]
}
