import React from 'react'
import styled from 'styled-components'
import { colors, breakpoints } from '../../../../constants/style'
import WaveSVG from '../../../../static/images/wave-light-1.svg'
import SectionHeader from '../../SectionHeader/SectionHeader'
import { PerkContent } from './components/PerkContent'

const PerksSection = styled('section').attrs({ className: 'perks' })`
  position: relative;
  background-color: ${({ theme }) => theme.background};
  z-index: 1;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    background: url(${WaveSVG}) no-repeat left top;
  }
  .perks {
    &__wrapper {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(16rem, 23.125rem));
      justify-content: center;
      gap: 2rem;
      position: relative;
      z-index: 1;
      @media only screen and (max-width: ${breakpoints.desktop}) {
        grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
      }
    }
  }
`
const themes = {
  light: {
    background: '#fff',
  },

  lightBluePerkHeading: {
    background: '#fff',
  },

  lightPurple: {
    background: colors.backgroundLight,
  },
}

const Perks = ({ header, description, perks, rawPerks, theme }) => {
  return (
    <PerksSection theme={themes.hasOwnProperty(theme) ? themes[theme] : themes['light']}>
      <SectionHeader title={header} subtitle={description} />
      <div className="perks__wrapper container">
        {perks.map(({ header, icon, url }, index) => (
          <PerkContent
            description={rawPerks[index].description}
            header={header}
            icon={icon.asset.url}
            url={url}
            key={`perks-item-${header}`}
          />
        ))}
      </div>
    </PerksSection>
  )
}

export default Perks
