import { useState } from 'react';
import Validator from '../../utils/validator';

const defaultFormData = {
  email: '',
  marketingAgreement: false,
};

const validationRules = {
  email: 'required|email',
  marketingAgreement: 'accepted',
};

const useDownloadPdfModal = ({ setSubmitComplete, fileUrl, isPlaybook = false }) => {
  const webhookEndpoint = isPlaybook ? '/api/download-playbook-pdf' : '/api/download-ebook-pdf';

  const [formData, setFormData] = useState(defaultFormData);

  const [errorMessages, setErrorMessages] = useState(defaultFormData);
  const [isLoading, setIsLoading] = useState(false);

  const validation = new Validator(formData, validationRules, { accepted: 'You need to accept terms of service' });

  const handleInputChange = (e) => {
    const { type, value, checked, name } = e.target;
    const newFormData = { ...formData };
    newFormData[name] = type === 'checkbox' ? checked : value;
    setFormData(newFormData);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validation.fails()) return setErrorMessages(validation.errors.all());
    setIsLoading(true);

    const data = { email: formData.email, sourcePage: window.location.href, name: 'download_pdf' };

    fetch(webhookEndpoint, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          // this check is to prevent parsing response from non json response
          // which would result in error: Unexpected end of JSON input
          const contentType = response.headers.get('content-type');
          if (contentType && contentType.includes('application/json')) {
            response.json().then((data) => setErrorMessages(data?.errors));
          }
        }
      })
      .finally(() => {
        setIsLoading(false);
        window.open(`${fileUrl}?dl=`, '_blank');
        setFormData(defaultFormData);
        setSubmitComplete(true);
      });
  };

  const resetFormData = () => {
    setFormData(defaultFormData);
  };

  return { formData, handleInputChange, errorMessages, handleSubmit, isLoading, resetFormData };
};

export default useDownloadPdfModal;
