import styled from 'styled-components';
import { colors } from '../../../../constants/style';
import { labelStyle, inputStyle, errorTextStyle, errorBorderStyle } from '../commonFormStyle';

export const StyledInput = styled('div')`
  position: relative;
  margin-bottom: 1.5rem;

  label {
    ${labelStyle}
  }

  input {
    height: 2.25rem;
    ${inputStyle};
  }

  .error {
    ${errorTextStyle};
  }

  &.has-error {
    input,
    input:focus {
      ${errorBorderStyle};
    }
  }
`;

export default StyledInput;
