import styled from 'styled-components'

import SectionHeader from '../../SectionHeader/SectionHeader'
import ScrollItem from './components/ScrollItem.jsx'
import { FullWidthScrollSlider } from '../FullWidthScrollSlider'

const ScrollSliderStyled = styled.section`
  padding-left: 0 !important;
  padding-right: 0 !important;

  .section-header {
    padding-inline: var(--margin-mobile);
  }
`

export const ScrollSlider = ({ title, description, items, rawItems }) => {
  return (
    <ScrollSliderStyled>
      <SectionHeader title={title} subtitle={description} />
      <FullWidthScrollSlider
        items={items.map((item, index) => ({
          content: <ScrollItem title={item.title} rawDescription={rawItems[index].description} />,
          image: item.img,
        }))}
      />
    </ScrollSliderStyled>
  )
}

export default ScrollSlider
