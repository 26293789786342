import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../../../../constants/style';

const StyledQuote = styled('div')`
  .quote__wrapper {
    width: 60%;
    font-family: DroidSerif;
    font-size: 1.875rem;
    font-style: italic;
    color: #5e20e4;

    /* float is used for overflowing text around the container */
    float: left;

    &:before {
      float: left;
      content: '“';
      font-family: BioSans-Regular;
      font-size: 8.75rem;
      font-style: italic;
      color: #5e20e4;
    }
  }

  .quote__text {
    font-family: DroidSerif;
    font-size: 1.875rem;
    font-style: italic;
    color: #5e20e4;
    margin-bottom: 0;
    padding: 3.375rem 2.5rem 1.875rem 5.375rem;
  }

  /* normalize styles inherited from blogPost please note it's min-width    */
  @media screen and (min-width: ${breakpoints.tablet}) {
    .quote__intro {
      margin-bottom: 0;
    }
    .quote__wrapper {
      border-left: none;
      padding-left: 0;
      letter-spacing: normal;
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: ${breakpoints.mobile}) {
    .quote__wrapper {
      width: 100%;
      float: none;

      &:before {
        display: none;
        float: none;
      }
    }

    .quote__text {
      padding: 2rem;
    }
  }
`;

const Quote = (props) => {
  // on first render for some weird reason props are not available :/
  if (!props || !props.wrappedText || !props.quote) {
    return null;
  }
  const { wrappedText, quote } = props;

  const [firstSentence, ...rest] = wrappedText.split('.');
  return (
    <StyledQuote>
      <p className="quote__intro">{firstSentence}.</p>
      <div>
        <blockquote className="quote__wrapper">
          <p className="quote__text">{quote}</p>
        </blockquote>
        <p>{rest.join('.')}</p>
      </div>
    </StyledQuote>
  );
};

export default Quote;
