import styled, { css } from 'styled-components'

import { breakpoints, colors } from '../../../../constants/style'
import { StyledSelect } from '../../Form/Select'

export const StyledJobOffersSection = styled('section')`
  background-color: #f8f8fc;
  .job-offers {
    &__container {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &__label {
      margin-bottom: 0.5rem;
      font-size: 0.875rem;
      color: ${colors.titleDark};
    }

    &__search {
      &-input {
        height: 3rem;
        border: solid 1px #c3c2d4;
        background-color: #fff;
        padding: 0.625rem 1rem;
        border-radius: 0;
        -webkit-appearance: none;
        &:focus {
          border: 1px solid ${colors.primary};
          outline: none;
        }
        &::placeholder {
          color: #727292;
          font-size: 1rem;
        }
      }
    }

    &__search-row {
      display: flex;
      gap: 2rem;
      margin: 0 auto;
      margin-bottom: 3.5rem;
      max-width: 64.6875rem;
      font-family: Roboto-Regular, sans-serif;

      ${StyledSelect} {
        height: 3rem;
        border: solid 1px #c3c2d4;
        background-color: #fff;
        margin-bottom: 0;
        display: flex;
        align-items: center;

        &::before {
          top: 50%;
          transform: translate(-50%, -50%);
        }

        button,
        select {
          padding: 0.625rem 1rem;
          padding-right: 0;
        }

        button {
          border-bottom: none;
          align-items: center;
          z-index: 1;
        }
        label {
          top: 1rem;
          left: 2rem;
          z-index: 0;
          transition: all 200ms ease;
        }
        .label--has__value {
          display: none;
        }
        ul {
          top: 0;
        }
      }
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__button {
      margin-top: 3.5rem;
      &:hover img {
        filter: brightness(0) invert(1);
      }
      img {
        transform-origin: center;
        margin-left: 0.6875rem;
        ${({ shouldShowAllJobOffers }) =>
          shouldShowAllJobOffers &&
          css`
            transform: rotate(180deg);
            margin-bottom: 1px;
          `};
      }
    }

    @media only screen and (max-width: ${breakpoints.desktopLargest}) {
      &__search-row {
        max-width: 45.8125rem;
      }
    }

    @media only screen and (max-width: ${breakpoints.desktopSmall}) {
      &__search-row {
        max-width: 100%;
      }
    }
    @media only screen and (max-width: ${breakpoints.mobileLarge}) {
      &__search-row {
        flex-direction: column;
      }
    }
  }
`
