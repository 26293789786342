import React, { Suspense } from 'react';

import { ProcessOfWorkSection } from './styled';
import LoadingIndicator from '../../LoadingIndicator/LoadingIndicator';
import { colors } from '../../../../constants/style';
import SectionHeader from '../../SectionHeader/SectionHeader';
import Process from '../../Process';

export const themes = {
  light: {
    background: '#fff',
  },
  lightPurple: {
    background: colors.backgroundLight,
  },
};

const ProcessOfWork = ({ header, description, items, raw, theme }) => {
  return (
    <ProcessOfWorkSection numOfItems={items.length} theme={themes.hasOwnProperty(theme) ? themes[theme] : themes['light']}>
      <SectionHeader title={header} subtitle={description} />
      <Suspense fallback={<LoadingIndicator />}>
        <Process items={items} raw={raw} theme={theme} />
      </Suspense>
    </ProcessOfWorkSection>
  );
};

export default ProcessOfWork;
