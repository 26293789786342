import React from 'react';
import { blockContentSerializers, sanityConfig } from '.';
import readingTime from 'reading-time';
import blocksToHtml from '@sanity/block-content-to-html';
import { trimSentence } from '../../utils/string';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import { GatsbyImage } from 'gatsby-plugin-image';
import getConvertedGatsbyImgDataToWEBP from '../../utils/images/getConvertedGatsbyImgDataToWEBP';

const getHtmlFromSanityBlock = (raw) =>
  blocksToHtml({
    blocks: raw,
    serializers: { ...blockContentSerializers },
    projectId: process.env.GATSBY_SANITY_PROJECT_ID,
    dataset: process.env.GATSBY_SANITY_DATASET,
  });

export const getTimeToReadFromSanityBlock = (raw) => {
  const html = getHtmlFromSanityBlock(raw);
  return readingTime(html).text;
};

// how to serialize blocks from sanity - https://www.sanity.io/docs/presenting-block-text#plain-text-serialization-ac67a867dd69
export const getPreviewFromSanityBlock = (block, length = 200) => {
  if (block._type !== 'block' || !block.children) {
    return '';
  }
  const blockAsString = block.children.map((child) => child.text).join('');
  return trimSentence(blockAsString, length);
};

export const serializeEnhancedImage = (props) => {
  if (!props.node.asset) {
    return null;
  }

  const index = props.node.asset._ref.indexOf('?');
  const ref = index === -1 ? props.node.asset._ref : props.node.asset._ref.substring(0, index);

  const imageProps = getGatsbyImageData(ref, { maxWidth: 1200 }, sanityConfig);
  return (
    <figure>
      <GatsbyImage image={getConvertedGatsbyImgDataToWEBP(imageProps)} alt={props.node.alt || '' }  />
      <figcaption>{props.node.caption}</figcaption>
    </figure>
  );
};
