import React from 'react'

import styled from 'styled-components'

import { colors, breakpoints } from '../../../../constants/style'
import {
  blogArrowAnimationStyle,
  blogTitleAnimationStyle,
} from '../../../features/blog/commonStyle'
import IconArrowLong from '../../icons/IconArrowLong'
import { parseSlug } from '../../../utils/slug'
import { NavLink } from '../../Link'
import Image from '../../Image'
import SliderSection from 'components/SliderSection/SliderSection'

const StyledSliderSection = styled(SliderSection)`
  background-color: ${colors.backgroundLight};

  .item {
    width: 100%;
    box-shadow: 0px 16px 40px rgba(71, 90, 158, 0.1);
    display: block !important;

    .link {
      padding-left: 0 !important;
      padding-right: 0 !important;
      text-transform: none;
      &:hover .item__header {
        ${blogTitleAnimationStyle.hover};
      }
      svg {
        ${blogArrowAnimationStyle.base}
        display: block;
        margin-top: 1rem;
        color: ${colors.titleDark};
      }

      &:hover svg {
        ${blogArrowAnimationStyle.hover}
      }
    }
    &__text {
      padding: 2rem;
    }
    &__category {
      color: ${colors.primary};
      margin-bottom: 0.5rem;
      font-size: 1rem;
      font-weight: 600;
      letter-spacing: 0.048rem;
      line-height: 1.25;
    }
    &__header {
      font-family: "BioSans-SemiBold", sans-serif;
      color: ${colors.titleDark};
      font-size: 1.5rem;
      font-weight: 600;
      letter-spacing: 0.01rem;
      line-height: 1.5;
      ${blogTitleAnimationStyle.base};
    }
    .gatsby-image-wrapper {
      overflow: hidden;
      height: 0;
      padding-top: 62.5%;
    }
  }
`

const Articles = ({ header, raw, blogPosts }) => (
  <StyledSliderSection
    header={header}
    description={raw.description}
    items={blogPosts}
    withBackground
  >
    {blogPosts.map((blogPost) => (
      <div className='item' key={`blogpost-${blogPost?.title}`}>
        <NavLink
          to={`/blog/${parseSlug(blogPost?.categories?.[0]?.slug?.current)}${parseSlug(
            blogPost.slug.current,
          )}`}
        >
          <Image image={blogPost.featuredImage} style={{ width: '100%' }} />
          <div className='item__text'>
            <div className='item__category'>
              {blogPost?.categories?.[0]?.slug?.current.split('-').join(' ').toUpperCase()}
            </div>
            <h3 className='item__header'>{blogPost.title}</h3>
            <IconArrowLong />
          </div>
        </NavLink>
      </div>
    ))}
  </StyledSliderSection>
)

export default Articles
