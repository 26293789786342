module.exports.capitalize = (word) => {
  return word.length ? word.charAt(0).toUpperCase() + word.slice(1) : '';
};

module.exports.trimSentence = (string, maxLength) => {
  const chunks = string.split('.');
  let resultingString = '';
  let index = 0;

  while (resultingString.length < maxLength) {
    if (!chunks[index]) {
      break;
    }
    resultingString += `${chunks[index]}.`;
    index += 1;
  }

  return resultingString;
};

const React = require('react');

module.exports.htmlContent = (string) => {
  if (!string) {
    return;
  }
  return string.split('\n').map((text, index) => (
    <React.Fragment key={index}>
      {text}
      <br />
    </React.Fragment>
  ));
};
