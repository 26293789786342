import React, { lazy, Suspense } from 'react'

import styled from 'styled-components'

import { useDictionaryQuery } from 'hooks/graphql/page'
import { breakpoints } from '../../../../constants/style'
import { horizontalLayoutPaddingTablet } from '../../../features/layout/components/commonLayoutStyle'
import LoadingIndicator from '../../LoadingIndicator/LoadingIndicator'
import SectionHeader from '../../SectionHeader/SectionHeader'
import EmptyCaseStudiesEntry from './components/EmptyCaseStudiesEntry'

const CaseStudiesEntry = lazy(() => import('./components/CaseStudiesEntry'))

const CaseStudiesSection = styled('section')`
  padding-left: 0 !important;
  padding-right: 0 !important;
  background: #fff;

  .casestudy {
    max-width: 120rem;
    margin: 0 auto;
    &:first-child {
      .casestudies__intro {
        padding-top: 0;
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    .section-header {
      padding-left: ${horizontalLayoutPaddingTablet};
      padding-right: ${horizontalLayoutPaddingTablet};
    }
  }
`

const Index = ({ header, subtitle, caseStudiesEntries }) => {
  const { showCaseStudyButtonLabel, downloadLabel } = useDictionaryQuery().caseStudy
  return (
    <CaseStudiesSection>
      <Suspense fallback={<LoadingIndicator />}>
        <SectionHeader title={header} subtitle={subtitle} />
        {caseStudiesEntries.map((item, index) => {
          return (
            <div className="casestudy" key={`case-studies-item-${index}`}>
              {Boolean(item.caseStudy && item.caseStudy.abstract) ? (
                <CaseStudiesEntry
                  buttonLabel={showCaseStudyButtonLabel}
                  downloadLabel={downloadLabel}
                  {...item}
                />
              ) : (
                <EmptyCaseStudiesEntry
                  {...item}
                />
              )}
            </div>
          )
        })}
      </Suspense>
    </CaseStudiesSection>
  )
}

export default Index
