import React from 'react';
import styled from 'styled-components';

import Image from '../../Image';
import Entry from './components/Entry';

import { colors, breakpoints } from '../../../../constants/style';

const AgileAproachSection = styled('section')`
  padding-top: 13rem;
  padding-bottom: 11.75rem;
  background: ${colors.background};

  .intro {
    h2 {
      margin: 0;
      font-family: "BioSans-Regular", sans-serif;
      font-size: 2.625rem;
      color: ${colors.sectionTitleLight};
      text-align: center;

      &:before {
        content: '';
        display: block;
        margin: 0 auto 3.5rem auto;
        width: 50px;
        height: 5px;
        background: ${colors.secondary};
      }
    }

    p {
      margin: 1.875rem auto 0;
      max-width: 910px;
      font-family: Roboto-Regular, sans-serif;
      font-size: 1.1875rem;
      line-height: 1.79;
      color: ${colors.mutedLight};
      text-align: center;
    }
  }

  .image {
    margin-top: 8.125rem;

    > img,
    .gatsby-image-wrapper {
      max-width: 1500px;
      margin: auto;
    }
  }

  .entries {
    padding: 3.25rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  @media screen and (max-width: ${breakpoints.desktopSmall}) {
    padding-top: 4.75rem;
    padding-bottom: 2rem;

    .intro {
      display: block;

      p {
        margin-top: 2rem;
      }
    }

    .entries {
      display: block;
    }
  }

  @media screen and (max-width: ${breakpoints.mobile}) {
    .intro {
      h2 {
        font-size: 2.5rem;
      }

      p {
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }

    .image {
      margin-top: 3rem;

      > img,
      .gatsby-image-wrapper {
        width: 100%;
      }
    }

    .entries {
      padding: 0;
    }
  }
`;

const AgileAproach = (props) => {
  const { title, description, image, entries } = props;

  return (
    <AgileAproachSection>
      <div className="intro">
        <h2>{title}</h2>
        <p>{description}</p>
      </div>

      <div className="image">
        <Image image={image} alt={title} />
      </div>

      <div className="entries">
        {entries.map((item, index) => {
          return (
            <Entry
              key={index}
              dashColor={item.dashColor}
              bigTitle={item.bigTitle}
              smallTitle={item.smallTitle}
              description={item.description}
            />
          );
        })}
      </div>
    </AgileAproachSection>
  );
};

export default AgileAproach;
