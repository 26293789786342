import React from 'react';

import styled from 'styled-components';

import { breakpoints, colors } from '../../../../constants/style';
import Image from '../../Image';
import SectionHeader from '../../SectionHeader/SectionHeader';

const PartnersSection = styled('section').attrs({ className: 'partners' })`
  background-color: ${colors.backgroundLight};

  .partners {
    &__logos {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__image-wrapper {
      max-width: 15.625rem;
      max-height: 2.25rem;
      &:not(:last-child) {
        margin-right: 5rem;
      }
    }
    @media only screen and (max-width: ${breakpoints.tablet}) {
      &__image-wrapper {
        margin-right: 0 !important;
      }
      &__logos {
        max-width: 34.25rem;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 5rem;
      }
    }

    @media only screen and (max-width: ${breakpoints.mobile}) {
      &__logos {
        grid-template-columns: 1fr;
        gap: 5rem;
        justify-items: center;
      }
    }
  }
`;

const Partners = ({ header, partners, raw }) => {
  return (
    <PartnersSection>
      <SectionHeader title={header} subtitle={raw?.description} />
      <div className="partners__logos container">
        {partners.map(({ logo, name }) => (
          <div className="partners__image-wrapper" key={`partners-logo-${name}`}>
            <Image image={logo} />
          </div>
        ))}
      </div>
    </PartnersSection>
  );
};

export default Partners;
