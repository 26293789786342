import React from 'react';
import styled from 'styled-components';

import Step from './components/Step';

import BackgroundLogo from '../../../../static/images/10c-logo/10c-icon-background.svg';
import { colors, breakpoints } from '../../../../constants/style';

const ProcessOfDevelopmentSection = styled('section')`
  padding-top: 10.125rem;
  background-image: url(${BackgroundLogo}), linear-gradient(45deg, #180f2b, #180045);
  background-position: right 222px, top left;
  background-repeat: no-repeat, repeat;
  color: ${colors.muted};

  h2 {
    padding: 0 28%;
    margin-bottom: 1.875rem;
    font-family: BioSans-Regular, sans-serif;
    font-size: 2.625rem;
    line-height: 1.33;
    color: ${colors.sectionTitleLight};
    text-align: center;

    &:before {
      content: '';
      display: block;
      margin: 0 auto 3.5rem auto;
      width: 50px;
      height: 5px;
      background: ${colors.secondary};
    }
  }

  > p {
    padding: 0 20%;
  }

  p {
    font-family: Roboto-Regular, sans-serif;
    font-size: 1.1875rem;
    line-height: 1.79;
    color: #9393a7;
    text-align: center;
    white-space: pre-line;
  }

  .steps {
    margin-top: 9.6875rem;
  }

  @media screen and (max-width: ${breakpoints.desktopSmall}) {
    .steps {
      margin-top: 4rem;
      padding-bottom: 8rem;
    }

    h2 {
      padding: 0;
    }

    > p {
      padding: 0;
    }
  }

  @media screen and (max-width: ${breakpoints.mobile}) {
    padding-top: 5rem;

    h2 {
      margin-bottom: 2.75rem;
      font-size: 2.5rem;
      line-height: 3rem;
    }

    p {
      font-size: 1rem;
    }
  }
`;

const ProcessOfDevelopment = (props) => {
  const { title, description, steps } = props;

  return (
    <ProcessOfDevelopmentSection className="process-of-development">
      <h2>{title}</h2>
      <p>{description}</p>
      <div className="steps">
        {steps.map((item, index) => {
          return <Step key={index} position={index} icon={item.icon} name={item.name} description={item.description} type={item.type} />;
        })}
        <Step />
      </div>
    </ProcessOfDevelopmentSection>
  );
};

export default ProcessOfDevelopment;
