import styled from 'styled-components';
import { breakpoints, colors } from '../../../../../../constants/style';
import { SliderWrapper } from '../../../../HorizontalSlider';

const mobileCellHeight = '7.25rem';

export const StyledTable = styled('table')`
  font-family: "BioSans-Regular", sans-serif;
  text-align: center;
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;

  th {
    font-size: 0.875rem;
    color: ${colors.muted};
    padding: 0.5rem;
  }

  td {
    text-align: center;
    color: ${colors.muted};
    padding: 1.5rem 0.5rem;
    p {
      font-size: 0.85rem;
    }
  }

  tr {
    &:nth-child(even) {
      background-color: #fafafc;
    }
  }

  .table-column--first {
    box-shadow: 5px 0 10px rgba(11, 15, 35, 0.03);
    p {
      font-family: "BioSans-Regular", sans-serif;
      font-size: 1rem;
      color: ${colors.titleDark};
    }
  }

  .table-column--last {
    color: ${colors.titleDark};
  }

  .table-column__entry--colored {
    p {
      font-family: "BioSans-SemiBold", sans-serif;
      color: #7142eb;
    }
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    display: none;
  }
`;

export const StyledMobileTable = styled('div')`
  display: none;

  @media only screen and (max-width: ${breakpoints.tablet}) {
    display: flex;

    .table-legend,
    ${SliderWrapper} {
      width: 50%;
    }

    .table-legend {
      .table-legend__entry {
        height: ${mobileCellHeight};
        display: flex;
        align-items: center;
        padding-left: 1rem;

        p {
          font-family: "BioSans-Regular", sans-serif;
          font-size: 0.8125rem;
          color: ${colors.titleDark};
        }

        &:not(:first-child) {
          box-shadow: 5px 0 10px rgba(11, 15, 35, 0.03);
          /* position ensures that the shadow can be cast on elements with background */
          position: relative;
          z-index: 1;
        }

        &:nth-child(even) {
          background-color: #fafafc;
        }
      }

      .table-legend__entry--colored {
        p {
          font-family: "BioSans-SemiBold", sans-serif;
          color: #7142eb;
        }
      }
    }

    ${SliderWrapper} {
      p {
        font-family: "BioSans-Regular", sans-serif;
        font-size: 0.75rem;
        color: ${colors.mutedLighter};
      }
    }

    .table-column__entry {
      height: ${mobileCellHeight};
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1.75rem;
      text-align: center;
      /* box-shadow: 0 10px 10px 0 rgba(11, 15, 35, 0.03);
       */
      img {
        margin-bottom: 0.5rem;
      }

      &:first-child {
        p {
          font-size: 0.875rem;
        }
      }

      &:nth-child(even) {
        background-color: #fafafc;
      }
    }
  }
`;
