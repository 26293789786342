import styled from 'styled-components';

import { colors, breakpoints, targetIE } from '../../../../constants/style';
import {
  horizontalLayoutPadding,
  horizontalLayoutPaddingTablet,
  horizontalLayoutPaddingMobile,
  navbarMinHeight,
} from '../../../features/layout/components/commonLayoutStyle';

const boxImageHeight = '330px';
const boxInnerPadding = '7.1875rem';

const WhatAreYouLookingForSection = styled('section')`
  padding: 5rem 0 7rem 0 !important;
  background: #ffffff;

  .header {
    padding: 0 ${horizontalLayoutPadding} 5rem ${horizontalLayoutPadding};
    color: ${colors.titleDark};
    text-align: center;

    &:before {
      content: '';
      display: block;
      margin: 0 auto 2rem auto;
      width: 50px;
      height: 5px;
      background: ${colors.primary};
    }
  }

  .box__header {
    font-family: BioSans-SemiBold, sans-serif;
    padding: 3.375rem 0 1.9375rem 0;
    font-size: 2.25rem;
    line-height: 1.83;
    color: ${colors.titleDark};
  }

  strong,
  b {
    color: #000000;
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: stretch;
  }

  .box__image {
    position: absolute;
    left: 0;
    top: 0;
    padding: 3rem ${boxInnerPadding} 0 ${horizontalLayoutPadding};
    width: 100%;
    height: calc(${boxImageHeight} + 3rem);
    opacity: 1;
    transition: top 0.2s ease-in-out, opacity 0.2s ease-in-out;

    > img,
    .gatsby-image-wrapper {
      display: block;
      max-width: 100%;
      max-height: ${boxImageHeight};
      object-fit: contain !important;
      object-position: left center !important;
    }
    .gatsby-image-wrapper {
      img {
        object-fit: contain !important;
        object-position: left center !important;
      }
    }
  }

  .box__short-description {
    width: 100%;
    padding: 0 ${boxInnerPadding} 5rem ${horizontalLayoutPadding};
    transform: translateY(${boxImageHeight});
    transition: transform 0.2s ease-in-out;

    p {
      color: ${colors.muted};
      font-size: 1.125rem;
      line-height: 2;
    }
  }

  .box__more {
    margin-top: 1.5rem;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;

    img {
      width: auto;
      height: 0.875rem;
    }
  }

  .box__long-description {
    width: 100%;
    min-height: 320px;
    opacity: 0;
    transition: top 0.2s ease-in-out, opacity 0.2s ease-in-out;

    p {
      color: ${colors.muted};
      font-size: 1.125rem;
      line-height: 2;
    }
  }

  .box__button {
    display: inline-block;
    margin-top: 2.25rem;
  }

  .box__cards {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }

  .box__card {
    padding: 3rem 2rem 2rem 2rem;
    background: #ffffff;
    /* fix for IE11 */
    max-width: 30%;
    flex: 0 0 30%;

    .box__card__image {
      width: 100%;
      height: 5.4375rem;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: auto;
        max-height: 100%;
      }
    }

    .box__card__index {
      font-family: BioSans-SemiBold, sans-serif;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: normal;
      color: ${colors.primary};
    }

    .box__card__text {
      margin-top: 1.3rem;
      p {
        font-size: 1.125rem;
        line-height: 2rem;
      }
    }
  }

  .box {
    position: relative;
    overflow: hidden;
    width: 50%;
    border-top: 1px solid ${(props) => props.theme.boxBorderColor};
    border-bottom: 1px solid ${(props) => props.theme.boxBorderColor};
    background-color: transparent;
    transition: background-color 0.5s ease-in-out;

    &:not(.show-all) {
      .box__image {
        position: static;
        margin: 0 auto;
        transform: translateX(0);
      }

      .box__short-description {
        transform: translateY(0);
      }

      .box__more {
        display: none;
      }

      .box__long-description {
        opacity: 1;
        min-height: unset;
      }
    }

    &:first-child {
      border-right: 1px solid ${(props) => props.theme.boxBorderColor};

      &:hover {
        background-color: ${colors.backgroundMilky};

        &.show-all {
          .box__image {
            top: -51%;
            opacity: 0;
          }

          .box__short-description {
            transform: translateY(0);
          }

          .box__more {
            opacity: 0;
          }

          .box__long-description {
            opacity: 1;
          }
        }
      }
    }

    &:last-child {
      .box__short-description {
        padding: 0 ${horizontalLayoutPadding} 5rem ${boxInnerPadding};
      }
      .box__image {
        padding: 3rem ${horizontalLayoutPadding} 0 ${boxInnerPadding};
      }

      &:hover {
        background-color: ${colors.backgroundMilky};

        &.show-all {
          .box__image {
            top: -51%;
            opacity: 0;
          }

          .box__short-description {
            transform: translateY(0);
          }

          .box__more {
            opacity: 0;
          }

          .box__long-description {
            opacity: 1;
          }
        }
      }
    }
  }

  // hack for IE11
  @media only screen and ${targetIE} {
    .box__image {
      .gatsby-image-wrapper {
        img {
          width: auto !important;
          height: auto !important;
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.desktopLarge}) {
    .box__short-description {
      padding: 0 ${horizontalLayoutPaddingTablet} 6.8rem ${horizontalLayoutPaddingTablet};
    }
    .box__image {
      padding: 3rem ${horizontalLayoutPaddingTablet} 0 ${horizontalLayoutPaddingTablet};
    }

    .box {
      &:last-child {
        .box__short-description {
          padding: 0 ${horizontalLayoutPaddingTablet} 6.8rem ${horizontalLayoutPaddingTablet};
        }
        .box__image {
          padding: 3rem ${horizontalLayoutPaddingTablet} 0 ${horizontalLayoutPaddingTablet};
        }
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.desktop}) {
    .box__short-description {
      width: 100%;
    }

    .box__card {
      .box__card__text {
        p {
          font-size: 0.8rem;
          line-height: 1.6rem;
        }
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.desktopSmall}) {
    .content {
      flex-direction: column;
    }

    .box__cards {
      flex-direction: column;
    }

    .box__card {
      padding: 1rem;
      display: flex;
      margin: 0.625rem 0;
      width: 100%;
      max-width: none;
      flex: 0 1 auto;
      align-items: center;

      .box__card__image {
        width: 5.4375rem;
        height: 100%;
        margin-right: 1rem;

        img {
          max-width: 100%;
          max-height: none;
          height: auto;
        }
      }

      .box__card__index {
        align-self: flex-end;
        margin-right: 1rem;
      }

      .box__card__text {
        margin-top: 0;
        p {
          font-size: 1.125rem;
          line-height: 2rem;
        }
      }
    }

    .box {
      width: 100%;

      &:not(:last-child) {
        border-right: none;
        border-bottom: none;
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    padding: ${navbarMinHeight} 0 5.8rem 0 !important;

    .header {
      font-size: 2.5rem;
      padding: 0 ${horizontalLayoutPaddingMobile} 3.6rem ${horizontalLayoutPaddingMobile};
    }

    .box__header {
      padding-top: 2.3rem;
      font-size: 2rem;
    }

    .box__image {
      position: static;
      margin: 0 auto;
      height: auto;
      transform: translateX(0);
      padding: 3rem ${horizontalLayoutPaddingMobile} 0 ${horizontalLayoutPaddingMobile};
    }

    .box__short-description {
      transform: translateY(0);
      padding: 0 ${horizontalLayoutPaddingMobile} 2rem ${horizontalLayoutPaddingMobile};
    }

    .box__more {
      display: none;
    }

    .box__long-description {
      font-size: 1.5rem;
      line-height: 2.5rem;
      opacity: 1;
      min-height: unset;
    }

    .box {
      background-color: ${colors.backgroundMilky};

      &:last-child {
        .box__image {
          padding: 0 ${horizontalLayoutPaddingMobile};
        }

        .box__short-description {
          padding: 0 ${horizontalLayoutPaddingMobile} 2rem ${horizontalLayoutPaddingMobile};
        }
      }

      &.show-all:hover {
        .box__image {
          opacity: 1;
        }
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.mobile}) {
    .box__card {
      .box__card__text {
        p {
          font-size: 1rem;
          line-height: 1.6rem;
        }
      }
    }
  }
`;

WhatAreYouLookingForSection.defaultProps = {
  theme: {
    boxBorderColor: '#e1e1eb',
  },
};

export default WhatAreYouLookingForSection;
