import React from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components'

import { Link as GatsbyLink, navigate } from 'gatsby'
import { colors, breakpoints } from '../../../constants/style'
import { buttonWithBorderStyle, GeneralButtonStyle } from '../Buttons'
import IconArrow from '../icons/IconArrow'

// component that provides fallback for external links would be usefull

const checkIfLinkIsAbsolute = (url) =>
  url?.substring(0, 4) === 'http' || url?.substring(0, 6) === 'mailto'

export const checkUrlIfOutsideOf10c = (url) =>
  url && checkIfLinkIsAbsolute(url) && !url?.startsWith('https://10clouds.com')

export const Link = ({
  className,
  to,
  children,
  icon,
  targetBlank = false,
  partiallyActive = false,
  disabled,
  isNested,
  state,
  actionOnClick = false, //when func, the Link instead of redirect will call this func onClick
}) => {
  const isAbsolute = checkIfLinkIsAbsolute(to)

  if (disabled) {
    return <DisabledLink className="link">{children}</DisabledLink>
  }

  const linkContent = (
    <span>
      {icon && <span className="link__icon">{icon}</span>}
      {children}
    </span>
  )

  // If link is nested inside another link do not use <a> tag inside another <a> tag as it's invalid HTML
  if (isNested) {
    return (
      <div
        className={`${className} link`}
        onClick={() => (actionOnClick ? actionOnClick() : navigate(to))}
      >
        {linkContent}
      </div>
    )
  } else if (actionOnClick) {
    return (
      <a
        className={`${className} link`}
        onClick={actionOnClick}
        {...(targetBlank && { target: '_blank', rel: 'noopener noreferrer' })}
      >
        {linkContent}
      </a>
    )
  }

  return isAbsolute ? (
    <a
      className={`${className} link`}
      href={to}
      {...(targetBlank && { target: '_blank', rel: 'noopener noreferrer' })}
    >
      {linkContent}
    </a>
  ) : (
    <GatsbyLink
      className={`${className} link`}
      activeClassName="link--active"
      partiallyActive={partiallyActive}
      to={to}
      state={state}
    >
      {linkContent}
    </GatsbyLink>
  )
}
Link.defaultProps = {
  variant: null,
  className: '',
  to: '',
  children: null,
  icon: null,
  targetBlank: false,
  partiallyActive: false,
  disabled: false,
  isNested: false,
  state: null,
  actionOnClick: false,
}

Link.propTypes = {
  className: propTypes.string,
  variant: propTypes.oneOf(['filled', 'outlined', 'bordered', null]),
  actionOnClick: propTypes.oneOfType([propTypes.func, propTypes.bool]),
}

const filledButtonGradient = `linear-gradient(90deg,#8658eb,#6831e4)`
const filledButtonGradientHover = `linear-gradient(90deg,#7f4fea,#6128e3)`

export const IconLink = styled((props) => <Link {...props} />)`
  font-family: Roboto-Medium, sans-serif;
  font-size: 1.125rem;

  > span {
    display: flex;
    white-space: nowrap;
    align-items: center;
  }

  .link__icon {
    display: flex;
    flex: 0 0 4.5rem;
    width: 4.5rem;
    height: 4.5rem;
    justify-content: center;
    align-items: center;
    border: 1px solid ${colors.primary};
    border-radius: 50%;
    margin-right: 1.875rem;

    svg {
      width: 30%;
    }
  }
`

export const NavLink = styled((props) => <Link {...props} />)`
  font-family: "BioSans-SemiBold", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  color: ${colors.navbarBackground};
  transition: color 0.2s ease-in-out;
  font-size: 0.9375rem;

  @media only screen and (max-width: ${breakpoints.desktopSmall}) {
    padding-right: 10px;
    padding-left: 10px;
  }

  &:hover,
  &.link--active {
    color: ${colors.secondary};
  }

  display: block;
  :hover {
    .nav-submenu {
      display: grid;
    }
  }

  ${({ hasSubmenu }) =>
    hasSubmenu &&
    `
    white-space: nowrap;
    position: relative;
  `}
`

const BORDER_SIZE = '2px'
export const NavLinkButton = styled((props) => <Link {...props} />)`
  ${GeneralButtonStyle}
  background: ${(props) => (props.variant === 'filled' ? filledButtonGradient : 'transparent')};
  background-origin: border-box;
  ${({ variant }) => variant === 'bordered' && buttonWithBorderStyle}

  // use ::before pseudo element together with span as workaround for linear background animation
  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: -${BORDER_SIZE};
    top: -${BORDER_SIZE};
    opacity: 0;
    background: ${(props) =>
      props.variant === 'filled' || props.variant === 'bordered'
        ? filledButtonGradientHover
        : 'transparent'};
    z-index: 0;
    transition: opacity 0.2s ease-in-out;
    background-origin: border-box;
    border: ${BORDER_SIZE} solid transparent;
  }

  & > span {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.025rem;
    position: relative;
    z-index: 1;
    color: #fff;
  }

  &:hover {
    &::before {
      opacity: 1;
    }
  }
`

export const DisabledLink = styled('span')`
  cursor: not-allowed;
  opacity: 0.4;
`

const NavLinkButtonWithArrowStyled = styled(NavLinkButton)`
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: ${({ arrowPosition }) => arrowPosition === 'left' && 'row-reverse;'};
  }
  svg {
    ${({ arrowPosition }) =>
      arrowPosition === 'right' ? 'margin-left: 12px;' : 'margin-right: 12px'};
    width: 13px;
    height: 13px;
    fill: #fff;
    ${({ arrowPosition }) => arrowPosition === 'left' && 'transform: rotate(180deg);'};
  }
`

export const NavLinkButtonWithArrow = ({
  children,
  variant,
  className,
  to,
  arrowPosition = 'right',
}) => {
  return (
    <NavLinkButtonWithArrowStyled
      to={to}
      variant={variant}
      arrowPosition={arrowPosition}
      className={className}
    >
      {children}
      <IconArrow />
    </NavLinkButtonWithArrowStyled>
  )
}
