import React from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'

import { SEO } from 'components/Seo'
import Layout from '../features/layout'
import { NavLinkButton } from '../components/Link'
import logo from '../../static/images/10c-logo/10clouds-logo.svg'
import { colors, breakpoints } from '../../constants/style'

import White404SVG from '../../static/images/404--white.svg'
import Purple404SVG from '../../static/images/404--purple.svg'
import Green404SVG from '../../static/images/404--green.svg'

const NotFoundSection = styled('section')`
  padding: 5rem 1rem;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #ffffff;
  background: #000000;

  .logo {
    height: 1.5625rem;
    margin-bottom: 5.9375rem;
  }

  .image {
    margin-bottom: 5rem;
    width: 355px;
    height: 125px;
    position: relative;
    background: url(${White404SVG}) 0 0 no-repeat;
    background-size: cover;

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
    }

    &::before {
      left: -1px;
      background: url(${Purple404SVG}) 0 0 no-repeat;
      clip: rect(24px, 550px, 90px, 0);
      animation: glitch-anim-2 3s infinite linear alternate-reverse;
    }

    &::after {
      left: -3px;
      background: url(${Green404SVG}) 0 0 no-repeat;
      clip: rect(85px, 550px, 140px, 0);
      animation: glitch-anim 2.5s infinite linear alternate-reverse;
    }
  }

  h2 {
    margin-bottom: 2rem;
  }

  p {
    margin-bottom: 3rem;
    color: ${colors.muted};
    max-width: 40.625rem;
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    padding: 2rem 1rem;

    .logo {
      margin-bottom: 3rem;
    }

    .image {
      width: 288px;
      height: 100px;
      margin-bottom: 2rem;
    }
  }

  @keyframes glitch-anim {
    0% {
      clip: rect(150px, 9999px, 90px, 0);
    }

    4.16666667% {
      clip: rect(117px, 9999px, 96px, 0);
    }

    8.33333333% {
      clip: rect(8px, 9999px, 93px, 0);
    }

    12.5% {
      clip: rect(126px, 9999px, 8px, 0);
    }

    16.66666667% {
      clip: rect(60px, 9999px, 113px, 0);
    }

    20.83333333% {
      clip: rect(3px, 9999px, 87px, 0);
    }

    25% {
      clip: rect(62px, 9999px, 116px, 0);
    }

    29.16666667% {
      clip: rect(83px, 9999px, 35px, 0);
    }

    33.33333333% {
      clip: rect(75px, 9999px, 27px, 0);
    }

    37.5% {
      clip: rect(131px, 9999px, 24px, 0);
    }

    41.66666667% {
      clip: rect(128px, 9999px, 93px, 0);
    }

    45.83333333% {
      clip: rect(92px, 9999px, 131px, 0);
    }

    50% {
      clip: rect(138px, 9999px, 118px, 0);
    }

    54.16666667% {
      clip: rect(119px, 9999px, 76px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999px, 121px, 0);
    }

    62.5% {
      clip: rect(57px, 9999px, 33px, 0);
    }

    66.66666667% {
      clip: rect(126px, 9999px, 128px, 0);
    }

    70.83333333% {
      clip: rect(72px, 9999px, 22px, 0);
    }

    75% {
      clip: rect(123px, 9999px, 73px, 0);
    }

    79.16666667% {
      clip: rect(131px, 9999px, 23px, 0);
    }

    83.33333333% {
      clip: rect(19px, 9999px, 103px, 0);
    }

    87.5% {
      clip: rect(109px, 9999px, 136px, 0);
    }

    91.66666667% {
      clip: rect(99px, 9999px, 113px, 0);
    }

    95.83333333% {
      clip: rect(61px, 9999px, 12px, 0);
    }

    to {
      clip: rect(141px, 9999px, 1px, 0);
    }
  }

  @keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(9px, 9999px, 108px, 0);
    }

    10% {
      clip: rect(2px, 9999px, 23px, 0);
    }

    13.33333333% {
      clip: rect(13px, 9999px, 20px, 0);
    }

    16.66666667% {
      clip: rect(145px, 9999px, 138px, 0);
    }

    20% {
      clip: rect(98px, 9999px, 125px, 0);
    }

    23.33333333% {
      clip: rect(114px, 9999px, 79px, 0);
    }

    26.66666667% {
      clip: rect(86px, 9999px, 60px, 0);
    }

    30% {
      clip: rect(70px, 9999px, 53px, 0);
    }

    33.33333333% {
      clip: rect(43px, 9999px, 142px, 0);
    }

    36.66666667% {
      clip: rect(81px, 9999px, 109px, 0);
    }

    40% {
      clip: rect(37px, 9999px, 80px, 0);
    }

    43.33333333% {
      clip: rect(82px, 9999px, 30px, 0);
    }

    46.66666667% {
      clip: rect(23px, 9999px, 6px, 0);
    }

    50% {
      clip: rect(129px, 9999px, 81px, 0);
    }

    53.33333333% {
      clip: rect(44px, 9999px, 39px, 0);
    }

    56.66666667% {
      clip: rect(44px, 9999px, 43px, 0);
    }

    60% {
      clip: rect(127px, 9999px, 110px, 0);
    }

    63.33333333% {
      clip: rect(65px, 9999px, 132px, 0);
    }

    66.66666667% {
      clip: rect(6px, 9999px, 4px, 0);
    }

    70% {
      clip: rect(87px, 9999px, 65px, 0);
    }

    73.33333333% {
      clip: rect(54px, 9999px, 83px, 0);
    }

    76.66666667% {
      clip: rect(30px, 9999px, 147px, 0);
    }

    80% {
      clip: rect(130px, 9999px, 85px, 0);
    }

    83.33333333% {
      clip: rect(75px, 9999px, 15px, 0);
    }

    86.66666667% {
      clip: rect(33px, 9999px, 77px, 0);
    }

    90% {
      clip: rect(102px, 9999px, 45px, 0);
    }

    93.33333333% {
      clip: rect(37px, 9999px, 96px, 0);
    }

    96.66666667% {
      clip: rect(62px, 9999px, 94px, 0);
    }

    to {
      clip: rect(24px, 9999px, 37px, 0);
    }
  }
`

const NotFoundPage = (props) => {
  const isOnCareerPage = props.location.pathname.includes('/careers/')
  if (props.location.pathname.includes('/careers/')) {
    navigate('/careers/')
  }

  if (props.location.pathname.includes('/blog/')) {
    navigate('/blog/')
  }

  return (
    <Layout showFooter={false} showNavigation={false}>
      <NotFoundSection>
        <img className="logo" src={logo} alt="10clouds logo" />
        <div className="image" />
        <h2>Whoops...</h2>
        <p>
          {isOnCareerPage
            ? 'It looks like the job offer you are after is not longer available.'
            : 'It looks like things got a little cloudy! Let’s clear this up - go back to our homepage and find what you were looking for.'}
        </p>
        {isOnCareerPage ? (
          <NavLinkButton to="/careers/" variant="filled">
            Go to careers
          </NavLinkButton>
        ) : (
          <NavLinkButton to="/" variant="filled">
            Back to homepage
          </NavLinkButton>
        )}
      </NotFoundSection>
    </Layout>
  )
}

export function Head({ location }) {
  return <SEO location={location} title="404: Not found" />
}

export default NotFoundPage
