import React from 'react';
import styled from 'styled-components';

import Image from '../../Image';

import { navbarMinHeight } from '../../../features/layout/components/commonLayoutStyle';
import { colors, breakpoints, targetIE } from '../../../../constants/style';

const StyledStatisticSection = styled('section')`
  padding-top: ${navbarMinHeight};
  padding-bottom: ${navbarMinHeight};

  .header {
    margin-bottom: 3.625rem;

    &::before {
      content: '';
      display: block;
      margin-bottom: 3rem;
      width: 50px;
      height: 5px;
      background: ${colors.primary};
    }
  }

  .description {
    font-size: 1.375rem;
    line-height: 1.91;
    color: ${colors.muted};
  }

  .columns {
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    .columnLeft {
      flex: 0 0 57%;
    }
    .columnRight {
      flex: 0 0 37%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }

  .withoutIcon {
    font-family: "BioSans-SemiBold", sans-serif;
    font-size: 1.125rem;
    text-transform: uppercase;

    .statistic {
      margin-top: 1.25rem;

      .statistic__value {
        color: ${colors.primary};
      }

      .statistic__name {
        color: ${colors.muted};
      }
    }
  }

  .withIcon {
    margin-top: 6rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .statistic {
      flex: 0 0 23%;
      padding: 6.5625rem 1.5rem 7.375rem 1.5rem;
      border: solid 1px #e5e5e8;
      background-color: #ffffff;

      &:nth-child(even) {
        margin-top: 5rem;
      }
      &:nth-child(odd) {
        margin-bottom: 5rem;
      }

      .statistic__icon {
        width: 95px;
        height: 95px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto 1.75rem auto;

        img,
        .gatsby-image-wrapper {
          max-width: 100%;
        }
      }

      .statistic__value {
        font-family: "BioSans-Light", sans-serif;
        font-size: 4.8125rem;
        color: ${colors.primary};
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 0.5625rem;
      }

      .statistic__name {
        font-family: Roboto-Medium, sans-serif;
        font-weight: 500;
        font-size: 1.25rem;
        color: ${colors.mutedDark};
        text-align: center;
        text-transform: uppercase;
      }
    }
  }

  // hack for IE11 not respecting box-sizing for flex-basis size
  @media only screen and ${targetIE} {
    .withIcon {
      .statistic {
        flex: 0 0 19%;
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.desktopLarge}) {
    .withIcon {
      .statistic {
        .statistic__value {
          font-size: 3.5rem;
        }
        .statistic__name {
          font-size: 1.15rem;
        }
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.desktopSmall}) {
    .withIcon {
      margin-top: 7rem;

      .statistic {
        flex: 0 0 48%;

        &:nth-child(even) {
          margin: 1rem 0;
        }
        &:nth-child(odd) {
          margin: 1rem 0;
        }
      }
    }
  }
  // hack for IE11 not respecting box-sizing for flex-basis size
  @media only screen and (max-width: ${breakpoints.desktopSmall}) and (-ms-high-contrast: none),
    only screen and (max-width: ${breakpoints.desktopSmall}) and (-ms-high-contrast: active) {
    .withIcon {
      .statistic {
        flex: 0 0 39%;
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    .withIcon {
      margin-top: 5rem;
    }
  }

  @media only screen and (max-width: ${breakpoints.mobile}) {
    .columns {
      flex-direction: column;
    }

    .withIcon {
      margin-top: 3rem;

      .statistic {
        flex: 0 0 100%;
        padding: 2rem 1rem;
      }
    }
  }
  // hack for IE11 not respecting box-sizing for flex-basis size
  @media only screen and (max-width: ${breakpoints.mobile}) and (-ms-high-contrast: none),
    only screen and (max-width: ${breakpoints.mobile}) and (-ms-high-contrast: active) {
    .withIcon {
      .statistic {
        flex: 0 0 90%;
      }
    }
  }
`;

const StatisticSection = ({ header, description, statisticsWithoutIcon, statisticsWithIcon }) => {
  return (
    <StyledStatisticSection className="statistics-section">
      <div className="columns">
        <div className="columnLeft">
          {header && <h2 className="header">{header}</h2>}
          <p className="description">{description}</p>
        </div>
        {Boolean(statisticsWithoutIcon && statisticsWithoutIcon.length) && (
          <div className="columnRight">
            <div className="withoutIcon">
              {statisticsWithoutIcon.map((item, index) => (
                <div className="statistic" key={`stat-${index}`}>
                  <span className="statistic__value">{item.value}</span> <span className="statistic__name">{item.name}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      {Boolean(statisticsWithIcon.length) && (
        <div className="withIcon">
          {statisticsWithIcon.map(({ icon, value, name }, index) => (
            <div className="statistic" key={`iconstat-${index}`}>
              <div className="statistic__icon">
                {icon && <Image image={icon.asset.url.publicURL} style={{ width: '85px', height: '89px' }} />}
              </div>
              <div className="statistic__value">{value}</div>
              <div className="statistic__name">{name}</div>
            </div>
          ))}
        </div>
      )}
    </StyledStatisticSection>
  );
};

export default StatisticSection;
