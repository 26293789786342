import styled from 'styled-components';
import { sidebarBackground } from '../../constants';
import { breakpoints } from '../../../../../constants/style';
import { colors } from '../../../../../constants/style';

export const SidebarContainer = styled.div`
  background: ${sidebarBackground};
  color: ${colors.sectionTitleLight};
  padding: 32px 16px;

  @media (min-width: ${breakpoints.mobile}) {
    padding-left: 44px;
    padding-right: 44px;
  }

  @media (min-width: ${breakpoints.desktopMac}) {
    width: 672px;
    height: 100%;
    min-height: 100vh;

    display: flex;
    flex-direction: column;

    padding-top: 192px;
    padding-left: 168px;
    padding-right: 80px;

    h2 {
      line-height: 56px;
      padding-bottom: 1.5rem;
    }

    p {
      font-size: 1rem;
      line-height: 2;
      color: #c3c2d4;
    }
  }

  @media (max-width: ${breakpoints.desktopLarge}) {
    width: 544px;
    padding-left: 80px;
    padding-right: 40px;
  }

  @media (max-width: calc(${breakpoints.desktopMac} - 1px)) {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  @media (min-width: ${breakpoints.desktopMax}) {
    width: 100%;
    align-items: end;
    padding: 192px 80px auto auto;
  }
`;

export const SidebarContent = styled.div`
  flex: 1 0 0;

  @media (max-width: calc(${breakpoints.desktopMac} - 1px)) {
    padding-left: 24px;
    padding-right: 24px;

    h2,
    p {
      position: absolute;
      margin: -1px;
      border: 0;
      padding: 0;
      width: 1px;
      height: 1px;
      overflow: hidden;
      clip: rect(0 0 0 0);
      text-transform: none;
      white-space: nowrap;
    }
  }
  @media (min-width: ${breakpoints.desktopMax}) {
    width: 424px;
  }
`;

export const GetInTouchSection = styled.div`
  @media (min-width: ${breakpoints.desktopMac}) {
    padding-top: 40px;
  }

  h3 {
    font-size: 1.313rem;
    line-height: 1.715;
    padding-bottom: 24px;

    @media (max-width: calc(${breakpoints.desktopMac} - 1px)) {
      text-align: center;
    }
  }
`;

export const SalesAgentsGroup = styled.div`
  @media (max-width: calc(${breakpoints.desktopMac} - 1px)) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: min-content;
    margin: 0 auto;
  }
`;

export const SidebarFooter = styled.footer`
  padding-top: 24px;
  margin-top: 24px;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  font-size: 0.813rem;
  color: #c3c2d4;

  letter-spacing: 0.2px;

  flex: auto 0 0;

  @media (min-width: ${breakpoints.tablet}) {
    margin-top: 48px;
  }

  @media (max-width: calc(${breakpoints.desktopMac} - 1px)) {
    margin-left: 24px;
    margin-right: 24px;
    text-align: center;
  }

  @media (min-width: ${breakpoints.desktopMax}) {
    width: 424px;
  }

  a {
    color: ${colors.sectionTitleLight};
    text-decoration: underline;

    &:hover,
    &:focus {
      color: #00ddaf;
    }
  }
`;

export const LeftSidebarX = styled.div`
  --aside-padding-left: 168px;
  --aside-padding-right: 82px;
  --aside-padding-bottom: 32px;
  --aside-padding-top: 160px;

  background: ${sidebarBackground};
  padding: var(--aside-padding-top) var(--aside-padding-right) var(--aside-padding-bottom) var(--aside-padding-left);
  max-width: 672px;
  width: 100%;
  height: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: column;

  color: ${colors.sectionTitleLight};

  h2 {
    padding-bottom: 1.5rem;
  }

  p {
    font-size: 1rem;
    line-height: 2;
    color: #c3c2d4;
  }
`;
