import styled from 'styled-components'
import emphasis from '../Styled/emphasis'
import ClipboardIcon from '../../../static/images/icons/clipboard.svg'
import SuccessIcon from '../../../static/images/icons/checkmark.svg'
import ErrorIcon from '../../../static/images/icons/cancel.svg'

const DOTS_MENU_COLOR = '#3c3c3c'

const StyledBlockContent = styled('div')`
  blockquote {
    ${emphasis}
  }

  .code-section-wrapper {
    position: relative;
    font-size: 1rem;
    font-weight: 400;
  }

  .code-section {
    padding-top: 2.5rem;
  }
  .code-header {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 2.25rem;
    padding: 0 0.625rem;
    z-index: 9;

    // Mac-menu dots
    &::before {
      content: '';
      display: inline-block;
      margin-left: 1rem;
      width: 0.75rem;
      height: 0.75rem;
      border-radius: 50%;
      background-color: ${DOTS_MENU_COLOR};
      box-shadow: 1.25rem 0 0 ${DOTS_MENU_COLOR}, 2.5rem 0 0 ${DOTS_MENU_COLOR};
    }

    span {
      text-align: center;
      margin: 0 auto;
      color: #bbb;
    }
  }

  // rewrite the default prism styles
  .toolbar {
    top: 0 !important;
    right: 1rem !important;
    opacity: 1 !important;
    visibility: visible !important;
    display: flex !important;
  }
  .copy-to-clipboard-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.25rem;
    padding: 0.5rem;
    background: transparent !important;
    box-shadow: none !important;

    span {
      min-width: 1rem;
      height: 1rem;
      background: url('${ClipboardIcon}');
      background-size: contain;
    }
  }
  button[data-copy-state='copy-success'] {
    span {
      background: url('${SuccessIcon}') !important;
    }
  }
  button[data-copy-state='copy-error'] {
    span {
      background: url('${ErrorIcon}') !important;
    }
  }
`

export default StyledBlockContent
