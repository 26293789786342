import { ReactNode, useMemo } from 'react'
import { BlockContentProps } from '@sanity/block-content-to-react'
import styled, { css } from 'styled-components'

import SanityBlockContent from 'components/SanityBlockContent'

type PerkContentType = {
  header: string
  icon: string
  description: BlockContentProps
  url?: string
}

const PerkIcon = styled.div`
  height: 7.5rem;
  margin-bottom: 2.75rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;

  &:before,
  &:after {
    background-size: contain;
  }
`

const PerkTitle = styled.h3`
  color: var(--t1-title);
  transition: color 0.3s;
  text-align: center;
  margin-bottom: 24px;
`

const perksStyles = css`
  background-color: #fff;
  box-shadow: 0px 8px 24px rgba(71, 90, 158, 0.1);
  padding: 4rem 2rem;
`

const PerkItem = styled.div`
  ${perksStyles}
`

const PerkLinkItem = styled.a`
  ${perksStyles}
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    transform: translateY(-6px);
    ${PerkTitle} {
      color: var(--t1-overline);
    }
  }
`

const StyledSanityBlockContent = styled(SanityBlockContent)`
  text-align: center;

  p {
    font: inherit;
  }
`

export const PerkContent = ({ header, description, url, icon }: PerkContentType) => {
  const PerkComponent = useMemo(
    () =>
      url ? (props: { children: ReactNode }) => <PerkLinkItem href={url} {...props} /> : PerkItem,
    [url],
  )

  return (
    <PerkComponent>
      <PerkIcon style={{ backgroundImage: `url(${icon})` }} />
      <PerkTitle className="h5">{header}</PerkTitle>
      <StyledSanityBlockContent className="p3" blocks={description} />
    </PerkComponent>
  )
}
