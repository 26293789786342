import { useSeoQuery } from 'hooks/graphql/page'
import { ReactNode } from 'react'
import contact from '../../constants/contact'
import locations from '../../constants/locations'

type SEOType = {
  location: Location
  description?: string
  title?: string
  index?: boolean
  follow?: boolean
  image: { asset: { url?: string; dimensions?: { width: string; height: string }, metadata?: { dimensions: { width: string; height: string } }, gatsbyImageData?: { images: { fallback: { src: string } } } } }
  children?: ReactNode
  isStructuredDataAdded?: boolean
}

type LocationType = { cityName: string; street: string; regionCode: string }

export const SEO = ({
  description,
  title,
  image,
  location,
  index,
  follow,
  children,
  isStructuredDataAdded = true,
}: SEOType) => {
  const { site } = useSeoQuery()
  const metaDescription = description || site.siteMetadata.description

  const seoImage = image?.asset
    ? {
        absoluteUrl: image.asset.url ? image.asset.url : image.asset.gatsbyImageData?.images?.fallback?.src,
        ...image.asset.dimensions ? image.asset.dimensions : image.asset.metadata?.dimensions,
      }
    : {
        ...site.siteMetadata.defaultImage,
      }

  const seoTitle = title || site.siteMetadata.title
  const pageUrl = `${site.siteMetadata.siteUrl}${location.pathname}`

  const detailedLocations = locations.reduce((acc, { address, cityName }) => {
    const [street, regionCode] = address.split(',')
    acc.push({ cityName, street, regionCode: regionCode.trim() })

    return acc
  }, [] as LocationType[])

  const [warsaw, poznan, wroclaw] = detailedLocations

  return (
    <>
      <html lang={site.siteMetadata.defaultLang} />
      <title>{seoTitle.length <= 49 ? `${seoTitle} | ${site.siteMetadata.title}` : seoTitle}</title>
      <link rel="canonical" href={pageUrl} />
      <meta name="description" content={metaDescription} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={site.siteMetadata.twitter.author} />
      <meta name="twitter:site" content={site.siteMetadata.twitter.author} />
      <meta name="twitter:title" content={seoTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={seoImage.absoluteUrl} />
      <meta property="og:title" content={seoTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content={site.siteMetadata.defaultLocale} />
      <meta property="og:url" content={pageUrl} />
      <meta property="og:site_name" content={site.siteMetadata.title} />
      <meta property="og:image" content={seoImage.absoluteUrl} />
      <meta property="og:image:secure_url" content={seoImage.absoluteUrl} />
      <meta property="og:image:width" content={seoImage.width} />
      <meta property="og:image:height" content={seoImage.height} />
      <meta property="p:domain_verify" content="299bba9cec5d48ede6ecbdd32f446e2a" />
      {(index || follow) && (
        <meta
          name="robots"
          content={`${index ? `index` : `noindex`},${follow ? `follow` : `nofollow`}`}
        />
      )}
      {isStructuredDataAdded && (
        <script type="application/ld+json">
          {JSON.stringify(
            {
              '@context': 'https://schema.org',
              '@type': 'ProfessionalService',
              image: [
                'https://10clouds.com/images/office/10clouds-office-3.png',
                'https://10clouds.com/images/office/10clouds-office-1.png',
                'https://10clouds.com/images/office/10clouds-office-2.png',
              ],
              name: '10Clouds',
              address: {
                '@type': 'PostalAddress',
                streetAddress: warsaw.street,
                addressLocality: warsaw.cityName,
                addressRegion: 'Mazowieckie',
                postalCode: warsaw.regionCode,
                addressCountry: 'PL',
              },
              geo: {
                '@type': 'GeoCoordinates',
                latitude: 52.228358,
                longitude: 20.9950973,
              },
              url: 'https://10clouds.com',
              priceRange: '$$',
              telephone: contact[0].phone,
              openingHoursSpecification: [
                {
                  '@type': 'OpeningHoursSpecification',
                  dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
                  opens: '08:00',
                  closes: '16:00',
                },
                {
                  '@type': 'OpeningHoursSpecification',
                  dayOfWeek: 'Sunday',
                  opens: '08:00',
                  closes: '23:00',
                },
              ],
              department: [
                {
                  '@type': 'ProfessionalService',
                  name: poznan.cityName,
                  priceRange: '$$',
                  telephone: contact[0].phone,
                  image: [
                    'https://10clouds.com/images/office/10clouds-office-3.png',
                    'https://10clouds.com/images/office/10clouds-office-1.png',
                    'https://10clouds.com/images/office/10clouds-office-2.png',
                  ],
                  address: {
                    '@type': 'PostalAddress',
                    streetAddress: poznan.street,
                    addressLocality: poznan.cityName,
                    addressRegion: 'Wielkopolskie',
                    postalCode: poznan.regionCode,
                    addressCountry: 'PL',
                  },
                  geo: {
                    '@type': 'GeoCoordinates',
                    latitude: 17.0499182,
                    longitude: 16.9291532,
                  },
                },
                {
                  '@type': 'ProfessionalService',
                  name: wroclaw.cityName,
                  priceRange: '$$',
                  telephone: contact[0].phone,
                  image: [
                    'https://10clouds.com/images/office/10clouds-office-3.png',
                    'https://10clouds.com/images/office/10clouds-office-1.png',
                    'https://10clouds.com/images/office/10clouds-office-2.png',
                  ],
                  address: {
                    '@type': 'PostalAddress',
                    streetAddress: wroclaw.street,
                    addressLocality: wroclaw.cityName,
                    addressRegion: 'Dolnoslaskie',
                    postalCode: wroclaw.regionCode,
                    addressCountry: 'PL',
                  },
                  geo: {
                    '@type': 'GeoCoordinates',
                    latitude: 51.11328,
                    longitude: 17.0499182,
                  },
                },
              ],
            },
            null,
            2,
          )}
        </script>
      )}
      {children}
    </>
  )
}
