import React, { useEffect, useState } from 'react'
import md5 from 'md5'

import { 
  StyledAnnouncementTopBar,
  StyledSanityBlockContent,
  StyledMessageCloseBox 
} from './styled'
import CloseSVG from '../../../static/images/icons/ico-close-light.svg'

const localStorageKey = '10c-top-message-bar-'

// this components works very similar to TopMessageBar, but it's not a global component,
// it can be used in selected pages, where we want to display some important information
const AnnouncementTopBar = ({ pageName, announcementData }) => {
  const { isDisplayed, longAnnouncement, shortAnnouncement } = announcementData
  const hash = md5(JSON.stringify(longAnnouncement))
  const isClient = typeof window === 'object'

  const [announcementText, setAnnouncementText] = useState('')

  useEffect(() => {
    const handleResize = () => {
      if (window?.innerWidth > 768) {
        setAnnouncementText(longAnnouncement)
      } else {
        setAnnouncementText(shortAnnouncement)
      }
    }

    handleResize()
    window?.addEventListener('resize', handleResize)

    return () => {
      window?.removeEventListener('resize', handleResize)
    }
  }, [longAnnouncement, shortAnnouncement])

  const customLocalStorageKey = `${localStorageKey}${pageName}`

  const show = isClient && localStorage.getItem(customLocalStorageKey) !== hash && isDisplayed
 
  const [animateHide, setAnimateHide] = useState(
    !isClient || localStorage.getItem(customLocalStorageKey) === hash || !isDisplayed,
  )

  const onClickHandler = () => {
    if (isClient) {
      localStorage.setItem(customLocalStorageKey, hash)
      setAnimateHide(true)
    }
  }

  if (!show) return null;
  
  return (
    <StyledAnnouncementTopBar className={animateHide ? 'hide' : ''}>
      <StyledSanityBlockContent blocks={announcementText} />
      <StyledMessageCloseBox onClick={onClickHandler}>
        <img src={CloseSVG} alt="close message" />
      </StyledMessageCloseBox>
    </StyledAnnouncementTopBar>
  )
}

export default AnnouncementTopBar