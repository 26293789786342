import React from 'react'
import styled from 'styled-components'

import { NavLinkButton } from '../Link'
import {
  navbarMinHeight,
  horizontalLayoutPadding,
  horizontalLayoutPaddingMobile,
  horizontalLayoutPaddingTablet,
} from '../../features/layout/components/commonLayoutStyle'
import { colors, breakpoints } from '../../../constants/style'
import { contact } from '../../../constants/links'

const Wrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  font-weight: 400;
  background: ${({ bgImg }) =>
    `linear-gradient(297deg, rgba(16, 7, 28, 0.13) 0%, rgba(16, 7, 39, 0.87) 52%, rgba(16, 7, 39, 1) 100%), url(${bgImg.src})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top right;
  padding: calc(${navbarMinHeight} + 60px) ${horizontalLayoutPadding} 60px;
  justify-content: center;

  @media only screen and (max-width: ${breakpoints.desktop}) {
    padding: calc(${navbarMinHeight} + 60px) ${horizontalLayoutPaddingTablet} 60px;
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    max-height: none;
    height: auto;
    padding: calc(${navbarMinHeight} + 60px) ${horizontalLayoutPaddingMobile} 60px;
  }

  @media only screen and (max-width: ${breakpoints.mobile}) {
    min-height: 100vh;
  }
`

const Content = styled.div`
  width: 50%;

  @media only screen and (max-width: ${breakpoints.desktop}) {
    width: 75%;
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    width: 100%;
  }
`

const Subheading = styled.span`
  font-family: 'Bio Sans', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  text-transform: uppercase;
  color: ${colors.primary};
`

const H1 = styled.h1`
  font-size: 3.25rem;
  color: ${colors.sectionTitleLight};
  margin-bottom: 40px;

  @media only screen and (max-width: ${breakpoints.tablet}) {
    font-size: 2.5rem;
    margin-bottom: 32px;
  }
`

const Subtitle = styled.p`
  font-family: "BioSans-Light", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 1.25rem;
  line-height: 2rem;
  opacity: 0.8;
  margin-bottom: 40px;
  color: ${colors.sectionTitleLight};

  @media only screen and (max-width: ${breakpoints.tablet}) {
    font-size: 1rem;
    line-height: 1.5;
  }
`

const BtnWrapper = styled.div`
  width: 228px;
`

const ScrollWrapper = styled.div`
  margin-top: 5rem;

  @media only screen and (max-width: ${breakpoints.mobile}) {
    bottom: 20px;
    transform: none;
  }

  .scroll-animation-text {
    color: ${colors.muted};
    margin-top: 1rem;
    font-size: 0.6rem;
    text-transform: uppercase;
    letter-spacing: 0.8px;
    transform-origin: 0 0;
    transform: translate(0, 200%) rotateZ(-90deg);
  }
  .animated-scroll {
    width: 2px;
    height: 80px;
    background: ${colors.muted};
    position: relative;
    overflow: hidden;
    display: block;
    margin-left: 0.3rem;
    margin-top: 2rem;

    &:after {
      content: '';
      width: 2px;
      height: 100%;
      background-color: ${colors.primary};
      top: 0;
      position: absolute;
      right: 0;
      animation: lineAnimation 1.5s linear infinite;
    }

    @keyframes lineAnimation {
      from {
        top: 0;
      }
      to {
        top: 100%;
      }
    }
  }
`

const HeroNew = ({ bgImg, preheader, header, desc, btnText, scrollToQuerySelector }) => (
  <Wrapper bgImg={bgImg}>
    <Content>
      <Subheading>{preheader}</Subheading>
      <H1>{header}</H1>
      <Subtitle>{desc}</Subtitle>
      <BtnWrapper>
        <NavLinkButton to={scrollToQuerySelector || contact.url} variant="filled">
          {btnText}
        </NavLinkButton>
      </BtnWrapper>
    </Content>
    <ScrollWrapper>
      <p className="scroll-animation-text">scroll</p>
      <span className="animated-scroll" />
    </ScrollWrapper>
  </Wrapper>
)

export default HeroNew
