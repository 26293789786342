import { Fragment, Suspense } from 'react'
import sectionsConfig from 'features/dynamicSection/sections.config'
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator'

type Section = {
  _type: keyof typeof sectionsConfig
  __typename: string
}

type SectionsContainerProps = {
  sections: Section[]
  _rawSections: Section[]
}

const SectionsContainer = ({ sections, _rawSections }: SectionsContainerProps) => (
  <Suspense fallback={<LoadingIndicator />}>
    {sections.map((section, index) => {

      const currentSection = sectionsConfig[section._type]

      if (!currentSection) {
        console.error('currentSection', currentSection, index, section)
        console.error('current Type', section?._type || section?.__typename)
        throw new Error(
          `Required section (${currentSection}) of type ${section?._type || section?.__typename} not defined. Please add it to the available sections list.`,
        )
      }

      return (
        <Fragment key={index}>
          {currentSection.condition(section) &&
            currentSection.template({
              section,
              index,
              isPreviousSectionOfSameType: Boolean(
                sections[index + 1] && sections[index + 1]._type === section._type,
              ),
              rawSection: _rawSections[index],
            })}
        </Fragment>
      )
    })}
  </Suspense>
)

export default SectionsContainer
