import React, { useState, useEffect } from 'react'
import {
  CookiesBar,
  SettingsModal,
  StyledCookies,
  ButtonsBox,
  AcceptButton,
  CookiesDescription,
  CookiesLink,
  Switch,
  ConsentInput,
  Slider,
  PreferenceBox,
  SettingsButtonsBox,
  FloatingButton,
} from './cookies.styles'
import cookie from '../../../../assets/images/icons/cookie.svg'

const CookieConsent = ({ consentTypes, settings, bar }) => {
  function getCookie(name) {
    if (typeof window !== 'undefined') {
      const value = `; ${document.cookie}`
      const parts = value.split(`; ${name}=`)
      if (parts.length === 2) return parts.pop().split(';').shift()
    }
  }
  function gtag() {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(arguments)
  }
  const [showSettingsModal, setShowSettingsModal] = useState(false)
  const [showBar, setShowBar] = useState(false)
  const [consentState, setConsentState] = useState(() => {
    const savedConsent = getCookie('cookies_consent')
    return savedConsent ? JSON.parse(savedConsent) : initializeConsentState()
  })

  useEffect(() => {
    gtag('js', new Date())
    gtag('config', 'GTM-W72RJ47')
    const savedConsent = getCookie('cookies_consent')
    if (savedConsent) {
      const parsedConsent = JSON.parse(savedConsent)
      setConsentState(parsedConsent)
      gtag('consent', 'default', parsedConsent)
      setShowBar(false)
    } else {
      gtag('consent', 'default', {
        ad_storage: 'denied',
        analytics_storage: 'denied',
        ad_user_data: 'denied',
        ad_personalization: 'denied',
      })
      setShowBar(true)
    }
  }, [])

  function initializeConsentState() {
    const initialConsent = consentTypes.reduce((acc, type) => {
      acc[type.name] = type.default
      return acc
    }, {})
    initialConsent.wait_for_update = 500
    return initialConsent
  }

  function updateConsentState(newState) {
    setConsentState(newState)
    if (typeof window !== 'undefined') {
      document.cookie = `cookies_consent=${JSON.stringify(newState)}; expires=${new Date(
        new Date().setFullYear(new Date().getFullYear() + 1),
      )}; path=/`
    }
  }
  function handleSave() {
    updateConsentState(consentState)
    setShowBar(false)
    setShowSettingsModal(false)
    gtag('consent', 'update', consentState)
  }
  function handleAcceptAll() {
    const updatedState = consentTypes.reduce((acc, type) => {
      acc[type.name] = 'granted'
      return acc
    }, {})
    updateConsentState(updatedState)
    setShowBar(false)
    gtag('consent', 'update', updatedState)
  }
  function closeSettingsModal() {
    setShowSettingsModal(false)
    gtag('consent', 'update', consentState)
  }

  return (
    <>
      {showBar && (
        <StyledCookies>
          <CookiesBar className="bar">
            <CookiesDescription>
              {bar.description}{' '}
              <CookiesLink as="a" href="/privacy-policy">
                Read our Privacy Policy
              </CookiesLink>
            </CookiesDescription>

            <ButtonsBox>
              <AcceptButton onClick={handleAcceptAll}>{bar.buttons.accept}</AcceptButton>
              <AcceptButton onClick={() => setShowSettingsModal(true)}>
                {bar.buttons.settings}
              </AcceptButton>
              <AcceptButton onClick={() => setShowBar(false)}>{bar.buttons.deny}</AcceptButton>
            </ButtonsBox>
          </CookiesBar>
        </StyledCookies>
      )}
      {showSettingsModal && (
        <SettingsModal>
          <h1>{settings.title}</h1>
          <p>{settings.description}</p>
          {consentTypes.map((type) => (
            <PreferenceBox key={type.name}>
              <h1>{type.title}</h1>
              <p>{type.description}</p>
              <Switch>
                <ConsentInput
                  type="checkbox"
                  checked={consentState[type.name] === 'granted'}
                  onChange={(e) => {
                    const updatedConsentState = {
                      ...consentState,
                      [type.name]: consentState[type.name] === 'granted' ? 'denied' : 'granted',
                    }
                    updateConsentState(updatedConsentState)
                  }}
                />
                <Slider checked={consentState[type.name] === 'granted'} />
              </Switch>
            </PreferenceBox>
          ))}
          <SettingsButtonsBox>
            <button onClick={handleSave}>{settings.buttons.save}</button>
            <button onClick={closeSettingsModal}>{settings.buttons.close}</button>
          </SettingsButtonsBox>
        </SettingsModal>
      )}
      {!showBar && !showSettingsModal && (
        <FloatingButton src={cookie} alt="cookie" onClick={() => setShowSettingsModal(true)} />
      )}
    </>
  )
}
export default CookieConsent
