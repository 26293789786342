import styled from 'styled-components';
import { colors } from '../../../../constants/style';
import { InputLabelStyle, InputWrapperStyle, InputStyle } from '../commonFormStyle';

export const StyledDiv = styled.div`
  ${InputWrapperStyle}

  .input-label {
    display: flex;
    align-items: center;
    color: ${colors.titleDark};
    cursor: pointer;

    ${InputLabelStyle}
  }

  input {
    display: none;
    border: ${({ isError }) => (isError ? `1px solid ${colors.error}` : `1px solid ${colors.grayLight}`)};

    ${InputStyle}
  }
`;
