import React from 'react'
import styled, { css } from 'styled-components'
import { colors, breakpoints } from '../../../constants/style'
import SanityBlockContent from '../SanityBlockContent'

import orderNumberBordered from '../Styled/orderNumberBordered'

const dashCss = css`
  content: '';
  display: block;
  margin-bottom: 3.5rem;
  width: 56px;
  height: 4px;
  background-color: var(--t1-overline);
  @media only screen and (max-width: ${breakpoints.mobile}) {
    margin-bottom: 2.5rem;
  }
`

const orderNumberCss = css`
  ${orderNumberBordered};
  margin-bottom: 0.5rem;
`

const StyledSectionHeader = styled.div.attrs({ className: 'section-header' })`
  position: relative;
  z-index: 1;
  max-width: 48.25rem;
  margin: ${({ headerPosition }) => (headerPosition === 'center' ? '0 auto' : '0 auto 0 0')};
  margin-bottom: 5rem;
  text-align: ${({ headerPosition }) => headerPosition};
  @media only screen and (max-width: ${breakpoints.desktopSmall}) {
    max-width: 34.5rem;
  }

  .section-header {
    &__title {
      margin-bottom: ${({ isSubtitle }) => isSubtitle && '3.5rem'};
      display: flex;
      flex-direction: column;
      align-items: ${({ headerPosition }) =>
        headerPosition === 'center' ? 'center' : 'flex-start'};
      justify-content: center;
      color: var(--t1-title);
      @media only screen and (max-width: ${breakpoints.mobile}) {
        font-size: 2.375rem;
        margin-bottom: ${({ isSubtitle }) => isSubtitle && '2.5rem'};
      }

      &::before {
        ${({ orderNumber }) => {
          return orderNumber ? `content: '${orderNumber}'; ${orderNumberCss}` : dashCss
        }}
      }
    }

    &__subtitle {
      p {
        line-height: 2rem;
        font-size: 1.1875rem;
        color: var(--t1-body);
        &:not(:last-child) {
          margin-bottom: 1.5rem;
        }
      }
      a {
        color: ${colors.primary};
        font-weight: 600;

        &:hover {
          color: ${colors.secondary};
        }
      }
    }
  }
`

/**
 * @param orderNumber number or false
 * @param title string
 * @param subtitle string or block content array
 * @param {('left'|'center')} headerPosition string
 * @param style styles object
 */

const SectionHeader = ({
  orderNumber = false,
  title,
  subtitle = '',
  theme = 'lightPurple',
  headerPosition = 'center',
  style = {},
}) => {
  const formatOrderNumber = (number) => {
    if (!number) {
      return number
    } else return String(number).padStart(2, '0')
  }
  const formattedOrderNumber = formatOrderNumber(orderNumber)

  return (
    <>
      {title || subtitle ? (
        <StyledSectionHeader
          headerPosition={headerPosition}
          isSubtitle={!!subtitle}
          style={style}
          orderNumber={formattedOrderNumber}
          className={theme === 'darkPurple' || theme === 'darkGreen' ? 'dark' : ''} // support for old themes
        >
          {title && <h2 className="section-header__title">{title}</h2>}
          {subtitle && (
            <div className="section-header__subtitle">
              {Array.isArray(subtitle) ? (
                <SanityBlockContent blocks={subtitle} />
              ) : (
                <p>{subtitle}</p>
              )}
            </div>
          )}
        </StyledSectionHeader>
      ) : null}
    </>
  )
}

export default SectionHeader
