import React from 'react'
import { NavLinkButton } from '../../../components/Link'
import StyledContactUsPage from '../styled'
import ErrorIcon from '../../../../static/images/icons/exclamation-mark.svg'

const SendingFormError = () => {
  return (
    <StyledContactUsPage>
      <div className="contact-us__column-right">
        <div className="thank-you">
          <div className="thank-you__title">
            <img src={ErrorIcon} alt="error icon" height={25} width={25} />
            <h2>There was an error</h2>
          </div>
          <p className="thank-you__message">We encountered problems. Please try again.</p>
          <hr className="thank-you__horizontal-line" />

          <NavLinkButton to="/contact-us/" variant="filled" className="thank-you__button">
            BACK TO THE FORM
          </NavLinkButton>
        </div>
      </div>
    </StyledContactUsPage>
  )
}

export default SendingFormError
