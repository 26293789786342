import React from 'react';
import StyledInput from './styled';

const Input = ({ errorMessage, label, value, handleInputChange, name }) => {
  return (
    <StyledInput className={`${errorMessage ? ' has-error' : ''}`}>
      <input id={name} placeholder={label} type="text" name={name} value={value} onChange={handleInputChange} />
      <label htmlFor={name}>{label}</label>
      <div className="error">{errorMessage && errorMessage[0]}</div>
    </StyledInput>
  );
};

export default Input;
