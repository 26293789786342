import { useEffect, useState, useCallback } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImageProps } from 'gatsby-plugin-image';

import { RecommendedArticle } from 'features/layout/components/navigation/components/recommendationSection/types';

import { useSeoQuery } from '../page';

type NavInternalUrlType = 'customPage' | 'servicesPage' | 'caseStudy' | 'servicesPageList' | 'caseStudyPageList'

interface NavigationQueryItem {
  entries: NavigationQueryItem[]
  externalUrl: string
  icon: {
    asset: {
      url: string
    }
  }
  internalUrl: {
    _type: NavInternalUrlType
    slug: {
      current: string
    }
  }
  renderAsButton: boolean
  title: string
  description: string
  shouldDisplayBottomButton: boolean
  bottomButtonLabel: string
  bottomButtonUrl: string
  isItemNew: boolean
  recommendation: NavigationRecommendation[]
  shouldDisplayBrowseAllButton: boolean
  recommendationSectionTitle: string
  browseAllButtonLabel: string
  browseAllButtonUrl: string
}

interface NavigationQueryData {
  sanityNavigation: {
    entries: NavigationQueryItem[]
  }
}

export interface NavItem {
  children: NavItem[] | null
  externalUrl: string | null
  icon: string | null
  internalUrl: string | null
  isButton: boolean
  label: string
  description: string | null
  shouldDisplayBottomButton: boolean
  bottomButtonLabel: string | null
  bottomButtonUrl: string | null
  isItemNew: boolean
  recommendation: NavigationRecommendation[]
  recommendationSectionTitle: string
  shouldDisplayBrowseAllButton: boolean
  browseAllButtonLabel: string | null
  browseAllButtonUrl: string | null
}

export interface NavigationRecommendation {
  _type: 'quoteWithAuthorAndLink' | 'review' | 'recommendedArticlesList',
  link: string
  linkText: string
  quote?: string
  reviewContent?: string
  author?: {
    name: string
    position: string
    image: { asset: { gatsbyImageData: GatsbyImageProps }; alt?: string }
  }
  articles?: RecommendedArticle[]
}

const buildInternalUrl = (type: NavInternalUrlType, slug: string | null) => {
  switch (type) {
    case 'caseStudy':
      return `/case-studies/${slug}/`
    case 'servicesPage':
      return `/services/${slug}/`
    case 'servicesPageList':
      return '/services/'
    case 'caseStudyPageList':
      return '/case-studies/'
    default:
      return slug || null
  }
}

export const useNavigationQuery = (): NavItem[] => {
  const [list, setList] = useState<NavItem[]>([])

  const { site: { siteMetadata: { siteUrl } } } = useSeoQuery()

  const data = useStaticQuery<NavigationQueryData>(graphql`
    fragment NavigationItemFields on SanityNavigationItem {
      title
      description
      renderAsButton
      shouldDisplayBottomButton
      bottomButtonLabel
      bottomButtonUrl
      isItemNew
      externalUrl
      icon {
        asset {
          url
        }
      }
      internalUrl {
        ... on SanityCaseStudy {
          _type
          slug {
            current
          }
        }
        ... on SanityCustomPage {
          slug {
            current
          }
          _type
        }
        ... on SanityServicesPage {
          _type
          slug {
            current
          }
        }
        ... on SanityServicesPageList {
          _type
        }
        ... on SanityCaseStudyPageList {
          _type
        }
      }
      shouldDisplayBrowseAllButton
      browseAllButtonLabel
      browseAllButtonUrl
      recommendationSectionTitle
      recommendation {
        ... on SanityQuoteWithAuthorAndLink {
          _type
          link
          linkText
          quote
          author {
            name
            position
            image {
              alt
              asset {
                gatsbyImageData(width: 180)
              }
            }
          }
        }
        ... on SanityReview {
          _type
          reviewContent
          author {
            name
            position
          }
          link
          linkText
        }
        ... on SanityRecommendedArticlesList {
          _type
          articles {
            linkText
            title
            image {
              asset {
                gatsbyImageData(width: 160)
              }
              alt
            }
            fullArticle {
              slug {
                current
              }
              categories {
                slug {
                  current
                }
              }
            }
          }
        }
      }
    }

    query navigationQuery {
      sanityNavigation {
        entries {
          ...NavigationItemFields
          entries {
            ...NavigationItemFields
            entries {
              ...NavigationItemFields
              entries {
                ...NavigationItemFields
                entries {
                  ...NavigationItemFields
                  entries {
                    ...NavigationItemFields
                    entries {
                      ...NavigationItemFields
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const mapEntry = useCallback((level: number) => (entry: NavigationQueryItem): NavItem => {
    let externalUrl: string | null = entry.externalUrl
    let internalUrl = buildInternalUrl(entry.internalUrl?._type, entry.internalUrl?.slug?.current)
    if (!internalUrl && externalUrl && !externalUrl.startsWith('https://') && !externalUrl.startsWith('http://')) {
      internalUrl = externalUrl
      externalUrl = null
    }

    if (internalUrl) {
      internalUrl = `/${internalUrl?.startsWith('/') ? internalUrl?.substring(1) : internalUrl}${!internalUrl?.endsWith('/') ? '/' : ''}`
    }

    return {
      children: level > 5 ? [] : entry.entries.map(mapEntry(level + 1)),
      externalUrl,
      icon: entry.icon?.asset?.url,
      internalUrl,
      isButton: entry.renderAsButton,
      label: entry.title,
      description: entry.description,
      shouldDisplayBottomButton: entry.shouldDisplayBottomButton,
      bottomButtonLabel: entry.shouldDisplayBottomButton ? entry.bottomButtonLabel : null,
      bottomButtonUrl: entry.shouldDisplayBottomButton ? entry.bottomButtonUrl : null,
      isItemNew: entry.isItemNew,
      recommendation: entry.recommendation,
      recommendationSectionTitle: entry.recommendationSectionTitle,
      shouldDisplayBrowseAllButton: entry.shouldDisplayBrowseAllButton,
      browseAllButtonLabel: entry.shouldDisplayBrowseAllButton ? entry.browseAllButtonLabel : null,
      browseAllButtonUrl: entry.shouldDisplayBrowseAllButton ? entry.browseAllButtonUrl : null,
    }
  }, [])


  useEffect(() => {
    if (data?.sanityNavigation?.entries?.length > 0) {
      const newList = data.sanityNavigation.entries.map(mapEntry(0))
      setList(newList)
    }
  }, [data, mapEntry])

  return list
}
