import styled, { createGlobalStyle } from 'styled-components'
import {
  verticalLayoutPadding,
  horizontalLayoutPadding,
  horizontalLayoutPaddingTablet,
  horizontalPaddingDesktop,
} from './components/commonLayoutStyle'
import { colors, breakpoints, containerMaxWidth } from '../../../constants/style'

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;

    ::selection {
      background: ${colors.primary};
      color: #FFFFFF;
    }
  }
  body, html{
    padding: 0;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body {
     /* if there is some transform transition that goes outside of page, prevent browser from showing horizontal scrollbars */
    overflow-x: hidden;
    font-size: 16px;
    line-height: 1.4;
    font-family: "BioSans-Regular", sans-serif;

    /* remove margin for the main div that Gatsby mounts into */
    > div {
      margin-top: 0;
    }
  }

  a {
    text-decoration: none;
    color: inherit;
    transition: all 0.2s ease-in-out;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: "BioSans-Regular", sans-serif;
    font-weight: 400;
    margin: 0;
  }

  h1 {
    font-size: 3.25rem;
    letter-spacing: 0.025rem;
  }

  h2 {
    font-size: 2.625rem;
    letter-spacing: 0.01rem;
    line-height: 1.33;
  }

  p {
    b, strong{
      font-family: Roboto-Bold, sans-serif;
    }
  }

  img {
    height: auto;
    max-width: 100%;
    transform: translate3d(0, 0, 0); // for better image rendering quality and performance
  }

  ol, ul {
    list-style: none;
  }

  @media only screen and (max-width: ${breakpoints.desktop}) {
    h1 {
      font-size: 2.5rem;
    }
  }
`

export const Main = styled('main')`
  margin: auto;
  /* this is for respecting vertical padding of navbar and footer */
  section {
    padding-top: ${verticalLayoutPadding};
    padding-bottom: ${verticalLayoutPadding};
    padding-left: ${horizontalLayoutPadding};
    padding-right: ${horizontalLayoutPadding};

    @media only screen and (max-width: ${breakpoints.desktopLarge}) {
      padding-left: ${horizontalPaddingDesktop};
      padding-right: ${horizontalPaddingDesktop};
    }

    @media only screen and (max-width: ${breakpoints.tablet}) {
      padding-left: ${horizontalLayoutPaddingTablet};
      padding-right: ${horizontalLayoutPaddingTablet};
    }
  }
  .container {
    max-width: ${containerMaxWidth};
    margin: 0 auto;
  }
`
