import React from 'react'

import { useDownloadForm } from './useDownloadForm'
import HeroContactForm from '../HeroContactForm'
import { useDictionaryQuery } from 'hooks/graphql/page'
import IconDownload from '../icons/IconDownload'

const DownloadForm = ({
  formName,
  header,
  subheader,
  fileUrl,
  raw,
  showProjectDescriptionInput,
  whitePaper = false,
}) => {
  const {
    contactForm: { downloadButtonLabel, whitePaperDownloadButtonLabel, submitButtonLabel },
  } = useDictionaryQuery()
  const { handleFormSubmit, isFormSent, errorMessages, formData, handleInputChange, isLoading } =
    useDownloadForm({
      formName,
      fileUrl,
      showProjectDescriptionInput,
    })

  return (
    <HeroContactForm
      header={header}
      subheader={subheader}
      buttonLabel={
        fileUrl ? (
          <>
            {whitePaper ? (
              whitePaperDownloadButtonLabel.toUpperCase()
            ) : (
              <>
                <IconDownload /> {downloadButtonLabel}
              </>
            )}
          </>
        ) : (
          submitButtonLabel
        )
      }
      handleFormSubmit={handleFormSubmit}
      isFormSent={isFormSent}
      errorMessages={errorMessages}
      formData={formData}
      handleInputChange={handleInputChange}
      isLoading={isLoading}
      rawDescription={raw.description}
      rawDisclaimer={raw.disclaimer}
      showProjectDescriptionInput={showProjectDescriptionInput}
    />
  )
}

export default DownloadForm
