import React from 'react';
import styled from 'styled-components';

import IconTextItem from './components/IconTextItem';

const StyledIconTextGrid = styled('div')`
  margin: 3.5rem 0;

  p.icon-text-grid__description {
    margin-bottom: 3.5rem !important;
  }

  .icon-text-grid__items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem 1.5rem;
  }

  @media only screen and (max-width: 35rem) {
    .icon-text-grid__items {
      margin: 0 auto;
      max-width: 14.375rem;
      grid-template-columns: 1fr;
      gap: 1.1875rem;
    }
  }
`;

const IconTextGrid = ({ description, items }) => {
  return (
    <StyledIconTextGrid>
      {description && (
        <p className="icon-text-grid__description">
          <strong>{description}</strong>
        </p>
      )}
      <div className="icon-text-grid__items">
        {items.map((item, index) => (
          <IconTextItem key={index} item={item} />
        ))}
      </div>
    </StyledIconTextGrid>
  );
};

export default IconTextGrid;
