import styled from 'styled-components';
import { breakpoints, colors, zIndexes } from '../../../../constants/style';
import { StyledButton } from '../../Buttons';
import { NavLinkButton } from '../../Link';

export const Blur = styled('div')`
  position: absolute;
  width: 11.125rem;
  height: 11.125rem;
  top: 3rem;
  right: 15rem;
  background: #7b51e6;
  opacity: 0.9;
  filter: blur(10.5rem);
  z-index: 5;
  @media only screen and (max-width: ${breakpoints.desktop}) {
    top: 5rem;
    right: 10rem;
  }
  @media only screen and (max-width: ${breakpoints.tablet}) {
    top: 5;
    left: 60%;
  }
  @media only screen and (max-width: ${breakpoints.mobileLarge}) {
    top: 40%;
  }
`;

export const ContactUsSection = styled('section')`
  ${(props) => (props.middle ? 'padding: 0;' : '')}
  position: relative;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  @media only screen and (max-width: ${breakpoints.desktopSmall}) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  ${(props) =>
    props.theme.blog
      ? `
    padding: 0!important;
    border: 0.0625rem solid rgba(0,0,0,0);
    margin: 0 12.5% 2rem 12.5%;
  `
      : ''}
  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 50%;
    position: absolute;
    left: 0;
    top: 0;
    background: ${(props) => props.theme.before.background};
    z-index: 2;
  }

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: ${(props) => (props.middle ? '100%' : '50%')};
    position: absolute;
    left: 0;
    bottom: -0.0625rem;
    background: ${(props) => props.theme.after.background};
    z-index: 1;
  }

  .contact-us {
    background: ${(props) => props.theme.background};
    position: relative;
    overflow: hidden;
    z-index: 2;
    text-align: center;
    display: ${({ shouldDisplayEmployee }) => (shouldDisplayEmployee ? 'flex' : 'block')};
    justify-content: center;
    @media only screen and (max-width: ${breakpoints.desktopSmall}) {
      ${({ shouldDisplayEmployee }) => shouldDisplayEmployee && `flex-direction: column;`}
    }
    &__left,
    &__right {
      position: relative;
      z-index: ${zIndexes.contactUs};
      padding: ${(props) => (props.theme.blog ? '0 0 4rem 0' : props.middle ? '5rem 1rem 5rem 1rem' : '5rem 3.4375rem')};
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 4rem 2rem;
    }

    &__left {
      width: 55%;
      font-size: 1rem;
      @media only screen and (max-width: ${breakpoints.desktopSmall}) {
        ${({ shouldDisplayEmployee }) => shouldDisplayEmployee && `width: 100%;`}
        padding: 4rem 2rem 0 2rem;
      }
    }

    &__right {
      width: 100%;
    }

    &__employee-photo {
      width: 10.375rem;
      height: 10.375rem;
      border-radius: 50%;
      margin-bottom: 1.75rem;
    }

    &__employee-name {
      color: #fff;
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
      letter-spacing: 0.01rem;
    }

    &__employee-position {
      font-family: Roboto-Regular, sans-serif;
      color: ${colors.grayLight};
      font-size: 1rem;
    }

    h2 {
      max-width: 48rem;
      margin-bottom: 1.8125rem;
      color: ${(props) => props.theme.color};
      @media screen and (max-width: ${breakpoints.desktopLargest}) {
        max-width: 46.125rem;
      }
      @media screen and (max-width: ${breakpoints.desktopSmall}) {
        max-width: 34.5rem;
      }
      @media screen and (max-width: ${breakpoints.tablet}) {
        max-width: 21.875rem;
      }
    }

    p {
      color: ${(props) => props.theme.p.color};
      margin-bottom: 3rem;
      max-width: 48rem;
      font-size: 1.3125rem;
      line-height: 2.25rem;
      font-family: Bio Sans, sans-serif;

      color: ${colors.grayLight};
      @media screen and (max-width: ${breakpoints.desktopSmall}) {
        max-width: 28.75rem;
      }
      @media screen and (max-width: ${breakpoints.tablet}) {
        max-width: 21.875rem;
      }
    }

    .link,
    ${StyledButton} {
      z-index: ${zIndexes.navigation};
      display: inline-block;
      padding: 0.75rem 3.5rem;
    }

    @media screen and (max-width: ${breakpoints.mobileLarge}) {
      ${StyledButton},
      ${NavLinkButton} {
        padding: 1rem 0;
        width: 100%;
      }
    }
  }
`;

export const themes = {
  light: {
    background: 'linear-gradient(135deg, #100727 14.64%, #070413 85.36%)',
    color: '#fff',
    p: {
      color: '#fff',
    },
    before: {
      background: '#ffffff',
    },
    after: {
      background: '#ffffff',
    },
  },

  lightPurple: {
    background: 'linear-gradient(135deg, #100727 14.64%, #070413 85.36%)',
    color: '#fff',
    p: {
      color: '#fff',
    },
    before: {
      background: colors.backgroundLight,
    },
    after: {
      background: colors.backgroundLight,
    },
  },

  lightTolightPurple: {
    background: 'linear-gradient(135deg, #100727 14.64%, #070413 85.36%)',
    color: '#fff',
    p: {
      color: '#fff',
    },
    before: {
      background: '#fff',
    },
    after: {
      background: colors.backgroundLight,
    },
  },

  lightPurpleToLight: {
    background: 'linear-gradient(135deg, #100727 14.64%, #070413 85.36%)',
    color: '#fff',
    p: {
      color: '#fff',
    },
    before: {
      background: colors.backgroundLight,
    },
    after: {
      background: '#ffffff',
    },
  },

  dark: {
    background: 'linear-gradient(135deg, #100727 14.64%, #070413 85.36%)',
    color: '#fff',
    p: {
      color: '#fff',
    },
    before: {
      background: '#110925',
    },
    after: {
      background: '#110925',
    },
  },

  lightMiddle: {
    background: colors.backgroundLight,
    color: colors.titleDark,
    p: {
      color: colors.mutedDark,
    },
    before: {
      background: colors.backgroundLight,
    },
    after: {
      background: colors.backgroundLight,
    },
  },

  lightToDark: {
    background: '#120e1f',
    color: '#fff',
    p: {
      color: '#fff',
    },
    before: {
      background: '#ffffff',
    },
    after: {
      background: colors.background,
    },
  },

  lightPurpleToDark: {
    background: '#120e1f',
    color: '#fff',
    p: {
      color: '#fff',
    },
    before: {
      background: colors.backgroundLight,
    },
    after: {
      background: colors.background,
    },
  },

  lightPurpleToTitleDark: {
    background: 'linear-gradient(135deg, #100727 14.64%, #070413 85.36%)',
    color: '#fff',
    p: {
      color: '#fff',
    },
    before: {
      background: colors.backgroundLight,
    },
    after: {
      border: 'none',
      background: colors.titleDark,
    },
  },

  darkToLight: {
    background: '#120e1f',
    color: '#fff',
    p: {
      color: '#fff',
    },
    before: {
      background: colors.background,
    },
    after: {
      background: '#ffffff',
    },
  },

  blog: {
    blog: true,
    background: '#f7f7fc',
    color: '#100727',
    p: {
      color: '#6c6c77',
    },
    before: {
      background: '#f7f7fc',
    },
    after: {
      background: 'transparent',
    },
  },
};
