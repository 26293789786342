import React from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

import DownloadFormWrapper from '../../../HeroContactForm/styled';
import { Link } from '../../../Link';
import Checkbox from '../../../NewForm/Checkbox';
import Input from '../../../NewForm/Input';
import CheckboxLabel from '../../../NewForm/Checkbox/CheckboxLabel';
import { breakpoints, colors } from '../../../../../constants/style';
import Button from '../../../Buttons';
import IconLoading from '../../../icons/IconLoading';
import { legal } from '../../../../../constants/links';
import rocket from '../../../../../static/images/icons/Rocket.svg';
import { ButtonWithIconAndSubMenu } from '../../../Buttons';

const SignupFormWrapper = styled(DownloadFormWrapper)`
  padding: 0;
  max-width: 40rem;
  position: relative;
  background-color: #fff;

  .success-window {
    display: none;
  }

  form {
    padding: 3.5rem;
  }

  .header {
    font-size: 1.75rem;
    line-height: 2.5rem;
    letter-spacing: 0.01rem;
    margin-bottom: 0.5rem;
  }
  .description {
    margin-bottom: 2.5rem;
    p {
      font-size: 1rem;
      line-height: 1.75rem;
    }
  }
  .signup-form__link {
    color: ${colors.primary};
  }
  .signup-form__submit-button,
  .loading {
    height: 3rem;
    margin-top: 1.5rem;
    border: none;
  }

  ${({ successMessage }) => {
    if (successMessage) {
      return `
      form {
        opacity: 0;
        z-index: 0;
      }
      .success-window {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        text-align: center;
        padding: 1.5rem;

        .success-img {
          height: 7.5rem;
          margin-bottom: 3rem;
        }

        h3.success-message {
          font-size: 1.75rem;
          line-height: 2.5rem;
          color: ${colors.titleDark};
          margin-bottom: .5rem;
        }

        p.success-comment {
          font-size: 1rem;
          line-height: 1.75rem;
          color: #4E4D6C;
          margin-bottom: 3rem;
        }

        .success-calendar-button{
          width: 24rem;

          span {
            display: block;
          }
        }
      }
      `;
    }
  }}

  @media only screen and (max-width: ${breakpoints.desktop}) {
    form {
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }

  @media only screen and (max-width: ${breakpoints.desktopSmall}) {
    max-width: 28rem;
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    form {
      padding: 3rem 2rem;
    }

    .success-window {
      .success-calendar-button {
        width: 18rem;
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.mobile}) {
    form {
      padding-left: 1.5rem;
    }

    .success-window {
      .success-calendar-button {
        width: fit-content;
      }
    }
  }
`;

const SignupForm = ({ AddToCalendarSubmenu }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSendError, setIsSendError] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState(null);

  const methods = useForm({ mode: 'all' });
  const {
    handleSubmit,
    formState: { isValid },
    reset,
  } = methods;

  const submitFormData = async (formData) => {
    setIsLoading(true);

    const [firstName, ...lastNameParts] = formData.fullName.split(' ');
    const lastName = lastNameParts.join(' ');

    const data = {
      firstName,
      lastName,
      ...formData,
      marketingConsent: !!formData.marketingConsent,
      termsConsent: !!formData.termsConsent,
    };

    try {
      const response = await fetch('/api/10c-voices-form', {
        method: 'POST',
        body: JSON.stringify(data),
      });

      if (response.ok) {
        const { message } = await response.json();
        setSuccessMessage(message);
        setIsLoading(false);
        reset();
      }

      if (response.status !== 200) {
        setIsLoading(false);
      }
    } catch (err) {
      setIsSendError(true);
      setIsLoading(false);
      console.log(err);
    }
  };

  return (
    <SignupFormWrapper successMessage={successMessage}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(submitFormData)}>
          <div className="header">Interested in this event?</div>
          <div className="description">
            <p>Sign up, you won’t miss a thing.</p>
          </div>
          <Input label="Full name" placeholder="John Snow" inputName="fullName" required />
          <Input label="Business Email" placeholder="john.snow@company.com" inputName="email" required />
          <div className="signup-form__checkbox-container">
            <Checkbox
              inputName="termsConsent"
              label={
                <CheckboxLabel inputName="termsConsent">
                  I agree to the{' '}
                  <Link className="signup-form__link" to={legal[0].url}>
                    {legal[0].name}
                  </Link>{' '}
                  and{' '}
                  <Link className="signup-form__link" to={legal[1].url}>
                    {legal[1].name}
                  </Link>
                  .
                </CheckboxLabel>
              }
              makeValueBoolean={true}
              required
              isTermsOfUse={true}
              showError={false}
            />
          </div>

          <div className="signup-form__checkbox-container">
            <Checkbox
              label="I agree to receive marketing communication from 10Clouds"
              makeValueBoolean={true}
              inputName="marketingConsent"
              isTermsOfUse={true}
              showError={false}
            />
          </div>
          {isLoading ? (
            <div className="loading">
              <IconLoading />
            </div>
          ) : isSendError ? (
            <div className="error">Sorry, but we had a problem saving your submission, please refresh the page and try again</div>
          ) : (
            <Button className="signup-form__submit-button" variant="filled" type="submit" disabled={!isValid}>
              SIGN UP
            </Button>
          )}
        </form>
      </FormProvider>
      <div className="success-window">
        <img src={rocket} alt="rocket" className="success-img" />
        <h3 className="success-message">Thanks for signing up!</h3>
        <p className="success-comment">Check email for a confirmation</p>
        <ButtonWithIconAndSubMenu className="success-calendar-button" variant="filled" subMenuItems={AddToCalendarSubmenu}>
          add to calendar
        </ButtonWithIconAndSubMenu>
      </div>
    </SignupFormWrapper>
  );
};

export default SignupForm;
