import React from 'react';

import StyledParagraphVideoSection from './StyledParagraphVideoSection';
import SanityBlockContent from '../../SanityBlockContent';
import { colors } from '../../../../constants/style';
import WaveSVG from '../../../../static/images/wave-light-2.svg';
import SectionHeader from '../../SectionHeader/SectionHeader';

//Additional tools
import getYouTubeID from 'get-youtube-id';

const themes = {
  light: {
    backgroundColor: '#fff',
    before: {
      background: `url(${WaveSVG}) no-repeat left top`,
    },
    content: {
      header: {
        color: colors.sectionTitleDark,
        before: {
          backgroundColor: colors.primary,
        },
      },
      paragraph: {
        color: '#4e4d6c',
      },
    },
  },

  lightPurple: {
    backgroundColor: colors.backgroundLight,
    before: {
      background: `url(${WaveSVG}) no-repeat left top`,
    },
    content: {
      header: {
        color: colors.sectionTitleDark,
        before: {
          backgroundColor: colors.primary,
        },
      },
      paragraph: {
        color: '#4e4d6c',
      },
    },
  },

  dark: {
    backgroundColor: colors.titleDark,
    before: {
      background: `url(${WaveSVG}) no-repeat left top`,
    },
    content: {
      header: {
        color: colors.sectionTitleLight,
        before: {
          backgroundColor: colors.secondary,
        },
      },
      paragraph: {
        color: '#7b7b8e',
      },
    },
  },
};

const ParagraphVideoSection = ({ theme, header, description, linkToVideo = false }) => {
  const id = linkToVideo ? getYouTubeID(linkToVideo) : null;
  const embededYoutubeURL = `https://www.youtube.com/embed/${id}`;

  return (
    <StyledParagraphVideoSection className="paragraph" theme={themes.hasOwnProperty(theme) ? themes[theme] : themes['light']}>
      <div className="container">
        <div className="content">
          {Boolean(header) && <SectionHeader title={header} headerPosition="left" theme={theme === 'dark' ? 'darkPurple' : undefined} />}
          <SanityBlockContent className="description" blocks={description} />
        </div>

        {linkToVideo ? (
          <div className="video">
            <iframe
              style={{ aspectRatio: '16/9' }}
              width="100%"
              loading="lazy"
              src={embededYoutubeURL}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        ) : null}
      </div>
    </StyledParagraphVideoSection>
  );
};

export default ParagraphVideoSection;
