import React, { useState, useEffect, useRef, Fragment } from 'react'
import styled from 'styled-components'

import '../HorizontalSlider/slick.css'
import Slider from 'react-slick'

import IconArrow from '../icons/IconArrow'
import IconStar from '../icons/IconStar'
import { breakpoints } from '../../../constants/style'

const progressWidth = '202px'
const progressMargin = '3rem'
const buttonWidth = '30px'

const SliderWrapper = styled('div')`
  position: relative;

  @media screen and (max-width: ${breakpoints.mobileLarge}) {
    padding-right: 0;
  }

  .slider {
    position: relative;
    overflow: hidden;
    transition: height 0.5s ease-in-out;

    .slides {
      display: flex;
      flex-direction: column;
      transition: transform 0.5s ease-in-out;

      .slide {
        min-height: 8.75rem; // minimal height for small slides to cover navigation size.
      }
    }
  }

  .slider__stars {
    svg {
      margin-right: 0.223rem;
    }
  }

  .slider__navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3.125rem;
    @media screen and (max-width: ${breakpoints.mobileLarge}) {
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column-reverse;
    }
  }

  .slider__button-wrapper {
    display: flex;
    align-items: center;
    @media screen and (max-width: ${breakpoints.mobileLarge}) {
      margin-bottom: 4.4375rem;
    }
  }

  .slider__button-left,
  .slider__button-right {
    width: ${buttonWidth};
    height: 50px;
    background-color: transparent;
    border: 0;
    padding: 0;
    outline: 0;
    cursor: pointer;
    transform: translateX(0);
    transition: transform 0.2s ease-in-out;

    span {
      font-size: 0;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.3;
    }

    &:focus {
      outline: 0;
    }

    svg {
      width: 1.1875rem;
      fill: var(--i1-default-in-button);
    }
  }

  .slider__button-left {
    svg {
      transform: rotate(180deg);
    }

    &:hover:not(:disabled) {
      transform: translateX(-0.25rem);
    }
  }
  .slider__button-right {
    &:hover:not(:disabled) {
      transform: translateX(0.25rem);
    }
  }
  .slider__progressbar {
    width: ${progressWidth};
    height: 2px;
    background: rgba(154, 153, 178, 0.5);
    margin: 0 ${progressMargin};

    .slider__progressbar-state {
      transition: width 200ms ease;
      background-color: var(--i1-hover);
      height: 100%;
    }
  }

  @media only screen and (max-width: ${breakpoints.mobile}) {
    .slider__button-wrapper {
      width: 100%;
    }
  }

  @media only screen and (max-width: ${breakpoints.mobileSmall}) {
    .slider__progressbar {
      margin: 0 2rem;
    }
  }
`

const StyledIconStar = styled(IconStar)`
  color: var(--i1-hover);
`

const returnComponent = (component, i) => <Fragment key={i}>{component}</Fragment>

const VerticalSlider = ({
  children,
  startPosition,
  onSlideChange,
  customSettings,
  testimonial: { numOfStars },
}) => {
  const slider = useRef()

  const [position, setPosition] = useState(startPosition)
  const [progress, setProgress] = useState('0%')
  const [isPrevDisabled, setIsPrevDisabled] = useState(false)
  const [isNextDisabled, setIsNextDisabled] = useState(false)

  const checkStatus = () => {
    let countChildren = React.Children.toArray(children).length

    let slidesToShow = slider.current.props.slidesToShow
    let slidesToScroll = slider.current.props.slidesToScroll

    if (slider.current.state.breakpoint) {
      const nextScrollUpdateStatus = slider.current.props.responsive.find(
        (responsiveSettings) => responsiveSettings.breakpoint === slider.current.state.breakpoint,
      )
      if (nextScrollUpdateStatus.settings.slidesToShow) {
        slidesToShow = nextScrollUpdateStatus.settings.slidesToShow
      }
      if (nextScrollUpdateStatus.settings.slidesToScroll) {
        slidesToScroll = nextScrollUpdateStatus.settings.slidesToScroll
      }
    }

    const maxPage = Math.ceil((countChildren - slidesToShow) / slidesToScroll)
    const currentPage = Math.floor(position / slidesToScroll)

    setProgress(`${(((currentPage + 1) / (maxPage + 1)) * 100).toFixed(2)}%`)

    setIsPrevDisabled(Boolean(currentPage <= 0))
    setIsNextDisabled(Boolean(currentPage >= maxPage))
  }

  const beforeChangeHandler = (oldIndex, newIndex) => {
    if (oldIndex !== newIndex) {
      setPosition(newIndex)
      if (typeof onSlideChange === 'function') {
        onSlideChange(newIndex)
      }
    }
  }

  const nextSlide = () => {
    slider.current.slickNext()
  }

  const prevSlide = () => {
    slider.current.slickPrev()
  }

  const goToSlide = (index) => {
    slider.current.slickGoTo(index)
  }

  useEffect(() => {
    goToSlide(startPosition)
  }, [startPosition])

  // prevent overriding event handlers
  if (customSettings.hasOwnProperty('beforeChange')) {
    delete customSettings.beforeChange
  }
  if (customSettings.hasOwnProperty('onReInit')) {
    delete customSettings.onReInit
  }

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: false,
    adaptiveHeight: true,
    beforeChange: beforeChangeHandler,
    onReInit: checkStatus,
    responsive: [
      {
        breakpoint: parseInt(breakpoints.tablet, 10) + 1,
        settings: {
          vertical: false,
        },
      },
    ],
    ...customSettings,
  }

  return (
    <SliderWrapper>
      <div className="slider__navigation">
        <div className="slider__stars">
          {!numOfStars
            ? Array(5)
                .fill(<StyledIconStar />, 0, 5)
                .map(returnComponent)
            : Array(Math.floor(numOfStars))
                .fill(<StyledIconStar />, 0, Math.floor(numOfStars))
                .map(returnComponent)}
          {numOfStars && !Number.isInteger(numOfStars) && <StyledIconStar half />}
        </div>
        {Boolean(children && children.length > 1) && (
          <div className="slider__button-wrapper">
            <button
              className="slider__button-left"
              onClick={() => prevSlide()}
              disabled={isPrevDisabled}
            >
              <span>Previous slide</span>
              <IconArrow />
            </button>
            <div className="slider__progressbar">
              <div className="slider__progressbar-state" style={{ width: progress }} />{' '}
            </div>
            <button
              className="slider__button-right"
              onClick={() => nextSlide()}
              disabled={isNextDisabled}
            >
              <span>Next slide</span>
              <IconArrow />
            </button>
          </div>
        )}
      </div>
      <Slider ref={slider} {...settings}>
        {children}
      </Slider>
    </SliderWrapper>
  )
}

VerticalSlider.defaultProps = {
  customSettings: {},
  startPosition: 0,
  theme: 'light',
  onSlideChange: null,
}

export default VerticalSlider
