import styled from 'styled-components';
import { navbarMinHeight } from '../../../features/layout/components/commonLayoutStyle';
import { breakpoints, colors } from '../../../../constants/style';

const sectionBasePadding = '8.125rem';

const ProcessOfDeliverySection = styled('section')`
  padding-top: ${sectionBasePadding};
  padding-bottom: ${sectionBasePadding};
  background: linear-gradient(${colors.backgroundGradients.dark.start}, ${colors.backgroundGradients.dark.end});

  .header {
    width: 70%;
    font-family: BioSans-regular, sans-serif;
    text-align: center;
    font-size: 2.625rem;
    margin: 0 auto 112px auto;
    color: #FFFFFF;
    
    * {
      text-align: center;
      font-size: 2.625rem;
    }

    &:before {
      content: '';
      display: block;
      margin: 0 auto 2.5rem;
      width: 50px;
      height: 5px;
      background: ${colors.secondary};
    }
  }

  button {
    font-family: BioSans-regular, sans-serif;
    display: block;
    width: 100%;
    background: transparent;
    border: 0;
    flex-grow: 1;
    cursor: pointer;
  }

  .content-navigation {
    display: flex;
    justify-content: space-evenly;
    position: relative;

    .content-navigation__item {
      width: 25%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer!important;
    }

    .content-navigation__item-image  {
      width: 20vh;
      height: 20vh;
      display: block;
    }

    .content-navigation__item-button {
      border-radius: 16px 16px 0 0;
      color: #FFFFFF;
      padding: 1.25rem 2.5rem;

      img {
        display: none;
      }

      &.active {
        background-color: #FFFFFF;
        color: ${colors.primary};
      }
    }
  }

  .content-navigation__item-decoration {
    position: relative;
    width: 100%;
    margin: 40px 0;

    .content-navigation__item-decoration-line {
      height: 2px;
      background-color: ${colors.primary};
      width: 100%;
      margin-left: -50%;
    }

    .content-navigation__item-decoration-circle {
      position: absolute;
      height: 14px;
      width: 14px;
      border-radius: 50%;
      border: ${colors.primary} 2px solid;
      background-color: #FFFFFF;
      top: -6px;
      left: calc(50% - 8px);
      z-index: 2;
    }

    &.active {
      .content-navigation__item-decoration-line {
        background-color: ${colors.secondary};
      }

      .content-navigation__item-decoration-circle {
        border-color: ${colors.secondary};
      }
    }

    &.chosen {
      .content-navigation__item-decoration-circle {
        border: 1px solid ${colors.secondary};
        width: 30px;
        height: 30px;
        top: -14px;
        left: calc(50% - 14px);

        &:after {
          display: block;
          content: '';
          width: 8px;
          height: 8px;
          position: absolute;
          top: 4px;
          left: 4px;
          border-radius: 50%;
          border: 6px solid ${colors.secondary};
          background-color: #ffffff;
        }
      }
    }
  }

  .content-navigation__item {
    &:first-child {
      .content-navigation__item-decoration-line {
        width: 0;
      }
    }
  }


  .content-body__column {
    width: 50%;
  }

  .content-body {
    min-height: 650px;
    display: flex;
    justify-content: space-between;
    background-color: #FFFFFF;
    padding: 5.5rem 7.5rem;
    color: ${colors.mutedLight};

    p {
      font-size: 1rem;
      line-height: 1.6;
    }
    
    

    .content-body__header {
      font-weight: 600;
      font-size: 2rem;
      margin-bottom: 2rem;
      color: ${colors.titleDark};
    }

    .content-body__description {
      margin-bottom: 28px;
      
      ul {
        margin: 16px 0;
      }
      
      * {
        font-size: 1rem;
      }
    }

    .content-body__steps-button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: ${colors.titleDark};
      font-size: 1rem;
      padding-top: 24px;
      
      img {
        transform: rotate(90deg);
        transition: all 0.2s ease-out;
      }

      &.active {
        img {
          transform: rotate(0deg);
        }
      }
    }
    
    .content-body__steps-item {
      padding-bottom: 16px;
      border-bottom: 1px solid rgba(147, 147, 167, 0.2);
    }

    .content-body__steps-header {
      font-size: 1rem;
      color: ${colors.titleDark};
      margin-bottom: 16px;

      &:not(:first-child) {
        margin-top: 16px;
      }
    }

    .content-body__steps-description {
      overflow: hidden;
      max-height: 0;
      transition: max-height 0.2s linear;      
      
      &.active {
        max-height: 400px;
      }
    }

    .content-body__steps-description p {
      margin-top: 16px;
      font-size: 0.875rem;
      line-height: 1.6;
    }
    .content-body__column-image-wrapper {
      display: flex;
      justify-content: center;
    }

    .content-body__image {
      width: 26vw;
      margin: auto;
    }
  }

  @media only screen and (max-width: ${breakpoints.desktopLarge}) {
    .header {
      width: 80%;
    }
    .content-navigation {
    .content-navigation__item-image {
      width: 14vh;
      height: 14vh;
    }
   }
  }

  @media only screen and (max-width: ${breakpoints.desktop}) {
    .header {
      width: 100%;
    }
    
    .content-body {
      padding: 2.25rem 3.75rem;
    }

    .content-body__column {
      width: 30%;

      &:first-child {
        width: 70%;
      }
    }

    .content-navigation .content-navigation__item-image {
      width: 15vh;
      height: 15vh;
    }
  }

  @media only screen and (max-width: ${breakpoints.desktopSmall}) {
    padding-top: ${navbarMinHeight};
    padding-bottom: ${navbarMinHeight};

    .content-navigation {
      display: block;

      .content-navigation__item {
        display: flex;
        justify-content: space-between;
        width: 100%;
        background-color: #FFFFFF;
        margin-bottom: 16px;

        button {
          padding: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: ${colors.titleDark};

          img {
            display: block;
            transform: rotate(90deg);
            transition: transform 0.1s ease-out;
          }

          &.active img {
            transform: rotate(0deg);
          }
        }

        .content-navigation__item-image {
          display: none;
        }

        .content-navigation__item-decoration {
          display: none;
        }
      }
    }

    .content-body {
      transition: max-height 0s;
      overflow: hidden;
      min-height: 0;
      max-height: 0;
      flex-direction: column-reverse;
      padding: 0;

      .content-body__column {
        width: 100%;
      }

      .content-body__header {
        display: none;
      }

      .content-body__image {
        width: 26vw;
        margin: 32px 0 48px;
      }

      .content-body__steps-description {
        max-height: initial;
      }

      &.active {
        padding: 32px;
        max-height: 2000px;
      }
    }
  }
  @media only screen and (max-width: ${breakpoints.tablet}) {
    .content-body {
      .content-body__image {
        width: 45vw;
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.mobile}) {
    .content-body {
      &.active {
        padding: 20px;
      }
    }
  }
`;

export default ProcessOfDeliverySection;
