import React from 'react'
import { SEO } from 'components/Seo'
import { useServicesList } from 'hooks/graphql/others/useServicesList'
import ServicesList from '../../templates/servicesListPage'

const ServicesListPage = () => {
  return <ServicesList />
}

export function Head({ location }) {
  const { seo } = useServicesList()

  return <SEO location={location} {...seo} />
}

export default ServicesListPage
