import styled, { css } from 'styled-components'

import { colors, breakpoints } from '../../../../constants/style'
import { navbarMinHeight } from '../../../features/layout/components/commonLayoutStyle'

import LineDashedSVG from '../../../../static/images/line-dashed.svg'

const backgroundImageStyles = (themeBackgroundImage) => css`
  &::before {
    content: '';
    display: block;
    width: 100%;
    height: calc(100% + 5rem);
    position: absolute;
    left: 0;
    top: 0;
    background: ${themeBackgroundImage};
    background-size: auto 100%;
    transform: translateY(-5rem);
  }
`

const imageDecorationStyles = (position, backgroundBorderColor) => css`
  &::before {
    content: '';
    position: absolute;
    left: ${position.image.before.left};
    top: ${position.image.before.top};
    right: ${position.image.before.right};
    bottom: ${position.image.before.bottom};
    width: 91px;
    height: 91px;
    background-color: ${backgroundBorderColor};
    z-index: -2;
  }
`

const undecoratedSectionStyles = (position) => css`
  align-items: center;

  .navigate-button {
    display: flex;
    justify-content: flex-start;
    margin-top: 3.5rem;
    @media screen and (max-width: ${breakpoints.tablet}) {
      margin-bottom: 5rem;
    }
    @media screen and (max-width: ${breakpoints.mobile}) {
      margin-top: 5rem;
      margin-bottom: 5.25rem;
      display: block;
    }
  }

  .image {
    position: static;
    align-self: unset;
    margin: ${position.image.margin};
    margin-bottom: 3rem;
  }

  .content {
    max-width: 39.9375rem;
    flex: 0 0 50%;
    padding: 0;

    @media screen and (max-width: ${breakpoints.desktop}) {
      max-width: 28rem;
    }

    @media screen and (max-width: ${breakpoints.tablet}) {
      margin: 0 auto;
      max-width: 34.5rem;
    }

    .description {
      @media screen and (max-width: ${breakpoints.tablet}) {
        margin-bottom: 5rem;
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.desktopLarge}) {
    margin: 0;
  }
`

const bulletListStyles = (customBulletListImage) => css`
  li::before {
    content: '';
    display: inline-block;
    margin-left: -2.1rem;
    margin-right: 1.3rem;
    vertical-align: middle;
    ${Boolean(customBulletListImage?.asset)
      ? `background: no-repeat center/contain url(${customBulletListImage.asset.url});
      width: 22px;
      height: 22px;`
      : `width: 7px;
      height: 7px;
      border: 2px solid ${colors.primary};
      border-radius: 50%;`}
  }
`

const StyledParagraphSection = styled('section')`
  background-color: ${({ theme }) => theme.backgroundColor};
  position: relative;

  padding-bottom: ${({ isLast, overlap, removeBottomMargin }) =>
    isLast && !overlap && !removeBottomMargin ? '7rem' : '0 !important'};

  ${({ decorate, theme }) => decorate && backgroundImageStyles(theme.before.background)}

  .container {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: ${({ position }) => position.flexDirection};
    column-gap: 10.375rem;
    .content {
      flex: 1;
    }
    .image {
      flex: 1;
    }
    @media only screen and (max-width: ${breakpoints.desktop}) {
      column-gap: 8.25rem;
    }
    @media only screen and (max-width: ${breakpoints.desktopSmall}) {
      column-gap: 4rem;
    }
    @media only screen and (max-width: ${breakpoints.tablet}) {
      flex-direction: column;
    }
  }

  .content {
    padding-bottom: 3rem;
    position: relative;
    z-index: 1;
    .header {
      margin-bottom: 5rem;
      color: ${({ theme }) => theme.content.header.color};

      .headerColored > p {
        font-size: 2.625rem;
        color: ${({ theme }) => theme.content.header.decorationColor};
      }

      &::before {
        content: '';
        display: block;
        margin-bottom: 3rem;
        width: 50px;
        height: 5px;
        background-color: ${({ theme }) => theme.content.header.before.backgroundColor};
      }
    }

    .description {
      color: ${({ theme }) => theme.content.paragraph.color};
      h3 {
        font-size: 1.5rem;
        line-height: 3rem;
      }
      p {
        font-size: 1.1875rem;
        line-height: 2rem;
        &:not(:last-child) {
          margin-bottom: 1.5rem;
        }
      }

      a {
        color: ${colors.primary};
      }

      ul {
        font-family: Roboto-Regular, sans-serif;
        color: ${colors.muted};
        line-height: 1.67;
        padding: 1rem 0 1rem 2.1rem;
        position: relative;

        ${({ customBulletListImage }) => bulletListStyles(customBulletListImage)}
      }

      li > ul::before {
        content: '';
        height: 80%;
        width: 1px;
        display: block;
        position: absolute;
        top: 1rem;
        left: calc(-2.1rem + 5px);
        background: url(${LineDashedSVG}) repeat left top;
      }
    }
  }

  .image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 1;

    > img,
    .gatsby-image-wrapper {
      width: 100%;
    }

    ${({ decorate, position, backgroundBorderColor }) =>
      decorate && imageDecorationStyles(position, backgroundBorderColor)}
  }

  ${({ decorate, position }) => !decorate && undecoratedSectionStyles(position)}

  @media only screen and (max-width: ${breakpoints.desktopSmall}) {
    padding-top: ${navbarMinHeight};
    padding-bottom: ${navbarMinHeight};
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    display: block;
    padding-bottom: 5rem;
    & + section:not(.paragraph) {
      margin-top: 0;
    }
    .image {
      transform: translateY(0);
    }
  }
`

export default StyledParagraphSection
