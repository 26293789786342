import { useStaticQuery, graphql } from 'gatsby'

const useJobOffersQuery = () => {
  const data = useStaticQuery(graphql`
    {
      allWorkableJob(filter: { state: { eq: "published" } }) {
        nodes {
          id
          title
          location {
            country
            telecommuting
            city
          }
          department
          employment_type
          benefits
          internal {
            content
          }
        }
      }
    }
  `)
  return data.allWorkableJob.nodes
}

export default useJobOffersQuery
