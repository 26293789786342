import { FC } from 'react'
import { Link as GatsbyLink } from 'gatsby'
import classnames from 'classnames'

import { NavItem } from 'hooks/graphql/others'

import styles from './MenuLink.module.scss'

interface MenuLinkProps {
  className?: string
  activeClassName?: string
  item: NavItem
}

export const MenuLink: FC<MenuLinkProps> = props => {
  const { activeClassName, className, item } = props

  if (item.internalUrl) {
    return <GatsbyLink
      className={classnames(styles.link, className)}
      activeClassName={classnames(styles.active, activeClassName)}
      partiallyActive={true}
      to={item.internalUrl}
    >
      {item.icon && <img className={styles.icon} src={item.icon} alt="" />}
      {item.label}
    </GatsbyLink>
  }

  return <a href={item.externalUrl || undefined} target="_blank" rel="noreferrer" className={classnames(styles.link, className)}>{item.label}</a>
}
