import { useState } from 'react';

//Styling
import styled from 'styled-components';
import { breakpoints, colors } from '../../../../constants/style';

//Images
import BackgroundImage from 'gatsby-background-image';
import { isSSR } from '../../../../constants/environment';
import linkIcon from '../../../../static/images/icons/link.svg';
import facebookLogo from '../../../../static/images/logo/logos-facebook.svg';
import linkedInLogo from '../../../../static/images/logo/logos-linkedin.svg';
import twitterLogo from '../../../../static/images/logo/logos-twitter.svg';
import agilePlaybookBackground from '../../../assets/images/agile-playbook-background.png';

//Components
import downloadIcon from '../../../../static/images/icons/download-icon.svg';
import { ButtonWithIcon } from '../../../components/Buttons';
import TooltipWrapper from '../../../components/Tooltip/TooltipWrapper';
import DownloadPdfModal from '../../DownloadPdfModal';
import SocialMediaLink from '../../SocialMediaLink';

const styleForImage = {
  backgroundSize: 'cover',
  backgroundPosition: 'top center',
};

const marginBottom = '10rem';
const letterSpacing = '.01rem';

const StyledHeroForPlaybook = styled('section')`
  padding: 0 !important;

  .background {
    position: fixed;
    z-index: -1;
  }

  .background-image {
    width: 100vw;
    height: 46.5vw;
  }

  .background-transparent {
    position: relative;
    width: 100vw;
    height: 46.5vw;
    box-sizing: content-box;
    border-bottom: ${marginBottom} solid white;
  }

  .patch {
    position: absolute;
    bottom: -${marginBottom};
    transform: translateY(50%);
    background-color: #fff;
    width: 100%;
    height: 0.25rem;
  }

  .text-box {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, ${marginBottom});
    width: 58rem;
    background-color: #fff;
    padding: 5rem;
    text-align: center;

    .button-container {
      display: flex;
      justify-content: center;
      margin: 2rem;
    }

    .pretitle {
      text-transform: uppercase;
      font-family: BioSans-Regular, Roboto-Regular, sans-serif;
      font-size: 1.5rem;
      line-height: 2.25rem;
      letter-spacing: ${letterSpacing};
      color: ${colors.primary};
      margin-bottom: 1rem;
    }

    .title {
      font-size: 2.625rem;
      line-height: 3.5rem;
      letter-spacing: ${letterSpacing};
      color: ${colors.titleDark};
      margin-bottom: 2rem;
    }

    .share-section {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1.5rem;
      flex-wrap: wrap;
    }

    .share-txt {
      font-size: 1.1875rem;
      line-height: 3rem;
      color: ${colors.titleDark};
    }

    .share-links {
      display: flex;
      gap: 1rem;
    }
  }

  @media only screen and (max-width: ${breakpoints.desktop}) {
    .background-image {
      height: 37rem;
    }

    .background-transparent {
      height: 37rem;
    }
  }

  @media only screen and (max-width: ${breakpoints.desktopSmall}) {
    .text-box {
      width: 46.5rem;
      padding: 3.5rem;
    }
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    .background-image {
      height: 32rem;
    }

    .text-box {
      width: 100%;
      padding-left: 2rem;
      padding-right: 2rem;

      .pretitle {
        margin-bottom: 0.75rem;
      }

      .title {
        font-size: 2.375rem;
        line-height: 3.25rem;
      }

      .share-links {
        gap: 0.8rem;
      }
    }
  }

  @media only screen and (max-width: 480px) {
    .background-image {
      height: 25rem;
    }
  }

  @media only screen and (max-width: ${breakpoints.mobile}) {
    .text-box {
      .share-section {
        flex-direction: column;
        gap: 0;
      }
    }
  }
`;

const currentURL = isSSR ? 'https://10clouds.com/' : document.URL;
const cleanURL = currentURL.slice(currentURL.indexOf('//') + 2);

const FILE_URL = 'https://cdn.sanity.io/files/s7xbv9bz/production/2786634e5567eb53ad4fc531f43b4dbcc100c757.pdf';

const HeroForPlaybook = ({ alt, backgroundImage, pretitle, title }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  const shareLinks = [
    {
      to: `https://www.linkedin.com/sharing/share-offsite/?url=${currentURL}`,
      icon: linkedInLogo,
      alt: 'LinkeIn',
    },
    {
      to: `https://twitter.com/intent/tweet?text=${currentURL}`,
      icon: twitterLogo,
      alt: 'Twiter',
    },
    {
      to: `https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2F${cleanURL}%2F&amp;src=sdkpreparse`,
      icon: facebookLogo,
      alt: 'facebook',
    },
    {
      to: currentURL,
      icon: linkIcon,
      alt: 'copyURL',
      actionOnClick: copyToClipboard,
    },
  ];

  const backgroundStack = [...(!backgroundImage ? [`url(${agilePlaybookBackground})`] : [backgroundImage])];

  const imageProps = {
    Tag: 'section',
    fluid: backgroundStack,
    alt,
    style: styleForImage,
  };

  return (
    <StyledHeroForPlaybook {...imageProps} className="hero-for-playbook">
      <div className="background">
        <BackgroundImage {...imageProps} className="background-image" />
      </div>
      <div className="background-transparent">
        <div className="text-box">
          <p className="pretitle">{pretitle}</p>
          <h1 className="title">{title}</h1>
          <div className="share-section">
            <p className="share-txt">Share</p>
            <div className="share-links">
              {shareLinks.map(({ to, icon, alt, actionOnClick }, index) =>
                actionOnClick ? (
                  <TooltipWrapper key={`tooltip-${index}`} content="Copy URL to clipboard">
                    <SocialMediaLink
                      key={index}
                      className="share-button"
                      to={to}
                      img={icon}
                      alt={alt}
                      actionOnClick={() => {
                        actionOnClick(currentURL);
                      }}
                    />
                  </TooltipWrapper>
                ) : (
                  <SocialMediaLink key={index} className="share-button" to={to} img={icon} alt={alt} />
                ),
              )}
            </div>
          </div>
          <div className="button-container">
            <ButtonWithIcon
              iconSrc={downloadIcon}
              iconPosition="right"
              variant="filled"
              onClick={() => {
                setIsModalVisible(true);
              }}
            >
              Download PDF
            </ButtonWithIcon>
          </div>
        </div>
        <div className="patch"></div>
      </div>
      <DownloadPdfModal
        isVisible={isModalVisible}
        handleOnClose={() => {
          setIsModalVisible(false);
        }}
        fileUrl={FILE_URL}
        isPlaybook
      />
    </StyledHeroForPlaybook>
  );
};

export default HeroForPlaybook;
