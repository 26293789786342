import React from 'react';
import styled from 'styled-components';

import SanityBlockContent from '../../SanityBlockContent';

const StyledItemList = styled('div')`
  display: flex;

  .item-list__mark {
    min-width: 3rem;
    height: 3rem;
    margin-right: 2rem;
    text-align: center;
    border-radius: 0.25rem;
    font-family: Roboto-Regular, sans-serif;
    font-size: 1.1875rem;
    line-height: 3rem;
    font-weight: 600;
    color: #3a199e;
    background: #ede7fd;
  }

  p {
    margin-bottom: 0.5rem !important;
    &:last-child {
      margin-bottom: 0rem !important;
    }
  }
`;

const ItemList = ({ blocks, index }) => {
  return (
    <StyledItemList index={index} className="item-list">
      <div className="item-list__mark">{index}</div>
      <SanityBlockContent className="item-list__content" blocks={blocks} />
    </StyledItemList>
  );
};

export default ItemList;
