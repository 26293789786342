import React from 'react'

import Button from '../Buttons'

import IconLoading from '../icons/IconLoading'
import SanityBlockContent from '../SanityBlockContent'
import { useDictionaryQuery } from 'hooks/graphql/page'
import DownloadFormWrapper from '../HeroContactForm/styled'
import Input from '../Form/Input'
import Textarea from '../Form/Textarea'
import Checkbox from '../Form/Checkbox'

const HeroContactForm = ({
  header,
  subheader,
  buttonLabel,
  handleFormSubmit,
  rawDescription,
  isFormSent,
  errorMessages,
  formData,
  handleInputChange,
  isLoading,
  display,
  showProjectDescriptionInput,
}) => {
  const {
    contactForm: { formInputFirstName, formInputLastName, formInputEmail, formInputPhone },
    contactUsForm: { formInputProjectDescription },
    _rawContactForm,
  } = useDictionaryQuery()

  return (
    <DownloadFormWrapper>
      <form onSubmit={handleFormSubmit}>
        {isFormSent ? (
          <>
            <div className="header">We've received your message</div>
            <div className="success">
              <SanityBlockContent blocks={_rawContactForm.thankYouMessage} />
            </div>
          </>
        ) : (
          <>
            <div className="header">{header}</div>
            <div className="subheader">{subheader}</div>
            {rawDescription && (
              <div className="description">
                <SanityBlockContent blocks={rawDescription} />
              </div>
            )}

            <Input
              errorMessage={errorMessages.firstName}
              handleInputChange={handleInputChange}
              label={formInputFirstName}
              value={formData.firstName}
              name="firstName"
            />

            <Input
              errorMessage={errorMessages.lastName}
              handleInputChange={handleInputChange}
              label={formInputLastName}
              value={formData.lastName}
              name="lastName"
            />

            <Input
              errorMessage={errorMessages.email}
              handleInputChange={handleInputChange}
              label={formInputEmail}
              value={formData.email}
              name="email"
            />

            <Input
              errorMessage={errorMessages.phone}
              handleInputChange={handleInputChange}
              label={formInputPhone}
              value={formData.phone}
              name="phone"
            />

            {showProjectDescriptionInput && (
              <Textarea
                errorMessage={errorMessages.projectDescription}
                handleInputChange={handleInputChange}
                label={formInputProjectDescription}
                value={formData.projectDescription}
                name="projectDescription"
              />
            )}
            <div className="form__submit">
              {isLoading ? (
                <div className="loading">
                  <IconLoading />
                </div>
              ) : (
                <Button className="submit-button" variant="filled" type="submit">
                  {buttonLabel}
                </Button>
              )}
            </div>

            <Checkbox
              name="policyAgreement"
              variant="purple"
              isChecked={formData.policyAgreement}
              onChange={handleInputChange}
              errorMessage={errorMessages.policyAgreement}
            >
              <SanityBlockContent blocks={_rawContactForm.checkboxDescription} />
            </Checkbox>

            <div className="form__disclaimer">
              <SanityBlockContent blocks={_rawContactForm.disclaimer} />
            </div>
          </>
        )}
      </form>
    </DownloadFormWrapper>
  )
}

export default HeroContactForm
