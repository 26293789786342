import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { NavLink } from '../../../components/Link';
import Image from '../../../components/Image';
import arrow from '../../../../static/images/icons/arrow.svg';
import {
  blogTitleAnimationStyle,
  blogArrowAnimationStyle,
  cardHorizontalPadding,
  blogCategoryStyle,
  blogCategoryContainerStyle,
} from '../commonStyle';
import { colors, breakpoints } from '../../../../constants/style';
import { parseSlug } from '../../../utils/slug';
import getConvertedGatsbyImgDataToWEBP from '../../../utils/images/getConvertedGatsbyImgDataToWEBP';

export const StyledPostCardsContainer = styled('section')`
  padding-top: 0 !important;
  display: grid;
  gap: 100px 50px;
  grid-template-columns: repeat(3, 1fr);

  @media only screen and (max-width: ${breakpoints.desktopSmall}) {
    grid-template-columns: repeat(2, 1fr);
    gap: 50px 20px;
  }

  @media only screen and (max-width: 600px) {
    padding-bottom: 2rem;
    grid-template-columns: repeat(1, 1fr);
    gap: 44px 0;
  }
`;

const StyledPostCard = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;

  .blog-link-image {
    display: block;
  }

  .blog-card {
    &_image-as-background {
      min-height: 16rem;
      margin-bottom: 2rem;
      height: 100%;
      max-height: 16rem;
    }

    &_content {
      padding-left: ${cardHorizontalPadding};
      padding-right: ${cardHorizontalPadding};
      height: 100%;
    }

    &_blog-link {
      img {
        height: 12px;
        display: block;
      }
    }

    &_categories-list {
      ${blogCategoryContainerStyle}
    }

    &_meta {
      display: flex;
      justify-content: space-between;
      ${blogCategoryStyle};

      ${NavLink} {
        font-family: "BioSans-SemiBold", "Roboto-Medium", sans-serif;
        color: inherit;

        &:hover {
          color: ${colors.secondary};
        }
      }
    }

    &_title {
      font-family: "BioSans-SemiBold", "Bitter-SemiBold", "Bitter-SemiBold", "Roboto-Bold", sans-serif;
      font-size: 1.375rem;
      color: ${colors.titleDark};

      ${blogTitleAnimationStyle.base};
    }

    &_blog-link {
      width: 100%;

      &:hover {
        .blog-card_title {
          ${blogTitleAnimationStyle.hover};
        }

        .blog-card_arrow {
          ${blogArrowAnimationStyle.hover}
        }
      }
    }

    &_arrow {
      margin-top: 1.1875rem;
      ${blogArrowAnimationStyle.base}
    }
  }

  .blog-link-image:hover + .blog-card_content {
    .blog-card_title {
      ${blogTitleAnimationStyle.hover};
    }

    .blog-card_arrow {
      ${blogArrowAnimationStyle.hover}
    }
  }

  @media only screen and (max-width: ${breakpoints.desktopSmall}) {
    .blog-card_image-as-background {
      min-height: 12rem;
      height: 100%;
      max-height: 12rem;
    }

    .blog-card_content {
      padding-left: calc(${cardHorizontalPadding} / 2);
      padding-right: calc(${cardHorizontalPadding} / 2);
    }
  }

  @media only screen and (max-width: ${breakpoints.mobile}) {
    .blog-card_excerpt {
      margin-bottom: 1rem;
    }

    .blog-card_content {
      padding-left: 0;
      padding-right: 0;
    }
  }
`;

const PostCardsContainer = ({ blogPosts, addContainerTopPadding, addCardTopMargin, smallerContainerTopPadding }) => {
  if (!Boolean(blogPosts.length)) {
    return null;
  }

  return (
    <StyledPostCardsContainer addContainerTopPadding={addContainerTopPadding} smallerContainerTopPadding={smallerContainerTopPadding}>
      {blogPosts.map(({ title, featuredImage, categories, _rawBody, author, coAuthor, slug, id }, index) => {
        const blogPostUrl = `/blog/${parseSlug(categories[0].slug.current)}${parseSlug(slug.current)}`;
        return (
          <StyledPostCard key={id} addCardTopMargin={addCardTopMargin} isInSecondRow={index > 2}>
            <Link className="blog-link-image" to={blogPostUrl}>
              <GatsbyImage
                image={getConvertedGatsbyImgDataToWEBP(getImage(featuredImage?.asset))}
                alt={featuredImage?.alt || ''}
                key={featuredImage}
                className="blog-card_image-as-background"
              />
            </Link>
            <div className="blog-card_content">
              <ul className="blog-card_categories-list">
                {categories.map((category) => (
                  <li key={category.title} className="blog-card_meta">
                    {category.title}
                  </li>
                ))}
              </ul>

              <Link to={blogPostUrl} className="blog-card_blog-link">
                <h3 className="blog-card_title">{title}</h3>
                <Image image={arrow} className="blog-card_arrow" />
              </Link>
            </div>
          </StyledPostCard>
        );
      })}
    </StyledPostCardsContainer>
  );
};

export default PostCardsContainer;
