import { graphql, useStaticQuery } from 'gatsby'

const useCaseStudiesItems = () => {
  const data = useStaticQuery(graphql`
    {
      sanityCaseStudyPageList {
        hero {
          ...SanityHeroFragment
        }
        featured {
          slug {
            current
          }
          abstract {
            projectName
            description
            tags

            imageForCaseStudiesList {
              alt
              asset {
                gatsbyImageData(width: 400)
              }
            }
          }
        }
        contactSection {
          header
          buttonLabel
        }
        seo {
          ...SeoFragment
        }
      }
    }
  `)
  return data.sanityCaseStudyPageList.featured
}

export default useCaseStudiesItems
