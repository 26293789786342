import React from 'react';
import { NavLink } from '../../components/Link';
import styled from 'styled-components';
import { colors } from '../../../constants/style';

const StyledNavLink = styled((props) => <NavLink {...props} />)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  border: 1px solid ${colors.primary};
  border-radius: 50%;
  cursor: pointer;

  > span {
    display: flex;
    justify-content: center;
    align-items: center;
    filter: grayscale(1) ${({ darkTheme }) => (!darkTheme ? 'brightness(.5)' : 'brightness(3)')};
    transition: filter 0.2s ease-in-out;

    img {
      height: 1.25rem;
    }
  }

  :hover > span {
    filter: grayscale(0);
  }
`;

const SocialMediaLink = ({ img, alt, to, className, darkTheme, actionOnClick }) => {
  return (
    <StyledNavLink to={to} targetBlank className={className} darkTheme={darkTheme} actionOnClick={actionOnClick}>
      <img src={img} alt={alt} />
    </StyledNavLink>
  );
};

export default SocialMediaLink;
