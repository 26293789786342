import React from 'react';
import styled from 'styled-components';
import { NavLinkButton } from '../../Link';
import { colors, breakpoints } from '../../../../constants/style';
import { navbarMinHeight } from '../../../features/layout/components/commonLayoutStyle';

import WaveSVG from '../../../../static/images/wave-dark-1.svg';

const ShowcaseSection = styled('section')`
  text-align: center;
  position: relative;
  padding-top: 8.125rem;
  padding-bottom: 8.125rem;
  background-image: ${({ theme }) => theme.backgroundImage};

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    background: ${({ theme }) => theme.before.background};
  }

  .showcase__header {
    color: ${colors.sectionTitleLight};
    font-size: 2.3125rem;
    line-height: 1.51;

    &::before {
      content: '';
      display: block;
      margin: 0 auto 2.5rem auto;
      width: 50px;
      height: 5px;
      background: ${colors.secondary};
    }
  }
  .showcase__text-wrapper {
    max-width: 50rem;
    margin: 0 auto;
  }
  .showcase__link {
    display: inline-block;
    margin: 3.125rem auto 0 auto;
  }

  .showcase__videos-wrapper {
    margin: 7.75rem -1rem 0 -1rem;
    display: flex;
    justify-content: center;
  }

  .showcase__video-wrapper {
    height: 0;
    padding-top: 27.25%;
    flex: 1 1 50%;
    margin: 1rem;
    position: relative;
    max-width: 50%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  @media only screen and (max-width: ${breakpoints.desktopSmall}) {
    padding-top: ${navbarMinHeight};
    padding-bottom: ${navbarMinHeight};

    .showcase__videos-wrapper {
      margin-top: 3rem;
    }
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    .showcase__videos-wrapper {
      display: block;
      margin-top: 3rem;
    }

    .showcase__video-wrapper {
      display: block;
      margin: 1rem 0;
      padding-top: 56.25%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
`;

const themes = {
  dark: {
    backgroundImage: `linear-gradient(172deg, ${colors.backgroundGradients.dark.start}, ${colors.backgroundGradients.dark.end});`,
    before: { background: `url(${WaveSVG}) no-repeat left top;` },
  },
  light: {
    backgroundImage: 'none',
    before: { background: 'none' },
  },
};

const Showcase = ({ theme, header, buttonLabel, buttonLink, vimeoMovieIds, youtubeMovieIds }) => {
  return (
    <ShowcaseSection theme={theme ? themes[theme] : themes['dark']}>
      {Boolean(header) && (
        <div className="showcase__text-wrapper">
          <h2 className="showcase__header">{header}</h2>
        </div>
      )}
      {Boolean(buttonLabel && buttonLink) && (
        <NavLinkButton className="showcase__link" to={buttonLink} variant="filled">
          {buttonLabel}
        </NavLinkButton>
      )}
      <div className="showcase__videos-wrapper">
        {Boolean(vimeoMovieIds) &&
          vimeoMovieIds.map((vimeoMovieId, index) => {
            return (
              <div className="showcase__video-wrapper" key={`vimeo-movie-${index}`}>
                <iframe
                  title={`vimeo-movie-${vimeoMovieId}`}
                  loading="lazy"
                  src={`https://player.vimeo.com/video/${vimeoMovieId}?title=0&amp;byline=0&amp;portrait=0`}
                  webkitallowfullscreen=""
                  mozallowfullscreen=""
                  allowFullScreen
                  frameBorder="0"
                />
              </div>
            );
          })}
        {Boolean(youtubeMovieIds) &&
          youtubeMovieIds.map((youtubeMovieId, index) => {
            return (
              <div className="showcase__video-wrapper" key={`youtube-movie-${index}`}>
                <iframe
                  title={`youtube-movie-${youtubeMovieId}`}
                  loading="lazy"
                  src={`https://www.youtube.com/embed/${youtubeMovieId}`}
                  webkitallowfullscreen=""
                  mozallowfullscreen=""
                  allowFullScreen
                  frameBorder="0"
                />
              </div>
            );
          })}
      </div>
    </ShowcaseSection>
  );
};

export default Showcase;
