import React, { useState } from 'react';

import styled from 'styled-components';

import Button from '../../../../Buttons';
import { breakpoints, colors } from '../../../../../../constants/style';

const ReferralCodeWindowStyled = styled('div').attrs({
  className: 'referral-code-window',
})`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  @media screen and (max-width: ${breakpoints.desktop}) {
    max-width: 22rem;
  }
  @media screen and (max-width: ${breakpoints.tablet}) {
    max-width: 29.5rem;
  }
  .referral-code-window {
    &__header {
      margin-bottom: 1.5rem;
      font-size: 1.75rem;
    }
    &__description {
      font-family: Roboto, sans-serif;
      margin-bottom: 1.5rem;
      color: ${colors.purpleGray};
    }
    &__code {
      color: ${colors.primary};
      font-size: 3.25rem;
      margin-bottom: 2.5rem;
    }
    &__copy-code-btn {
      width: 100%;
      &--copied {
        background: #edecf8;
        border: 2px solid #edecf8;
        color: ${colors.titleDark};
        span {
          color: ${colors.titleDark};
        }
        &:hover::before {
          opacity: 0;
        }
      }
    }
    &__new-code-btn {
      margin-top: 1.75rem;
      cursor: pointer;
      color: #4e4d6c;
    }
  }
`;

const copyTextToClipboard = async (text) => {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand('copy', true, text);
  }
};

const ReferralCodeWindow = ({ onClick, referralCode = '' }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = () => {
    copyTextToClipboard(referralCode)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <ReferralCodeWindowStyled>
      <h3 className="referral-code-window__header">Congrats! You’ve just generated your unique referral code.</h3>
      <div className="referral-code-window__description">
        Remember that your friend needs to use this unique code in the application form.
      </div>
      <div className="referral-code-window__code">{referralCode}</div>
      <Button
        className={`referral-code-window__copy-code-btn ${isCopied && 'referral-code-window__copy-code-btn--copied'}`}
        variant="filled"
        onClick={handleCopyClick}
      >
        {isCopied ? 'CODE COPIED!' : 'COPY CODE'}
      </Button>
      <div className="referral-code-window__new-code-btn" onClick={onClick}>
        Generate a new code
      </div>
    </ReferralCodeWindowStyled>
  );
};

export default ReferralCodeWindow;
